import { Box } from "@mui/material";
// import "./AuthorizationTabs.scss";
import { Link, useLocation } from "react-router-dom";
import { useValue } from "../../context/ContextProvider";

function AuthorizationTabs() {
  const { pathname } = useLocation();
  let part = pathname.split("/");
  const { dispatch, state: currentFilter } = useValue();
  //
  const handleClick = (newFilter) => {
    dispatch({
      type: "UPDATE_FILTER",
      payload: newFilter,
    });
  };
  // console.log(currentFilter)

  return (
    <Box boxShadow={"small"} className="project-filter">
      <nav style={{ left: "260px", marginTop: "18px", zIndex: "10", backdropFilter: "blur(10px)", }}>
        <Link to="/manageAuthorization/applications">
          <button
            onClick={() => handleClick("Manage Authorization Applications")}
            className={
              (part[1] === "manageAuthorization" && part.length === 2) ||
              part[2] === "applications"
                ? "active"
                : ""
            }
          >
            Applications
          </button>
        </Link>
        <Link to="/manageAuthorization/roles">
          <button
            onClick={() => handleClick("Manage Authorization Roles")}
            className={part[2] === "roles" ? "active" : ""}
          >
            Permission and Roles
          </button>
        </Link>
        <Link to="/manageAuthorization/users">
          <button
            onClick={() => handleClick("Manage Authorization Users")}
            className={part[2] === "users" ? "active" : ""}
          >
            Users
          </button>
        </Link>
        {/* <Link to="/manageDatasources">
          <button
            onClick={() => handleClick("Manage Datasources")}
            className={part[1] === "manageDatasources" ? "active" : ""}
          >
            Manage Datasources
          </button>
        </Link>
        <Link to="/manageMetadatacrawls">
          <button
            onClick={() => handleClick("Manage MetadataCrawls")}
            className={part[1] === "manageMetadatacrawls" ? "active" : ""}
          >
            Manage MetadataCrawls
          </button>
        </Link>
        <Link to="/manageAuthorization">
          <button
            onClick={() => handleClick("Manage Authorization")}
            className={part[1] === "manageAuthorization" ? "active" : ""}
          >
            Manage Authorization
          </button>
        </Link>
        <Link to="/manageAuditLog">
          <button
            onClick={() => handleClick("Manage Audit Log")}
            className={part[1] === "manageAuditLog" ? "active" : ""}
          >
            Manage Audit Log
          </button>
        </Link> */}
      </nav>
    </Box>
  );
}

export default AuthorizationTabs;
