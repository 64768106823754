import { useState, useEffect, useContext } from "react";
import { Button, Chip, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  Grid,
  DialogTitle,
  TableContainer,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import axios from "axios";
import { userRequestMartbuilder } from "../../requestMethod";
import { AuthContext } from "../../AuthContext";

const PermissionTableEdit = ({
  boolean,
  onToggle,
  row,
  tableType,
  fetchPermissionsData,
}) => {
  // const [tableValue, setTableValue] = useState(tableType);
  // console.log(tableValue)
  const { getToken, token, error } = useContext(AuthContext);

  const [appID, setAppID] = useState(row?.original?.applicationName || "");
  // const [appName, setAppName] = useState();
  const [aFlag, setAFlag] = useState(row?.original?.accessFlag || "");
  const [resourceID, setResourceID] = useState(row?.original?.resources?.map((res)=>res.id) || []);
  const [resourceName, setResourceName] = useState(row?.original?.resources?.map((res)=>res.name)  || []);
  const [operaName, setOperaName] = useState(row?.original?.operations || []);
  const [applicationData, setApplicationData] = useState();
  const [resourceData, setResourceData] = useState();

  const operationList = [
    { value: 1, label: "Create" },
    { value: 2, label: "Update" },
    { value: 3, label: "Delete" },
    { value: 4, label: "Read" },
  ];

  const accsessFlagList = [
    { value: 1, label: "Allow" },
    { value: 2, label: "Deny" },
  ];

    // Fetching values for application dropdown
    const fetchApplicationsData = async () => {
      const ourRequest = axios.CancelToken.source();
      try {
        let response = await userRequestMartbuilder.get(`/aatapplications`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        });
        setApplicationData(response?.data);
        // console.log(response?.data);
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        } else {
          console.error(err);
        }
      }
      return () => {
        ourRequest.cancel();
      };
    };

    const fetchResourcesData = async () => {
      const ourRequest = axios.CancelToken.source();
      try {
        let response = await userRequestMartbuilder.get(
          `/aatapplications/resources`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            CancelToken: ourRequest.token,
          }
        );
        setResourceData(response?.data);
        // console.log(response?.data);
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        } else {
          console.error(err);
        }
      }
      return () => {
        ourRequest.cancel();
      };
    };

  //for submitting the edit response
  const UpdatePermissionData = async (updatedData) => {
    const ourRequest = axios.CancelToken.source();
    console.log("Payload ", updatedData)
    try {
      let response = await userRequestMartbuilder.put(
        `/aatpermissions/${updatedData.id}`,
        updatedData,
        {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      // console.log(response.data);
      fetchPermissionsData();
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    // UpdatePermissionData();
  };

  const [values, setValues] = useState({
    name: row?.original?.name || "", // Set default value to an empty string
    // description: row?.original?.description || "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedValues = { ...values, [name]: value };
    setValues(updatedValues);
  };

  const handleAppChange = (event) => {
    setAppID(event.target.value);
    // setAppName(event.target.name);
  };
  
  const handleAFChange = (event) => {
    setAFlag(event.target.value);
  };
  
  const handleResChange = (event) => {
    // console.log(event)
    const {
      target: { value },
    } = event;
    setResourceName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    // setResourceID(event.target.value)
    event.target.value.map((name)=>{
      resourceData?.map((resource)=>{ 
        // console.log(resource.name, String(name));
        if(resource.name == name){
          if(resource.id !== undefined){
            // console.log(resource.id)
            setResourceID([...resourceID, resource.id])
          }
        }
      })
      // console.log(resourceID);
    })
  };

  const handleOperaChange = (event) => {
    // console.log(event)
    const {
      target: { value },
    } = event;
    setOperaName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  }

  const handleSubmit = async () => {
    if (values.name.trim()) {
      const updatedData = {
        id: row.original.id,
        name: values.name.trim(),
        // description: values.description.trim(),
        accessFlag: aFlag,
        applicationName: appID,
        resources: resourceID,
        operations: operaName,
      };
      try {
        await UpdatePermissionData(updatedData);
        console.log(tableType);
        onToggle();
      } catch (error) {
        // Handle any errors here
        console.error("Error updating data:", error);
      }
    }
  };

  useEffect(() => {
    (async () => await getToken())();
    fetchApplicationsData();
    fetchResourcesData();
  }, [token]);

  return (
    <div>
      <Dialog open={boolean} onClose={() => onToggle(row, tableType)}>
        <DialogContent style={{ width: "600px" }}>
          <Grid container mb={3}>
            <Grid item xs={11}>
              <Typography variant="h6" component="h2">
                Edit Permission Data
              </Typography>
            </Grid>
            <Grid color="text.secondary" item xs={1}>
              <CloseIcon
                onClick={() => onToggle(row, tableType)}
                sx={{
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Name
            </Typography>
          <TextField
            value={values.name}
            onChange={handleChange}
            name="name"
            label="Name"
            margin="dense"
            // fullWidth
            size="small"
            style={{ width: "71.5%" }}
            // disabled
          />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Application
            </Typography>
            <Select
              style={{ width: "71.5%", margin: "4px 0", padding: "0" }}
              labelId="application"
              id="application"
              size="small"
              // multiple
              value={appID}
              onChange={handleAppChange}
              // input={<OutlinedInput id="select-multiple-chip" label="Application" />}
              // renderValue={(selected) => (
              //   <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              //     {selected.map((value) => (
              //       <Chip key={value} label={value} />
              //     ))}
              //   </Box>
              // )}
              // MenuProps={MenuProps}
            >
              {applicationData?.map((appData) => (
                <MenuItem
                  key={appData.id}
                  value={appData.name}
                  name={appData.name}
                  // style={getStyles(name, personName, theme)}
                >
                  {appData.name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Access Flag
            </Typography>
            <Select
              style={{ width: "71.5%", margin: "4px 0", padding: "0" }}
              labelId="accessFlag"
              id="accessFlag"
              size="small"
              // multiple
              value={aFlag}
              onChange={handleAFChange}
              // input={<OutlinedInput id="select-multiple-chip" label="Application" />}
              // renderValue={(selected) => (
              //   <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              //     {selected.map((value) => (
              //       <Chip key={value} label={value} />
              //     ))}
              //   </Box>
              // )}
              // MenuProps={MenuProps}
            >
              {accsessFlagList?.map((af) => (
                <MenuItem
                  key={af.value}
                  value={af.label}
                  name={af.label}
                  // style={getStyles(name, personName, theme)}
                >
                  {af.label}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Resources
            </Typography>
             <Select
              style={{ width: "71.5%", margin: "4px 0", padding: "0" }}
              labelId="resources"
              id="resources"
              size="small"
              multiple
              value={resourceName}
              onChange={handleResChange}
              // input={<OutlinedInput id="select-multiple-chip" label="Application" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              // MenuProps={MenuProps}
            >
              {resourceData?.map((resource) => (
                <MenuItem
                  key={resource.id}
                  value={resource.name}
                  // name={resource.name}
                  // style={getStyles(name, personName, theme)}
                >
                  {resource.name}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Operations
            </Typography>
            {/* <TextField
            margin="dense"
            label="Description"
            name="description"
            value={values.description}
            onChange={handleChange}
            // fullWidth
            style={{ width: "71.5%" }}
            // size="small"
            /> */}
            {/* <div
              style={{ width: "71.5%", marginTop: "8px", marginBottom: "4px" }}
            >
              <Select
                id="operation"
                placeholder={false}
                aria-label="operation"
                isMulti
                options={operationList}
                className="basic-multi-select"
                classNamePrefix="select"
                defaultValue={values.operation}
                hideSelectedOptions={false}
                noOptionsMessage={() => "No options available"}
                onChange={(event) => handlePolicyChange(event, "operations")}
              />
            </div> */}
            <Select
              style={{ width: "71.5%", margin: "4px 0", padding: "0" }}
              labelId="operation"
              id="operation"
              size="small"
              multiple
              value={operaName}
              onChange={handleOperaChange}
              // input={<OutlinedInput id="select-multiple-chip" label="Application" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              // MenuProps={MenuProps}
            >
              {operationList?.map((opera) => (
                <MenuItem
                  key={opera.value}
                  value={opera.label}
                  // name={resource.name}
                  // style={getStyles(name, personName, theme)}
                >
                  {opera.label}
                </MenuItem>
              ))}
            </Select>
          </div>

        </DialogContent>
        <Box sx={{ pb: 2, px: 2 }}>
          <DialogActions>
            <Button variant="contained" onClick={handleSubmit}>
              Update
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
};

export default PermissionTableEdit;
