import React, { useEffect, useState } from "react"
import { Avatar, Box, IconButton, Tooltip } from "@mui/material"
// import UserMenu from "./UserMenu"
import { useMsal } from "@azure/msal-react"
import UserMenu from "../UserMenu"

const UserIcons = () => {
  const [username, setUsername] = useState("")
  const currentUser = {
    name: "",
  }
  const { instance } = useMsal()

  useEffect(() => {
    const currentAccount = instance.getActiveAccount()
    // console.log(currentAccount)
    if (currentAccount) {
      setUsername(currentAccount.name)
    }
  }, [instance])

  const [anchorUserMenu, setAnchorUserMenu] = useState(null)
  return (
    <Box>
      <Tooltip title="Open User Menu">
        <IconButton onClick={(e) => setAnchorUserMenu(e.currentTarget)}>
          <Avatar
            src={currentUser?.photoURL}
            alt={currentUser?.name}
            sx={{ background: "#666f7a", fontSize: "15px" }}
          >
            {username?.split(" ")[0]?.charAt(0).toUpperCase() +
              username?.split(" ")[1]?.charAt(0).toUpperCase()}
          </Avatar>
        </IconButton>
      </Tooltip>
      <UserMenu {...{ anchorUserMenu, setAnchorUserMenu }} />
    </Box>
  )
}

export default UserIcons
