import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  useFormContext,
  Controller,
  useForm,
  FormProvider,
} from "react-hook-form";
import Select from "react-select";
import CircularLoader from "../Atoms/CircularLoader";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select as MUISelect,
  MenuItem,
  TextField,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  ListSubheader,
  InputAdornment,
  Tooltip,
  IconButton,
} from "@mui/material";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { useValue } from "../../context/ContextProvider";
import axios from "axios";
import PostgreSQL from "../../utils/assets/postgre.svg";
import MySQL from "../../utils/assets/mysql.svg";
import MicrosoftSQL from "../../utils/assets/MicrosoftSQL.svg";
import Redshift from "../../utils/assets/Redshift.svg";
import Clickhouse from "../../utils/assets/Clickhouse.svg";
import Oracle from "../../utils/assets/Oracle.svg";
import Dremio from "../../utils/assets/dreamio.svg";
import Azure from "../../utils/assets/Azureblob.svg";
import Snowflake from "../../utils/assets/snowflake.svg";
import AzureDataLake from "../../utils/assets/AzureDataLake.webp";
import SalesForce from "../../utils/assets/Salesforce.png";
import SFTP from "../../utils/assets/SFTC.png";
import { userRequestMartbuilder } from "../../requestMethod";
import ScheduleModal from "./ScheduleModal";
import { East } from "@mui/icons-material";
import ConnectionDetailsModal from "./connectionDetailsModal/ConnectionDetailsModal";
// <<<<<<< HEAD
import "./IngestionForm.css";
// =======
import { AuthContext } from "../../AuthContext";
// >>>>>>> 01c6052b9c8cdc5c8d3eeb06a3dddae811c9ace9

const BasicDetails = () => {
  const {
    state: {
      datasetDetails,
      schedulerInfoBatch,
      sourceConnectionBatch,
      destinationConnectionBatch,
      availableJobId,
      toggleNextButton,
    },
    dispatch,
  } = useValue();
  const [detail, setDetail] = useState(datasetDetails);
  const [openModal, setOpenModal] = useState(false);
  const [schedulers, setSchedulers] = useState([]);
  const [sourceConnectionDetails, setSourceConnectionDetails] = useState();
  const [destinationDetails, setDestinationDetails] = useState();
  const [JobsBySourceAndDestination, setJobsBySourceAndDestination] =
    useState();
  const [singleSchedule, setSingleSchedule] = useState();
  const [availableSchedule, setAvailableSchedule] = useState();
  //below usestate to show/hide the schedule and available jobs
  const [isCdcEnabled, setIsCdcEnabled] = useState(false);
  const [verifyPreRequisites, setVerifyPreRequisites] = useState(false);

  //show/hide the details of source connection
  const [showSourceConnectionDetails, setShowSourceConnectionDetails] =
    useState(false);
  //conditionally sending the prop to modal
  const [connectionDetailsProp, setConnectionDetailsProp] = useState();

  const [loadType, setLoadType] = useState("");
  const handleLoadTypeChange = (event) => {
    setLoadType(event.target.value);
  };

  const [availableJob, setAvailableJob] = useState(null);
  const { control, setValue, register } = useFormContext();
  const { getToken, token, error } = useContext(AuthContext);

  const columnsData = datasetDetails?.dataset?.columns.map((column) => {
    return {
      value: column.id,
      label: column.name,
    };
  });

  const SourceConnection = sourceConnectionDetails?.datasourceType;
  const DestinationConnection = destinationDetails?.datasourceType;
  const isSFTPSource = SourceConnection === "SFTP";
  // console.log(isSFTPSource, "isSFTPSource");
  //   conditionaly handling the images
  let imageSrc = "";
  if (SourceConnection === "PostgreSQL") {
    imageSrc = PostgreSQL;
  } else if (SourceConnection === "MySQL") {
    imageSrc = MySQL;
  } else if (SourceConnection === "MicrosoftSQL") {
    imageSrc = MicrosoftSQL;
  } else if (SourceConnection === "Redshift") {
    imageSrc = Redshift;
  } else if (SourceConnection === "Oracle") {
    imageSrc = Oracle;
  } else if (SourceConnection === "Clickhouse") {
    imageSrc = Clickhouse;
  } else if (SourceConnection === "Dremio") {
    imageSrc = Dremio;
  } else if (
    SourceConnection === "AzureBlobStorage" ||
    SourceConnection === "Azure Blob Storage"
  ) {
    imageSrc = Azure;
  } else if (SourceConnection === "Snowflake") {
    imageSrc = Snowflake;
  } else if (SourceConnection === "AzureDataLake") {
    imageSrc = AzureDataLake;
  } else if (SourceConnection === "Azure Data Lake") {
    imageSrc = AzureDataLake;
  } else if (SourceConnection === "SalesForce") {
    imageSrc = SalesForce;
  } else if (SourceConnection === "SFTP") {
    imageSrc = SFTP;
  }

  let DestinationImage = "";
  if (DestinationConnection === "PostgreSQL") {
    DestinationImage = PostgreSQL;
  } else if (DestinationConnection === "MySQL") {
    DestinationImage = MySQL;
  } else if (DestinationConnection === "MicrosoftSQL") {
    DestinationImage = MicrosoftSQL;
  } else if (DestinationConnection === "Redshift") {
    DestinationImage = Redshift;
  } else if (DestinationConnection === "Oracle") {
    DestinationImage = Oracle;
  } else if (DestinationConnection === "Clickhouse") {
    DestinationImage = Clickhouse;
  } else if (DestinationConnection === "Dremio") {
    DestinationImage = Dremio;
  } else if (
    DestinationConnection === "AzureBlobStorage" ||
    DestinationConnection === "Azure Blob Storage"
  ) {
    DestinationImage = Azure;
  } else if (DestinationConnection === "Snowflake") {
    DestinationImage = Snowflake;
  } else if (DestinationConnection === "AzureDataLake") {
    DestinationImage = AzureDataLake;
  } else if (DestinationConnection === "Azure Data Lake") {
    DestinationImage = AzureDataLake;
  } else if (DestinationConnection === "SalesForce") {
    DestinationImage = SalesForce;
  } else if (DestinationConnection === "SFTP") {
    DestinationImage = SFTP;
  }

  // Search function for dropdown
  const [searchWCText, setSearchWCText] = useState();
  const containsText = (text, searchtext) => {
    return text?.toLowerCase().indexOf(searchtext?.toLowerCase()) > -1;
  };
  let displayedWCOptions = useMemo(
    () =>
      datasetDetails?.dataset?.columns?.filter((option) =>
        containsText(option.name, searchWCText)
      ),
    [searchWCText]
  );

  // select
  const handleChange = (e) => {
    // setSelectedItem(e.target.value);
    dispatch({
      type: "SET_SCHEDULE_SELECT_BATCH_I",
      payload: e.target.value,
    });
  };

  const handleAvailableJob = (e) => {
    const availableJobDetails = e.target.value;
    setAvailableJob(availableJobDetails);
    dispatch({
      type: "SET_AVAILABLE_ID",
      payload: availableJobDetails,
    });
  };

  const handleClearAvailableJob = () => {
    console.log("clear");
    // Clear the value of the "Available Jobs" dropdown by setting it to an empty string
    setValue("availableJob", "");
    dispatch({ type: "SET_AVAILABLE_ID", payload: "" });
    setAvailableJob(null);
  };

  // FETCHING SCHEDULERs
  const fetchAllSchedulers = async () => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(`/schedule`, {
        headers: {
          // Define your custom headers here
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });
      // console.log(response.data);
      setSchedulers(response.data);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    // fetchDataSource();
    return () => {
      ourRequest.cancel();
    };
  };

  // FETCHING SourceDetails
  const fetchSourceDetails = async () => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(
        `/datasources/${sourceConnectionBatch}`,
        {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      setSourceConnectionDetails(response.data);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    // fetchDataSource();
    return () => {
      ourRequest.cancel();
    };
  };

  // FETCHING Destination Details
  const fetchDestinationSourceDetails = async () => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(
        `/datasources/${destinationConnectionBatch}`,
        {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      setDestinationDetails(response.data);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    // fetchDataSource();
    return () => {
      ourRequest.cancel();
    };
  };

  // Fetching Available Jobs
  const fetchAvailableJob = async () => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(
        `/ingestion/batch/source/${sourceConnectionBatch}/destination/${destinationConnectionBatch}`,
        {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      setJobsBySourceAndDestination(response?.data);
      // console.log(response.data, "testing");
      // dispatch({ type: "END_CIRCULAR_LOADING" })
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    // fetchJobs();
    return () => {
      ourRequest.cancel();
    };
  };

  useEffect(() => {
    if (availableJobId) {
      fetchSingleSchedule(availableJobId);
    }
  }, [availableJobId]);

  // useEffect(() => {
  //   console.log("singleSchedule", singleSchedule?.scheduleInfo?.cronSchedule);
  // }, [singleSchedule]);

  //for fetching single schedule
  const fetchSingleSchedule = async () => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(
        `ingestion/batch/${availableJobId}`,
        {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      setSingleSchedule(response?.data?.scheduleInfo?.fqn);
      // dispatch({ type: "END_CIRCULAR_LOADING" })
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  useEffect(() => {
    // (async () => await getToken())();
    let timeoutId;
    const fetchAllSchedulersWithDelay = () => {
      timeoutId = setTimeout(() => {
        fetchAllSchedulers();
      }, 400);
    };
    fetchSourceDetails();
    fetchDestinationSourceDetails();
    fetchAvailableJob();
    fetchAllSchedulersWithDelay();
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    setDetail(datasetDetails?.dataset);
  }, [datasetDetails?.dataset]);

  //function to show and hide the modal
  const handleModal = () => {
    setOpenModal(!openModal);
  };

  const showSourceDetails = (check) => {
    setShowSourceConnectionDetails(!showSourceConnectionDetails);
    if (check) {
      setConnectionDetailsProp(sourceConnectionDetails);
    } else {
      setConnectionDetailsProp(destinationDetails);
    }
  };

  const handleIsCdcEnabledChange = (event) => {
    setIsCdcEnabled(event.target.checked);
    if (!event.target.checked) {
      setVerifyPreRequisites(false);
    }
  };

  const handleVerifyPreRequisitesChange = (event) => {
    setVerifyPreRequisites(!verifyPreRequisites);
    if (!verifyPreRequisites) {
      console.log(verifyPreRequisites, "11verifyPreRequisites");
      dispatch({ type: "SHOW_BATCH_NEXT_BUTTON" });
    } else {
      console.log(verifyPreRequisites, "22verifyPreRequisites");
      dispatch({ type: "HIDE_BATCH_NEXT_BUTTON" });
    }
  };
 

  const sourceConnectionUrls = {
    mysql: "https://debezium.io/documentation/reference/stable/connectors/mysql.html#setting-up-mysql",
    postgresql: "https://debezium.io/documentation/reference/stable/connectors/postgresql.html#setting-up-postgresql", 
  };
   
  const documentationUrl = sourceConnectionUrls[SourceConnection?.toLowerCase()];
  
  // if (datasetDetails === null) {
  //   <CircularLoader />;
  // }
  return (
    <>
      <div style={{ marginTop: "50px", padding: "5px 45px" }}>
        <div
          className="source-form-inner"
          // style={{
          //   display: "flex",
          //   justifyContent: "flex-start",
          //   alignItems: "center",
          //   gap: "12px",
          // }}
        >
          <div style={{}}>
            <p>Source</p>
            <div className="sourceDetails"
              style={{ cursor: "pointer" }}
              onClick={() => showSourceDetails(true)}
            >
              <p style={{ fontSize: "13px" }}>{SourceConnection}</p>
              {sourceConnectionDetails && (
                <img
                  src={imageSrc}
                  alt="Source_Image"
                  className="connectionLogo"
                />
              )}
            </div>
            {/* <h6
              onClick={() => showSourceDetails(true)}
              style={{ cursor: "pointer", color: "lightblue" }}
            >
              View Source Detail
            </h6> */}
          </div>
          <East />
          <div style={{ textAlign: "center" }}>
            <p>Destination </p>
            <div className="sourceDetails"
              style={{ cursor: "pointer" }}
              onClick={() => showSourceDetails(false)}
            >
              <span style={{ fontSize: "13px" }}>{DestinationConnection} </span>
              {destinationDetails && (
                <img
                  src={DestinationImage}
                  alt="Destination_Image"
                  className="connectionLogo"
                />
              )}
            </div>
            {/* <p
              onClick={() => showSourceDetails(false)}
              style={{ cursor: "pointer", color: "lightblue" }}
            >
              View Destination Details
            </p> */}
          </div>
        </div>
        {/* <h3 style={{ marginBottom: "20px" }}></h3> */}
        <div style={{ marginTop: "20px" }}>
          <Controller
            name="batchIngestionJob.isCdcEnabled"
            control={control}
            render={({ field: { onChange, value, ...fieldProps } }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value}
                    onChange={(e) => {
                      handleIsCdcEnabledChange(e);
                      onChange(e.target.checked);
                    }}
                    {...fieldProps}
                  />
                }
                label="Is CDC Enabled"
              />
            )}
          />
          {isCdcEnabled && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={verifyPreRequisites}
                    onChange={handleVerifyPreRequisitesChange}
                  />
                }
                label="Verify Pre-requisites"
              />
              {documentationUrl && (
                <Tooltip arrow placement="right" title="More Info">
                  <a
                    href={documentationUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "inherit" }}
                  >
                    <IconButton>
                      <InfoOutlinedIcon />
                    </IconButton>
                  </a>
                </Tooltip>
              )}
            </>
          )}
        </div>

        {!isCdcEnabled && (
          <>
        <div
          className="available-jobs-form"
          // style={{
          //   display: "flex",
              //   justifyContent: "center",
              //   alignContent: "center",
              // }}
            >
              <FormControl
                size="small"
                variant="outlined"
                fullWidth
                sx={{ pr: 3, minWidth: 160, mt: 3 }}
              >
                <InputLabel id="availableJob">Available Jobs</InputLabel>
                <MUISelect
                  label="Available Jobs"
                  name="availableJobId"
                  onChange={handleAvailableJob}
                  value={availableJobId}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: "28rem",
                      },
                    },
                  }}
                >
                  {JobsBySourceAndDestination &&
                    JobsBySourceAndDestination?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.dagName}
                      </MenuItem>
                    ))}
                </MUISelect>
              </FormControl>

              <Button
                variant="outlined"
                size="small"
                // sx={{ height: "40px" }}
                sx={{
                  color: "#247ba0",
                  borderColor: "#247ba0",
                  fontSize: "11px",
                  height: "30px",
                  position: "relative",
                  top: "25px",
                }}
                color="info"
                onClick={handleClearAvailableJob}
              >
                clear
              </Button>
            </div>

            <div
              className="schedule-div-outer"
              // style={{
              //   marginTop: "0px",
              //   // display: "grid",
              //   // gridTemplateColumns: "1fr 1fr",
              //   // columnGap: "60px",
              //   marginBottom: "20px",
              //   padding: "10px 45px",
              // }}
            >
              {availableJob && availableJob ? (
                <div
                  className="schedule-div-inner"
                  // style={{
                  //   display: "flex",
                  //   justifyContent: "center",
                  //   alignItems: "center",
                  //   marginTop: "2px",
                  //   marginBottom: "15px",
                  // }}
                >
                  <Controller
                    control={control}
                    name="batchIngestionJob.scheduleInfo.id"
                    render={({ field }) => (
                      <FormControl
                        size="small"
                        variant="outlined"
                        fullWidth
                        // sx={{ pr: 2, minWidth: 160 }}
                        sx={{
                          pr: 3,
                          minWidth: 200,
                          mt: 1,
                        }}
                      >
                        <InputLabel id="Schedule">Schedule</InputLabel>
                        <MUISelect
                          // labelId="Schedule"
                          label="Schedule"
                          {...field}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: "28rem",
                              },
                            },
                          }}
                          value={singleSchedule || ""}
                          disabled
                        >
                          {singleSchedule && (
                            <MenuItem value={singleSchedule}>
                              {singleSchedule}
                            </MenuItem>
                          )}
                        </MUISelect>
                      </FormControl>
                    )}
                  />

                  <Button
                    variant="outlined"
                    size="small"
                    // sx={{ height: "40px" }}
                    sx={{
                      color: "#247ba0",
                      borderColor: "#247ba0",
                      fontSize: "11px",
                      marginLeft: "6px",
                    }}
                    disabled={availableJob !== null}
                    color="info"
                    onClick={handleModal}
                  >
                    create
                  </Button>
                </div>
              ) : (
                <div
                  className="schedule-div2-inner"
                  // style={{
                  // display: "flex",
                  // justifyContent: "center",
                  // alignItems: "center",
                  // marginTop: "2px",
                  // marginBottom: "15px",
                  // }}
                >
                  <Controller
                    control={control}
                    name="batchIngestionJob.scheduleInfo.id"
                    render={({ field }) => (
                      <FormControl
                        size="small"
                        variant="outlined"
                        fullWidth
                        sx={{ pr: 2, minWidth: 160 }}
                      >
                        <InputLabel id="Schedule">Schedule</InputLabel>
                        <MUISelect
                          // labelId="Schedule"
                          label="Schedule"
                          {...field}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: "28rem",
                              },
                            },
                          }}
                          // onBlur={() => fetchDataSourceById(field.value)}
                        >
                          {schedulers?.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.fqn}
                            </MenuItem>
                          ))}
                        </MUISelect>
                        {/* {dataSourceView && (
                <span
                  style={{ color: "#0093bb", cursor: "pointer" }}
                  onClick={handleOpen}
                >
                  view connection
                </span>
              )} */}
                      </FormControl>
                    )}
                  />

                  <Button
                    variant="outlined"
                    size="small"
                    // sx={{ height: "40px" }}
                    sx={{
                      color: "#247ba0",
                      borderColor: "#247ba0",
                      fontSize: "11px",
                      marginLeft: "6px",
                    }}
                    color="info"
                    onClick={handleModal}
                  >
                    create
                  </Button>
                </div>
              )}

              {openModal && (
                <Box sx={{ position: "absolute", top: "100%", left: 0 }}>
                  <ScheduleModal
                    boolean={openModal}
                    onOpen={() => setOpenModal(false)}
                    onDataFetch={fetchAllSchedulers}
                  />
                </Box>
              )}
            </div>
          </>
        )}

        <h4>Source Details</h4>
        <div
          className="sourcedet-div"
          // style={{
          // marginTop: "0px",
          // display: "grid",
          // gridTemplateColumns: "1fr 1fr",
          // columnGap: "150px",
          // // height: "115px",
          // }}
        >
          <Controller
            disabled={!verifyPreRequisites  && isCdcEnabled}
            control={control}
            name="batchIngestionTasks[0].srcDatasetName"
            render={({ field }) => (
              <TextField
                // InputLabelProps={{ shrink: true }}
                id="source-dataset-name"
                label="Source dataset"
                variant="outlined"
                size="small"
                // placeholder={detail?.dataset?.name}

                type="text"
                // fullWidth
                // defaultValue={datasetDetails?.dataset?.name}
                sx={{ mb: 0, mt: 2, minWidth: 120 }}
                {...field}
              />
            )}
          />

          <Controller
            control={control}
            name="batchIngestionTasks[0].srcWatermarkColumn"
            disabled={isCdcEnabled}
            render={({ field }) => (
              <FormControl
                size="small"
                variant="outlined"
                sx={{ mb: 0, mt: 2, minWidth: 120 }}
              >
                <InputLabel id="watermark-column">
                  Watermark Column:
                </InputLabel>
                <MUISelect
                  labelId="watermark-column"
                  id="watermark-column"
                  label="watermark-column"
                  {...field}
                  MenuProps={{
                    autoFocus: false,
                    PaperProps: {
                      style: {
                        maxHeight: "28rem", // Adjust the max height as needed
                      },
                    },
                  }}
                >
                  <ListSubheader sx={{ border: "none" }}>
                    <TextField
                      style={{ margin: "0 10px", width: "98%" }}
                      size="small"
                      // Autofocus on textfield
                      autoFocus
                      placeholder="Type to search..."
                      // fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => setSearchWCText(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key !== "Escape") {
                          // Prevents autoselecting item while typing (default Select behaviour)
                          e.stopPropagation();
                        }
                      }}
                    />
                  </ListSubheader>
                  <MenuItem value="">None</MenuItem>
                  {displayedWCOptions !== "" &&
                    displayedWCOptions
                      ?.sort((a, b) =>
                        a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1
                      )
                      .map((item) => (
                        <MenuItem key={item.id} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                  {displayedWCOptions !== "" &&
                    datasetDetails?.dataset?.columns
                      ?.sort((a, b) =>
                        a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1
                      )
                      .map((item) => (
                        <MenuItem key={item.id} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                </MUISelect>

                {/* <Select
                  id="watermark-column"
                  placeholder={false}
                  aria-label="watermark-column"
                  options={columnsData}
                  // isMulti
                  className="basic-multi-select"
                  classNamePrefix="select"
                  {...field}

                  // value={field.value !== null ? field.value : []}
                ></Select> */}

                {/* <Autocomplete
                size="small"
                  disablePortal
                  id="watermark-column"
                  {...field}
                  // value={}
                  options={datasetDetails?.dataset?.columns?.sort((a, b) =>
                    a.name?.toLowerCase() >
                    b.name?.toLowerCase()
                      ? 1
                      : -1
                  )}
                  getOptionLabel={(option) => option.name? option.name : ""}
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      key={option.id}
                      value={option.name}
                      style={{ border: "none" }}
                    >
                      {option.name}
                    </li>
                  )}
                  // sx={{ width: 300 }}
                  // onChange={(event, value) => {
                  //   console.log(value?.name)
                  //   // dispatch({
                  //   //   type: "SET_SOURCE_SELECT_BATCH_I",
                  //   //   payload: value ? value.connectionId : "",
                  //   // });
                  // }}
                  renderInput={(params) => (
                    <TextField {...params} label="Watermark Column" />
                  )}
                /> */}
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="batchIngestionTasks[0].srcPrimaryKeys"
            render={({ field }) => {
              // console.log(field);
              return (
                <FormControl
                  size="small"
                  variant="outlined"
                  sx={{ mb: 0, mt: 2, minWidth: 120, zIndex: 10 }}
                >
                  <label htmlFor="primary-keys">Primary Keys:</label>
                  <Select
                    id="primary-keys"
                    placeholder={false}
                    aria-label="primary-keys"
                    options={columnsData}
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isDisabled={!verifyPreRequisites  && isCdcEnabled}
                    {...field}

                    // value={field.value !== null ? field.value : []}
                  ></Select>
                </FormControl>
              );
            }}
          />
          <Controller
            control={control}
            name="batchIngestionTasks[0].srcOrderByKeys"
            render={({ field }) => {
              // console.log(field);
              return (
                <FormControl
                  size="small"
                  variant="outlined"
                  sx={{ mb: 0, mt: 2, minWidth: 120, zIndex: 10 }}
                >
                  <label htmlFor="primary-keys">Order By Keys:</label>
                  <Select
                    id="primary-keys"
                    placeholder={false}
                    aria-label="primary-keys"
                    options={columnsData}
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isDisabled={!verifyPreRequisites  && isCdcEnabled}
                    {...field}
                    // value={field.value !== null ? field.value : []}
                  ></Select>
                </FormControl>
              );
            }}
          />
          {/* for handling the SFTP case */}
          {isSFTPSource && (
            <div className="" style={{}}>
              {/* <Controller
                control={control}
                name="batchIngestionTasks[0].filterByStartTime"
                render={({ field }) => (
                  <TextField
                    // InputLabelProps={{ shrink: true }}
                    id="filterByStartTime"
                    label="Filter By Start Time"
                    variant="outlined"
                    size="small"
                    placeholder=""
                    type="text"
                    sx={{ mb: 0, mt: 2, width: "100%" }}
                    // fullWidth
                    margin="normal"
                    {...field}
                  />
                )}
              /> */}
              {/* <Controller
                control={control}
                name="batchIngestionTasks[0].filterByEndTime"
                render={({ field }) => (
                  <TextField
                    // InputLabelProps={{ shrink: true }}
                    id="filterByEndTime"
                    label="Filter By End Time"
                    variant="outlined"
                    size="small"
                    placeholder=""
                    type="text"
                    sx={{ mb: 0, mt: 2, width: "100%" }}
                    // fullWidth
                    margin="normal"
                    {...field}
                  />
                )}
              /> */}
            </div>
          )}
          {isSFTPSource && (
            <div className="" style={{}}>
              <Controller
                control={control}
                name="batchIngestionTasks[0].wildcardPath"
                render={({ field }) => (
                  <TextField
                    // InputLabelProps={{ shrink: true }}
                    id="wildcardPath"
                    label="Wildcard Path"
                    variant="outlined"
                    size="small"
                    placeholder=""
                    type="text"
                    sx={{ mb: 0, mt: 2, width: "100%" }}
                    // fullWidth
                    margin="normal"
                    {...field}
                  />
                )}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    {...register("batchIngestionTasks[0].recursively")}
                  />
                }
                label="Recursively"
              />
            </div>
          )}
        </div>
      </div>

      {/* Destination Details */}
      <div
        className="destinationdet-div-outer"
        // style={{
        // marginTop: "30px",
        // // display: "grid",
        // // gridTemplateColumns: "1fr 1fr",
        // // columnGap: "60px",
        // marginBottom: "20px",
        // padding: "5px 45px",
        // }}
      >
        <h4>Destination Details</h4>

        <div
          className="destinationdet-div-inner"
          // style={{
          // marginTop: "0px",
          // display: "grid",
          // gridTemplateColumns: "1fr 1fr",
          // columnGap: "150px",
          // }}
        >
          <Controller
            control={control}
            name="batchIngestionTasks[0].tgtFileFormat"
            render={({ field }) => (
              <FormControl
                size="small"
                variant="outlined"
                sx={{ mb: 0, mt: 2, minWidth: 120 }}
              >
                <InputLabel id="select-fileFormat">File Format</InputLabel>
                <MUISelect
                  labelId="select-fileFormat"
                  id="fileFormat"
                  label="File Format"
                  {...field}
                  disabled={!verifyPreRequisites && isCdcEnabled}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="parquet">parquet</MenuItem>
                  <MenuItem value="delta">delta</MenuItem>
                  <MenuItem value="binaryCopy">Binary Copy</MenuItem>
                </MUISelect>
              </FormControl>
            )}
          />

          <Controller
            control={control}
            name="batchIngestionTasks[0].tgtContainerName"
            render={({ field }) => (
              <TextField
                // InputLabelProps={{ shrink: true }}
                // id="publish-Message"
                label="Container Name"
                variant="outlined"
                size="small"
                placeholder=""
                type="text"
                // fullWidth
                margin="normal"
                {...field}
                disabled={!verifyPreRequisites && isCdcEnabled}
              />
            )}
          />

          <Controller
            control={control}
            name="batchIngestionTasks[0].tgtAccountName"
            render={({ field }) => (
              <TextField
                // InputLabelProps={{ shrink: true }}
                id="account-name"
                label="Account Name"
                variant="outlined"
                size="small"
                placeholder=""
                type="text"
                sx={{ mb: 0, mt: 2 }}
                // fullWidth
                margin="normal"
                {...field}
                disabled={!verifyPreRequisites && isCdcEnabled}
              />
            )}
          />

          <Controller
            control={control}
            name="batchIngestionTasks[0].tgtDirectoryName"
            render={({ field }) => (
              <TextField
                // InputLabelProps={{ shrink: true }}
                id="directory-name"
                label="Directory Name"
                variant="outlined"
                size="small"
                placeholder=""
                type="text"
                sx={{ mb: 0, mt: 2 }}
                // fullWidth
                margin="normal"
                {...field}
                disabled={!verifyPreRequisites && isCdcEnabled}
              />
            )}
          />
          
          {!isSFTPSource && (
          <>
          <Controller
            control={control}
            disabled={!verifyPreRequisites && isCdcEnabled}
            name="batchIngestionTasks[0].loadType"
            render={({ field: { onChange, value, ...fieldProps } }) => (
              <FormControl
                size="small"
                variant="outlined"
                sx={{ mb: 0, mt: 2, minWidth: 120 }}
              >
                <InputLabel id="select-loadType">Load Type</InputLabel>
                <MUISelect
                  labelId="select-loadType"
                  id="loadType"
                  label="Load Type"
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    handleLoadTypeChange(e);
                  }}
                  {...fieldProps}
                >
                   <MenuItem value="">None</MenuItem>
                  <MenuItem value="full-refresh">full-refresh</MenuItem>
                  <MenuItem value="incremental">incremental</MenuItem>
                </MUISelect>
              </FormControl>
            )}
          />
          <Controller
            control={control}
            disabled={!verifyPreRequisites && isCdcEnabled}
            name="batchIngestionTasks[0].ingestionMode"
            render={({ field }) => (
              <FormControl
                size="small"
                variant="outlined"
                sx={{ mb: 0, mt: 2, minWidth: 120 }}
              >
                <InputLabel id="select-targetLoadMethod">
                  Ingestion Mode
                </InputLabel>
                <MUISelect
                  labelId="select-targetLoadMethod"
                  id="targetLoadMethod"
                  label="Ingestion Mode"
                  {...field}
                >
                   <MenuItem value="">None</MenuItem>
                  <MenuItem value="append">append</MenuItem>
                  <MenuItem value="overwrite">overwrite</MenuItem>
                </MUISelect>
              </FormControl>
            )}
          />
          </>
          )}
          {loadType === "incremental" && (
            <FormControlLabel
              control={
                <Checkbox
                  {...register("batchIngestionTasks[0].loadHistoricalData")}
                />
              }
              label="HistoricalFill"
            />
          )}
        </div>
      </div>
      {showSourceConnectionDetails && (
        <Box sx={{ position: "absolute", top: "100%", left: 0 }}>
          <ConnectionDetailsModal
            dataDetails={connectionDetailsProp}
            onOpen={showSourceDetails}
            boolean={showSourceConnectionDetails}
          />
        </Box>
      )}
    </>
  );
};

export default BasicDetails;
