import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { MARTBUILDER_API } from "../../requestMethod";

const manageProductsApi = createApi({
  reducerPath: "manageProducts",
  baseQuery: fetchBaseQuery({
    baseUrl: MARTBUILDER_API,
  }),
  endpoints(builder) {
    return {
      fetchProductsData: builder.query({
        providesTags: ["Products"],
        query: () => {
          return {
            url: "/product",
            method: "GET",
          };
        },
      }),
      AddProductsData: builder.mutation({
        invalidatesTags: ["Products"],
        query: (data) => {
          return {
            url: `/product`, // Corrected the URL to match the 'todos' endpoint
            body: { ...data },
            method: "POST",
          };
        },
      }),
      RemoveProductsData: builder.mutation({
        invalidatesTags: ["Products"],
        query: (dataId) => {
          // Changed to use dynamic parameter 'dataId'
          return {
            url: `/product/${dataId}`, // Corrected the URL to use dynamic parameter 'dataId'
            method: "DELETE",
          };
        },
      }),
      UpdateProductsData: builder.mutation({
        invalidatesTags: ["Products"],
        query: (updatedData) => {
          return {
            url: `/product/${updatedData.id}`,
            body: { ...updatedData },
            method: "PUT",
          };
        },
      }),
    };
  },
});

export const {
  useFetchProductsDataQuery,
  useAddProductsDataMutation,
  useRemoveProductsDataMutation,
  useUpdateProductsDataMutation,
} = manageProductsApi;

export { manageProductsApi };
