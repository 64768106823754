import React, { Suspense } from "react";
import Navbar from "./components/Navbar";
import DashBoard from "./pages/dashboard/DashBoard";
import { BrowserRouter } from "react-router-dom";
import styled from "styled-components";

import "./App.css";
import { Provider } from "react-redux";
import { store } from "./store";

import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { AuthProvider } from "./AuthContext";
import { UserScopeProvider } from "./UserScopeContext";
import SignIn from "./components/SignIn";
import Breadcrumbs from "./components/Atoms/Breadcrumbs";
import SideNavigationBar from "./components/sideNavigationBar/SideNavigationBar";

const Block = styled.div`
  margin-top: 64px;
  /* background-color: #fff; */
  background-color: #f8f9fa;
  min-height: 95vh;
  height: 100%;
  /* padding-top: 64px; */
  /* padding-top: 20px; */
  /* background-color: #f4f7f8; */
  /* height: 100vh; */
`;

function App({ instance }) {
  return (
    <BrowserRouter>
      <MsalProvider instance={instance}>
        <Suspense fallback={<div>Loading.....</div>}>
          {/* <UnauthenticatedTemplate> */}
          {/* <SignIn /> */}
          {/* </UnauthenticatedTemplate> */}
          <AuthenticatedTemplate>
            <Provider store={store}>
              <AuthProvider>
                {/* <UserScopeProvider> */}
                <SideNavigationBar/>
                <Block>
                  <Navbar />
                  <Breadcrumbs />
                  <DashBoard />
                </Block>
                {/* </UserScopeProvider> */}
              </AuthProvider>
            </Provider>
          </AuthenticatedTemplate>
        </Suspense>
      </MsalProvider>
    </BrowserRouter>
  );
}

export default App;
