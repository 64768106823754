import axios from "axios";
import React, { useEffect, useState,useContext } from "react";
import { useLocation  } from "react-router-dom";
import { userRequestMartbuilder } from "../../requestMethod";
import { useValue } from "../../context/ContextProvider";
import BackdropLoader from "../../components/Atoms/BackdropLoader";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Box } from "@mui/material";
import  "./JobView.css";
import BatchTaskView from "../../components/jobViewTask/BatchTaskView";
import {AuthContext} from "../../AuthContext"


export const Name = styled.p`
  width: 135px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.8px;
  text-align: right;
`;

export const Aligner = styled.div`
  display: flex;
  justify-content: start;
  align-items: flex-start;
  gap: 8px;
  padding-top: 8px;
`;

export const Value = styled.div`
  border: 0.4px solid gray;
  padding: 5px;
  border-radius: 4px;
  width: 350px;
  min-height: 25px;
  overflow: auto;
  font-size: 13.5px;
`;

export const Container = styled.div`
  padding: 15px;
  padding-inline: 6%;
  display: flex;
  flex-direction: column;
  height: 85vh;
  overflow-y: auto;
`;

export const Title = styled.h3`
  font-size: 20px;
  color: #d7d7d7;
  letter-spacing: 0.5px;
  font-weight: 700;
  margin-left: 11px;
`;

export const ChartsDiv = styled.div`
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0px;
  margin-bottom: 100px;
`;

export const MetricsDiv = styled.div`
  height: 85vh;
  /* margin-left: 10px; */
  padding-left: 45px;
  width: 645px;
  margin: 10px;
  display: flex;
  /* margin-top: 120px; */
  margin-bottom: 100px;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
`;

export const Divider = styled.div`
  height: 160px;
  width: 1px;
  border: none;
  background: linear-gradient(
    180deg,
    rgba(217, 25, 32, 1) 0%,
    rgba(217, 25, 32, 0.8786108193277311) 48%,
    rgba(217, 25, 66, 0.19793855042016806) 100%
  );
`;

export const Heading = styled.p`
  font-size: 14px;
  letter-spacing: 1px;
  padding: 5px;
  color: #cfcfcf;
  margin-bottom: 3px;
`;

export const Chips = styled.li`
  width: fit-content;
  background: #e8e8e8;
  font-weight: 300;
  font-size: 12px;
  color: #6b6b6b;
  padding: 1.5px 11px;
  border: 0.4px solid #6b6b6b;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 5px;
`;

const BatchJobsView = () => {
  const location = useLocation();
  const [jobDetails, setjobDetails] = useState(null);
  const [data2, setData2] = useState(null);


  const params = useParams();
  // console.log(params.jobId)
  //const id = location.state; //params.jobId;
  //const id = params.jobId; //params.jobId;
  // console.log("ID IS ---->", id);


 
  const { getToken, token, error } = useContext(AuthContext);

 // console.log("Id is --------------->", id);  // commenting now
  //const newid = `2114fa038bde0947`;
  const {
    dispatch,
    state: { backdropLoading,batchIngestionEditJobId },
  } = useValue();
  const id = batchIngestionEditJobId;
  //console.log("ID IS ---->", id);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [handleRerendering, setHandleRerendering] = useState(false);

  const deleteTrigger = ()=>{
    setHandleRerendering(!handleRerendering);
  }

  useEffect(()=>{ 
  },[handleRerendering])

  useEffect(() => {
    async function fetchData1() {
    //console.log("Id inside first api call is ==>", id);
    const ourRequest = axios.CancelToken.source();
    dispatch({ type: "START_BACKDROP_LOADING" });
    const fetchPendingApprovalJobs = async () => {
      try {
        let response = await userRequestMartbuilder.get(`ingestion/batch/${id}`, {
          headers: { 
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        });
        setjobDetails(response.data);
        // setJobDetails(response.data);
        dispatch({ type: "SET_JOB_DETAILS", payload: response.data });
        dispatch({ type: "END_BACKDROP_LOADING" });
       // console.log("first api Response is ====>",response.data);   // commenting now
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        } else {
          console.error(err);
        }
      }
    };
    fetchPendingApprovalJobs();
    return () => {
      ourRequest.cancel();
    };
    }
    // making second api call 
    async function fetchData2() {
    const SecondRequest = axios.CancelToken.source();
    dispatch({ type: "START_BACKDROP_LOADING" });
    const fetchJobTasks = async () => {
      //console.log("Id inside second api call is ==>", id);  // commenting now
      try {
        let response = await userRequestMartbuilder.get(`ingestion/batch/${id}/tasks/`, {
          headers: { 
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: SecondRequest.token,
        });
        setData2(response.data);
        // setJobDetails(response.data);
        dispatch({ type: "SET_JOB_DETAILS", payload: response.data });
        dispatch({ type: "END_BACKDROP_LOADING" });
        //console.log("Tasks Response is ====>",response.data);  // commenting now
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        } else {
          console.error(err);
        }
      }
    };
    fetchJobTasks();
    return () => {
      SecondRequest.cancel();
    };
  }
   
  fetchData1();
  fetchData2();
  }, [id, handleRerendering]);
  
  
  //  console.log("Data from second api call is ==>",data2);  // commenting now
 

  if (backdropLoading) {
    return <BackdropLoader />;
  }

   if (jobDetails && data2) {
  return (
    <Container style={{paddingInline:"1%", height:"auto"}}>
      <div>
        {/* <JobViewDetails fqn={fqn} /> */}
        <div
          style={{
            width: "100%",
            height: "120%",
            margin: "10px",
            marginTop:"30px",
            marginBottom:"30px",
            padding: "10px",
            border: "1.5px solid #eaeaea",
            borderRadius: "4px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Title id="details">Details</Title>
           {/*  <Box className="button-box">
              <Link to={`/jobView/${id}/edit`}>
                <button disabled className="edit-button">Edit</button>
              </Link>
            </Box> */}
          </div>
          <div
            id = "above box"
            style={{
              // paddingInline: "15px",
              // paddingTop: "15px",

              display: "flex",
              gap: "25px",
              // gridTemplateColumns: "1fr 1fr",
              marginBottom: "0px",
              // height: "90vh",
              marginTop: "10px",
              marginLeft: "20px",
              scrollbarWidth: "thin",
            }}
           >
            <Box style={{ width: "100%" }} id="new id">
              
              <div
                style={{
                  margin: "5px",
                  color: "#247ba0",
                  // paddingBottom: "5px",
                  fontWeight: "600",
                }}
               >
                <span>Source Connection</span>
                {/* <hr style={{ width: "550px", marginBottom: "10px" }} /> */}
               </div>

               <Aligner>
                <Name>Connection ID : </Name>
                <Value>
                  <span>{jobDetails?.sourceConnection?.connectionId}</span>
                </Value>
               </Aligner>
              
               <Aligner>
                <Name>Connection Fqn : </Name>
                <Value>
                  <span>{jobDetails?.sourceConnection?.connectionFqn}</span>
                </Value>
               </Aligner>
              
                <Aligner>
                  <Name>Connection Name : </Name>
                  <Value>
                    <span>{jobDetails?.sourceConnection?.connectionName}</span>
                  </Value>
                </Aligner>

                <Aligner>
                  <Name>Datasource Type : </Name>
                  <Value>
                    <span>{jobDetails?.sourceConnection?.datasourceType}</span>
                  </Value>
                </Aligner>

                <Aligner>
                  <Name>Connection Creds : </Name>
                  <Value>
                    <span>{jobDetails?.sourceConnection?.connectionCreds}</span>
                  </Value>
                </Aligner>


                <Aligner>
                  <Name>Vault Key : </Name>
                  <Value>
                    <span>{jobDetails?.sourceConnection?.vaultKey}</span>
                  </Value>
                </Aligner>
 


           






           



                {/*   SCHEDULE */}
                <div
                  style={{
                    margin: "5px",
                    color: "#247ba0",
                    // paddingBottom: "5px",
                    marginTop: "35px",
                    fontWeight: "600",
                  }}
                >
                  <span>Schedule :</span>
                  {/* <hr style={{ width: "550px", marginBottom: "10px" }} /> */}
                </div>

                <Aligner>
                  <Name>Schedule ID : </Name>
                  <Value>
                    <span>{jobDetails?.scheduleInfo?.id}</span>
                  </Value>
                </Aligner>

                <Aligner>
                  <Name>FQN : </Name>
                  <Value>
                    <span>{jobDetails?.scheduleInfo?.fqn} Minutes</span>
                  </Value>
                </Aligner>


                <Aligner>
                  <Name> Profile Name : </Name>
                  <Value>
                  <span>{jobDetails?.scheduleInfo?.profileName}</span>
                  </Value>
                </Aligner>

                <Aligner>
                  <Name> Start Date : </Name>
                  <Value>
                  <span>{jobDetails?.scheduleInfo?.startDate}</span>
                  </Value>
                </Aligner>

                <Aligner>
                  <Name> End Date : </Name>
                  <Value>
                  <span>{jobDetails?.scheduleInfo?.endDate}</span>
                  </Value>
                </Aligner>

                <Aligner>
                  <Name> Back Fill : </Name>
                  <Value>
                  <span>{jobDetails?.scheduleInfo?.backFill}</span>
                  </Value>
                </Aligner>

                <Aligner>
                  <Name>  Time Zone : </Name>
                  <Value>
                  <span>{jobDetails?.scheduleInfo?.timezone}</span>
                  </Value>
                </Aligner>

                <Aligner>
                  <Name> Cron Schedule : </Name>
                  <Value>
                  <span>{jobDetails?.scheduleInfo?.cronSchedule}</span>
                  </Value>
                </Aligner>

                <Aligner>
                  <Name> SLA : </Name>
                  <Value>
                  <span>{jobDetails?.scheduleInfo?.sla}</span>
                  </Value>
                </Aligner>
                
                <Aligner>
                  <Name> Retry Count : </Name>
                  <Value>
                  <span>{jobDetails?.scheduleInfo?.retryCount}</span>
                  </Value>
                </Aligner>

                <Aligner>
                  <Name> Retry Interval : </Name>
                  <Value>
                  <span>{jobDetails?.scheduleInfo?.retryInterval}</span>
                  </Value>
                </Aligner>

                <Aligner>
                  <Name>Publish Message: </Name>
                  <Value>
                    <span>{jobDetails?.scheduleInfo?.publishMessage}</span>
                  </Value>
                </Aligner>



                <Aligner>
                  <Name>Success Email : </Name>
                  <Value>
                    <span>{jobDetails?.scheduleInfo?.notificationSuccessEmail}</span>
                  </Value>
                </Aligner>
                <Aligner>
                  <Name>Failed Email : </Name>
                  <Value>
                    <span>{jobDetails?.scheduleInfo?.notificationFailureEmail}</span>
                  </Value>
                </Aligner>


                <Aligner>
                  <Name>Missed SLA Email : </Name>
                  <Value>
                    <span>{jobDetails?.scheduleInfo?.notificationMissedSLAEmail}</span>
                  </Value>
                </Aligner>

              {/* <Box> */}
              {/* {toggleEditBtn ? (
                <Box className="button-box">
                  <button className="edit-button" onClick={updateData}>
                    Save
                  </button>
                  <button className="edit-button" onClick={toggleEdit}>
                    Cancel
                  </button>
                </Box>
              ) : ( */}

              {/* )} */}
              {/* </Box> */}
              </Box>

              

              <Box style={{ width: "100%" }} id="new id">
              
              <div
                style={{
                  margin: "5px",
                  color: "#247ba0",
                  // paddingBottom: "5px",
                  fontWeight: "600",
                }}
               >
                  <span>Target Connection : </span>
                  {/* <hr
                  style={{
                    width: "550px",
                    marginBottom: "10px",
                  }}
                /> */}
                </div>

                <Aligner>
                  <Name>Connection Id : </Name>
                  <Value>
                    <span>{jobDetails?.targetConnection?.connectionId}</span>
                  </Value>
                </Aligner>
                
                <Aligner>
                  <Name>Connection Fqn : </Name>
                  <Value>
                    <span>{jobDetails?.targetConnection?.connectionFqn}</span>
                  </Value>
                </Aligner>


                <Aligner>
                  <Name>Connection Name : </Name>
                  <Value>
                    <span>{jobDetails?.targetConnection?.connectionName}</span>
                  </Value>
                </Aligner>

                <Aligner>
                  <Name>Datasource Type : </Name>
                  <Value>
                    <span>{jobDetails?.targetConnection?.datasourceType}</span>
                  </Value>
                </Aligner>


                <Aligner>
                  <Name>Connection Creds : </Name>
                  <Value>
                    <span>{jobDetails?.targetConnection?.connectionCreds}</span>
                  </Value>
                </Aligner>


                <Aligner>
                  <Name>Vault Key</Name>
                  <Value>
                    <span>{jobDetails?.targetConnection?.vaultKey}</span>
                  </Value>
                </Aligner>

                


                  {/*   PRODUCT */} 

              <div
                  style={{
                    margin: "5px",
                    color: "#247ba0",
                    // paddingBottom: "5px",
                    fontWeight: "600",
                    marginTop:"30px",
                  }}
                >
                  <span>Product details :</span>
                  {/* <hr style={{ width: "550px", marginBottom: "10px" }} /> */}
                </div>  
                <Aligner>
                  <Name> Product Name : </Name>
                  <Value>
                    <span>{jobDetails?.sourceConnection?.product?.name}</span>
                  </Value>
                </Aligner>

                <Aligner>
                  <Name>Domain Name : </Name>
                  <Value>
                    <span>{jobDetails?.sourceConnection?.product?.domain?.name}</span>
                  </Value>
                </Aligner>

                <Aligner>
                  <Name>Business : </Name>
                  <Value>
                    <span>{jobDetails?.sourceConnection?.product?.business?.name}</span>
                  </Value>
                </Aligner>

                <Aligner>
                  <Name>Org Model : </Name>
                  <Value>
                    <span>{jobDetails?.sourceConnection?.product?.orgModel?.name}</span>
                  </Value>
                </Aligner>





                
           {/*      <div style = {{marginTop:"1rem"}}>
                <div
                style={{
                  margin: "5px",
                  marginLeft:"5px",
                  marginBottom:"10px",
                  color: "#247ba0",
                  fontWeight: "600",
                  marginTop:"30px",
                }}
              >
                <span>Source Target Mapping </span>
              </div>

               <BatchsourceTarget data={data2[0]?.sourceTargetColumnMapping} />  
                </div> */}
              
              </Box>
            {/*  <BatchTaskView details={data2} /> commented tasks temp */} 
            {/*  {jobDetails && <JobViewTasks details={jobDetails} />} */}
           </div>
           <BatchTaskView details={data2} deleteTrigger={deleteTrigger} />
         </div>
      </div>
    </Container>
  );
} else {
  return null; 
};

}
export default BatchJobsView;
