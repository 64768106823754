import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  Grid,
  DialogTitle,
  TableContainer,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import "./ConnectionDetailsModal.css";

function ConnectionDetailsModal({ dataDetails, boolean, onOpen }) {
  // console.log(dataDetails?.datasourceType);
  const [data, setData] = useState();
  const [parsedData, setParsedData] = useState();

  useEffect(() => {
    if (dataDetails) {
      console.log(dataDetails);
      setData(dataDetails);
    }
  }, [dataDetails]);

  // Handle parsedData changes
  useEffect(() => {
    if (data && data.connectionCreds !== "") {
      try {
        setParsedData(JSON.parse(data.connectionCreds));
      } catch (error) {
        // console.error("Error parsing connectionCreds:", error);
        alert("Target connection is not selected or Connection Cred is empty");
      }
    } else if (data?.connectionCreds === "") {
      alert("Target connection is not selected or Connection Cred is empty");
    }
  }, [data]);

  return (
    <div style={{}}>
      {parsedData && (
        <Dialog open={boolean} onClose={onOpen}>
          <DialogContent style={{ width: "600px" }}>
            <Grid container mb={3}>
              <Grid item xs={11}>
                <Typography variant="h6" component="h2">
                  Connection Details
                </Typography>
                <div style={{ margin: "35px" }}>
                  {data && (
                    <div style={{ marginTop: "30px" }}>
                      <div>
                        <label htmlFor="">Connection Name </label>
                        <br />
                        <input
                          className="inputField"
                          type="text"
                          placeholder={data.connectionName}
                          disabled
                        />
                      </div>
                      <div>
                        <label htmlFor="">Connection Fqn </label>
                        <br />
                        <input
                          className="inputField"
                          type="text"
                          placeholder={data.connectionFqn}
                          disabled
                        />
                      </div>
                    </div>
                  )}
                  {data &&
                    parsedData &&
                    (data?.datasourceType === "PostgreSQL" ||
                      data?.datasourceType === "MySQL" ||
                      data?.datasourceType === "MicrosoftSQL" ||
                      data?.datasourceType === "Redshift" ||
                      data?.datasourceType === "Clickhouse" ||
                      data?.datasourceType === "Oracle" ||
                      data?.datasourceType === "Dremio") && (
                      <div style={{}}>
                        <div>
                          <label htmlFor="">Host</label>
                          <br />
                          <input
                            className="inputField"
                            type="text"
                            placeholder={parsedData?.host}
                            disabled
                          />
                        </div>
                        <div>
                          <label htmlFor="">Port</label>
                          <br />
                          <input
                            className="inputField"
                            type="text"
                            placeholder={parsedData?.port}
                            disabled
                          />
                        </div>
                        <div>
                          <label htmlFor="">Username</label>
                          <br />
                          <input
                            className="inputField"
                            type="text"
                            placeholder={parsedData?.username}
                            disabled
                          />
                        </div>
                      </div>
                    )}
                  {data &&
                    parsedData &&
                    data.datasourceType === "SalesForce" && (
                      <div style={{}}>
                        <div>
                          <label htmlFor="">SF Username</label>
                          <br />
                          <input
                            className="inputField"
                            type="text"
                            placeholder={parsedData?.sf_username}
                            disabled
                          />
                        </div>
                        <div>
                          <label htmlFor="">SFDC Security Token</label>
                          <br />
                          <input
                            className="inputField"
                            type="text"
                            placeholder={parsedData?.sfdc_security_token}
                            disabled
                          />
                        </div>
                      </div>
                    )}
                  {data &&
                    parsedData &&
                    data.datasourceType === "Snowflake" && (
                      <div style={{}}>
                        <div>
                          <label htmlFor="">Account Name</label>
                          <br />
                          <input
                            className="inputField"
                            type="text"
                            placeholder={parsedData?.accountName}
                            disabled
                          />
                        </div>
                        <div>
                          <label htmlFor="">Role</label>
                          <br />
                          <input
                            className="inputField"
                            type="text"
                            placeholder={parsedData?.role}
                            disabled
                          />
                        </div>
                        <div>
                          <label htmlFor="">Warehouse</label>
                          <br />
                          <input
                            className="inputField"
                            type="text"
                            placeholder={parsedData?.warehouse}
                            disabled
                          />
                        </div>
                        <div>
                          <label htmlFor="">Database</label>
                          <br />
                          <input
                            className="inputField"
                            type="text"
                            placeholder={parsedData?.database}
                            disabled
                          />
                        </div>
                        <div>
                          <label htmlFor="">Username</label>
                          <br />
                          <input
                            className="inputField"
                            type="text"
                            placeholder={parsedData?.username}
                            disabled
                          />
                        </div>
                      </div>
                    )}
                  {data &&
                    parsedData &&
                    data.datasourceType === "AzureDataLake" && (
                      <div style={{}}>
                        <div>
                          <label htmlFor="">Server</label>
                          <br />
                          <input
                            className="inputField"
                            type="text"
                            placeholder={parsedData?.server}
                            disabled
                          />
                        </div>
                        <div>
                          <label htmlFor="">Client Id</label>
                          <br />
                          <input
                            className="inputField"
                            type="text"
                            placeholder={parsedData?.client_id}
                            disabled
                          />
                        </div>
                        <div>
                          <label htmlFor="">Username</label>
                          <br />
                          <input
                            className="inputField"
                            type="text"
                            placeholder={parsedData?.username}
                            disabled
                          />
                        </div>

                        <div>
                          <label htmlFor="">Database</label>
                          <br />
                          <input
                            className="inputField"
                            type="text"
                            placeholder={parsedData?.database}
                            disabled
                          />
                        </div>
                      </div>
                    )}
                  {data &&
                    parsedData &&
                    data.datasourceType === "AzureBlobStorage" && (
                      <div style={{}}>
                        <div>
                          <label htmlFor="">Account Key</label>
                          <br />
                          <input
                            className="inputField"
                            type="text"
                            placeholder={parsedData?.accountKey}
                            disabled
                          />
                        </div>
                        <div>
                          <label htmlFor="">Azure AccountName</label>
                          <br />
                          <input
                            className="inputField"
                            type="text"
                            placeholder={parsedData?.azureAccountName}
                            disabled
                          />
                        </div>

                        <div>
                          <label htmlFor="">Storage ContainerName</label>
                          <br />
                          <input
                            className="inputField"
                            type="text"
                            placeholder={parsedData?.StorageContainerName}
                            disabled
                          />
                        </div>
                      </div>
                    )}
                </div>
              </Grid>
              <Grid color="text.secondary" item xs={1}>
                <CloseIcon
                  onClick={onOpen}
                  sx={{
                    cursor: "pointer",
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

export default ConnectionDetailsModal;
