import { useState, useContext, useEffect } from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import { TextField } from "@mui/material"
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material"

import CloseIcon from "@mui/icons-material/Close"
import {
  Dialog,
  Grid,
  DialogTitle,
  TableContainer,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material"
import axios from "axios"
import { userRequestMartbuilder } from "../../../requestMethod"
import { AuthContext } from "../../../AuthContext"
import { useValue } from "../../../context/ContextProvider"

function TransformationModal({boolean, onOpen, onSubmit, generatePayload, usePayload,}) {
  const [transExpVal, setTransExpVal] = useState()
  const [transExpValName, setTransExpValName] = useState(false)
  // Add a state to track form validity
  const [isFormValid, setIsFormValid] = useState(false)

  const [textFieldValues, setTextFieldValues] = useState([])
  const {
    state: { transformationCatalog },
    dispatch,
  } = useValue()
  // Initialize the state
  const [selectedTransformation, setSelectedTransformation] = useState("")

  //token for new axios
  const { getToken, token, error } = useContext(AuthContext)

  const fetchTransExpVal = async () => {
    const ourRequest = axios.CancelToken.source()
    try {
      let response = await userRequestMartbuilder.get(
        `/transformationCatalog`,
        {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      )
      setTransExpVal(response?.data)
      // console.log(response?.data)
    } catch (err) {
      if (axios.isCancel(err)) {
        return
      } else {
        console.error(err)
      }
    }
  }

  const fetchIndTransExpVal = async (transformationExpression) => {
    const ourRequest = axios.CancelToken.source()
    try {
      let response = await userRequestMartbuilder.get(
        `/transformationCatalog/${transformationExpression}`,
        {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      )
      setTransExpValName(response?.data.transformationArguments)
      // console.log(response?.data.transformationArguments)
    } catch (err) {
      if (axios.isCancel(err)) {
        return
      } else {
        console.error(err)
      }
    }
  }


  useEffect(() => {
    fetchTransExpVal()
    if (transExpValName) {
      const initialTextFields = transExpValName.map(() => "")
      // console.log("k")
      setTextFieldValues(initialTextFields)
    }
  }, [transExpValName])

  useEffect(() => {
    // Update form validity whenever textFieldValues change
    const allFieldsFilled = textFieldValues.every((fieldValue) => {
      const value = fieldValue.split(":")[1] || "";
      return value.trim() !== "";
    });
    setIsFormValid(allFieldsFilled);
  }, [textFieldValues]); 

  const [showEmptyFieldMessage, setShowEmptyFieldMessage] = useState(false)
  const [values, setValues] = useState({
    name: "",
    description: "",
  })

  const getTextFieldType = (selectedValue, transformationArgument) => {
    if (
      selectedValue === "to_date" &&
      transformationArgument === "someCondition"
    ) {
      return "date"
    }
    return "text"
  }


  const handleSubmit = () => {
    console.log(selectedTransformation, "selectedTransformation")
    console.log(textFieldValues, "textFieldValues")

    if (selectedTransformation) {
      let transformationArguments = {}
      if (textFieldValues.length > 0) {
        textFieldValues.forEach((fieldValue) => {
          const [key, value] = fieldValue.split(":")
          transformationArguments[key] = value
        })
      }
      let formattedTransformationExpression = {
        ...transformationArguments,
      }
      console.log("Submitting:", {
        [selectedTransformation]: formattedTransformationExpression,
      })
      onSubmit({ [selectedTransformation]: formattedTransformationExpression })
    }
    if (usePayload) {
      generatePayload()
    }
    onOpen()
  }

  const handleSelectChange = (event) => {
    setSelectedTransformation(event.target.value)
    fetchIndTransExpVal(event.target.value)
  }

  const handleTextFieldChange = (index, value) => {
    const updatedValues = [...textFieldValues]
    const placeholder = transExpValName ? transExpValName[index] : ""
    updatedValues[index] = `${placeholder}:${value}`
    setTextFieldValues(updatedValues)
  }

  return (
    <div style={{}}>
      <Dialog open={boolean} onClose={onOpen}>
        <DialogContent style={{ width: "600px" }}>
          <Grid container mb={3}>
            <Grid item xs={11}>
              <Typography variant="h6" component="h2">
                Transformation Catalog
              </Typography>
            </Grid>
            <Grid color="text.secondary" item xs={1}>
              <CloseIcon
                onClick={onOpen}
                sx={{
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>
          <FormControl
            variant="standard"
            sx={{
              m: 1,
              width: "50%",
              marginBottom: "8px",
              marginRight: "20px",
              marginLeft: "0",
            }}
          >
            <InputLabel id="TranformationDrop-down">
              Tranformation Drop-down
            </InputLabel>
            <Select
              labelId="TranformationDrop-down"
              id="Tranformation_Drop-down"
              name="TranformationDropdown"
              label="Tranformation Dropdown"
              value={selectedTransformation} // Set the value of Select
              onChange={handleSelectChange}
            
            >
              {transExpVal &&
                transExpVal?.map((item) => (
                  <MenuItem
                    key={item?.transformationExpression}
                    value={item?.transformationExpression}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {item?.transformationExpression}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <br />
          {transExpValName &&
            transExpValName.map((item, index) => (
              <TextField
                size="small"
                key={index}
                // type={getTextFieldType(selectedTransformation, item)}
                value={
                  textFieldValues[index]
                    ? textFieldValues[index].split(":")[1] || ""
                    : ""
                } // Show only user input part
                onChange={(e) => handleTextFieldChange(index, e.target.value)}
                placeholder={item}
                style={{ marginRight: "20px", marginTop: "20px" }}
              />
            ))}
          {showEmptyFieldMessage && (
            <Typography
              sx={{ fontSize: "14px", color: "red", margin: "5px 5px" }}
            >
              Please enter the name
            </Typography>
          )}
        </DialogContent>
        <Box sx={{ pb: 2, px: 2 }}>
          <DialogActions sx={{ justifyContent: "center" }}>
            <button
              type="button"
              // disabled={  values.email.trim() === ""}
              className="rounded-md job-btn btn width-72p"
              disabled={!isFormValid} 
              // fullWidth
              // variant="contained"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  )
}

export default TransformationModal
