import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { MARTBUILDER_API } from "../../requestMethod";

const manageDataSourceApi = createApi({
  reducerPath: "manageDataSource",
  baseQuery: fetchBaseQuery({
    baseUrl: MARTBUILDER_API,
  }),
  endpoints(builder) {
    return {
      getDataSource: builder.query({
        providesTags: ["DataSource"],
        query: () => {
          return {
            url: "/datasources/",
            method: "GET",
          };
        },
      }),
    };
  },
});

export const { useGetDataSourceQuery } = manageDataSourceApi;

export { manageDataSourceApi };
