import React, { useEffect, useState,useContext } from "react";
import "./CreateJob.css";
import FolderTree from "../folderTree/FolderTree";
import {
  Close,
  Dataset,
  DesktopMac,
  Folder,
  InsertDriveFile,
  SimCardDownload,
  Storage,
  TableView,
} from "@mui/icons-material";
import {
  Box,
  Container,
  CssBaseline,
  IconButton,
  Paper,
  TextField,
} from "@mui/material";
import StepperForm from "../stepperForm/StepperForm";
import EdaTable from "../reactTable/EdaTable";
import { Link } from "react-router-dom";
import { useValue } from "../../context/ContextProvider";
import IngestionStepperForm from "../ingestionStepperForm/IngestionStepperForm";
import JobTypeModal from "../selectJobtypeModal/JobTypeModal";
import axios from "axios";
import { userRequestDataBook } from "../../requestMethod";
import { AuthContext } from "../../AuthContext";

const CreateJob = () => {

  const { getToken, token, error, getDatabookToken,DCtoken } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const {
    state: { isEDAVisible, dataBookToken, dataSources },
    dispatch,
  } = useValue();

  // console.log(showIngestionJobForm)

  useEffect(() => dispatch({ type: "DISABLE_EDA" }), []);

  const handleEdaClick = () => {
    dispatch({ type: "DISABLE_EDA" });
  };

  //state for ingestionStepper form
  // const [showIngestion, setShowIngestion] = useState(false);

  // FETCHING DATASOURCE
  useEffect(() => {
    const ourRequest = axios.CancelToken.source();

    const fetchDataSource = async () => {
      try {
        let response = await userRequestDataBook.get(
          `/databook/datasets/sources`,
          {
            headers: {
              // Define your custom headers here
              Authorization: `Bearer ${DCtoken}`,
              "Content-Type": "application/json",
            },
            CancelToken: ourRequest.token,
          }
        );
        // console.log(response.data)
        // setDataSource(response.data)
        dispatch({ type: "SET_DATASOURCES", payload: response.data });
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        } else {
          console.error(err);
        }
      }
    };
    fetchDataSource();
    return () => {
      ourRequest.cancel();
    };
  }, []);

  const handleCreateJobClick = () => {
    // console.log("JOB_TYPE_MODALfff")
    // setOpenModal(!openModal)
    dispatch({ type: "JOB_TYPE_MODAL" });
  };

  return (
    <div className="create-job-main"
      // style={{
        // paddingTop: "30px",
        // paddingInline: "3.5%",
        // display: "grid",
        // gridTemplateColumns: "2fr 7.2fr 1.8fr",
        // border:"1px solid red"
      // }}
    >
      <div
        className="foldertree-outer-contanier"
        style={{
          height: "80vh",
          overflow: "hidden",
          position: "fixed",
          left: "120px",
          width: "16%",
          transition: "all 0.5s",
          marginTop: "30px",
        }}
      >
        {/* <h3 style={{ fontSize: "16px", color: "#6b6b6b", marginLeft: "10px" }}>
          Databook Catalog
        </h3> */}
        <div className="foldertree-inner-contanier"
          // style={{
            // height: "76vh",
            // marginTop: "5px",
            // overflow: "scroll",
            // backgroundColor: "#fff",
            // borderRadius: "4px",
            // border: "1.2px solid #eaeaea",
          // }}
        >
          <FolderTree data={dataSources} isLoading={isLoading} />
        </div>
      </div>
      <div style={{}}>
        {/* <Link to="/" style={{ paddingLeft: "10px", fontSize: "14px" }}>
          Back
        </Link> */}
        <CssBaseline />
        <Container component={Box} px={4} py={1}>
          <div
            style={{
              width: "62%",
              position: "fixed",
              left: "28%",
              marginTop: "30px",
              marginLeft: "30px",
              // border: "1px solid red",
            }}
          >
            <Paper component={Box} p={2}>
              <StepperForm />
            </Paper>
          </div>
        </Container>
      </div>

      {isEDAVisible && (
        <div style={{ position: "relative" }}>
          <h4
            style={{
              alignSelf: "center",
              paddingBottom: "8px",
              color: "#6b6b6b",
            }}
          >
            EDA
          </h4>
          <IconButton
            aria-label="delete"
            onClick={handleEdaClick}
            sx={{
              position: "absolute",
              top: -5,
              right: 0,
            }}
            size="small"
          >
            <Close fontSize="20px" />
          </IconButton>
          {/* <TextField
            id="outlined-multiline-static"
            multiline
            rows={4}
            defaultValue="Query_1"
          /> */}
          <EdaTable />
        </div>
      )}
    </div>
  );
};

export default CreateJob;
