import React, { useState } from "react";

const PieLabel = ({ data, onClick, handleLoadTypeData }) => {
  const [hoveredItem, setHoveredItem] = useState(null);

  return (
    <div>
      {data.map((item) => (
        <p
          key={item.name}
          style={{
            textAlign: "left",
            fontSize: "12px",
            cursor: "pointer",
            color: hoveredItem === item.name ? "blue" : "inherit",
          }}
          // onClick={() => onClick(item.name)}
          onMouseEnter={() => setHoveredItem(item.name)}
          onMouseLeave={() => setHoveredItem(null)}
        >
          {item.name ? item.name : "ANY"} : {item.value}
        </p>
      ))}
    </div>
  );
};

export default PieLabel;
