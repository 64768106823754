import React, { useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useValue } from "../../context/ContextProvider";
import axios from "axios";
import { userRequestMartbuilder } from "../../requestMethod";
import ColumnsTransformation from "../IngestionForm/ColumnsTranformation";
import { Button } from "@mui/material";
import { json, useNavigate } from "react-router-dom";
import BasicDetails from "./BasicDetails";
import { AuthContext } from "../../AuthContext";
import { Link } from "react-router-dom";

function getStepContent(step, activeStep, handelBack) {
  switch (step) {
    case 2:
      return <BasicDetails />;
    case 3:
      return <ColumnsTransformation />;
    case 4:
      return (
        <div
          style={{
            height: "250px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h3>Please wait...</h3> <br />
          <h3>Redirecting to <Link to="/">home page </Link> </h3>
        </div>
      );
    default:
      return "unknown step";
  }
}

function FormTaskSection({
  activeStep,
  handelBack,
  globalNext,
  setActiveStep,
}) {
  const [collectedData, setCollectedData] = useState({});
  const navigate = useNavigate();
  const {
    state: {
      datasetDetails,
      schedulerInfoBatch,
      sourceConnectionBatch,
      destinationConnectionBatch,
      availableJobId,
      toggleNextButton,
    },
    dispatch,
  } = useValue();
  const { getToken, token, error } = useContext(AuthContext);
  // console.log("availableJobId", availableJobId);

  const blankForm = {
    batchIngestionJob: {
      dagName: "",
      id: "",
      scheduleInfo: {
        id: schedulerInfoBatch,
      },
      sourceConnection: {
        connectionId: sourceConnectionBatch,
      },
      targetConnection: {
        connectionId: destinationConnectionBatch,
      },
      isCdcEnabled:false,
    },
    batchIngestionTasks: [
      {
        batchIngestionJob: {},
        ingestionMode: "",
        loadType: "",
        sourceTargetColumnMapping: datasetDetails?.dataset?.columns.map(
          (column) => {
            return {
              destinationType: column.colDatatype,
              sourceColumnName: column.name,
              sourceType: column.colDatatype,
              targetColumnName: column.name,
              transformations: [],
            };
          }
        ),
        srcDatasetName: datasetDetails?.dataset?.fqn,
        srcTableName: datasetDetails?.dataset?.name,
        srcDatasetSchema: datasetDetails?.dataset?.datasetSchema,
        //srcDatasourceId: datasetDetails?.dataset?.id,
        
        // filterByStartTime:"",
        // filterByEndTime:"",
        wildcardPath: "",
        recursively: false,
        // isCdcEnabled:false,

        srcOrderByKeys: [],
        srcPrimaryKeys: [],
        srcWatermarkColumn: "lastmodifiedtimestamp",
        tgtAccountName: "",
        tgtContainerName: "",
        tgtDirectoryName: "",
        tgtFileFormat: "",
        loadHistoricalData: false,
      },
    ],
  };

  useEffect(() => {
    //(async () => await getToken())();
  }, []);

  const methods = useForm({
    defaultValues: blankForm,
  });

  // Turning  array of objects into objects with key and value
  const handleMultiSelectArrayPayload = (selectedOptions) => {
    const selectedObject = {};

    if (Array.isArray(selectedOptions)) {
      selectedOptions.forEach((option) => {
        selectedObject[option.value] = option.label;
      });
    }
    return selectedObject;
  };

  function transformExpressions(expressions) {
    console.log(expressions, "expressions")
    // let transformed = [];

    // expressions.forEach((expression) => {
    //   Object.keys(expression).forEach((key) => {
    //     const transformationType = key;
    //     const args = expression[transformationType];

    //     // Assuming args is an object where each key-value pair represents an argument for the transformation
    //     const transformationObj = {
    //       [transformationType]: args,
    //     };

    //     transformed.push(transformationObj);
    //   });
    // });

    return expressions;
  }

  const handelNext = (data) => {
    // console.log(data.batchIngestionTasks[0].srcPrimaryKeys, "hello");

    let multiPrimaryColumns = data.batchIngestionTasks[0].srcPrimaryKeys;
    let multiOrderByKeys = data.batchIngestionTasks[0].srcOrderByKeys;

    let transformationCatalog =
      data.batchIngestionTasks[0].sourceTargetColumnMapping;

    const modifiedData = { ...data };
    console.log(modifiedData, "modifiedData sss");
    // modifiedData.batchIngestionTasks.forEach((task) => {
    //   if (
    //     task.sourceTargetColumnMapping &&
    //     Array.isArray(task.sourceTargetColumnMapping)
    //   ) {
    //     task.sourceTargetColumnMapping.forEach((mapping) => {
    //       if (Array.isArray(mapping.transformationExpression)) {
    //         mapping.transformationExpression = transformExpressions(
    //           mapping.transformationExpression
    //         );
    //       }
    //     });
    //   }
    // });

    modifiedData.batchIngestionTasks.forEach((task) => {
      task.sourceTargetColumnMapping.forEach((mapping) => 
      {
        if (Array.isArray(mapping.transformationExpression) && mapping.transformationExpression.length > 0) {
          mapping.transformationExpression = transformExpressions(mapping.transformationExpression);
        } else {
          console.log("Transformation Expression is empty or not in the expected format:", mapping.transformationExpression);
        }
      });
    });

    const modifiedPrimaryKeys =
      handleMultiSelectArrayPayload(multiPrimaryColumns);
    const modifiedOrderByKeys = handleMultiSelectArrayPayload(multiOrderByKeys);
    // console.log(modifiedPrimaryKeys, "entry");

    let modifyAvailableJobObject;
    if (availableJobId) {
      modifyAvailableJobObject = {
        id: availableJobId,
      };
      modifiedData.batchIngestionJob = modifyAvailableJobObject;
    }

    modifiedData.batchIngestionTasks[0].srcPrimaryKeys = modifiedPrimaryKeys;
    modifiedData.batchIngestionTasks[0].srcOrderByKeys = modifiedOrderByKeys;

    console.log(modifiedData, "test");
    setCollectedData({ ...collectedData, ...data });

    // Submission on final step
    if (activeStep > 3) {
      const ourRequest = axios.CancelToken.source();
      const sendJobData = async () => {
        try {
          let response = await userRequestMartbuilder.post(
            "/ingestion/batch",
            modifiedData,
            {
              headers: {
                // Define your custom headers here
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              CancelToken: ourRequest.token,
            }
          );
          console.log(response.data);
          if (response.status === 200) {
            console.log(response.data);
            alert("Submitted Successfully");
            navigate("/");
          } else {
            // This is for any other status code other than 200
            alert("Response status" + response.status);
            navigate("/");
          }
        } catch (err) {
          if (axios.isCancel(err)) {
            return;
          } else {
            console.error(err);
            alert("Job creation failed");
            navigate("/");
          }
        }
      };
      sendJobData();
    }
    // setActiveStep(activeStep + 1);
  };

  const submitHandler = (data) => {
    // console.log("ko")
    // return
    console.log(data.batchIngestionTasks[0].srcPrimaryKeys, "hello");

    const ourRequest = axios.CancelToken.source();
    const sendJobData = async () => {
      try {
        let response = await userRequestMartbuilder.post("/jobs", data, {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        });
        console.log(response.data);
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        } else {
          console.error(err);
        }
      }
    };
    sendJobData();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handelNext)}>
        {/* {activeStep === 3 && getStepContent(3)}
        {activeStep === 4 && getStepContent(4)} */}
        {activeStep === 2 && getStepContent(2)}
        {activeStep === 3 && getStepContent(3)}
        {activeStep === 4 && getStepContent(4)}
        {/* <div className="button-tab-jobview">
          <button
            className="rounded-md job-btn-plain btn"
            disabled={activeStep === 0}
            onClick={handelBack}
          >
            Back
          </button>
 
          {toggleNextButton && activeStep !== 3 && (
            <button className="rounded-md job-btn btn" onClick={globalNext}>
              Next
            </button>
          )}
 
          {activeStep === 4 && (
            <button
              className="rounded-md job-btn btn"
              type="submit"
              onClick={globalNext}
            >
              Finish
            </button>
          )}
        </div> */}
        <div className="button-tab-jobview">
          <button
            className="rounded-md job-btn-plain btn"
            // variant="text"
            // size="small"
            disabled={activeStep === 0}
            onClick={handelBack}
            style={{ display: activeStep > 3 ? 'none' : 'inline-block' }}
          >
            Back
          </button>

        {activeStep === 3 ? (
            <button
              className="rounded-md job-btn btn"
              // variant="contained"
              // size="small"
              type="submit"
              onClick={globalNext}
              // onClick={onSubmit}
            >
              Finish
            </button>
          ) : (
           <button
            className="rounded-md job-btn btn"
              // variant="contained"
              // size="small"
              // disabled={disableNext}
              onClick={globalNext}
              style={{ display: activeStep > 3 ? 'none' : 'inline-block' }}
              // type="submit"
            >
              Next
            </button>
          )}
        </div>
      </form>
    </FormProvider>
  );
}

export default FormTaskSection;
