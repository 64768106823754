import React, {
  useCallback,
  useMemo,
  useEffect,
  useState,
  useContext,
} from "react";
import { MaterialReactTable } from "material-react-table";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import { Delete, Edit, CheckCircle, Cancel } from "@mui/icons-material";
import { data } from "./makeData";
import { userRequestMartbuilder } from "../../requestMethod";
import { useValue } from "../../context/ContextProvider";
import { useDispatch } from "react-redux";
import StatusModal from "../statusModal/StatusModal";
import CircularLoader from "../Atoms/CircularLoader";
import { AuthContext } from "../../AuthContext";
import DeleteModal from "../Atoms/DeleteModal";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import JobSummaryDetailsModal from "../jobSummaryDetailsModal/JobSummaryDetailsModal";
import RunStatusModal from "../runStatusModal/RunStatusModal";

const JobSummaryTable = ({ data, fetchJobSummary }) => {
  const navigate = useNavigate();
  const [jobs, setJobs] = useState(data);
  const [tableData, setTableData] = useState(() => data);
  //handle delete notification modal
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  //show hide job Details modal
  const [showJobDetails, setShowJobDetails] = useState(false)
  const [tableRowData, setTableRowData] = useState()
  const [rowData, setRowData] = useState(null);
  const [Dag, setDag] = useState();
  //showhide run status modal
  const [showRunStatus, setShowRunStatus] = useState(false);
  //for sending as a prop single row data
  const [singleRowData, setSingleRowData] = useState();

  const { getToken, token, error } = useContext(AuthContext);

  const {
    state: { martBuilderEditJobId, toggleEditButton },
    dispatch,
  } = useValue();

  const handleJobViewClick = (rowData) => {
    console.log(rowData);
    if (rowData?.jobType === "MART_BUILDER") {
      // console.log(rowData)
      // return;
      dispatch({ type: "SHOW_EDIT_BUTTON" });
      navigate(`/jobView/${rowData?.jobName}`);
      dispatch({ type: "MART_BUILDER_EDIT_JOB_ID", payload: rowData.jobId });
    } else {
      dispatch({ type: "SHOW_EDIT_BUTTON" });
      navigate(`/Batchview/${rowData?.jobName}`);
      dispatch({ type: "BATCH_INGESTION_EDIT_JOB_ID", payload: rowData.jobId });
    }
  };

  const handleDeleteRow = useCallback(
    (row) => {
      let JobId = row?.original?.jobId;
      let JobType = row?.original?.jobType;
      console.log(row);
      console.log(row?.original?.jobType);

      if (JobType === "MART_BUILDER") {
        const ourRequest = axios.CancelToken.source();
        const deleteJob = async () => {
          try {
            let response = await userRequestMartbuilder.delete(
              `/jobs/${JobId}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
                CancelToken: ourRequest.token,
              }
            );
            const updatedData = tableData.filter(
              (_, index) => index !== row.index
            );
            setTableData(updatedData);
            setJobs(updatedData);
            fetchJobSummary();
          } catch (err) {
            if (axios.isCancel(err)) {
              return;
            } else {
              console.error(err);
            }
          }
        };
        deleteJob();
      } else if (JobType === "BATCH_INGESTION") {
        const ourRequest = axios.CancelToken.source();
        const deleteBatchIngestionJob = async () => {
          try {
            let response = await userRequestMartbuilder.delete(
              `/ingestion/batch/${JobId}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
                CancelToken: ourRequest.token,
              }
            );
            const updatedData = tableData.filter(
              (_, index) => index !== row.index
            );
            setTableData(updatedData);
            setJobs(updatedData);
            fetchJobSummary();
          } catch (err) {
            if (axios.isCancel(err)) {
              return;
            } else {
              console.error(err);
            }
          }
        };
        deleteBatchIngestionJob();
      }
      setOpenDeleteModal(false);
      // if (
      //   alert(`Are you sure you want to delete ${row.getValue("firstName")}`)
      // ) {
      //   return;
      // }
      // tableData.splice(row.index, 1);
      // setTableData([...tableData]);
    },
    [tableData]
  );

  const handleDeleteModalToggle = (row) => {
    // console.log(row)
    setOpenDeleteModal(!openDeleteModal);
    setRowData(row);
  };

  const handleInfoModal = (data)=>{
    // console.log(data)
    setShowJobDetails(!showJobDetails)
    if(data){
      setTableRowData(data)
      if(data?.jobType === "BATCH_INGESTION"){
        dispatch({ type: "HIDE_EDIT_BUTTON" });
        dispatch({ type: "BATCH_INGESTION_EDIT_JOB_ID", payload: data?.jobId });
        navigate(`/Batchview/${data?.jobName}`);
      }
    }
  }

  const runStatusCheck = (runStatus) => {
    setSingleRowData(runStatus);
    setShowRunStatus(!showRunStatus);
  };

  useEffect(() => {
    // (async () => await getToken())();
  }, []);

  const columns = useMemo(() => [
    {
      accessorKey: "jobType",
      header: "Job Type",
      enableColumnOrdering: false,
      enableEditing: false,
      enableSorting: false,
      size: 80,
    },
    {
      accessorKey: "jobFrequencyType",
      header: "Job Frequency Type",
      size: 140,
    },
    {
      accessorKey: "product",
      header: "Product Name",
      size: 140,
    },
    {
      accessorKey: "jobName",
      header: "Job Name",
    },
    {
      accessorKey: "jobCreationStatus",
      header: "Job Creation Status",
      size: 80,
    },
    {
      accessorKey: "createdAt",
      header: "Created At",
      size: 80,
    },
    {
      accessorKey: "lastModifiedAt",
      header: "Last Modified At",
      size: 80,
    },
    {
      accessorKey: "jobOwner",
      header: "Job Owner",
      size: 80,
    },
    {
      accessorKey: "lastnRuns",
      header: "Last n Runs",
      size: 70,

      Cell: ({ cell }) => {
        return (
          <>
            <button
              className="rounded-md job-btn btn nar-wid" 
              // variant="outlined"
              // size="small"
              // sx={{
              //   color: "#247ba0",
              //   borderColor: "#247ba0",
              //   fontSize: "9.5px",
              // }}
              onClick={() => runStatusCheck(cell?.row?.original)}
            >
              Run Details
            </button>
          </>
        );
        //   const [runs, setRuns] = useState();
        //   const [isLoading, setIsLoading] = useState(false);
        //   const [openStatusModal, setOpenStatusModal] = useState(false);
        //   const [status, setStatus] = useState();
        //   const handleOpenStatusModal = (item) => {
        //     // console.log(item);
        //     setStatus(item);
        //     setOpenStatusModal(!openStatusModal);
        //   };
        //   // console.log(cell.row.original?.jobName)
        //   const dag_Name = [cell?.row.original.jobName];

        //   const runStatusPayload = {
        //     BATCH_INGESTION: batchIngestionSuffix,
        //     MART_BUILDER: martBuilderJobs,
        //   };

        //   useEffect(() => {
        //     if (martBuilderJobs.length > 0 && batchIngestionSuffix  ) {
        //       console.log('ll');
        //       const ourRequest = axios.CancelToken.source();
        //       setIsLoading(true);
        //       const fetchJobs = async () => {
        //         try {
        //           let response = await userRequestMartbuilder.post(
        //             `/jobs/dag/runs`,
        //             runStatusPayload,
        //             {
        //               headers: {
        //                 Authorization: `Bearer ${token}`,
        //                 'Content-Type': 'application/json',
        //               },
        //               CancelToken: ourRequest.token,
        //             }
        //           );
        //           setRuns(response.data[dag_Name].dagRuns?.dag_runs);
        //           console.log(response.data, 'cccccc');
        //           setIsLoading(false);
        //         } catch (err) {
        //           if (axios.isCancel(err)) {
        //             return;
        //           } else {
        //             console.error(err);
        //           }
        //         }
        //       };
        //       fetchJobs();
        //       return () => {
        //         ourRequest.cancel();
        //       };
        //     }
        //   }, [martBuilderJobs, runStatusPayload,batchIngestionSuffix, token ]);

        //   if (isLoading) {
        //     return (
        //       <>
        //         <CircularLoader size={25} />
        //       </>
        //     );
        //   }
        //   return (
        //     <>
        //       {runs?.map((item) =>
        //         item.state === 'success' ? (
        //           <Tooltip key={item.dag_run_id} title={item.execution_date}>
        //             <CheckCircle
        //               color="success"
        //               sx={{
        //                 p: '0px 0.6px',
        //                 cursor: 'pointer',
        //               }}
        //               onClick={() => handleOpenStatusModal(item)}
        //             />
        //           </Tooltip>
        //         ) : (
        //           <Tooltip key={item.dag_run_id} title={item.execution_date}>
        //             <Cancel
        //               color="error"
        //               sx={{ p: '0px 0.6px', cursor: 'pointer' }}
        //               onClick={() => handleOpenStatusModal(item)}
        //             />
        //           </Tooltip>
        //         )
        //       )}
        //       {openStatusModal && (
        //         <StatusModal
        //           onOpen={handleOpenStatusModal}
        //           boolean={openStatusModal}
        //           Item={status}
        //         />
        //       )}
        //     </>
        //   );
      },
    },
  ]);

  return (
    <>
      <div
        style={{
          width: "90%",
          margin: "0px auto",
        }}
      >
        {/* <h2 style={{ margin: "20px 0px" }}>Job Summary Table</h2> */}
        <MaterialReactTable
          displayColumnDefOptions={{
            "mrt-row-actions": {
              muiTableHeadCellProps: {
                align: "center",
              },
            },
          }}
          initialState={{
            density: "compact",
            pagination: { pageSize: 10, pageIndex: 0 },
          }}
          columns={columns}
          data={jobs}
          editingMode="modal" //defaulta
          positionActionsColumn="last"
          enableColumnOrdering
          enableEditing
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Tooltip arrow placement="left" title="Edit">
                <IconButton onClick={() => handleJobViewClick(row?.original)}>
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="left" title="Info">
                <IconButton onClick={() => handleInfoModal(row?.original)}>
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="right" title="Delete">
                <IconButton
                  color="error"
                  onClick={() => handleDeleteModalToggle(row)}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        />
      </div>
      <DeleteModal
        handleDeleteRow={handleDeleteRow}
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        row={rowData}
      />
      {showJobDetails && (
        <Box sx={{ position: "absolute", top: "100%", left: 0 }}>
          <JobSummaryDetailsModal
            data={tableRowData}
            onOpen={() => setShowJobDetails(false)}
            boolean={showJobDetails}
          />
        </Box>
      )}

      {showRunStatus && (
        <Box sx={{ position: "absolute", top: "100%", left: 0 }}>
          <RunStatusModal
            singleRowData={singleRowData}
            onOpen={() => setShowRunStatus(false)}
            boolean={showRunStatus}
          />
        </Box>
      )}
    </>
  );
};

export default JobSummaryTable;
