import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

const AzureDataLakeForm = ({ azureData, setAzureData,disableFieldProp }) => {

  const [pathContainsTable, setPathContainsTable] = useState(azureData.adlsDoesPathContainTables || false);

  useEffect(() => {
    setPathContainsTable(azureData.adlsDoesPathContainTables || false);
  }, [azureData.adlsDoesPathContainTables]);

  const handleInputChange = (e) => {
    setAzureData({
      ...azureData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePathContainsTableChange = (e) => {
    const isChecked = e.target.checked;
    setPathContainsTable(isChecked);
    setAzureData({
      ...azureData,
      adlsDoesPathContainTables: isChecked,
    });
  };
  

  return (
    <div style={{marginTop:"5px"}} >
      <div
        className="arrayItem"
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography 
          style={{
            color: "black !important",
            fontWeight: 550,
            marginRight: "146px",
            minWidth: "max-content",
            textAlign: "left",
          }}
        >
          Storage Account Name
        </Typography>
        <TextField
          sx={{ minWidth: "220px" }}
          size="small"
          margin="dense"
          name="adlsStorageAccountName"
          value={azureData.adlsStorageAccountName}
          onChange={handleInputChange} 
          disabled={disableFieldProp}
        />
      </div>
      <div
        className="arrayItem"
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography 
          style={{
            color: "black !important",
            fontWeight: 550,
            marginRight: "193px",
            minWidth: "max-content",
            textAlign: "left",
          }}
        >
          Container Name
        </Typography>
        <TextField
          sx={{ minWidth: "220px" }}
          size="small"
          margin="dense"
          name="adlsContainerName"
          value={azureData.adlsContainerName}
          onChange={handleInputChange} 
          disabled={disableFieldProp}
        />
      </div>
      <div
        className="arrayItem"
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography 
          style={{
            color: "black !important",
            fontWeight: 550,
            marginRight: "216px",
            minWidth: "max-content",
            textAlign: "left",
          }}
        >
          Dataset Path
        </Typography>
        <TextField
          sx={{ minWidth: "220px" }}
          size="small"
          margin="dense"
          name="adlsDatasetPath"
          value={azureData.adlsDatasetPath}
          onChange={handleInputChange} 
          disabled={disableFieldProp}
        />
      </div>
      {/* <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          textAlign: "center",
          width: "900px",
          marginTop: "20px",
        }}
      >
        <Typography
          style={{
            color: "black !important",
            fontWeight: 550,
            marginRight: "14px",
          }}
        >
          Does Path Contains Tables
        </Typography>
        <input
          style={{ width: "232px" }}
          type="checkbox"
          id="autoCrawl"
          name="adlsDoesPathContainTables" 
          onChange={handlePathContainsTableChange}
          checked={pathContainsTable} 
        />
      </div> */}
      {pathContainsTable && (
        <div
          className="arrayItem"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography 
            style={{
              color: "black !important",
              fontWeight: 550,
              marginRight: "205px",
              minWidth: "max-content",
              textAlign: "left",
            }}
          >
            Schema Name
          </Typography>
          <TextField
            sx={{ minWidth: "220px" }}
            size="small"
            margin="dense"
            name="adlsDatasetSchemaName"
            value={azureData.adlsDatasetSchemaName}
            onChange={handleInputChange} 
            disabled={disableFieldProp}
          />
        </div>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          textAlign: "center",
          width: "900px",
        }}
      >
        <Typography
          style={{
            color: "black !important",
            fontWeight: 550,
            marginRight: "228px", 
          }}
        >
          File Format
        </Typography>
        <FormControl>
          <Select
            sx={{ width: "220px", marginTop:"8px" }}
            size="small"
            name="adlsFileFormat"  
            value={azureData.adlsFileFormat}
            onChange={handleInputChange}
            id="file-format-select"
            labelId="file-format-label"  
            disabled={disableFieldProp}
          >
            <MenuItem value="">None</MenuItem>
            <MenuItem value="parquet">parquet</MenuItem>
            <MenuItem value="orc">orc</MenuItem>
            <MenuItem value="avro">avro</MenuItem>
            <MenuItem value="json">json</MenuItem>
            <MenuItem value="csv">csv</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default AzureDataLakeForm;
