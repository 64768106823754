import { useState, useEffect, useContext } from "react";
import {
  Button,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  Grid,
  DialogTitle,
  TableContainer,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import axios from "axios";
import { userRequestMartbuilder } from "../../requestMethod";
import { AuthContext } from "../../AuthContext";

const ApplicationTableEdit = ({
  boolean,
  onToggle,
  row,
  tableType,
  fetchApplicationsData,
}) => {
  // const [tableValue, setTableValue] = useState(tableType);
  // console.log(tableValue)
  const { getToken, token, error } = useContext(AuthContext);

  // const [domainValue, setDomainValue] = useState("");
  // const [domainArray, setDomainArray] = useState([]);
  // const [rolesArray, setRolesArray] = useState([]);

  // const domainslist = [
  //   'Sales',
  // ];
  
  // const roleslist = [
  //   'domainadmin',
  //   'domainlead',
  //   'domaindeveloper',
  //   'user',
  // ];

  // Dropdown change function
  // const handleDropdownChange = (event, type) => {
  //   if (type === "domain") {
  //     let data = [];
  //     event.map((e) => {
  //       data.push(e.label);
  //       console.log(data);
  //     });
  //     setDomainArray(data);
  //     console.log("ddd", domainArray);
  //   }

  //   if (type === "roles") {
  //     let data = [];
  //     event.map((e) => {
  //       data.push(e.label);
  //       console.log(data);
  //     });
  //     setRolesArray(data);
  //     console.log("rrr", rolesArray);
  //   }
  // };

  //for submitting the edit response
  const UpdateApplicationData = async (updatedData) => {
    const ourRequest = axios.CancelToken.source();
    console.log("Payload ", updatedData)
    try {
      let response = await userRequestMartbuilder.put(
        `/aatapplications/${updatedData.id}`,
        updatedData,
        {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      // console.log(response.data);
      fetchApplicationsData();
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    // UpdateMaerskModalData();
  };

  const [values, setValues] = useState({
    name: row?.original?.name || "", // Set default value to an empty string
    description: row?.original?.description || "",
    // domains: row?.original?.domains || "",
    // roles:row?.original?.roles || "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedValues = { ...values, [name]: value };
    setValues(updatedValues);
  };

  const handleSubmit = async () => {
    if (values.name.trim()) {
      const updatedData = {
        id: row.original.id,
        name: values.name.trim(),
        description: values.description.trim(),
        // domains: domainArray,
        // roles:rolesArray,
      };

      try {
        await UpdateApplicationData(updatedData);
        console.log(tableType);
        onToggle(row, tableType);
      } catch (error) {
        // Handle any errors here
        console.error("Error updating data:", error);
      }
    }
  };

  // const handleInputChange = (e) => {
  //   setDomainValue(e.target.value);
  // };

  // const handleDomainInputChange = (event) => {
  //  const {
  //    target: { value },
  //  } = event;
  //  setDomainArray(
  //    // On autofill we get a stringified value.
  //    typeof value === 'string' ? value.split(',') : value,
  //  );
  // };
  
  // const handleRolesInputChange = (event) => {
  //  const {
  //    target: { value },
  //  } = event;
  //  setRolesArray(
  //    // On autofill we get a stringified value.
  //    typeof value === 'string' ? value.split(',') : value,
  //  );
  // };

  //array handling
  // const handleAddToDataArray = () => {
  //   if (domainValue.trim() !== "") {
  //     setDomainArray((prevDomainArray) => [...prevDomainArray, domainValue]);
  //     setDomainValue("");
  //   }
  // };

  //delete the item
  // const handleDelete = (index) => {
  //   const updateDomain = domainArray.filter((_, i) => i !== index);
  //   setDomainArray(updateDomain);
  // };

  return (
    <div>
      <Dialog open={boolean} onClose={() => onToggle(row, tableType)}>
        <DialogContent style={{ width: "600px" }}>
          <Grid container mb={3}>
            <Grid item xs={11}>
              <Typography variant="h6" component="h2">
                Edit Application Data
              </Typography>
            </Grid>
            <Grid color="text.secondary" item xs={1}>
              <CloseIcon
                onClick={() => onToggle(row, tableType)}
                sx={{
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Name
            </Typography>
            <TextField
              value={values.name}
              onChange={handleChange}
              name="name"
              label="Name"
              margin="dense"
              // fullWidth
              size="small"
              style={{ width: "71.5%" }}
              // disabled
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Description
            </Typography>
            <TextField
              value={values.description}
              onChange={handleChange}
              name="description"
              label="Description"
              margin="dense"
              // fullWidth
              size="small"
              style={{ width: "71.5%" }}
            />
          </div>

          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "10px 0",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Domain
            </Typography>
            <Select
              multiple
              labelId="domain"
              id="domain"
              value={domainArray}
              onChange={handleDomainInputChange}
              style={{ width: "71.5%" }}
            >
              {domainslist?.map((item) => (
                <MenuItem
                  key={item}
                  value={item}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    // You can add more styles here if needed
                  }}
                >
                  {item}
                </MenuItem>
              ))}
            </Select>
          </div> */}
          
          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "10px 0",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Roles
            </Typography>
            <Select
              multiple
              labelId="roles"
              id="roles"
              value={rolesArray}
              onChange={handleRolesInputChange}
              style={{ width: "71.5%" }}
            >
              {roleslist?.map((item) => (
                <MenuItem
                  key={item}
                  value={item}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    // You can add more styles here if needed
                  }}
                >
                  {item}
                </MenuItem>
              ))}
            </Select>
          </div> */}

          {/* {domainArray.length > 0 && (
            <div className="enteredText" style={{ margin: "10px 0 0 0" }}>
              <ul
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                {domainArray.map((data, index) => (
                  <li key={index}>
                    {data}
                    <span
                      className="cancel"
                      onClick={() => handleDelete(index)}
                    >
                      x
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          )} */}
        </DialogContent>
        <Box sx={{ pb: 2, px: 2 }}>
          <DialogActions>
            <Button variant="contained" onClick={handleSubmit}>
              Update
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
};

export default ApplicationTableEdit;
