import { useState, useEffect, useContext } from "react";
// import Select from "react-select";
import {
  Button,
  Chip,
  InputLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  Grid,
  DialogTitle,
  TableContainer,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import axios from "axios";
import { userRequestMartbuilder } from "../../requestMethod";
import { AuthContext } from "../../AuthContext";
import { useNavigate } from "react-router";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";

const ResourceTableEdit = ({
  boolean,
  onToggle,
  row,
  tableType,
  fetchResourcesData,
}) => {
  // const [tableValue, setTableValue] = useState(tableType);
  // console.log(tableValue)
  const { getToken, token, error } = useContext(AuthContext);

  // const [permissionsArray, setPermissionsArray] = useState([]);
  // const [permissionsValue, setPermissionsValue] = useState("");
  // const [appsArray, setAppsArray] = useState([]);
  // const [resourcesArray, setResourcesArray] = useState([]);
  // const [operasArray, setOperasArray] = useState([]);
  // const [domainsArray, setDomainsArray] = useState([]);
  // const [permName, setPermName] = useState([]);
  // const [permID, setPermID] = useState([]);
  // const [permissionData, setPermissionData] = useState();

  const [appID, setAppID] = useState();
  const [appName, setAppName] = useState();
  const [applicationData, setApplicationData] = useState();

  // const permissionsList = [
  //   { value: 1, label: "Read_Write_Jobs" },
  //   { value: 2, label: "Read_Only_jobs" },
  //   { value: 3, label: "Can_Approve_Jobs" },
  // ];

  const navigate = useNavigate();

  // Fetching data for permissions dropdown
  // const fetchPermissionsData = async () => {
  //   const ourRequest = axios.CancelToken.source();
  //   try {
  //     let response = await userRequestMartbuilder.get(`/aatpermissions`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //       },
  //       CancelToken: ourRequest.token,
  //     });
  //     setPermissionData(response?.data);
  //     console.log(response?.data);
  //   } catch (err) {
  //     if (axios.isCancel(err)) {
  //       return;
  //     } else {
  //       console.error(err);
  //     }
  //   }
  //   return () => {
  //     ourRequest.cancel();
  //   };
  // };

  // Fetching values for application dropdown
  const fetchApplicationsData = async () => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(`/aatapplications`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });
      setApplicationData(response?.data);
      console.log(response?.data);
      console.log(row.original)
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  //for submitting the edit response
  const UpdateResourceData = async (updatedData) => {
    const ourRequest = axios.CancelToken.source();
    console.log("Payload ", values);
    try {
      let response = await userRequestMartbuilder.put(
        `/aatapplications/addResource/${appID}`,
        updatedData,
        // updatedData
        {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      // console.log(response.data);
      fetchResourcesData();
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    // onToggle(null, tableType);
  };

  const [values, setValues] = useState({
    name: row?.original?.name || "", // Set default value to an empty string
    description: row?.original?.description || "",
  });

  // const [policyFields, setPolicyFields] = useState([
  //   {
  //     applications: row?.original?.applications || "",
  //     resource: row?.original?.resource || [],
  //     permissions: row?.original?.permissions || "",
  //     operations: row?.original?.operations || [],
  //     domains: row?.original?.domains || [],
  //   },
  // ]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedValues = { ...values, [name]: value };
    setValues(updatedValues);
  };

  const handleAppChange = (event) => {
    setAppID(event.target.value);
    setAppName(event.target.name);
  };

  const handleSubmit = async () => {
    if (values.name.trim()) {
      const updatedData = {
        id: row.original.id,
        name: values.name.trim(),
        description: values.description.trim(),
        // permissions: policyFields.permissions, //This may be the problem
        // applications: policyFields.applications,
        // resource: policyFields.resource,
        // operations: policyFields.operations,
        // domains: policyFields.domains,
      };

      try {
        await UpdateResourceData(updatedData);
        // console.log(tableType);
        onToggle(null, tableType);
        // navigate("/manageAuthorization")
      } catch (error) {
        // Handle any errors here
        console.error("Error updating data:", error);
      }
    }
  };

  // const handlePolicyChange = (event, index, target) => {
  //   if (
  //     target === "domains" ||
  //     target === "operations" ||
  //     target === "resource"
  //   ) {
  //     let data = [...policyFields];
  //     data[index][target] = [];
  //     event.map((e) => {
  //       data[index][target].push(e.label);
  //     });
  //     setPolicyFields(data);
  //     console.log(policyFields);
  //   }

  //   if (target === "applications" || target === "permissions") {
  //     let data = [...policyFields];
  //     data[index][target] = "";
  //     data[index][target] = event.label;
  //     setPolicyFields(data);
  //     console.log(policyFields);
  //   }
  // };

  // const addPolicy = () => {
  //   let object = {
  //     applications: "",
  //     resource: [],
  //     permissions: "",
  //     operations: [],
  //     domains: [],
  //   };

  //   setPolicyFields([...policyFields, object]);
  // };

  // const removePolicy = (index) => {
  //   let data = [...policyFields];
  //   data.splice(index, 1);
  //   setPolicyFields(data);
  // };

  // const handlePolicyDelete = (event, index) => {
  //   console.log(event.target.value);
  //   // var array = [...domainsArray]; // make a separate copy of the array
  //   // var index = array.indexOf(e.target.value);
  //   // if (index !== -1) {
  //   //   array.splice(index, 1);
  //   //   setDomainsArray(array);
  //   // }
  //   let data = [...policyFields];
  //   data[index][event.target.name] = event.target.value;
  //   // setPolicyFields(data);
  //   setPolicyFields((currentSelectedItems) =>
  //     currentSelectedItems.filter((item) => item !== data)
  //   );
  // };

  // const handlePermChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setPermName(
  //     // On autofill we get a stringified value.
  //     typeof value === 'string' ? value.split(',') : value,
  //   );
  //   // setResourceID(event.target.value)
  //   event.target.value.map((name)=>{
  //     permissionData?.map((perm)=>{
  //       // console.log(resource.name, String(name));
  //       if(perm.name == name){
  //         if(perm.id !== undefined){
  //           // console.log(resource.id)
  //           setPermID([...permID, perm.id])
  //         }
  //       }
  //     })
  //     // console.log(resourceID);
  //   })
  // };

  // const handleOperaChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setOperasArray(
  //     // On autofill we get a stringified value.
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // };

  // const handleDomainChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setDomainsArray(
  //     // On autofill we get a stringified value.
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // };

  // const handleResourceChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setResourcesArray(
  //     // On autofill we get a stringified value.
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // };

  // const handleAppChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setAppsArray(
  //     // On autofill we get a stringified value.
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // };

  // const handleInputChange = (e) => {
  //   setPermissionsValue(e.target.value);
  // };

  // const handleInputChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setPermissionsArray(
  //     // On autofill we get a stringified value.
  //     typeof value === 'string' ? value.split(',') : value,
  //   );
  // };

  // //array handling
  // const handleAddToDataArray = () => {
  //   if (permissionsValue.trim() !== "") {
  //     setPermissionsArray((prevPermissionsArray) => [
  //       ...prevPermissionsArray,
  //       permissionsValue,
  //     ]);
  //     setPermissionsValue("");
  //   }
  // };

  // //delete the item
  // const handleDelete = (index) => {
  //   const updatePermissions = permissionsArray.filter((_, i) => i !== index);
  //   setPermissionsArray(updatePermissions);
  // };

  useEffect(() => {
    (async () => await getToken())();
    // fetchPermissionsData();
    fetchApplicationsData();
  }, [token]);

  return (
    // <div style={{ height: "100%", paddingTop: "60px" }}>
    //   <div
    //     style={{
    //       padding: "80px 20px 20px",
    //       // maxWidth: "700px",
    //       maxWidth: "94%",
    //       margin: "0px auto",
    //       border: "1px solid lightgray",
    //       borderRadius: "4px",
    //       backgroundColor: "#ffffff",
    //       boxShadow: "0 2px 4px 0 rgba(0,0,0,0.2)",
    //       // transition: "0.3s",
    //     }}
    //   >
    //     <div style={{}}>
    //       <Grid container mb={3}>
    //         <Grid item xs={11}>
    //           <Typography variant="h4" component="h4">
    //             Edit Role Details
    //           </Typography>
    //         </Grid>

    //         <Grid color="text.secondary" item xs={1}>
    //           <CloseIcon
    //             onClick={() => navigate("/manageAuthorization")}
    //             sx={{
    //               cursor: "pointer",
    //             }}
    //           />
    //         </Grid>
    //       </Grid>
    //     </div>
    //     <div
    //       style={{
    //         display: "flex",
    //         alignItems: "center",
    //       }}
    //     >
    //       <Typography
    //         style={{
    //           flex: 1,
    //           color: "black !important",
    //           fontWeight: 600,
    //         }}
    //       >
    //         Role Name
    //       </Typography>
    //       <TextField
    //         margin="dense"
    //         label="Enter name"
    //         name="name"
    //         value={values.name}
    //         onChange={handleChange}
    //         style={{ width: "71.5%" }}
    //       />
    //     </div>

    //     <div
    //       style={{
    //         display: "flex",
    //         alignItems: "center",
    //         margin: "20px 0px",
    //       }}
    //     >
    //       <Typography
    //         style={{
    //           flex: 1,
    //           color: "black !important",
    //           fontWeight: 600,
    //         }}
    //       >
    //         Role Description
    //       </Typography>
    //       <TextField
    //         margin="dense"
    //         label="Enter Description"
    //         name="name"
    //         value={values.name}
    //         onChange={handleChange}
    //         style={{ width: "71.5%" }}
    //       />
    //     </div>
    //     {policyFields.map((policy, index) => {
    //       return (
    //         <div
    //           key={index}
    //           style={{
    //             // maxWidth:"90%",
    //             margin: "20px 0px",
    //             padding: "28px 16px",
    //             border: "2px solid lightgray",
    //             borderRadius: "4px",
    //             backgroundColor: "#ffffff",
    //             // boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    //             // transition: "0.3s",
    //           }}
    //         >
    //           <div
    //             style={{
    //               display: "flex",
    //               alignItems: "center",
    //               margin: "20px 0px",
    //             }}
    //           >
    //             <Typography
    //               style={{
    //                 flex: 1,
    //                 color: "black !important",
    //                 fontWeight: 600,
    //               }}
    //             >
    //               Policy {index + 1}
    //             </Typography>
    //             <div
    //               style={{
    //                 display: "flex",
    //                 alignItems: "center",
    //                 justifyContent: "center",
    //                 // maxWidth: "335px",
    //                 // maxHeight: "80px",
    //               }}
    //             >
    //               <CancelIcon onClick={() => removePolicy(index)} />
    //             </div>
    //           </div>
    //           <div
    //             style={{
    //               display: "grid",
    //               gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    //               gap: "1rem",
    //             }}
    //           >
    //             <div
    //               style={{
    //                 maxWidth: "285px",
    //                 maxHeight: "80px",
    //                 marginBottom: "1rem",
    //               }}
    //             >
    //               <label htmlFor="application">Application</label>
    //               {/* <Select
    //               labelId="application"
    //               id="application"
    //               name="applications"
    //               onChange={(event) => handlePolicyChange(event, index)}
    //               value={policy.applications}
    //               style={{ width: "100%" }}
    //               renderValue={(selected) => (
    //                 <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.2 }}>
    //                   <Chip
    //                     key={selected}
    //                     label={selected}
    //                     size="small"
    //                     onMouseDown={(event) => {
    //                       event.stopPropagation();
    //                       handleApplicationDelete(event, index);
    //                     }}
    //                     // onDelete={(event) => {
    //                     //   handlePolicyDelete(event, index);
    //                     // }}
    //                     // deleteIcon={<CloseIcon />}
    //                   />
    //                 </Box>
    //               )}
    //             >
    //               {permissionsList?.map((item) => (
    //                 <MenuItem
    //                   key={item}
    //                   value={item}
    //                   style={{
    //                     display: "flex",
    //                     alignItems: "center",
    //                     // You can add more styles here if needed
    //                   }}
    //                 >
    //                   {item}
    //                 </MenuItem>
    //               ))}
    //             </Select> */}
    //               <Select
    //                 id="application"
    //                 placeholder={false}
    //                 aria-label="application"
    //                 options={permissionsList}
    //                 className="basic-multi-select"
    //                 classNamePrefix="select"
    //                 defaultValue={policyFields[index].applications}
    //                 hideSelectedOptions={false}
    //                 noOptionsMessage={() => "No options available"}
    //                 onChange={(event) =>
    //                   handlePolicyChange(event, index, "applications")
    //                 }
    //               />
    //             </div>

    //             <div
    //               style={{
    //                 maxWidth: "285px",
    //                 maxHeight: "80px",
    //                 marginBottom: "1rem",
    //               }}
    //             >
    //               <label htmlFor="resource">Resource</label>
    //               {/* <Select
    //               multiple
    //               labelId="resource"
    //               id="resource"
    //               name="resource"
    //               onChange={(event) => handlePolicyChange(event, index)}
    //               value={policy.resource}
    //               style={{ width: "100%" }}
    //               renderValue={(selected) => (
    //                 <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.2 }}>
    //                   {selected.map((value) => (
    //                     <Chip
    //                       key={value}
    //                       label={value}
    //                       size="small"
    //                       onMouseDown={(event) => {
    //                         event.stopPropagation();
    //                         handleResourceDelete(event, index);
    //                       }}
    //                       // onDelete={(event) => {
    //                       //   handlePolicyDelete(event, index);
    //                       // }}
    //                       // deleteIcon={<CloseIcon />}
    //                     />
    //                   ))}
    //                 </Box>
    //               )}
    //             >
    //               {permissionsList?.map((item) => (
    //                 <MenuItem
    //                   key={item}
    //                   value={item}
    //                   style={{
    //                     display: "flex",
    //                     alignItems: "center",
    //                     // You can add more styles here if needed
    //                   }}
    //                 >
    //                   {item}
    //                 </MenuItem>
    //               ))}
    //             </Select> */}
    //               <Select
    //                 id="resource"
    //                 placeholder={false}
    //                 aria-label="resource"
    //                 isMulti
    //                 options={permissionsList}
    //                 className="basic-multi-select"
    //                 classNamePrefix="select"
    //                 defaultValue={policyFields[index].resource}
    //                 hideSelectedOptions={false}
    //                 noOptionsMessage={() => "No options available"}
    //                 onChange={(event) =>
    //                   handlePolicyChange(event, index, "resource")
    //                 }
    //               />
    //             </div>

    //             <div
    //               style={{
    //                 maxWidth: "285px",
    //                 maxHeight: "80px",
    //                 marginBottom: "1rem",
    //               }}
    //             >
    //               <label htmlFor="permission">Permission</label>
    //               {/* <Select
    //               labelId="permission"
    //               id="permission"
    //               name="permissions"
    //               onChange={(event) => handlePolicyChange(event, index)}
    //               value={policy.permissions}
    //               style={{ width: "100%" }}
    //               renderValue={(selected) => (
    //                 <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.2 }}>
    //                   <Chip
    //                     key={selected}
    //                     label={selected}
    //                     size="small"
    //                     onMouseDown={(event) => {
    //                       event.stopPropagation();
    //                       handlePermissionDelete(event, index);
    //                     }}
    //                     // onDelete={(event) => {
    //                     //   handlePolicyDelete(event, index);
    //                     // }}
    //                     // deleteIcon={<CloseIcon />}
    //                   />

    //                 </Box>
    //               )}
    //             >
    //               {permissionsList?.map((item) => (
    //                 <MenuItem
    //                   key={item}
    //                   value={item}
    //                   style={{
    //                     display: "flex",
    //                     alignItems: "center",
    //                     // You can add more styles here if needed
    //                   }}
    //                 >
    //                   {item}
    //                 </MenuItem>
    //               ))}
    //             </Select> */}
    //               <Select
    //                 id="permission"
    //                 placeholder={false}
    //                 aria-label="permission"
    //                 options={permissionsList}
    //                 className="basic-multi-select"
    //                 classNamePrefix="select"
    //                 defaultValue={policyFields[index].permissions}
    //                 // isClearable={true}
    //                 hideSelectedOptions={false}
    //                 noOptionsMessage={() => "No options available"}
    //                 onChange={(event) =>
    //                   handlePolicyChange(event, index, "permissions")
    //                 }
    //               />
    //             </div>

    //             <div
    //               style={{
    //                 maxWidth: "285px",
    //                 maxHeight: "80px",
    //                 marginBottom: "1rem",
    //               }}
    //             >
    //               <InputLabel htmlFor="operation">Operation</InputLabel>
    //               {/* <Select
    //               multiple
    //               labelId="operation"
    //               id="operation"
    //               name="operations"
    //               onChange={(event) => handlePolicyChange(event, index)}
    //               value={policy.operations}
    //               style={{ width: "100%" }}
    //               renderValue={(selected) => (
    //                 <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.2 }}>
    //                   {selected.map((value) => (
    //                     <Chip
    //                       key={value}
    //                       label={value}
    //                       size="small"
    //                       onMouseDown={(event) => {
    //                         event.stopPropagation();
    //                         handleOperationDelete(event, index);
    //                       }}
    //                       // onDelete={(event) => {
    //                       //   handlePolicyDelete(event, index);
    //                       // }}
    //                       // deleteIcon={<CloseIcon />}
    //                     />
    //                   ))}
    //                 </Box>
    //               )}
    //             >
    //               {permissionsList?.map((item) => (
    //                 <MenuItem
    //                   key={item}
    //                   value={item}
    //                   style={{
    //                     display: "flex",
    //                     alignItems: "center",
    //                     // You can add more styles here if needed
    //                   }}
    //                 >
    //                   {item}
    //                 </MenuItem>
    //               ))}
    //             </Select> */}
    //               <Select
    //                 id="operation"
    //                 placeholder={false}
    //                 aria-label="operation"
    //                 options={permissionsList}
    //                 isMulti
    //                 className="basic-multi-select"
    //                 classNamePrefix="select"
    //                 defaultValue={policyFields[index].operations}
    //                 hideSelectedOptions={false}
    //                 noOptionsMessage={() => "No options available"}
    //                 onChange={(event) =>
    //                   handlePolicyChange(event, index, "operations")
    //                 }
    //               />
    //             </div>

    //             <div
    //               style={{
    //                 maxWidth: "285px",
    //                 maxHeight: "80px",
    //                 marginBottom: "1rem",
    //               }}
    //             >
    //               <label htmlFor="domains">Domain</label>
    //               {/* <Select
    //               multiple
    //               labelId="domains"
    //               id="domains"
    //               name="domains"
    //               onChange={(event) => handlePolicyChange(event, index)}
    //               value={policy.domains}
    //               style={{ width: "100%" }}
    //               renderValue={(selected) => (
    //                 <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.2 }}>
    //                   {selected.map((value) => (
    //                     <Chip
    //                       key={value}
    //                       label={value}
    //                       size="small"
    //                       onMouseDown={(event) => {
    //                         event.stopPropagation();
    //                         handleDomainDelete(event, index);
    //                       }}
    //                       // onDelete={handlePolicyDelete}
    //                       // deleteIcon={<CloseIcon />}
    //                     />
    //                   ))}
    //                 </Box>
    //               )}
    //             >
    //               {permissionsList?.map((item) => (
    //                 <MenuItem
    //                   key={item}
    //                   value={item}
    //                   style={{
    //                     display: "flex",
    //                     alignItems: "center",
    //                     // You can add more styles here if needed
    //                   }}
    //                 >
    //                   {item}
    //                 </MenuItem>
    //               ))}
    //             </Select> */}
    //               <Select
    //                 id="domains"
    //                 placeholder={false}
    //                 aria-label="domains"
    //                 options={permissionsList}
    //                 isMulti
    //                 className="basic-multi-select"
    //                 classNamePrefix="select"
    //                 defaultValue={policyFields[index].domains}
    //                 hideSelectedOptions={false}
    //                 noOptionsMessage={() => "No options available"}
    //                 onChange={(event) =>
    //                   handlePolicyChange(event, index, "domains")
    //                 }
    //               />
    //             </div>
    //           </div>
    //         </div>
    //       );
    //     })}

    //     <Box>
    //       <DialogActions sx={{ justifyContent: "flex-end" }}>
    //         <button
    //           className="rounded-md job-btn-plain btn"
    //           style={{
    //             display: "flex",
    //             alignItems: "center",
    //             justifyContent: "center",
    //             gap: "0.8rem",
    //             color: "#ffffff",
    //             backgroundColor: "#2e7d32",
    //           }}
    //           onClick={addPolicy}
    //         >
    //           <AddCircleOutlineIcon /> <span>Add Policy</span>
    //         </button>
    //       </DialogActions>
    //     </Box>

    //     <Box sx={{ pb: 2, px: 2, m: 8 }}>
    //       <DialogActions sx={{ justifyContent: "center" }}>
    //         <button
    //           className="rounded-md job-btn btn"
    //           style={{ width: "28%", height: "2.8rem", fontSize: "1.2rem" }}
    //           // fullWidth variant="contained"
    //           onClick={handleSubmit}
    //         >
    //           Submit
    //         </button>
    //       </DialogActions>
    //     </Box>
    //     {/* {openModal && <WarningPopUp />} */}
    //   </div>
    // </div>

    <div>
      <Dialog open={boolean} onClose={() => onToggle(row, tableType)}>
        <DialogContent style={{ width: "600px", height: "40vh" }}>
          <Grid container mb={3}>
            <Grid item xs={11}>
              <Typography variant="h6" component="h2">
                Edit Resource Data
              </Typography>
            </Grid>
            <Grid color="text.secondary" item xs={1}>
              <CloseIcon
                onClick={() => onToggle(null, tableType)}
                sx={{
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Application
            </Typography>
            <Select
              style={{ width: "71.5%", margin: "0", padding: "0" }}
              labelId="application"
              id="application"
              size="small"
              // multiple
              value={appName}
              onChange={handleAppChange}
              // input={<OutlinedInput id="select-multiple-chip" label="Application" />}
              // renderValue={(selected) => (
              //   <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              //     {selected.map((value) => (
              //       <Chip key={value} label={value} />
              //     ))}
              //   </Box>
              // )}
              // MenuProps={MenuProps}
            >
              {applicationData?.map((appData) => (
                <MenuItem
                  key={appData.id}
                  value={appData.id}
                  name={appData.name}
                  // style={getStyles(name, personName, theme)}
                >
                  {appData.name}
                </MenuItem>
              ))}
            </Select>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Name
            </Typography>
            <TextField
              value={values.name}
              onChange={handleChange}
              name="name"
              label="Name"
              margin="dense"
              // fullWidth
              size="small"
              style={{ width: "71.5%" }}
              // disabled
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Description
            </Typography>
            <TextField
              value={values.description}
              onChange={handleChange}
              name="description"
              label="Description"
              margin="dense"
              // fullWidth
              size="small"
              style={{ width: "71.5%" }}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "10px 0",
            }}
          >
            {/* <TextField
              sx={{ minWidth: "85%" }}
              size="small"
              margin="dense"
              // name="permissionsArray"
              placeholder="Permissions"
              value={permissionsValue}
              onChange={handleInputChange}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleAddToDataArray}
            >
              Add
            </Button> */}
            {/* <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Permissions
            </Typography>
            <Select
              style={{ width: "71.5%", margin: "4px 0", padding: "0" }}
              labelId="permissions"
              id="permissions"
              size="small"
              multiple
              value={permName}
              onChange={handlePermChange}
              // input={<OutlinedInput id="select-multiple-chip" label="Application" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              // MenuProps={MenuProps}
            >
              {permissionData?.map((permData) => (
                <MenuItem
                  key={permData.id}
                  value={permData.name}
                  // name={permData.name}
                  // style={getStyles(name, personName, theme)}
                >
                  {permData.name}
                </MenuItem>
              ))}
            </Select> */}
          </div>

          {/* {permissionsArray.length > 0 && (
            <div className="enteredText" style={{ margin: "10px 0 0 0" }}>
              <ul
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                {permissionsArray.map((data, index) => (
                  <li key={index}>
                    {data}
                    <span
                      className="cancel"
                      onClick={() => handleDelete(index)}
                    >
                      x
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          )} */}
        </DialogContent>
        <Box sx={{ pb: 2, px: 2 }}>
          <DialogActions>
            <Button variant="contained" onClick={handleSubmit}>
              Update
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
};

export default ResourceTableEdit;
