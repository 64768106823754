import { Box } from "@mui/material";
// import "../dashboard/DashBoardTabs.scss";
import { Link, useLocation } from "react-router-dom";
import { useValue } from "../../context/ContextProvider";

function DashBoardTabs() {
  const { pathname } = useLocation();
  let part = pathname.split("/");
  const { dispatch, state: currentFilter } = useValue();
  //
  const handleClick = (newFilter) => {
    dispatch({
      type: "UPDATE_FILTER",
      payload: newFilter,
    });
  };
  // console.log(currentFilter)

  return (
    <Box boxShadow={"small"} className="project-filter">
      <nav>
        <Link to="/">
          <button
            onClick={() => handleClick("Manage Jobs")}
            className={part[1] === "" ? "active" : ""}
          >
            Manage Jobs
          </button>
        </Link>
        <Link to="/jobRequest">
          <button
            onClick={() => handleClick("Job Requests")}
            className={part[1] === "jobRequest" ? "active" : ""}
          >
            Job Requests
          </button>
        </Link>
        <Link to="/manageProjects">
          <button
            onClick={() => handleClick("Manage Projects")}
            className={part[1] === "manageProjects" ? "active" : ""}
          >
            Manage Products
          </button>
        </Link>
        <Link to="/manageDatasources">
          <button
            onClick={() => handleClick("Manage Datasources")}
            className={part[1] === "manageDatasources" ? "active" : ""}
          >
            Manage Datasources
          </button>
        </Link>
        <Link to="/manageMetadatacrawls">
          <button
            onClick={() => handleClick("Manage MetadataCrawls")}
            className={part[1] === "manageMetadatacrawls" ? "active" : ""}
          >
            Manage MetadataCrawls
          </button>
        </Link>
        <Link to="/manageAuthorization">
          <button
            onClick={() => handleClick("Manage Authorization")}
            className={part[1] === "manageAuthorization" ? "active" : ""}
          >
            Manage Authorization
          </button>
        </Link>
        <Link to="/manageAuditLog">
          <button
            onClick={() => handleClick("Manage Audit Log")}
            className={part[1] === "manageAuditLog" ? "active" : ""}
          >
            Manage Audit Log
          </button>
        </Link>
      </nav>
    </Box>
  );
}

export default DashBoardTabs;
