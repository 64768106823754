import axios from "axios";

export const MARTBUILDER_API = "https://datamart-api-dev.maestro.maersk.com";
// export const MARTBUILDER_PREPROD_API = "https://datamart-api-preprod.maersk-digital.net";
export const DATABOOK_API = "https://databook-api-dev.maestro.maersk.com";
export const EMP_CONSUMER_API = "https://empconsumer-api.dev.maersk-digital.net";

export const userRequestMartbuilder = axios.create({
  baseURL: MARTBUILDER_API,
});

// export const userRequestMartbuilderPreprod = axios.create({
//   baseURL: MARTBUILDER_PREPROD_API,
// });

export const userRequestDataBook = axios.create({
  baseURL: DATABOOK_API,
});

export const userRequestEmpConsumerAPI = axios.create({
  baseURL: EMP_CONSUMER_API,
});

// {
//             headers: {
//               // Define your custom headers here
//               Authorization: `Bearer ${dataBookToken.access_token}`,
//               "Content-Type": "application/json",
//             },
//             CancelToken: ourRequest.token,
//           }
