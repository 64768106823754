import React, { useContext, useEffect, useState } from "react";
import { userRequestMartbuilder } from "../../requestMethod";
import axios from "axios";
import { AuthContext } from "../../AuthContext";
import { useFormContext, Controller, useFieldArray } from "react-hook-form";

import {
  FormControl,
  InputLabel,
  // MenuItem,
  // Select,
  // TextField,
} from "@mui/material";
import { useValue } from "../../context/ContextProvider";
import { MenuItem, TextField, Box } from "@mui/material";
import { Select as MUISelect } from "@mui/material";
import Select from "react-select";
import "./IngestionForm.css";
import TransformationModal from "./transformationModal/TransformationModal";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const ColumnsTranformation = () => {
  const { control, setValue, getValues } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "batchIngestionTasks[0].sourceTargetColumnMapping",
  });
  const {
    dispatch,
    state: { datasetDetails, dataSources, transformationCatalog },
  } = useValue();
  // console.log(transformationCatalog);

  const { getToken, token, error } = useContext(AuthContext);
  const [transExpVal, setTransExpVal] = useState();
  const [transExpValName, setTransExpValName] = useState(false);
  // const [transExpValName, setTransExpValName] = useState([]);
  const [TransformationCatalog, setTransformationCatalog] = useState("");
  //below usestate for multiselect drop-down
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [activeRowIndex, setActiveRowIndex] = useState(null);

  const [payload, setPayloadd] = useState();

  const transformedCatalogOptions = transformationCatalog?.map((item) => ({
    value: item,
    label: item,
  }));

  //useState to handle transformation modal
  const [openTranformationModal, setOpenTranformationModal] = useState(false);

  // const handletransExpVal = (event) => {
  //   const selectedId = event.target.value;
  //   console.log(selectedId);
  //   setTransExpValName(selectedId);
  // };

  const fetchTransExpVal = async () => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(
        `/transformationCatalog`,
        {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      setTransExpVal(response?.data);
      console.log(response?.data);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
  };

  const fetchIndTransExpVal = async (transformationExpression) => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(
        `/transformationCatalog/${transformationExpression}`,
        {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      setTransExpValName(response?.data.transformationArguments);
      console.log(response?.data.transformationArguments);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
  };

  const handleTransformation = (index) => {
    // console.log("modalopen");
    setActiveRowIndex(index);
    setOpenTranformationModal(!openTranformationModal);
  };
  
  const handleTransformationSubmit = (transformationExpression) => {
    let selectedValue = getValues(
      `batchIngestionTasks[0].sourceTargetColumnMapping[${activeRowIndex}].transformations`
    );

    console.log("Received transformationExpression:", transformationExpression);

    if (activeRowIndex !== null) {
      setPayloadd([...selectedValue, transformationExpression]);
      setValue(
        `batchIngestionTasks[0].sourceTargetColumnMapping[${activeRowIndex}].transformations`,
        [...selectedValue, transformationExpression]
      );
    }
  };

  const handleRemoveTransformation = (fieldIndex, transformationIndex) => {
    const currentTransformations = getValues(
      `batchIngestionTasks[0].sourceTargetColumnMapping[${fieldIndex}].transformations`
    );
    const updatedTransformations = currentTransformations.filter(
      (_, idx) => idx !== transformationIndex
    );
    setValue(
      `batchIngestionTasks[0].sourceTargetColumnMapping[${fieldIndex}].transformations`,
      updatedTransformations
    );
  };

  return (
    <>
      {datasetDetails && (
        <div
          className="columntransf-main"
          // style={{
          // maxWidth: "100%",
          // marginTop: "40px",
          // overflow: "scroll",
          // marginBottom: "20px",
          // padding: "12px 35px",
          // display: "flex",
          // flexDirection: "column",
          // gap: "10px",
          // background: "#f5f5f5de",
          // borderRadius: "5px",
          // }}
        >
          {/* Headers */}
          <div
            className={
              transExpValName
                ? "columntransf-header-args"
                : "columntransf-header"
            }
            // style={{
            // display: "grid",
            // gridTemplateColumns: "1.8fr 1.2fr 1.8fr 1.2fr 3fr",
            // columnGap: "25px",

            // fontSize: "12px",
            // }}
          >
            <h3 className="small-h3">Source Columns</h3>
            <h3 className="small-h3">Source Type</h3>
            <h3 className="small-h3">Target Columns</h3>
            <h3 className="small-h3">Target Type</h3>
            <h3 className="small-h3">Transformation Expression</h3>

            {/* <div
        style={{
          marginTop: "15px",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          columnGap: "150px",
        }}
      >
        <Controller
          control={control}
          name="product.id"
          render={({ field }) => (
            <FormControl
              size="small"
              variant="outlined"
              sx={{ mb: 2, mt: 2, minWidth: 120 }}
            ></FormControl>
          )}
        />
      </div> */}
          </div>
          {fields.map((task, index) => (
            <div
              className={
                transExpValName
                  ? "columntransf-taskcol-args"
                  : "columntransf-taskcol"
              }
              style={
                transExpValName
                  ? {
                      gridTemplateColumns: `repeat(${
                        transExpValName.length + 5
                      }, 1.8fr)`,
                    }
                  : { gridTemplateColumns: "1.8fr 1.2fr 1.8fr 1.2fr 3fr 0.8fr" }
              }
              key={task.id}
              // style={{
              // display: "grid",
              // gridTemplateColumns: "1.8fr 1.2fr 1.8fr 1.2fr 3fr",
              // columnGap: "25px",
              // fontSize: "12.5px",
              // }}
            >
              {/* <h4 style={{ paddingBlock: "4px" }}>Task {index + 1}</h4> */}
              {/* <div style={{ display: "flex", gap: "10px" }}> */}
              <Controller
                name={`batchIngestionTasks[0].sourceTargetColumnMapping[${index}].sourceColumnName`}
                control={control}
                // defaultValue={task.id}

                render={({ field }) => (
                  <>
                    <TextField
                      id="Source Column"
                      // label="Source Column"
                      // fullWidth
                      size="small"
                      disabled
                      placeholder={`tasks[${index}].name`}
                      {...field}
                    />
                  </>
                )}
              />
              <Controller
                name={`batchIngestionTasks[0].sourceTargetColumnMapping[${index}].sourceType`}
                control={control}
                // defaultValue={task.id}
                render={({ field }) => (
                  <>
                    <TextField
                      id="Source DataType"
                      // label="Source DataType"
                      // fullWidth
                      disabled
                      size="small"
                      // placeholder={`tasks[${index}].name`}
                      {...field}
                    />
                  </>
                )}
              />

              <Controller
                name={`batchIngestionTasks[0].sourceTargetColumnMapping[${index}].targetColumnName`}
                control={control}
                // defaultValue={task.id}
                render={({ field }) => (
                  <>
                    <TextField
                      id="Target Column"
                      // label="Target Column"
                      // fullWidth
                      size="small"
                      placeholder={`tasks[${index}].name`}
                      {...field}
                    />
                  </>
                )}
              />
              <Controller
                name={`batchIngestionTasks[0].sourceTargetColumnMapping[${index}].destinationType`}
                control={control}
                // defaultValue={task.id}
                render={({ field }) => (
                  <>
                    <TextField
                      id="Source DataType"
                      // label="Source DataType"
                      // fullWidth

                      size="small"
                      // placeholder={`tasks[${index}].name`}
                      {...field}
                    />
                  </>
                )}
              />
              <div style={{ display: "flex", alignItems: "center" }}>
                <Controller
                  control={control}
                  name={`batchIngestionTasks[0].sourceTargetColumnMapping[${index}].transformations`}
                  render={({ field }) => {
                    return (
                      <div
                        style={{
                          border: "1px solid grey",
                          minWidth: "270px",
                          minHeight: "40px",
                          borderRadius: "4px",
                        }}
                        {...field}
                      >
                        {field.value.map((transformation, idx) => {
                          console.log(transformation, "transformation m");
                          const transformationType =
                            Object.keys(transformation)[0];
                          const transformationArgs =
                            transformation[transformationType];
                          const argsString = Object.entries(transformationArgs)
                            .map(([key, value]) => ` ${key}: ${value}`)
                            .join(", ");
                          return (
                            // <p
                            //   key={idx}
                            //   style={{
                            //     border: "1px solid grey",
                            //     backgroundColor: "",
                            //     borderRadius: "3px",
                            //     margin: "0px",
                            //     padding: "1px",
                            //   }}
                            // >
                            //   {transformationType}: {argsString}
                            // </p>
                            <div
                              key={idx}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                border: "1px solid grey",
                                borderRadius: "3px",
                                padding: "1px 3px",
                                // margin: "2px 0",
                              }}
                            >
                              <span>
                                {transformationType} {argsString}
                              </span>
                              <span
                                style={{ color: "red", cursor: "pointer", fontWeight: "bold"  }}
                                onClick={() =>
                                  handleRemoveTransformation(index, idx)
                                }
                              >
                                X
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    );
                  }}
                />
                <AddCircleOutlineIcon
                  style={{
                    fontSize: 22,
                    cursor: "pointer",
                    marginLeft: "13px",
                  }}
                  onClick={() => handleTransformation(index)}
                />
              </div>
              {/* <Controller
                name={`batchIngestionTasks[0].sourceTargetColumnMapping[${index}].transformationExpression`}
                control={control}
                // defaultValue={task.id}
                render={({ field }) => (
                  <> */}

              {/* <TextField
                      id="Expression"
                      // label="Expression"
                      placeholder="Expression"
                      // fullWidth
                      size="small"
                      // placeholder={`tasks[${index}].name`}
                      {...field}
                      onChange={(e) => field.onChange(e.target.value.trim())}
                    /> */}
              {/* <Select
                      // sx={{ width: "500px" }}
                      id="Expression"
                      // label="Expression"
                      placeholder="Expression"
                      size="small"
                      // name="Expression"
                      // labelId="Expression"
                      {...field}
                      // label="Choose Datasource Connection"
                      onChange={(e) => {
                        fetchIndTransExpVal(e.target.value);
                        setTaskId(task.id);
                        // setTaskId((oldArray) => [...oldArray, task.id]);
                        setAddExpFlag(!addExpFlag);
                        return field.onChange(e.target.value.trim());
                      }}
                    >
                      {transExpVal &&
                        transExpVal?.map((item) => (
                          <MenuItem
                            key={item?.transformationExpression}
                            value={item?.transformationExpression}
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {item?.transformationExpression}
                          </MenuItem>
                        ))}
                    </Select> */}
              {/* </>
                )}
              /> */}

              {/* {transExpValName &&
                      transExpValName?.map((item) => (
                  <Controller
                    name={`batchIngestionTasks[0].sourceTargetColumnMapping[${index}].transformationArguments`}
                    control={control}
                    // defaultValue={task.id}
                    render={({ field }) => (
                      <>
                        <TextField
                          id="Arguments"
                          // label="Arguments"
                          placeholder="Arguments"
                          // fullWidth
                          size="small"
                          // placeholder={`tasks[${index}].name`}
                          {...field}
                          value={item}
                          onChange={(e) =>
                            field.onChange(e.target.value.trim())
                          }
                        />
                      </>
                    )}
                  />
                ))} */}

              {/* <Controller
              name={`tasks[${index}].targetName`}
              control={control}
              defaultValue={task.text}
              render={({ field }) => (
                <>
                  <TextField
                    id="Target Table"
                    label="Target Table"
                    fullWidth
                    size="small"
                    // placeholder={`tasks[${index}].name`}
                    {...field}
                  />
                </>
              )}
            /> */}
              {/* </div> */}
            </div>
          ))}
        </div>
      )}
      {openTranformationModal && (
        <Box sx={{ position: "absolute", top: "100%", left: 0 }}>
          <TransformationModal
            // onToggle={handleTransformation}
            boolean={openTranformationModal}
            onOpen={handleTransformation}
            onSubmit={handleTransformationSubmit}
            usePayload={false}
          />
        </Box>
      )}
    </>
  );
};

export default ColumnsTranformation;
