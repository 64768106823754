import { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
// import Modal from "@mui/material/Modal";
// import { useFormContext, Controller } from "react-hook-form";
// import ReactSelect from "react-select";
// import axios from "axios";
import { userRequestMartbuilder } from "../../requestMethod";
import {
  Dialog,
  Grid,
  // DialogTitle,
  // TableContainer,
  DialogContent,
  // DialogContentText,
  DialogActions,
} from "@mui/material";
import { AuthContext } from "../../AuthContext";
import axios from "axios";
import "./IngestionForm.css";

const initialValues = {
  profileName: "",
  publishMessage: "",
  startDate: "",
  endDate: "",
  timezone: "UTC",
  notificationFailureEmail: "",
  notificationMissedSLAEmail: "",
  notificationSuccessEmail: "",
  cronSchedule: "",
  sla: 0,
  retryCount: 0,
  retryInterval: 0,
  runAsAccount: "",
};

function ScheduleModal({ boolean, onOpen, onDataFetch }) {
  // const { control, handleSubmit } = useFormContext();
  const [showEmptyFieldMessage, setShowEmptyFieldMessage] = useState(false);

  const { values, errors, handleBlur, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      // console.log(values);
      handleSubmitFn(values);
    },
  });
  const { getToken, token, error } = useContext(AuthContext);

  const InputField = {
    padding: "8px",
  };

  const inputFieldWrapper = {
    display: "flex",
    justifyContent: "space-between",
    margin: "25px auto",
  };

  const handleSubmitFn = async (values) => {
    // console.log("event", values);
    // data.retryCount = parseInt(data.retryCount);
    // data.retryInterval = parseInt(data.retryInterval);
    // data.sla = parseInt(data.sla);
    const requestData = values;
    console.log("Request data:", requestData);
    const ourRequest = axios.CancelToken.source();
    try {
      const response = await userRequestMartbuilder.post(
        "/schedule",
        requestData,
        {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      console.log("Response:", response.data);
      onOpen();
    } catch (error) {
      console.error("Error:", error);
      if (error.response && error.response.data) {
        console.error("API Error:", error.response.data);
      }
    }
    onDataFetch();
  };

  return (
    <div style={{}}>
      <Dialog open={boolean} onClose={onOpen} maxWidth={"md"} fullWidth>
        <DialogContent style={{}}>
          <Grid container mb={3}>
            <Grid item xs={11}>
              <Typography variant="h6" component="h2">
                Create Schedule
              </Typography>
            </Grid>
          </Grid>
          <form onSubmit={handleSubmit}>
            <div style={inputFieldWrapper}>
              <div>
                <label
                  htmlFor=""
                  style={{
                    display: "block",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  Profile Name
                </label>
                <input
                  type="text"
                  name="profileName"
                  id="profileName"
                  placeholder="Profile Name"
                  value={values.profileName}
                  onChange={handleChange}
                  style={InputField}
                />
              </div>
              <div>
                <label
                  htmlFor=""
                  style={{
                    display: "block",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  Publish Message
                </label>
                <input
                  type="text"
                  name="publishMessage"
                  id="publishMessage"
                  placeholder="Publish Message"
                  value={values.publishMessage}
                  onChange={handleChange}
                  style={InputField}
                />
              </div>
            </div>
            <div style={inputFieldWrapper}>
              <div>
                <label
                  htmlFor=""
                  style={{
                    display: "block",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  Start Date
                </label>
                <input
                  type="date"
                  name="startDate"
                  id="startDate"
                  placeholder="Start Date"
                  value={values.startDate}
                  onChange={handleChange}
                  style={InputField}
                />
              </div>
              <div>
                <label
                  htmlFor=""
                  style={{
                    display: "block",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  End Date
                </label>
                <input
                  type="date"
                  name="endDate"
                  id="endDate"
                  placeholder="End Date"
                  value={values.endDate}
                  onChange={handleChange}
                  style={InputField}
                />
              </div>
            </div>
            <div style={inputFieldWrapper}>
              <div>
                <label
                  htmlFor=""
                  style={{
                    display: "block",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  Time Zone
                </label>
                <input
                  type="text"
                  name="timezone"
                  id="timezone"
                  placeholder="Time Zone"
                  value={"UTC"}
                  onChange={handleChange}
                  style={InputField}
                  disabled
                />
              </div>
              <div>
                <label
                  htmlFor=""
                  style={{
                    display: "block",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  Notification Failure Email
                </label>
                <input
                  type="text"
                  name="notificationFailureEmail"
                  id="notificationFailureEmail"
                  placeholder="Notification Failure Email"
                  value={values.notificationFailureEmail}
                  onChange={handleChange}
                  style={InputField}
                />
              </div>
            </div>
            <div style={inputFieldWrapper}>
              <div>
                <label
                  htmlFor=""
                  style={{
                    display: "block",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  Notification MissedSLA Email
                </label>
                <input
                  type="text"
                  name="notificationMissedSLAEmail"
                  id="notificationMissedSLAEmail"
                  placeholder="Notification MissedSLA Email"
                  value={values.notificationMissedSLAEmail}
                  onChange={handleChange}
                  style={InputField}
                />
              </div>
              <div>
                <label
                  htmlFor=""
                  style={{
                    display: "block",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  Notification Success Email
                </label>
                <input
                  type="text"
                  name="notificationSuccessEmail"
                  id="notificationSuccessEmail"
                  placeholder="Notification Success Email"
                  value={values.notificationSuccessEmail}
                  onChange={handleChange}
                  style={InputField}
                />
              </div>
            </div>
            <div style={inputFieldWrapper}>
              <div>
                <label
                  htmlFor=""
                  style={{
                    display: "block",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  CronSchedule
                </label>
                <input
                  type="text"
                  name="cronSchedule"
                  id="cronSchedule"
                  placeholder="cronSchedule"
                  value={values.cronSchedule}
                  onChange={handleChange}
                  style={InputField}
                />
              </div>
              <div>
                <label
                  htmlFor=""
                  style={{
                    display: "block",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  SLA
                </label>
                <input
                  type="number"
                  name="sla"
                  id="sla"
                  placeholder="SLA"
                  value={values.sla}
                  onChange={handleChange}
                  style={InputField}
                />
              </div>
            </div>
            <div style={inputFieldWrapper}>
              <div>
                <label
                  htmlFor=""
                  style={{
                    display: "block",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  Retry Count
                </label>
                <input
                  type="number"
                  name="retryCount"
                  id="retryCount"
                  placeholder="Retry Count"
                  value={values.retryCount}
                  onChange={handleChange}
                  style={InputField}
                />
              </div>
              <div>
                <div>
                  <label
                    htmlFor=""
                    style={{
                      display: "block",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    Retry Interval
                  </label>
                  <input
                    type="number"
                    name="retryInterval"
                    id="retryInterval"
                    placeholder="Retry Interval"
                    value={values.retryInterval}
                    onChange={handleChange}
                    style={InputField}
                  />
                </div>
              </div>
            </div>
            <div style={inputFieldWrapper}>
              <div>
                <label
                  htmlFor=""
                  style={{
                    display: "block",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  Run As Account
                </label>
                <input
                  type="text"
                  name="runAsAccount"
                  id="runAsAccount"
                  placeholder="Run As Account"
                  value={values.runAsAccount}
                  onChange={handleChange}
                  style={InputField}
                />
              </div>
            </div>

            {/*   <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Controller
              control={control}
              name="profileName"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  // InputLabelProps={{ shrink: true }}
                  id="profile-Name"
                  label="Profile Name"
                  variant="outlined"
                  size="small"
                  placeholder="Profile Name"
                  type="text"
                  // fullWidth
                  style={{ width: "350px" }}
                  margin="normal"
                  {...field} 
                />
              )}
            />
            <Controller
              control={control}
              name="publishMessage"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  id="publish-Message"
                  label="Publish Message"
                  variant="outlined"
                  size="small"
                  placeholder="Publish Message"
                  type="text"
                  // fullWidth
                  style={{ width: "350px"}}
                  margin="normal"
                  {...field}
                />
              )}
            />
          </Box>
         <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Controller
              control={control}
              name="startDate"
              render={({ field }) => (
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="start-date"
                  label="Start Date"
                  variant="outlined"
                  size="small"
                  type="date"
                  style={{ width: "350px" }}
                  margin="normal"
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="endDate"
              render={({ field }) => (
                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="end-date"
                  label="End Date"
                  variant="outlined"
                  size="small"
                  type="date"
                  style={{ width: "350px" }}
                  margin="normal"
                  {...field}
                />
              )}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Controller
              control={control}
              name="timezone"
              render={({ field }) => (
                <FormControl
                  size="small"
                  variant="outlined"
                  sx={{ mb: 2, mt: 2 }}
                  style={{ width: "350px" }}
                >
                  <InputLabel id="select-timezone">Timezone</InputLabel>
                  <Select
                    labelId="select-timezone"
                    id="timezone"
                    label="timezone"
                    {...field}
                    // TODO
                    disabled
                  >
                    <MenuItem value="UTC">UTC</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
            <Controller
              control={control}
              name="notificationFailureEmail"
              render={({ field }) => (
                <TextField
                  // InputLabelProps={{ shrink: true }}
                  id="notify-failure-email"
                  label="Notify Failure"
                  variant="outlined"
                  size="small"
                  placeholder="Enter email"
                  type="email"
                  // fullWidth
                  margin="normal"
                  style={{ width: "350px" }}
                  {...field}
                />
              )}
            />
          </Box>

          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Controller
              control={control}
              name="notificationMissedSLAEmail"
              render={({ field }) => (
                <TextField
                  // InputLabelProps={{ shrink: true }}
                  id="notify-missed-sla-email"
                  label="Notify Missed SLA"
                  variant="outlined"
                  size="small"
                  placeholder="Enter email"
                  type="email"
                  // fullWidth
                  style={{ width: "350px" }}
                  margin="normal"
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="notificationSuccessEmail"
              render={({ field }) => (
                <TextField
                  id="notify-Success-email"
                  label="Notify Success"
                  variant="outlined"
                  size="small"
                  placeholder="Enter email"
                  type="email"
                  // fullWidth
                  style={{ width: "350px" }}
                  margin="normal"
                  {...field}
                />
              )}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Controller
              control={control}
              name="sla"
              render={({ field }) => (
                <TextField
                  id="sla"
                  label="SLA min"
                  variant="outlined"
                  type="number"
                  placeholder="Enter SLA"
                  //   fullWidth
                  style={{ width: "350px" }}
                  margin="normal"
                  size="small"
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="cronSchedule"
              defaultValue=""
              render={({ field }) => (
                <TextField
                  id="cronSchedule"
                  label="Schedule"
                  variant="outlined"
                  placeholder="Enter Schedule"
                  //   fullWidth
                  style={{ width: "350px" }}
                  margin="normal"
                  size="small"
                  {...field}
                />
              )}
            />
          </Box>

          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Controller
              control={control}
              name="retryCount"
              render={({ field }) => (
                <TextField
                  id="retryCount"
                  label="Retry Count"
                  variant="outlined"
                  size="small"
                  type="number"
                  //   fullWidth
                  style={{ width: "350px" }}
                  margin="normal"
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="retryInterval"
              render={({ field }) => (
                <TextField
                  id="retryInterval"
                  label="Retry Interval"
                  variant="outlined"
                  size="small"
                  type="number"
                  //   fullWidth
                  style={{ width: "350px" }}
                  margin="normal"
                  {...field}
                />
              )}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Controller
              control={control}
              name="runAsAccount"
              render={({ field }) => (
                <TextField
                  id="runAsAccount"
                  label="Run As Account"
                  variant="outlined"
                  size="small"
                  //   fullWidth
                  style={{ width: "350px" }}
                  margin="normal"
                  sx={{ mt: 4.3 }}
                  {...field}
                />
              )}
            />
          </Box> */}

            {showEmptyFieldMessage && (
              <Typography
                sx={{ fontSize: "14px", color: "red", margin: "5px 5px" }}
              >
                Please enter the name
              </Typography>
            )}
            <Box sx={{ pb: 2, px: 2 }}>
              <DialogActions>
                <Button
                  type="submit"
                  style={{
                    width: "50%",
                    margin: "0px auto",
                  }}
                  variant="contained"
                  // onClick={handleSubmit(onSubmit)}
                >
                  Submit
                </Button>
              </DialogActions>
            </Box>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={onOpen}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ScheduleModal;
