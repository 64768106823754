import React from "react";
import { useState, useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { useForm } from "react-hook-form";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import { userRequestEmpConsumerAPI } from "../../requestMethod";

const StreamingSyncForm = () => {
  // const [empVersion, setEmpVersion] = useState("");
  const [validationResponse, setValidationResponse] = useState();
  const [isApiCallSuccessful, setIsApiCallSuccessful] = useState(false);
  const [partitions, setPartitions] = useState();
  const [topicName, setTopicName] = useState();
  const [dlqName, setDlqName] = useState("");

  // const [keyType, setKeyType] = useState("");
  // const [valueType, setValueType] = useState("");
  // const [enableautocommit, setEnableautocommit] = useState(false);
  // const [autooffsetreset, setAutooffsetreset] = useState("earliest");
  // const [concurrencyValue, setConcurrencyValue] = useState("");
  // const [enableExactlyOnceDelivery, setEnableExactlyOnceDelivery] =
  //   useState(false);
  // const [adlsGen, setAdlsGen] = useState("");

  useEffect(() => {
    if (topicName) {
      setDlqName(topicName + ".DLT");
    }
  }, [topicName]);

  const onSubmit = (data) => {
    console.log("Form Data:", data);
    SubmitFinalPayload(data)
  };

  const [payload, setPayload] = useState({
    consumerGroup: "",
    empPwdSecretKey: "",
    empUserSecretKey: "",
    empVersion: "",
    topicName: "",
    vaultName: "",
  });

  const { control, handleSubmit } = useForm({
    defaultValues: {
      additionalUsers: "",
      adlsGen: "",
      adlsAccountName: "",
      adlsPath: "",
      concurrency: null,
      consumerConfig: {
        enableAutoCommit: true,
        autoCommitIntervalMS: "",
        heartbeatIntervalMS: "",
        sessionTimeoutMS: "",
        fetchMinBytes: "",
        maxPartitionFetchBytes: "",
        fetchMaxWaitMS: "",
        fetchMaxBytes: "",
        autoOffsetReset: "earliest",
        maxPollRecords: "",
      },
      enableIdempotentConsumer: false,
      idempotentConsumerTopic: "",
      idempotentConsumerGroupId: "", //ni
      destinationFileFormat: "", //ni
      // dlqName: "apmt_cdh_datastream2_moin_crpmn_n4_prod.inv_move_event.DLT",
      dlqName: topicName,
      enableDLQ: false,
      enableDLQMessageProcessing: false,
      topicKeyType: "", //d
      topicValueType: "", //d
      userEmail: "",
      empVersion: "V3",
      topicName: "apmt_cdh_datastream2_moin_crpmn_n4_prod.inv_move_event",
      consumerGroup: "apmt_dopm2_nonprod",
      vaultName: "akvazeusdmoin",
      empPwdSecretKey: "Tos-Kafka-ApiSecret-Moin",
      empUserSecretKey: "Tos-Kafka-Apikey-Moin",
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };

  const tokenw = localStorage.getItem("userToken");

  //below code for hitting validating POST api
  const ValidateConsumerDetails = async (payload) => {
    console.log(payload, "payload");
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestEmpConsumerAPI.post(
        `/e2e/validateTenantDetails`,
        payload,
        {
          headers: {
            "Access-Control-Allow-Origin": "http://localhost:3000",
            Authorization: `Bearer ${tokenw}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      setValidationResponse(response.data);
      setIsApiCallSuccessful(true);
      setPartitions(response?.data.partitions);
      if (response.status === 200 && response.data.topicName) {
        alert("Data Validated successfully");
        setTopicName(response?.data.topicName);
      }
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  //below code for hitting validating POST api
  const SubmitFinalPayload = async (finalPayload) => {
    console.log(finalPayload, "finalPayload");
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestEmpConsumerAPI.post(
        `/e2e/registerTenantTopic`,
        finalPayload,
        {
          headers: {
            "Access-Control-Allow-Origin": "http://localhost:3000",
            Authorization: `Bearer ${tokenw}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      // setValidationResponse(response.data);
      // setIsApiCallSuccessful(true);
      // setPartitions(response?.data.partitions);
      // if (response.status === 200 && response.data.topicName) {
      //   setTopicName(response?.data.topicName + ".DLT");
      // }
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  const OnValidate = (e) => {
    console.log("validate button");
    e.preventDefault();
    ValidateConsumerDetails(payload);
  };

  const OnFinalSubmit = (e) => {
    console.log("validate button");
    e.preventDefault();
    // SubmitFinalPayload(finalPayload);
  };

  return (
    <div style={{ margin: "0px 100px" }}>
      <div style={{ border: "0.0001px solid white" }}>
        <form
          action=""
          style={{
            marginTop: "100px",
            marginBottom: "30px",
            border: "1px solid lightgray",
          }}
        >
          <fieldset
            style={{
              border: "1px solid grey",
              borderRadius: "5px",
              padding: "9px",
              paddingTop: "20px", 
              margin: "20px 10px", 
            }}
          >
            <legend>Consumer Details:</legend> <br />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <FormControl
                variant="standard"
                sx={{
                  m: 1,
                  width: "30%",
                  marginBottom: "8px",
                  marginRight: "20px",
                  marginLeft:"0"
                }}
              >
                <InputLabel id="EmpVersion">EMP-Version</InputLabel>
                <Select
                  labelId="EmpVersion"
                  id="emp_Version"
                  name="empVersion"
                  label="EMP-Version"
                  value={payload.empVersion}
                  onChange={handleChange}
                >
                  <MenuItem value={"V2"}>V2</MenuItem>
                  <MenuItem value={"V3"}>V3 Shared</MenuItem>
                </Select>
              </FormControl>

              <Controller
                name="topicName"
                control={control}
                render={({ field }) => (
                  <TextField
                    id="topic-name"
                    label="Topic Name"
                    variant="standard"
                    name="topicName"
                    value={payload.topicName}
                    onChange={handleChange}
                    style={{
                      width: "30%",
                      marginRight: "20px",
                    }}
                  />
                )}
              />

              <TextField
                id="consumer-group"
                label="Consumer-Group"
                variant="standard"
                name="consumerGroup"
                value={payload.consumerGroup}
                onChange={handleChange}
                style={{ width: "30%", marginRight: "20px" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                margin: "20px 0px",
              }}
            >
              <TextField
                id="vault-name"
                label="Vault Name"
                variant="standard"
                name="vaultName"
                value={payload.vaultName}
                onChange={handleChange}
                style={{ width: "30%", marginRight: "20px" }}
              />

              <TextField
                id="user-vault-secretKey"
                label="User Vault SecretKey"
                variant="standard"
                name="empUserSecretKey"
                value={payload.empUserSecretKey}
                onChange={handleChange}
                style={{ width: "30%", marginRight: "20px" }}
              />
              <TextField
                id="password-vault-secretKey"
                label="Password Vault SecretKey"
                variant="standard"
                name="empPwdSecretKey"
                value={payload.empPwdSecretKey}
                onChange={handleChange}
                style={{ width: "30%", marginRight: "20px" }}
              />
            </div>
            <div className="button-tab-jobview" style={{ columnGap: "0" }}>
              <button
                className="rounded-md job-btn btn"
                style={{ margin: "10px", padding: "10px 20px", width: "12rem" }}
                // variant="contained"
                // size="small"
                type="submit"
                onClick={OnValidate}
              >
                Validate
              </button>
              <button
                className="rounded-md job-btn-plain btn"
                style={{ margin: "10px", padding: "10px 20px", width: "12rem" }}
                // variant="text"
                // size="small"
                onClick={() => {}}
              >
                Reset
              </button>
            </div>
          </fieldset>
        </form>

        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset
            style={{
              border: "1px solid grey",
              borderRadius: "5px",
              padding: "9px",
              paddingTop: "20px",
              //   width: "100%",
              margin: "20px 10px",
              //   display:"flex",
              //   justifyContent:"center",
              //   alignItems:"center",
            }}
          >
            <legend>Message Type: </legend> <br />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Controller
                name="key_type"
                control={control}
                render={({ field }) => (
                  <FormControl
                    variant="standard"
                    disabled={!isApiCallSuccessful}
                    sx={{
                      m: 1,
                      width: "30%",
                      marginBottom: "8px",
                      marginRight: "20px",
                      marginLeft: "0",
                    }}
                  >
                    <InputLabel id="keytype">Key Type</InputLabel>
                    <Select
                      labelId="keytype"
                      id="key_type"
                      label="Key-Type"
                      {...field}
                      value={field.value || ""}
                      // name="key_type"
                      // value={finalPayload?.topicKeyType}
                       
                    >
                      <MenuItem value={"Avro"}>Avro</MenuItem>
                      <MenuItem value={"String"}>String</MenuItem>
                      <MenuItem value={"JSON"}>JSON</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />

              <Controller
                name="value_type"
                control={control}
                render={({ field }) => (
                  <FormControl
                    variant="standard"
                    disabled={!isApiCallSuccessful}
                    sx={{
                      m: 1,
                      width: "30%",
                      marginBottom: "8px",
                      marginRight: "20px",
                      marginLeft: "0",
                    }}
                  >
                    <InputLabel id="valueType">Value Type</InputLabel>
                    <Select
                      labelId="valueType"
                      id="value_type"
                      label="Value-Type"
                      {...field}
                      value={field.value || ""}
                      // value={finalPayload?.topicValueType}
                      
                    >
                      <MenuItem value={"Avro"}>Avro</MenuItem>
                      <MenuItem value={"String"}>String</MenuItem>
                      <MenuItem value={"JSON"}>JSON</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </div>
          </fieldset>

          <fieldset
            style={{
              border: "1px solid grey",
              borderRadius: "5px",
              padding: "9px",
              paddingTop: "20px",
              //   width: "100%",
              margin: "20px 10px",
              //   display:"flex",
              //   justifyContent:"center",
              //   alignItems:"center",
            }}
          >
            <legend>Consumer Config Details:</legend> <br />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Controller
                name="consumerConfig.enableAutoCommit"
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <FormControl
                    variant="standard"
                    disabled={!isApiCallSuccessful}
                    sx={{
                      m: 1,
                      width: "30%",
                      marginBottom: "8px",
                      marginRight: "20px",
                      marginLeft: "0",
                    }}
                  >
                    <InputLabel id="enableautocommit">
                      enable.auto.commit
                    </InputLabel>
                    <Select
                      labelId="enableautocommit"
                      id="enableautocommit"
                      label="enable.auto.commit"
                      value={value}
                      onChange={onChange}
                      ref={ref}
                    >
                      <MenuItem value={false}>false</MenuItem>
                      <MenuItem value={true}>true</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
              <Controller
                name="consumerConfig.autoCommitIntervalMS"
                control={control}
                render={({ field }) => (
                  <TextField
                    disabled={!isApiCallSuccessful}
                    id="autocommitintervalms"
                    label="auto.commit.interval.ms"
                    // value="5000"
                    placeholder="5000"
                    // value={finalPayload?.consumerConfig?.autoCommitIntervalMS || ""}
                    
                    {...field}
                    variant="standard"
                    type="number"
                    style={{
                      width: "30%",
                      marginRight: "20px",
                    }}
                  />
                )}
              />

              <Controller
                name="consumerConfig.heartbeatIntervalMS"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={!isApiCallSuccessful}
                    id="heartbeatintervalms"
                    label="heartbeat.interval.ms"
                    // value="3000"
                    // value={finalPayload?.consumerConfig?.heartbeatIntervalMS || ""}
                    variant="standard" 
                    type="number"
                    style={{
                      width: "30%",
                      marginRight: "20px",
                    }}
                  />
                )}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                margin: "20px 0px",
              }}
            >
              <Controller
                name="consumerConfig.sessionTimeoutMS"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={!isApiCallSuccessful}
                    id="sessiontimeoutms"
                    label="session.timeout.ms"
                    // value="45000"
                    // value={finalPayload?.consumerConfig?.sessionTimeoutMS}
                    variant="standard" 
                    style={{
                      width: "30%",
                      marginRight: "20px",
                    }}
                  />
                )}
              />
              <Controller
                name="consumerConfig.fetchMinBytes"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={!isApiCallSuccessful}
                    id="fetchminbytes"
                    label="fetch.min.bytes"
                    // value="1"
                    // value={finalPayload?.consumerConfig?.fetchMinBytes}
                    variant="standard"
                    style={{
                      width: "30%",
                      marginRight: "20px",
                    }}
                  />
                )}
              />

              <Controller
                name="consumerConfig.maxPartitionFetchBytes"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={!isApiCallSuccessful}
                    id="maxpartionfetchbytes"
                    label="max.partion.fetch.bytes"
                    // value="1048476"
                    // value={finalPayload?.consumerConfig?.maxPartitionFetchBytes}
                    variant="standard"
                    style={{
                      width: "30%",
                      marginRight: "20px",
                    }}
                  />
                )}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                margin: "20px 0px",
              }}
            >
              <Controller
                name="consumerConfig.fetchMaxWaitMS"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={!isApiCallSuccessful}
                    id="fetchmaxwaitms"
                    label="fetch.max.wait.ms"
                    // value="500"
                    // value={finalPayload?.consumerConfig?.fetchMaxWaitMS}
                    variant="standard"
                    style={{
                      width: "30%",
                      marginRight: "20px",
                    }}
                  />
                )}
              />
              <Controller
                name="consumerConfig.fetchMaxBytes"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={!isApiCallSuccessful}
                    id="fetchmaxbytes"
                    label="fetch.max.bytes"
                    // value="1048476"
                    // value={finalPayload?.consumerConfig?.fetchMaxBytes}
                    variant="standard"
                    style={{
                      width: "30%",
                      marginRight: "20px",
                    }}
                  />
                )}
              />
              <Controller
                name="consumerConfig.autoOffsetReset"
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <FormControl
                    variant="standard"
                    disabled={!isApiCallSuccessful}
                    sx={{
                      m: 1,
                      width: "30%",
                      marginBottom: "8px",
                      marginRight: "20px",
                      marginLeft: "0",
                    }}
                  >
                    <InputLabel id="autooffsetreset">
                      auto.offset.reset
                    </InputLabel>
                    <Select
                      labelId="autooffsetreset"
                      id="autooffsetreset"
                      label="auto.offset.reset"
                      value={value}
                      onChange={onChange}
                      ref={ref}
                      // value={finalPayload?.consumerConfig?.autoOffsetReset}
                       
                    >
                      <MenuItem value={"earliest"}>earliest</MenuItem>
                      <MenuItem value={"latest"}>latest</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                margin: "20px 0px",
              }}
            >
              <Controller
                name="consumerConfig.maxPollRecords"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={!isApiCallSuccessful}
                    id="maxpollrecords"
                    label="max.poll.records"
                    // value="500"
                    // value={finalPayload?.consumerConfig?.maxPollRecords}
                    variant="standard"
                    style={{
                      width: "30%",
                      marginRight: "20px",
                    }}
                  />
                )}
              />
              <Controller
                name="concurrency"
                control={control}
                render={({ field }) => (
                  <FormControl
                    variant="standard"
                    disabled={!isApiCallSuccessful}
                    sx={{
                      m: 1,
                      width: "30%",
                      marginBottom: "8px",
                      marginRight: "20px",
                      marginLeft: "0",
                    }}
                  >
                    <InputLabel id="concurrency">Concurrency Value</InputLabel>
                    <Select
                      labelId="concurrency"
                      id="concurrency"
                      // name="ConcurrencyValue"
                      label="Concurrency Value"
                      {...field}
                      value={field.value ?? ""}
                      // value={finalPayload?.concurrency ?? ""} 
                    >
                      {[...Array(partitions)].map((_, index) => (
                        <MenuItem key={index} value={index + 1}>
                          {index + 1}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </div>
          </fieldset>

          <fieldset
            style={{
              border: "1px solid grey",
              borderRadius: "5px",
              padding: "9px",
              paddingTop: "20px",
              //   width: "100%",
              margin: "20px 10px",
              //   display:"flex",
              //   justifyContent:"center",
              //   alignItems:"center",
            }}
          >
            <legend>Delivery Semantics:</legend> <br />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Controller
                name="enableIdempotentConsumer"
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <FormControl
                    variant="standard"
                    disabled={!isApiCallSuccessful}
                    sx={{
                      m: 1,
                      width: "30%",
                      marginBottom: "8px",
                      marginRight: "20px",
                      marginLeft: "0",
                    }}
                  >
                    <InputLabel id="EnableExactlyOnceDelivery">
                      Enable Exactly Once Delivery
                    </InputLabel>
                    <Select
                      labelId="EnableExactlyOnceDelivery"
                      id="EnableExactlyOnceDelivery"
                      label="Enable Exactly Once Delivery"
                      value={value}
                      onChange={onChange}
                      ref={ref}
                      // value={finalPayload?.enableDLQ}
                      // value={finalPayload?.enableDLQ ? "true" : "false"}
                    
                    >
                      <MenuItem value={false}>false</MenuItem>
                      <MenuItem value={true}>true</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
              <Controller
                name="idempotentConsumerTopic"
                control={control}
                render={({ field }) => (
                  <TextField
                    // disabled={!finalPayload?.enableDLQ}
                    id="exactlyoncekeytopic"
                    label="Exactly Once Key Topic"
                    variant="standard"
                    // value={}
                    style={{
                      width: "70%",
                      marginRight: "20px",
                    }}
                  />
                )}
              />
            </div>
          </fieldset>

          <fieldset
            style={{
              border: "1px solid grey",
              borderRadius: "5px",
              padding: "9px",
              paddingTop: "20px",
              //   width: "100%",
              margin: "20px 10px",
              //   display:"flex",
              //   justifyContent:"center",
              //   alignItems:"center",
            }}
          >
            <legend>DLQ Details:</legend> <br />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Controller
                name="dlqName"
                control={control}
                render={({ field }) => (
                  <TextField
                    disabled={!isApiCallSuccessful}
                    id="dlqtopic"
                    label="DLQ Topic"
                    variant="standard"
                    value={dlqName}
                    onChange={(e) => setDlqName(e.target.value)}
                    // value={finalPayload?.dlqName}
                    // onChange={(e) => setTopicName(e.target.value)}
                    style={{
                      width: "30%",
                      marginRight: "20px",
                    }}
                  />
                )}
              />

              <Controller
                name="enableDLQMessageProcessing"
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="enabledlqmessagereprocessing"
                        onChange={onChange}
                        checked={value}
                        ref={ref}
                      />
                    }
                    label="Enable DLQ Message Reprocessing"
                    style={{ width: "30%", marginRight: "20px" }}
                  />
                )}
              />
            </div>
          </fieldset>

          <fieldset
            style={{
              border: "1px solid grey",
              borderRadius: "5px",
              padding: "9px",
              paddingTop: "20px",
              //   width: "100%",
              margin: "20px 10px",
              //   display:"flex",
              //   justifyContent:"center",
              //   alignItems:"center",
            }}
          >
            <legend>ADLS Details:</legend> <br />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Controller
                name="adlsGen"
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <FormControl
                    variant="standard"
                    disabled={!isApiCallSuccessful}
                    sx={{
                      m: 1,
                      width: "30%",
                      marginBottom: "8px",
                      marginRight: "20px",
                      marginLeft: "0",
                    }}
                  >
                    <InputLabel id="adlsgen">ADLS Gen</InputLabel>
                    <Select
                      labelId="adlsgen"
                      id="adlsgen"
                      label="ADLS Gen"
                      value={value}
                      onChange={onChange}
                      ref={ref}

                      // name="adlsgen"
                      // value={finalPayload?.adlsGen ?? ""}
                       
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />

              <Controller
                name="adlsAccountName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={!isApiCallSuccessful}
                    id="adlsAccountName"
                    label="Account Name"
                    variant="standard"
                    // value={finalPayload?.adlsAccountName}
                    style={{
                      width: "30%",
                      marginRight: "20px",
                    }}
                  />
                )}
              />

              <Controller
                name="adlsPath"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={!isApiCallSuccessful}
                    id="adlsPath"
                    label="Container Path"
                    // value={finalPayload?.adlsPath}
                    variant="standard"
                    style={{ width: "30%", marginRight: "20px" }}
                  />
                )}
              />
            </div>
          </fieldset>

          <fieldset
            style={{
              border: "1px solid grey",
              borderRadius: "5px",
              padding: "9px",
              paddingTop: "20px",
              //   width: "100%",
              margin: "20px 10px",
              //   display:"flex",
              //   justifyContent:"center",
              //   alignItems:"center",
            }}
          >
            <legend>Additional Users:</legend> <br />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Controller
                name="additionalUsers"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={!isApiCallSuccessful}
                    id="additionalusers"
                    label="Additional Users"
                    variant="standard"
                    // value={finalPayload?.userEmail}
                     
                    style={{
                      width: "100%",
                      marginRight: "20px",
                    }}
                  />
                )}
              />
            </div>
          </fieldset>

          <div className="button-tab-jobview" style={{ columnGap:"0"}}>
          <button
              disabled={!isApiCallSuccessful}
              className="rounded-md job-btn btn"
              style={{ margin: "10px", padding: "10px 20px", width: "12rem" }}
              // variant="contained"
              // size="small"
              type="submit"
              // onClick={OnFinalSubmit}
              // onClick={onSubmit}
            >
              Submit
            </button>
            <button
              className="rounded-md job-btn-plain btn"
              style={{ margin: "10px", padding: "10px 20px", width: "12rem" }}
              // variant="text"
              // size="small"
              onClick={() => {}}
            >
              Reset
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default StreamingSyncForm;
