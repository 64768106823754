import React, { useContext, useEffect, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";

import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import { userRequestMartbuilder } from "../../requestMethod";
import axios from "axios";
import { AuthContext } from "../../AuthContext";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const AdvanceInfoForm = () => {
  const [jobs, setJobs] = useState();
  const theme = useTheme();
  const { control } = useFormContext();
  const { getToken, token, error } = useContext(AuthContext);
  useEffect(() => {
    // (async () => await getToken())();
    const ourRequest = axios.CancelToken.source();
    // dispatch({ type: "START_CIRCULAR_LOADING" })
    const fetchJobs = async () => {
      try {
        let response = await userRequestMartbuilder.get(`/jobs`, {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        });
        setJobs(response.data);
        // dispatch({ type: "END_CIRCULAR_LOADING" })
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        } else {
          console.error(err);
        }
      }
    };
    fetchJobs();
    return () => {
      ourRequest.cancel();
    };
  }, []);

  return (
    <>
      <div
        style={{
          marginTop: "40px",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          columnGap: "150px",
          marginBottom: "30px",
          padding: "5px 45px",
        }}
      >
        <Controller
          control={control}
          name="startDate"
          render={({ field }) => (
            <TextField
              InputLabelProps={{ shrink: true }}
              id="start-date"
              label="Start Date"
              variant="outlined"
              size="small"
              type="date"
              fullWidth
              margin="normal"
              {...field}
            />
          )}
        />

        <Controller
          control={control}
          name="endDate"
          render={({ field }) => (
            <TextField
              InputLabelProps={{ shrink: true }}
              id="end-date"
              label="End Date"
              variant="outlined"
              size="small"
              type="date"
              fullWidth
              margin="normal"
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="timezone"
          render={({ field }) => (
            <FormControl
              size="small"
              variant="outlined"
              sx={{ mb: 2, mt: 2, minWidth: 120 }}
            >
              <InputLabel id="select-timezone">Timezone</InputLabel>
              <Select
                labelId="select-timezone"
                id="timezone"
                label="timezone"
                {...field}
                // TODO
                disabled
              >
                <MenuItem value="UTC">UTC</MenuItem>
              </Select>
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="notificationFailureEmail"
          render={({ field }) => (
            <TextField
              // InputLabelProps={{ shrink: true }}
              id="notify-failure-email"
              label="Notify Failure"
              variant="outlined"
              size="small"
              placeholder="Enter email"
              type="email"
              // fullWidth
              margin="normal"
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="notificationMissedSLAEmail"
          render={({ field }) => (
            <TextField
              // InputLabelProps={{ shrink: true }}
              id="notify-missed-sla-email"
              label="Notify Missed SLA"
              variant="outlined"
              size="small"
              placeholder="Enter email"
              type="email"
              // fullWidth
              margin="normal"
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="notificationSuccessEmail"
          render={({ field }) => (
            <TextField
              id="notify-Success-email"
              label="Notify Success"
              variant="outlined"
              size="small"
              placeholder="Enter email"
              type="email"
              // fullWidth
              margin="normal"
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="sla"
          render={({ field }) => (
            <TextField
              id="sla"
              label="SLA min"
              variant="outlined"
              type="number"
              placeholder="Enter SLA"
              fullWidth
              margin="normal"
              size="small"
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="schedule"
          render={({ field }) => (
            <TextField
              id="schedule"
              label="Schedule"
              variant="outlined"
              placeholder="Enter Schedule"
              fullWidth
              margin="normal"
              size="small"
              {...field}
            />
          )}
        />

        <Controller
          control={control}
          name="runAsAccount"
          render={({ field }) => (
            <TextField
              id="runAsAccount"
              label="Run As Account"
              variant="outlined"
              size="small"
              fullWidth
              margin="normal"
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="dependsOnJobs"
          render={({ field }) => (
            <FormControl
              size="small"
              variant="outlined"
              sx={{ mb: 2, mt: 2, minWidth: 120 }}
            >
              <InputLabel id="dependsOnJobs">DependsOn Job</InputLabel>
              <Select
                labelId="dependsOnJobs"
                id="dependsOnJobs"
                multiple
                fullWidth
                size="small"
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    label="Depends On Job"
                  />
                }
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
                {...field}
                value={field.value !== null ? field.value : []}
              >
                {jobs?.map((job) => (
                  <MenuItem
                    key={job.id}
                    value={job.id}
                    // style={getStyles(job.name, job.name, theme)}
                  >
                    {job.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="dependsOnPackages"
          render={({ field }) => (
            <FormControl
              size="small"
              variant="outlined"
              sx={{ mb: 2, mt: 2, minWidth: 120 }}
            >
              <InputLabel id="dependsOnPackages">Depends On Package</InputLabel>
              <Select
                labelId="dependsOnPackages"
                id="dependsOnPackages"
                multiple
                fullWidth
                size="small"
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    label="Depends On Package"
                  />
                }
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
                {...field}
                value={field.value !== null ? field.value : []}
              >
                <MenuItem
                  value=""
                  selected
                  // style={getStyles(job.name, job.name, theme)}
                >
                  None 
                </MenuItem>
              </Select>
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name="retryCount"
          render={({ field }) => (
            <TextField
              id="retryCount"
              label="Retry Count"
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              margin="normal"
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="retryInterval"
          render={({ field }) => (
            <TextField
              id="retryInterval"
              label="Retry Interval"
              variant="outlined"
              size="small"
              type="number"
              fullWidth
              margin="normal"
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="targetLoadMethod"
          render={({ field }) => (
            <FormControl
              size="small"
              variant="outlined"
              sx={{ mb: 2, mt: 2, minWidth: 120 }}
            >
              <InputLabel id="select-targetLoadMethod">
                Target Load Method
              </InputLabel>
              <Select
                labelId="select-targetLoadMethod"
                id="targetLoadMethod"
                label="Target Load Method"
                {...field}
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="full-refresh">full-refresh</MenuItem>
                <MenuItem value="incremental">incremental</MenuItem>
              </Select>
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="updateVersion"
          render={({ field }) => (
            <div style={{ display: "flex", gap: "6px", marginTop: "32px" }}>
              <TextField
                id="updateVersion"
                variant="standard"
                type="checkbox"
                {...field}
              />
              <p>Update Version</p>
            </div>
          )}
        />
        <Controller
          control={control}
          name="backfill"
          render={({ field }) => (
            <div style={{ display: "flex", gap: "6px", marginTop: "32px" }}>
              <TextField
                id="backfill"
                variant="standard"
                type="checkbox"
                {...field}
              />
              <p>Backfill</p>
            </div>
          )}
        />
      </div>
    </>
  );
};

export default AdvanceInfoForm;
