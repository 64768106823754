import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { maerskModelApi } from "./apis/maerskModalApi";
import { manageBusinessApi } from "./apis/ManageBusinessApi";
import { manageDomainsApi } from "./apis/manageDomainsApi";
import { manageProductsApi } from "./apis/manageProductsApi";
import { manageDataSourceApi } from "./apis/manageDataSourceApi";

const store = configureStore({
  reducer: {
    [maerskModelApi.reducerPath]: maerskModelApi.reducer,
    [manageBusinessApi.reducerPath]: manageBusinessApi.reducer,
    [manageDomainsApi.reducerPath]: manageDomainsApi.reducer,
    [manageProductsApi.reducerPath]: manageProductsApi.reducer,
    [manageDataSourceApi.reducerPath]: manageDataSourceApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(maerskModelApi.middleware)
      .concat(manageBusinessApi.middleware)
      .concat(manageDomainsApi.middleware)
      .concat(manageProductsApi.middleware)
      .concat(manageDataSourceApi.middleware)
  },
});

setupListeners(store.dispatch);

export {
  useFetchModelDataQuery,
  useAddDataMutation,
  useRemoveDataMutation,
  useUpdateDataMutation,
} from "./apis/maerskModalApi";

export {
  useFetchBusinessDataQuery,
  useAddBusinessDataMutation,
  useRemoveBusinessDataMutation,
  useUpdateBusinessDataMutation,
} from "./apis/ManageBusinessApi";

export {
  useFetchDomainsDataQuery,
  useAddDomainsDataMutation,
  useRemoveDomainsDataMutation,
  useUpdateDomainsDataMutation,
} from "./apis/manageDomainsApi";

export {
  useFetchProductsDataQuery,
  useAddProductsDataMutation,
  useRemoveProductsDataMutation,
  useUpdateProductsDataMutation,
} from "./apis/manageProductsApi";

export {
  useGetDataSourceQuery
} from "./apis/manageDataSourceApi"


export { store };
