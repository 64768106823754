import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { MARTBUILDER_API } from "../../requestMethod";

const maerskModelApi = createApi({
  reducerPath: "maerskModel",
  baseQuery: fetchBaseQuery({
    baseUrl: MARTBUILDER_API,
  }),
  endpoints(builder) {
    return {
      fetchModelData: builder.query({
        providesTags: ["Model"],
        query: () => {
          return {
            url: "/orgmodel",
            method: "GET",
          };
        },
      }),
      AddData: builder.mutation({
        invalidatesTags: ["Model"],
        query: (addData) => {
          return {
            url: `/orgmodel`, // Corrected the URL to match the 'todos' endpoint
            body: { ...addData },
            method: "POST",
          };
        },
      }),
      RemoveData: builder.mutation({
        invalidatesTags: ["Model"],
        query: (dataId) => {
          // Changed to use dynamic parameter 'dataId'
          return {
            url: `/orgmodel/${dataId}`, // Corrected the URL to use dynamic parameter 'dataId'
            method: "DELETE",
          };
        },
      }),
      UpdateData: builder.mutation({
        invalidatesTags: ["Model"],
        query: (updatedData) => {
          return {
            url: `/orgmodel/${updatedData.id}`,
            body: { ...updatedData },
            method: "PUT",
          };
        },
      }),
    };
  },
});

export const {
  useFetchModelDataQuery,
  useAddDataMutation,
  useRemoveDataMutation,
  useUpdateDataMutation,
} = maerskModelApi;

export { maerskModelApi };
