import { Button, Step, StepLabel, Stepper } from "@mui/material";
import React, { useContext, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import "./Expand.css";
import BasicInfoForm from "../basicInfoForm/BasicInfoForm";
import AdvanceInfoForm from "../advanceInfoForm/AdvanceInfoForm";
import TaskForm from "../taskForm/TaskForm";
import axios from "axios";
import { userRequestMartbuilder } from "../../requestMethod";
import { Link, useNavigate } from "react-router-dom";
import { useValue } from "../../context/ContextProvider";
import { AuthContext } from "../../AuthContext";

function getSteps() {
  return ["Basic", "Advance", "Tasks"];
}
function getStepContent(step, watch) {
  switch (step) {
    case 0:
      return <BasicInfoForm />;
    case 1:
      return <AdvanceInfoForm />;
    case 2:
      return <TaskForm watch={watch} />;
    default:
      return "unknown step";
  }
}

const blankForm = {
  name: "",
  environment: "development",
  description: "",
  dependsOnJobs: [],
  dependsOnPackages: [],
  //  TODO CHANGE
  business: "",
  domain: "",
  project: "",
  orgModel: "",
  publishMessage: "",
  rejectReason: "",
  version: "",
  //  TODO CHANGE
  dagName: "",
  schedule: "",
  //schedule
  product: {
    id: "",
  },
  executionEngine: "",
  notificationFailureEmail: "",
  notificationMissedSLAEmail: "",
  notificationSuccessEmail: "",
  startDate: "",
  endDate: "",
  timezone: "UTC",
  sla: "",
  runAsAccount: "",
  notify: "",
  retryCount: "",
  retryInterval: "",
  backFill: false,
  targetLoadMethod: "",
  updateVersion: false,
  tasks: [
    {
      code: "",
      description: "",
      targetName: "",
      codeUrl: "",
      dependsOnTask: "",
      enableQc: "",
      exportToKafka: "",
      fileFormat: "",
      incrementalMode: "",
      partitionByCols: "",
      name: "",
      kafkaTopic: "",
      signedUrl: "",
      targetLoadMethod: "",
      uniqueKeys: "",
      watermarkCols: "",
      schemaChange: "",
      type: "",
    },
  ],
};

const StepperForm = () => {
  const {
    state: { jobDetails },
  } = useValue();

  const [collectedData, setCollectedData] = useState({});
  const { getToken, token, error } = useContext(AuthContext);
  // console.log(jobDetails)
  const dataPresent = !!jobDetails;

  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const steps = getSteps();
  const methods = useForm({
    defaultValues: jobDetails !== null ? jobDetails : blankForm,
  });

  // const handleBack = () => {
  //   setActiveStep(activeStep - 1);
  // };

  const handelNext = (data) => {
    // console.log(data);
    // Submission on final step
    setCollectedData({ ...collectedData, ...data });

    if (activeStep === 2) {
      // changing the string into array string for valid payload
      data.tasks.forEach((task) => {
        task.partitionByCols = task.partitionByCols.split(",");
      });
      data.tasks.forEach((task) => {
        task.watermarkCols = task.watermarkCols.split(",");
      });
      data.tasks.forEach((task) => {
        task.uniqueKeys = task.uniqueKeys.split(",");
      });
      console.log(data, "4893248888888------------->>>>>>>>>>>>>>");
      const ourRequest = axios.CancelToken.source();
      const sendJobData = async () => {
        try {
          let response = await userRequestMartbuilder.post("/jobs", data, {
            headers: {
              // Define your custom headers here
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            CancelToken: ourRequest.token,
          });
          console.log(response.data);
        } catch (err) {
          if (axios.isCancel(err)) {
            return;
          } else {
            console.error(err);
          }
        }
      };
      sendJobData();
      navigate("/");
    }
    setActiveStep(activeStep + 1);
  };

  const handelBack = () => {
    setActiveStep(activeStep - 1);
  };

  //for submitting the edit response
  const submitEdit = () => {
    const ourRequest = axios.CancelToken.source();
    const updatedData = async () => {
      try {
        let response = await userRequestMartbuilder.put(
          `/jobs/${jobDetails.id}`,
          collectedData,
          {
            headers: {
              // Define your custom headers here
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            CancelToken: ourRequest.token,
          }
        );
        // console.log(response.data);
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        } else {
          console.error(err);
        }
      }
    };
    updatedData();
  };

  // TODO
  // can divide the functionality b/w next step and on final submit
  const onSubmit = (data) => {
    console.log("ko");
    // return
    console.log(data);
    const ourRequest = axios.CancelToken.source();
    const sendJobData = async () => {
      try {
        let response = await userRequestMartbuilder.post("/jobs", data, {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        });
        console.log(response.data);
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        } else {
          console.error(err);
        }
      }
    };
    sendJobData();
  };

  return (
    <div className="maersk-fonts">
      <Stepper activeStep={activeStep}>
        <Step>
          <StepLabel>Basic</StepLabel>
        </Step>
        <Step>
          <StepLabel>Advance</StepLabel>
        </Step>
        <Step>
          <StepLabel>Tasks</StepLabel>
        </Step>
      </Stepper>
      {/* {dataPresent ? <button>hihi</button> : "" } */}
      {activeStep === steps.length ? (
        <div
          style={{
            height: "250px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h4>Thank You</h4>
        </div>
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handelNext)}>
            <div className="form-box"
              // style={{
                // maxHeight: "600px",
                // overflowX: "scroll",
                // border: "1px solid green",
              // }}
            >
              {getStepContent(activeStep, methods.watch)}
            </div>

            <div className="button-tab-jobview">
              <button
                className="rounded-md job-btn-plain btn"
                // variant="text"
                // size="small"
                disabled={activeStep === 0}
                onClick={handelBack}
              >
                Back
              </button>
              {activeStep === 2 ? (
                dataPresent ? (
                  <Link to={"/"}>
                    <button
                      className="rounded-md job-btn btn"
                      // variant="contained"
                      // size="small"
                      type="button"
                      onClick={submitEdit}
                    >
                      Submit
                    </button>
                  </Link>
                ) : (
                  <button
                  className="rounded-md job-btn btn"
                    // variant="contained"
                    // size="small"
                    type="submit"
                    // onClick={handelNext}
                  >
                    Finish
                  </button>
                )
              ) : (
                <button
                className="rounded-md job-btn btn"
                  // variant="contained"
                  // size="small"
                  // onClick={handelNext}
                  type="submit"
                >
                  Next
                </button>
              )}
            </div>
          </form>
        </FormProvider>
      )}
    </div>
  );
};

export default StepperForm;
