import { CheckCircle } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { userRequestMartbuilder } from "../../requestMethod";
import axios from "axios";
import { AuthContext } from "../../AuthContext";
import { Cancel } from "@mui/icons-material";

const TaskStatusLogs = ({ data, jobType }) => {
  // console.log("ddddd", data?.state)
  const [expanded, setExpanded] = useState(false);
  const [taskInstanceLog, setTaskInstanceLog] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state
  const { getToken, token, error } = useContext(AuthContext);

  useEffect(() => {
    // (async () => await getToken())();
    const ourRequest = axios.CancelToken.source();
    setLoading(true);
    const fetchInstanceLog = async () => {
      try {
        if (data) {
          const { dag_id, dag_run_id, task_id, try_number } = data;
          const response = await userRequestMartbuilder.get(
            `/jobs/dag/${dag_id}/runs/${dag_run_id}/tasks/${task_id}/trynumber/${try_number}?jobType=${jobType}`,
            {
              headers: {
                // Define your custom headers here
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              CancelToken: ourRequest.token,
            }
          );
          setTaskInstanceLog(response?.data);
          setLoading(false);
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        } else {
          console.error(err);
        }
      }
    };
    fetchInstanceLog();
    return () => {
      ourRequest.cancel();
    };
  }, [data]);

  // console.log(taskInstanceLog)
  const toggleDetails = () => {
    setExpanded(!expanded);
  };

  return (
    <Box>
      <div className="overall-box">
        <Box className="status-box">
          <Box className="status-info">
            {data.state === "upstream_failed" ? (
              <Cancel color="error" />
            ) : data?.state === "success" ? (
              <CheckCircle color="success" className="check-circle" />
            ) : (
              <Cancel color="error" />
            )}
            <Typography className="status-text">{data?.operator}</Typography>
          </Box>
          <Box>
            <Button
              size="small"
              variant="outlined"
              color="inherit"
              onClick={() => toggleDetails()}
              sx={{ fontSize: "10px" }}
            >
              {expanded ? "exit logs" : "view logs"}
            </Button>
          </Box>
        </Box>
        {expanded &&
          (loading ? (
            <Typography>Loading...</Typography>
          ) : (
            <Typography style={{ padding: "20px" }}>
              {taskInstanceLog}
            </Typography>
          ))}
      </div>
    </Box>
  );
};

export default TaskStatusLogs;
