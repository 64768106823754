import React, { useMemo, useState } from "react"
import MaterialReactTable from "material-react-table"
import { edaData } from "./makeData"

const EdaTable = () => {
  const [tableData, setTableData] = useState(() => edaData)

  const columns = useMemo(
    () => [
      {
        accessorKey: "col 1",
        header: "col 1",
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 13,
      },
      // {
      //   accessorKey: "description",
      //   header: "Description",
      //   size: 140,
      // },
      {
        accessorKey: "col 2",
        header: "col 2",
        size: 13,
      },
      // {
      //   accessorKey: "Project",
      //   header: "Project",
      // },
      {
        accessorKey: "col 3",
        header: "col 3",
        size: 13,
      },
      {
        accessorKey: "col 4",
        header: "col 4",
        size: 13,
      },

      // {
      //   accessorKey: "updatedBy",
      //   header: "Updated By",
      //   size: 80,
      // },
      // {
      //   accessorKey: "updatedAt",
      //   header: "Updated At",
      //   size: 80,
      // },
      // {
      //   accessorKey: "creationStatus",
      //   header: "Creation Status",
      // },
    ],
    []
  )

  return (
    <>
      <MaterialReactTable
        // displayColumnDefOptions={{
        //   "mrt-row-actions": {
        //     muiTableHeadCellProps: {
        //       align: "center",
        //     },
        //     size: 120,
        //   },
        // }}
        initialState={{
          density: "compact",
          pagination: { pageSize: 5, pageIndex: 0 },
        }}
        columns={columns}
        data={tableData}
        muiTableHeadCellProps={{
          //no useTheme hook needed, just use the `sx` prop with the theme callback
          sx: (theme) => ({
            color: "InfoText",
            fontSize: "10px",
            padding: "3px",
          }),
        }}
        muiTableBodyCellProps={{
          sx: (theme) => ({
            fontSize: "11px",
            padding: "3px",
            background: "#cccccc",
          }),
        }}
        muiTableBodyRowProps={{
          sx: (theme) => ({
            height: "25px",
          }),
        }}
        muiTablePaginationProps={{
          sx: (theme) => ({
            fontSize: "12px",
          }),
        }}
        // muiTableFooterRowProps={{
        //   sx: (theme) => ({
        //     fontSize: "12px",
        //   }),
        // }}
        // editingMode="modal" //default
        // enableColumnOrdering
      />
    </>
  )
}

//example of creating a mui dialog modal for creating new rows

export default EdaTable
