import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useContext,
} from "react";
import { AddCircleOutline } from "@mui/icons-material";
import { MaterialReactTable } from "material-react-table";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
// import { data, edaData} from "../reactTable/makeData"
import { Link } from "react-router-dom";

import axios from "axios";
import { userRequestMartbuilder } from "../../requestMethod";
import { useValue } from "../../context/ContextProvider";
import { AuthContext } from "../../AuthContext";
import useWindowDimensions from "../Atoms/useWindowDimensions";

const ManageDataSourceTable = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const [dataSource, setDataSource] = useState([]);
  const [tableData, setTableData] = useState(() => dataSource);
  const [isLoading, setIsLoading] = useState(false);
  const { getToken, token, error } = useContext(AuthContext);

  const navigate = useNavigate();

  const { height, width } = useWindowDimensions();
  const rowValue = height > 570 ? 10 : 5;

  const {
    state: { connectionId },
    dispatch,
  } = useValue();

  //for new row
  // const handleCreateNewRow = (values) => {
  //   tableData.push(values)
  //   setTableData([...tableData])
  // }

  // const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
  //   if (!Object.keys(validationErrors).length) {
  //     tableData[row.index] = values
  //     //send/receive api updates here, then refetch or update local table data for re-render
  //     setTableData([...tableData])
  //     exitEditingMode() //required to exit editing mode and close modal
  //   }
  // }

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  // const handleDeleteRow = useCallback(
  //   (row) => {
  //     if (
  //       !alert(`Are you sure you want to delete ${row.getValue("firstName")}`)
  //     ) {
  //       return
  //     }
  //     //send api delete request here, then refetch or update local table data for re-render
  //     tableData.splice(row.index, 1)
  //     setTableData([...tableData])
  //   },
  //   [tableData]
  // )

  // const handleDeleteRow = (connectionId) => {
  //   // Log the connectionId when the delete button is clicked
  //   console.log("Connection ID to delete:", connectionId);

  //   // Perform your delete API call or other delete logic here
  //   // ...
  // };

  const handleDeleteRow = useCallback(
    (row) => {
      let connectionId = row.original.connectionId;
      // console.log("Connection ID to delete:", connectionId);
      if (connectionId) {
        const ourRequest = axios.CancelToken.source();
        const deleteJob = async () => {
          try {
            let response = await userRequestMartbuilder.delete(
              `/datasources/${connectionId}`,
              {
                headers: {
                  // Define your custom headers here
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
                CancelToken: ourRequest.token,
              }
            );
            setDataSource((prevData) => {
              return prevData.filter(
                (item) => item.connectionId !== connectionId
              );
            });
            // console.log(response.data)
            // dispatch({ type: "END_CIRCULAR_LOADING" })
          } catch (err) {
            if (axios.isCancel(err)) {
              return;
            } else {
              console.error(err);
            }
          }
        };
        deleteJob();
      }
      // setOpenDeleteModal(false);
      //send api delete request here, then refetch or update local table data for re-render
      // tableData.splice(row.index, 1)
      // setTableData([...tableData])
    },
    [tableData, token]
  );

  // useEffect(() => {
  const fetchDataSource = async () => {
    const ourRequest = axios.CancelToken.source();
    setIsLoading(true);
    try {
      let response = await userRequestMartbuilder.get(`/datasources/`, {
        headers: {
          // Define your custom headers here
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });
      // console.log(response.data)
      setDataSource(response.data);
      setIsLoading(false);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    // fetchDataSource();
    return () => {
      ourRequest.cancel();
    };
  };

  // }, []);

  useEffect(() => {
    (async () => await getToken())();
    let timeoutId;
    const fetchDataWithDelay = () => {
      timeoutId = setTimeout(() => {
        fetchDataSource();
      }, 1100);
    };
    fetchDataWithDelay();
    return () => {
      clearTimeout(timeoutId);
    };
  }, [navigate, tableData, token]);

  const handleEditDataSource = (connectionId) => {
    dispatch({
      type: "SET_CONNECTION_ID",
      payload: connectionId,
    });
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "connectionFqn",
        header: "Connection Fqn",
        size: 1,
        // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        //   ...getCommonEditTextFieldProps(cell),
        //   type: 'email',
        // }),
      },
      {
        accessorKey: "connectionName",
        header: "Connection Name",
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: "datasourceType",
        header: "Datasource Type",
        size: 140,
        // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        //   ...getCommonEditTextFieldProps(cell),
        // }),
      },
      {
        accessorKey: "product.name",
        header: "Product",
        size: 40,
        // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        //   ...getCommonEditTextFieldProps(cell),
        //   type: 'email',
        // }),
      },
      {
        accessorKey: "vaultKey",
        header: "Vault Key",
        size: 40,
        // muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        //   ...getCommonEditTextFieldProps(cell),
        // }),
      },
    ]
    // [getCommonEditTextFieldProps],
  );

  return (
    <div
      style={{
        width: "80%",
        margin: "0px auto",
        paddingTop: "35px",
        // border:"1px solid red",
      }}
    >
      <h2 style={{ marginBottom: "35px" }}>Manage DataSource</h2>
      <Link to={"/manageDatasources/ChooseDatasourceType"}>
        {/* <Button
          variant="outlined"
          size="small"
          sx={{
            color: "#247ba0",
            borderColor: "#247ba0",
            fontSize: "9.5px",
            marginBottom: "20px",
            // position: "relative",
            // left: "85em"
          }}
          startIcon={<AddCircleOutline />}
        >
          Create New Connection
        </Button> */}

        <button
          className="rounded-md job-btn btn nar-wid mar-bottom"
          // variant="outlined"
          // size="small"
          // sx={{
          // color: "#247ba0",
          // borderColor: "#247ba0",
          // fontSize: "9.5px",
          // marginBottom: "20px",
          // position: "relative",
          // left: "85em"
          // }}
        >
          <AddCircleOutline />
          <span>Create New Connection</span>
        </button>
      </Link>
      <MaterialReactTable
        // size="small"
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              align: "center",
            },
            size: 120,
          },
        }}
        initialState={{
          density: "compact",
          pagination: { pageSize: rowValue, pageIndex: 0 },
          columnVisibility: { address: false },
        }}
        columns={columns}
        data={dataSource}
        editingMode="modal" //default
        positionActionsColumn="last"
        enableColumnOrdering
        enableEditing
        // onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Tooltip arrow placement="left" title="Edit">
              <Link
                to={`/manageDatasources/edit/${row?.original.datasourceType}/${row?.original.connectionFqn}/${row?.original.connectionId}`}
              >
                <IconButton
                  onClick={() =>{
                    console.log("roowww", row.original)
                    handleEditDataSource(row?.original?.connectionId)
                  }
                  }
                >
                  <Edit />
                </IconButton>
              </Link>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      />
    </div>
  );
};

export default ManageDataSourceTable;
