import { useState, useContext } from "react";
import Select from "react-select";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Checkbox, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  Grid,
  DialogTitle,
  TableContainer,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import axios from "axios";
import { userRequestMartbuilder } from "../../requestMethod";
import { AuthContext } from "../../AuthContext";
import "../../components/metadataCrawls/addNewData/AddNewMetaDataCrawl.css";

function AddNewApplication({ boolean, onOpen, tableType }) {
  // const [domainArray, setDomainArray] = useState([]);
  // const [domainValue, setDomainValue] = useState("");

  // const operationList = [
  //   { value: 1, label: "Create" },
  //   { value: 2, label: "Update" },
  //   { value: 3, label: "Delete" },
  //   { value: 4, label: "Read" },
  // ];

  const [values, setValues] = useState({
    name: "",
    description: "",
    // domain: "",
    // accessFlag: false,
    // application: "",
    // operation: [],
  });

  // const handleInputChange = (e) => {
  //   setDomainValue(e.target.value);
  // };

  //array handling
  // const handleAddToDataArray = () => {
  //   if (domainValue.trim() !== "") {
  //     setDomainArray((prevDomainArray) => [...prevDomainArray, domainValue]);
  //     setDomainValue("");
  //   }
  // };

  //delete the item
  // const handleDelete = (index) => {
  //   const updateDomain = domainArray.filter((_, i) => i !== index);
  //   setDomainArray(updateDomain);
  // };

  //token for new axios
  const { getToken, token, error } = useContext(AuthContext);

  //api call for adding new data
  const AddNewApplicationData = async () => {
    const payload = {
      name: values.name,
      description: values.description,
      // domain: domainArray,
    };
    console.log("Payload ", payload);
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.post(
        `/aatapplications`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  const [showEmptyFieldMessage, setShowEmptyFieldMessage] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedValues = { ...values, [name]: value };
    setValues(updatedValues);
  };

  // const handlePolicyChange = (event, target) => {
  //   let data = {values};
  //   data[target] = [];
  //   event.map((e) => {
  //     data[target].push(e.label);
  //   });
  //   setValues(data);
  //   console.log(values);
  // };

  // const handleTick = (event, target) => {
  //   let data = {values};
  //   data[target] = !data[target];
  //   setValues(data);
  // };

  const handleSubmit = async () => {
    if (!values.name.trim()) {
      setShowEmptyFieldMessage(true);
      setTimeout(() => {
        setShowEmptyFieldMessage(false);
      }, 2000);
      return;
    }
    await AddNewApplicationData();
    onOpen();
  };

  return (
    <div style={{}}>
      <Dialog open={boolean} onClose={onOpen}>
        <DialogContent style={{ width: "600px", height:"auto" }}>
          <Grid container mb={3}>
            <Grid item xs={11}>
              <Typography variant="h6" component="h2">
                Add New Application
              </Typography>
            </Grid>
            <Grid color="text.secondary" item xs={1}>
              <CloseIcon
                onClick={onOpen}
                sx={{
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Name
            </Typography>
            <TextField
              margin="dense"
              label="Name"
              name="name"
              value={values.name}
              onChange={handleChange}
              // fullWidth
              style={{ width: "71.5%" }}
              size="small"
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Description
            </Typography>
            {/* <TextField
            margin="dense"
            label="Description"
            name="description"
            value={values.description}
            onChange={handleChange}
            // fullWidth
            style={{ width: "71.5%" }}
            // size="small"
            /> */}
            {/* <div style={{ width: "71.5%", margin: "0", padding: "0" }}> */}
            <TextField
              margin="dense"
              label="Description"
              name="description"
              value={values.description}
              onChange={handleChange}
              // fullWidth
              style={{ width: "71.5%" }}
              size="small"
            />
            {/* </div> */}
          </div>

          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Access Flag
            </Typography>
            <div style={{ width: "71.5%", margin: "0", padding: "0" }}>
            <Checkbox onChange={(event)=>{handleTick(event, "accessFlag")}} />
            </div>
          </div> */}

          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TextField
              sx={{ minWidth: "85%" }}
              size="small"
              margin="dense"
              // name="domainArray"
              placeholder="Domain"
              value={domainValue}
              onChange={handleInputChange}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleAddToDataArray}
            >
              Add
            </Button>
          </div> */}

          {/* {domainArray.length > 0 && (
            <div className="enteredText" style={{ margin: "10px 0 0 0" }}>
              <ul
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                {domainArray.map((data, index) => (
                  <li key={index}>
                    {data}
                    <span
                      className="cancel"
                      onClick={() => handleDelete(index)}
                    >
                      x
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          )} */}

          {showEmptyFieldMessage && (
            <Typography
              sx={{ fontSize: "14px", color: "red", margin: "5px 5px" }}
            >
              Please enter the name
            </Typography>
          )}
        </DialogContent>
        <Box sx={{ pb: 2, px: 2 }}>
          <DialogActions sx={{ justifyContent: "center" }}>
            <button
              // disabled={  values.email.trim() === ""}
              className="rounded-md job-btn btn width-72p"
              // fullWidth
              // variant="contained"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}

export default AddNewApplication;
