import React, { useState } from "react"
import PieGraph from "./PieGraph"
import PieLabel from "./PieLabel"
import { Box, Paper, Typography, styled } from "@mui/material"

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  backgroundColor: "#fff",
  height: 250,
  width: 390,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  marginTop: "15px",
  flexWrap: "wrap",
  borderRadius: "4px",
  // lineHeight: "60px",
  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
  transition: "0.3s",
  // cursor: "pointer",
}))

function PieBlock({ data, title,onClick }) {
  const[pieHover, setPieHover] = useState(false);
  return (
    <Item elevation={1} onMouseEnter={()=>{setPieHover(true)}} onMouseLeave={()=>{setPieHover(false)}}
    style={{boxShadow: pieHover && "0 8px 16px 0 rgba(0,0,0,0.2)",transform: pieHover &&  "scale(1.025)",}}>
      <Typography
        component="h3"
        sx={{
          fontSize: "12px",
          fontWeight: 600,
          color: "black",
          letterSpacing: "0.3px",
        }}
      >
        {title}
      </Typography>
      <div
        style={{
          width: "360px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          // gap: "50px",
          flexDirection: "row",
        }}
      >
        <PieGraph data={data} />
        <PieLabel data={data} onClick={onClick}/>
      </div>
    </Item>
  )
}

export default PieBlock
