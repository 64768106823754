import { useState, useContext } from "react";
import Select from "react-select";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Chip, InputLabel, OutlinedInput, TextField } from "@mui/material";
import { Select as MUISelect } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Dialog,
  Grid,
  DialogTitle,
  TableContainer,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import axios from "axios";
import { userRequestMartbuilder } from "../../requestMethod";
import { AuthContext } from "../../AuthContext";
import "../../components/metadataCrawls/addNewData/AddNewMetaDataCrawl.css";
import { useNavigate } from "react-router";
import { Input } from "@mui/base";

function AddNewRole() {
  // function AddNewRole({ boolean, onOpen, tableType }) {
  const [permissionsArray, setPermissionsArray] = useState([]);
  const [appsArray, setAppsArray] = useState([]);
  const [resourcesArray, setResourcesArray] = useState([]);
  const [operasArray, setOperasArray] = useState([]);
  const [domainsArray, setDomainsArray] = useState([]);
  // const [permissionsValue, setPermissionsValue] = useState("");

  const [values, setValues] = useState({
    name: "",
    description: "",
  });

  const [policyFields, setPolicyFields] = useState([
    {
      applications: "",
      resource: [],
      permissions: "",
      operations: [],
      domains: [],
    },
  ]);

  const permissionsList = [
    { value: 1, label: "Read_Write_Jobs" },
    { value: 2, label: "Read_Only_jobs" },
    { value: 3, label: "Can_Approve_Jobs" },
  ];

  const handlePolicyChange = (event, index, target) => {
    // let data = [...policyFields];
    // data[index][event.target.name] = event.target.value;
    // setPolicyFields(data);
    // console.log(policyFields);

    if (
      target === "domains" ||
      target === "operations" ||
      target === "resource"
    ) {
      let data = [...policyFields];
      data[index][target] = [];
      event.map((e) => {
        data[index][target].push(e.label);
      });
      setPolicyFields(data);
      console.log(policyFields);
    }

    if (target === "applications" || target === "permissions") {
      let data = [...policyFields];
      data[index][target] = "";
      data[index][target] = event.label;
      setPolicyFields(data);
      console.log(policyFields);
    }
  };

  const addPolicy = () => {
    let object = {
      applications: "",
      resource: [],
      permissions: "",
      operations: [],
      domains: [],
    };

    setPolicyFields([...policyFields, object]);
  };

  const removePolicy = (index) => {
    let data = [...policyFields];
    data.splice(index, 1);
    setPolicyFields(data);
  };

  //array handling
  // const handleAddToDataArray = () => {
  //   if (permissionsValue.trim() !== "") {
  //     setPermissionsArray((prevPermissionsArray) => [
  //       ...prevPermissionsArray,
  //       permissionsValue,
  //     ]);
  //     setPermissionsValue("");
  //   }
  // };

  //delete the item
  // const handleDelete = (index) => {
  //   const updatePermissions = permissionsArray.filter((_, i) => i !== index);
  //   setPermissionsArray(updatePermissions);
  // };

  //token for new axios
  const { getToken, token, error } = useContext(AuthContext);

  //api call for adding new data
  const AddNewRoleData = async () => {
    const payload = {
      name: values.name,
      description: values.description,
      permissions: policyFields.permissions,
      applications: policyFields.applications,
      resource: policyFields.resource,
      operations: policyFields.operations,
      domains: policyFields.domains,
    };
    console.log("Payload", payload);
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.post(`/aatroles`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  const [showEmptyFieldMessage, setShowEmptyFieldMessage] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedValues = { ...values, [name]: value };
    setValues(updatedValues);
  };

  const handleSubmit = async () => {
    if (!values.name.trim()) {
      setShowEmptyFieldMessage(true);
      setTimeout(() => {
        setShowEmptyFieldMessage(false);
      }, 2000);
      return;
    }
    await AddNewRoleData();
    // onOpen();
    navigate("/manageAuthorization")
  };

  const navigate = useNavigate();

  return (
    <div style={{ height: "100%", paddingTop: "60px" }}>
      <div
        style={{
          padding: "80px 20px 20px",
          // maxWidth: "700px",
          maxWidth: "94%",
          margin: "0px auto",
          border: "1px solid lightgray",
          borderRadius: "4px",
          backgroundColor: "#ffffff",
          boxShadow: "0 2px 4px 0 rgba(0,0,0,0.2)",
          // transition: "0.3s",
        }}
      >
        <div style={{}}>
          <Grid container mb={3}>
            <Grid item xs={11}>
              <Typography variant="h4" component="h4">
                Add Role Details
              </Typography>
            </Grid>

            <Grid color="text.secondary" item xs={1}>
              <CloseIcon
                onClick={() => navigate("/manageAuthorization")}
                sx={{
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              flex: 1,
              color: "black !important",
              fontWeight: 600,
            }}
          >
            Role Name
          </Typography>
          <TextField
            margin="dense"
            label="Enter name"
            name="name"
            value={values.name}
            onChange={handleChange}
            style={{ width: "71.5%" }}
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            margin: "20px 0px",
          }}
        >
          <Typography
            style={{
              flex: 1,
              color: "black !important",
              fontWeight: 600,
            }}
          >
            Role Description
          </Typography>
          <TextField
            margin="dense"
            label="Enter Description"
            name="name"
            value={values.name}
            onChange={handleChange}
            style={{ width: "71.5%" }}
          />
        </div>
        {policyFields.map((policy, index) => {
          return (
            <div
              key={index}
              style={{
                // maxWidth:"90%",
                margin: "20px 0px",
                padding: "28px 16px",
                border: "2px solid lightgray",
                borderRadius: "4px",
                backgroundColor: "#ffffff",
                // boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                // transition: "0.3s",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "20px 0px",
                }}
              >
                <Typography
                  style={{
                    flex: 1,
                    color: "black !important",
                    fontWeight: 600,
                  }}
                >
                  Policy {index + 1}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // maxWidth: "335px",
                    // maxHeight: "80px",
                  }}
                >
                  <CancelIcon onClick={() => removePolicy(index)} />
                </div>
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
                  gap: "1rem",
                }}
              >
                <div
                  style={{
                    maxWidth: "285px",
                    maxHeight: "80px",
                    marginBottom: "1rem",
                  }}
                >
                  <label htmlFor="application">Application</label>
                  {/* <Select
                    labelId="application"
                    id="application"
                    name="applications"
                    onChange={(event) => handlePolicyChange(event, index)}
                    value={policy.applications}
                    style={{ width: "100%" }}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.2 }}>
                        <Chip
                          key={selected}
                          label={selected}
                          size="small"
                          onMouseDown={(event) => {
                            event.stopPropagation();
                            handleApplicationDelete(event, index);
                          }}
                          // onDelete={(event) => {
                          //   handlePolicyDelete(event, index);
                          // }}
                          // deleteIcon={<CloseIcon />}
                        />
                      </Box>
                    )}
                  >
                    {permissionsList?.map((item) => (
                      <MenuItem
                        key={item}
                        value={item}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          // You can add more styles here if needed
                        }}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Select> */}
                  <Select
                    id="application"
                    placeholder={false}
                    aria-label="application"
                    options={permissionsList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    defaultValue={policyFields[index].applications}
                    hideSelectedOptions={false}
                    noOptionsMessage={() => "No options available"}
                    onChange={(event) =>
                      handlePolicyChange(event, index, "applications")
                    }
                  />
                </div>

                <div
                  style={{
                    maxWidth: "285px",
                    maxHeight: "80px",
                    marginBottom: "1rem",
                  }}
                >
                  <label htmlFor="resource">Resource</label>
                  {/* <Select
                    multiple
                    labelId="resource"
                    id="resource"
                    name="resource"
                    onChange={(event) => handlePolicyChange(event, index)}
                    value={policy.resource}
                    style={{ width: "100%" }}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.2 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            size="small"
                            onMouseDown={(event) => {
                              event.stopPropagation();
                              handleResourceDelete(event, index);
                            }}
                            // onDelete={(event) => {
                            //   handlePolicyDelete(event, index);
                            // }}
                            // deleteIcon={<CloseIcon />}
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {permissionsList?.map((item) => (
                      <MenuItem
                        key={item}
                        value={item}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          // You can add more styles here if needed
                        }}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Select> */}
                  <Select
                    id="resource"
                    placeholder={false}
                    aria-label="resource"
                    isMulti
                    options={permissionsList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    defaultValue={policyFields[index].resource}
                    hideSelectedOptions={false}
                    noOptionsMessage={() => "No options available"}
                    onChange={(event) =>
                      handlePolicyChange(event, index, "resource")
                    }
                  />
                </div>

                <div
                  style={{
                    maxWidth: "285px",
                    maxHeight: "80px",
                    marginBottom: "1rem",
                  }}
                >
                  <label htmlFor="permission">Permission</label>
                  {/* <Select
                    labelId="permission"
                    id="permission"
                    name="permissions"
                    onChange={(event) => handlePolicyChange(event, index)}
                    value={policy.permissions}
                    style={{ width: "100%" }}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.2 }}>                        
                        <Chip
                          key={selected}
                          label={selected}
                          size="small"
                          onMouseDown={(event) => {
                            event.stopPropagation();
                            handlePermissionDelete(event, index);
                          }}
                          // onDelete={(event) => {
                          //   handlePolicyDelete(event, index);
                          // }}
                          // deleteIcon={<CloseIcon />}
                        />
                    
                      </Box>
                    )}
                  >
                    {permissionsList?.map((item) => (
                      <MenuItem
                        key={item}
                        value={item}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          // You can add more styles here if needed
                        }}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Select> */}
                  <Select
                    id="permission"
                    placeholder={false}
                    aria-label="permission"
                    options={permissionsList}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    defaultValue={policyFields[index].permissions}
                    // isClearable={true}
                    hideSelectedOptions={false}
                    noOptionsMessage={() => "No options available"}
                    onChange={(event) =>
                      handlePolicyChange(event, index, "permissions")
                    }
                  />
                </div>

                <div
                  style={{
                    maxWidth: "285px",
                    maxHeight: "80px",
                    marginBottom: "1rem",
                  }}
                >
                  <InputLabel htmlFor="operation">Operation</InputLabel>
                  {/* <Select
                    multiple
                    labelId="operation"
                    id="operation"
                    name="operations"
                    onChange={(event) => handlePolicyChange(event, index)}
                    value={policy.operations}
                    style={{ width: "100%" }}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.2 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            size="small"
                            onMouseDown={(event) => {
                              event.stopPropagation();
                              handleOperationDelete(event, index);
                            }}
                            // onDelete={(event) => {
                            //   handlePolicyDelete(event, index);
                            // }}
                            // deleteIcon={<CloseIcon />}
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {permissionsList?.map((item) => (
                      <MenuItem
                        key={item}
                        value={item}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          // You can add more styles here if needed
                        }}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Select> */}
                  <Select
                    id="operation"
                    placeholder={false}
                    aria-label="operation"
                    options={permissionsList}
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select"
                    defaultValue={policyFields[index].operations}
                    hideSelectedOptions={false}
                    noOptionsMessage={() => "No options available"}
                    onChange={(event) =>
                      handlePolicyChange(event, index, "operations")
                    }
                  />
                </div>

                <div
                  style={{
                    maxWidth: "285px",
                    maxHeight: "80px",
                    marginBottom: "1rem",
                  }}
                >
                  <label htmlFor="domains">Domain</label>
                  {/* <Select
                    multiple
                    labelId="domains"
                    id="domains"
                    name="domains"
                    onChange={(event) => handlePolicyChange(event, index)}
                    value={policy.domains}
                    style={{ width: "100%" }}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.2 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            size="small"
                            onMouseDown={(event) => {
                              event.stopPropagation();
                              handleDomainDelete(event, index);
                            }}
                            // onDelete={handlePolicyDelete}
                            // deleteIcon={<CloseIcon />}
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {permissionsList?.map((item) => (
                      <MenuItem
                        key={item}
                        value={item}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          // You can add more styles here if needed
                        }}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Select> */}
                  <Select
                    id="domains"
                    placeholder={false}
                    aria-label="domains"
                    options={permissionsList}
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select"
                    defaultValue={policyFields[index].domains}
                    hideSelectedOptions={false}
                    noOptionsMessage={() => "No options available"}
                    onChange={(event) =>
                      handlePolicyChange(event, index, "domains")
                    }
                  />
                </div>
              </div>
            </div>
          );
        })}

        <Box>
          <DialogActions sx={{ justifyContent: "flex-end" }}>
            <button
              className="rounded-md job-btn-plain btn"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.8rem",
                color: "#ffffff",
                backgroundColor: "#2e7d32",
              }}
              onClick={addPolicy}
            >
              <AddCircleOutlineIcon /> <span>Add Policy</span>
            </button>
          </DialogActions>
        </Box>

        <Box sx={{ pb: 2, px: 2, m: 8 }}>
          <DialogActions sx={{ justifyContent: "center" }}>
            <button
              className="rounded-md job-btn btn"
              style={{ width: "28%", height: "2.8rem", fontSize: "1.2rem" }}
              // fullWidth variant="contained"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </DialogActions>
        </Box>
        {/* {openModal && <WarningPopUp />} */}
      </div>
    </div>

    // <div style={{}}>
    //   <Dialog
    //   // open={boolean}
    //   // onClose={onOpen}
    //   >
    //     <DialogContent style={{ width: "600px" }}>
    //       <Grid container mb={3}>
    //         <Grid item xs={11}>
    //           <Typography variant="h6" component="h2">
    //             Add New Role Data
    //           </Typography>
    //         </Grid>
    //         <Grid color="text.secondary" item xs={1}>
    //           <CloseIcon
    //             // onClick={onOpen}
    //             sx={{
    //               cursor: "pointer",
    //             }}
    //           />
    //         </Grid>
    //       </Grid>

    //       <div
    //         style={{
    //           display: "flex",
    //           justifyContent: "space-between",
    //           alignItems: "center",
    //         }}
    //       >
    //         <Typography
    //           style={{
    //             flex: 1,
    //             color: "black !important",
    //             fontWeight: 600,
    //           }}
    //         >
    //           Role Name
    //         </Typography>
    //       <TextField
    //         margin="dense"
    //         label="Role Name"
    //         name="name"
    //         value={values.name}
    //         onChange={handleChange}
    //         // fullWidth
    //         style={{ width: "71.5%" }}
    //         // size="small"
    //       />
    //       </div>

    //       <div
    //         style={{
    //           display: "flex",
    //           justifyContent: "space-between",
    //           alignItems: "center",
    //         }}
    //       >
    //         <Typography
    //           style={{
    //             flex: 1,
    //             color: "black !important",
    //             fontWeight: 600,
    //           }}
    //         >
    //           Role Description
    //         </Typography>
    //       <TextField
    //         margin="dense"
    //         label="Role Description"
    //         name="description"
    //         value={values.description}
    //         onChange={handleChange}
    //         // fullWidth
    //         style={{ width: "71.5%" }}
    //         // size="small"
    //       />
    //       </div>

    //       <div
    //         style={{
    //           display: "flex",
    //           justifyContent: "space-between",
    //           alignItems: "center",
    //           margin: "10px 0",
    //         }}
    //       >
    //         {/* <TextField
    //           sx={{ minWidth: "85%" }}
    //           size="small"
    //           margin="dense"
    //           // name="permissionsArray"
    //           placeholder="Permissions"
    //           value={permissionsValue}
    //           onChange={handleInputChange}
    //         />

    //         <Button
    //           variant="contained"
    //           color="primary"
    //           onClick={handleAddToDataArray}
    //         >
    //           Add
    //         </Button> */}

    //         <Typography
    //           style={{
    //             flex: 1,
    //             color: "black !important",
    //             fontWeight: 600,
    //           }}
    //         >
    //           Permissions
    //         </Typography>
    //         <Select
    //           multiple
    //           labelId="permission"
    //           id="permission"
    //           value={permissionsArray}
    //           onChange={handleInputChange}
    //           style={{ width: "71.5%" }}
    //         >
    //           {permissionsList?.map((item) => (
    //             <MenuItem
    //               key={item}
    //               value={item}
    //               style={{
    //                 display: "flex",
    //                 alignItems: "center",
    //                 // You can add more styles here if needed
    //               }}
    //             >
    //               {item}
    //             </MenuItem>
    //           ))}
    //         </Select>
    //       </div>

    //       {/* {permissionsArray.length > 0 && (
    //         <div className="enteredText" style={{ margin: "10px 0 0 0" }}>
    //           <ul
    //             style={{
    //               display: "flex",
    //               alignItems: "center",
    //               justifyContent: "flex-start",
    //             }}
    //           >
    //             {permissionsArray.map((data, index) => (
    //               <li key={index}>
    //                 {data}
    //                 <span
    //                   className="cancel"
    //                   onClick={() => handleDelete(index)}
    //                 >
    //                   x
    //                 </span>
    //               </li>
    //             ))}
    //           </ul>
    //         </div>
    //       )} */}

    //       {showEmptyFieldMessage && (
    //         <Typography
    //           sx={{ fontSize: "14px", color: "red", margin: "5px 5px" }}
    //         >
    //           Please enter the name
    //         </Typography>
    //       )}
    //     </DialogContent>
    //     <Box sx={{ pb: 2, px: 2 }}>
    //       <DialogActions sx={{justifyContent:"center"}}>
    //         <button
    //           // disabled={  values.email.trim() === ""}
    //           className="rounded-md job-btn btn width-72p"
    //           // fullWidth
    //           // variant="contained"
    //           onClick={handleSubmit}
    //         >
    //           Submit
    //         </button>
    //       </DialogActions>
    //     </Box>
    //   </Dialog>
    // </div>
  );
}

export default AddNewRole;
