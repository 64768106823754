import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { MARTBUILDER_API } from "../../requestMethod";

const manageBusinessApi = createApi({
  reducerPath: "manageBusiness",
  baseQuery: fetchBaseQuery({
    baseUrl: MARTBUILDER_API,
  }),
  endpoints(builder) {
    return {
      fetchBusinessData: builder.query({
        providesTags: ["Business"],
        query: () => {
          return {
            url: "/business",
            method: "GET",
          };
        },
      }),
      AddBusinessData: builder.mutation({
        invalidatesTags: ["Business"],
        query: (data) => {
          return {
            url: `/business`, // Corrected the URL to match the 'todos' endpoint
            body: { ...data },
            method: "POST",
          };
        },
      }),
      RemoveBusinessData: builder.mutation({
        invalidatesTags: ["Business"],
        query: (dataId) => {
          return {
            url: `/business/${dataId}`, // Corrected the URL to use dynamic parameter 'dataId'
            method: "DELETE",
          };
        },
      }),
      UpdateBusinessData: builder.mutation({
        invalidatesTags: ["Business"],
        query: (updatedData) => {
          return {
            url: `/business/${updatedData.id}`,
            body: { ...updatedData },
            method: "PUT",
          };
        },
      }),
    };
  },
});

export const {
  useFetchBusinessDataQuery,
  useAddBusinessDataMutation,
  useRemoveBusinessDataMutation,
  useUpdateBusinessDataMutation,
} = manageBusinessApi;

export { manageBusinessApi };
