import PostgreSQL from "./assets/postgre.svg"
import MySQL from "./assets/mysql.svg"
import Oracle from "./assets/Oracle.svg"
import MicrosoftSQL from "./assets/MicrosoftSQL.svg"
import Redshift from "./assets/Redshift.svg"
import Clickhouse from "./assets/Clickhouse.svg"
import Dremio from "./assets/dreamio.svg"
import Snowflake from "./assets/snowflake.svg"
import Azure from "./assets/Azureblob.svg"
import SalesForce from "./assets/Salesforce.png"
import AzureDataLake from "./assets/AzureDataLake.webp"
import SecureFileTransferProtocol from "./assets/SFTC.png"
import ApplicationProgrammingInterface from "./assets/API.png"

// import Snowflake from "./assets/snowflake.svg"




export const CardData = [
  {
    image: PostgreSQL,
    name: "PostgreSQL",
    otherInfo: "Details",
  },
  {
    image: MySQL,
    name: "MySQL",
    otherInfo: "Details",
  },
  {
    image: Oracle,
    name: "Oracle",
    otherInfo: "Details",
  },
  {
    image: MicrosoftSQL,
    name: "MicrosoftSQL",
    otherInfo: "Details",
  },
  // {
  //   image: Redshift,
  //   name: "Redshift",
  //   otherInfo: "Details",
  // },
  // {
  //   image: Clickhouse,
  //   name: "Clickhouse",
  //   otherInfo: "Details",
  // },
  {
    image: Dremio,
    name: "Dremio",
    otherInfo: "Details",
  },
  
  // {
  //   image: Azure,
  //   name: "Azure Blob Storage",
  //   otherInfo: "Details",
  // },
  {
    image: Snowflake,
    name: "Snowflake",
    otherInfo: "Details",
  },
  {
    image: SalesForce,
    name: "SalesForce",
    otherInfo: "Details",
  },
  {
    image: AzureDataLake,
    name: "Azure Data Lake",
    otherInfo: "Details",
  },
  {
    image: SecureFileTransferProtocol,
    name: "SFTP",
    otherInfo: "Details",
  },
  // {
  //   image: ApplicationProgrammingInterface,
  //   name: "API",
  //   otherInfo: "Details",
  // },
  
]
