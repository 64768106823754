import React from "react";
import { useParams } from "react-router-dom";
// import { useFormContext, Controller } from "react-hook-form"
import PostgreSQL from "../manageDatasources/assets/postgre.svg";
import MySQL from "../manageDatasources/assets/mysql.svg";
import MicrosoftSQL from "../manageDatasources/assets/MicrosoftSQL.svg";
import Redshift from "../manageDatasources/assets/Redshift.svg";
import Clickhouse from "../manageDatasources/assets/Clickhouse.svg";
import Oracle from "../manageDatasources/assets/Oracle.svg";
import Dremio from "../manageDatasources/assets/dreamio.svg";
import Azure from "../manageDatasources/assets/Azureblob.svg";
import Snowflake from "../manageDatasources/assets/snowflake.svg";
import AzureDataLake from "../manageDatasources/assets/AzureDataLake.webp";
import SalesForce from "../manageDatasources/assets/Salesforce.png";
import SFTP from "../manageDatasources/assets/SFTC.png";
import API from "../manageDatasources/assets/API.png";

import "./NewConnection.css";
import { Button } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { userRequestMartbuilder } from "../../requestMethod";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import WarningPopUp from "../../components/Atoms/WarningPopUp";
import { AuthContext } from "../../AuthContext";
import { useValue } from "../../context/ContextProvider";
import BackdropLoader from "../../components/Atoms/BackdropLoader";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";

const NewConnection = ({
  databaseCardName,
  batchIngestion,
  resetRadioBtn,
  hideInputField,
}) => {
  const {
    state: { backdropLoading },
    dispatch,
  } = useValue();

  // console.log("batchIngestion", batchIngestion)
  const [selectedProduct, setSelectedProduct] = useState();
  const [openModal, setOpenModal] = useState(false);

  // State to control password visibility
  const [showPassword, setShowPassword] = useState(false);
  const { databaseName } = useParams();
  const { datasourceType } = useParams();
  const navigate = useNavigate();

  // Authorisation flags like OAuth, API Key, Bearer
  const [oauthSelect, setOAuthSelect] = useState(false);
  const [apiKeySelect, setApiKeySelect] = useState(false);
  const [bearerKeySelect, setBearerKeySelect] = useState(false);
  const [basicAuthSelect, setBasicAuthSelect] = useState(false);

  const actualDatabaseName = databaseCardName || databaseName;

  const [productTableData, setProductTableData] = useState();
  const { getToken, token, error } = useContext(AuthContext);
  //for product
  const fetchProductTableData = async () => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(`/product`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });
      setProductTableData(response?.data);
      // console.log(response.data);
      // dispatch({ type: "PRODUCT_DATA", payload: response.data });
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  useEffect(() => {
    (async () => await getToken())();
    fetchProductTableData();
  }, [token]);

  const shouldShowInputField =
    actualDatabaseName === "PostgreSQL" ||
    actualDatabaseName === "MySQL" ||
    actualDatabaseName === "MicrosoftSQL" ||
    actualDatabaseName === "Redshift" ||
    actualDatabaseName === "Clickhouse" ||
    actualDatabaseName === "Oracle" ||
    actualDatabaseName === "Dremio" ||
    actualDatabaseName === "AzureBlobStorage" ||
    actualDatabaseName === "Azure Blob Storage" ||
    actualDatabaseName === "Snowflake" ||
    actualDatabaseName === "SalesForce" ||
    actualDatabaseName === "AzureDataLake" ||
    actualDatabaseName === "Azure Data Lake" ||
    actualDatabaseName === "SFTP" ||
    actualDatabaseName === "API";

  const isMySQL = actualDatabaseName === "MySQL";
  const isPostgreSQL = actualDatabaseName === "PostgreSQL";
  const isMicrosoftSQL = actualDatabaseName === "MicrosoftSQL";
  const isDremio = actualDatabaseName === "Dremio";
  const isOracle = actualDatabaseName === "Oracle";
  const isSnowflake = actualDatabaseName === "Snowflake";
  const isAzureBlobStorage =
    actualDatabaseName === "AzureBlobStorage" ||
    actualDatabaseName === "Azure Blob Storage";
  const isAzureDataLake =
    actualDatabaseName === "AzureDataLake" ||
    actualDatabaseName === "Azure Data Lake";
  const isSalesForce = actualDatabaseName === "SalesForce";
  const isSFTP = actualDatabaseName === "SFTP";
  const isAPI = actualDatabaseName === "API";

  //   conditionaly handling the images
  let imageSrc = "";
  if (actualDatabaseName === "PostgreSQL") {
    imageSrc = PostgreSQL;
  } else if (actualDatabaseName === "MySQL" || datasourceType === "mysql") {
    imageSrc = MySQL;
  } else if (actualDatabaseName === "MicrosoftSQL") {
    imageSrc = MicrosoftSQL;
  } else if (actualDatabaseName === "Redshift") {
    imageSrc = Redshift;
  } else if (actualDatabaseName === "Oracle") {
    imageSrc = Oracle;
  } else if (actualDatabaseName === "Clickhouse") {
    imageSrc = Clickhouse;
  } else if (actualDatabaseName === "Dremio") {
    imageSrc = Dremio;
  } else if (
    actualDatabaseName === "AzureBlobStorage" ||
    actualDatabaseName === "Azure Blob Storage"
  ) {
    imageSrc = Azure;
  } else if (actualDatabaseName === "Snowflake") {
    imageSrc = Snowflake;
  } else if (actualDatabaseName === "AzureDataLake") {
    imageSrc = AzureDataLake;
  } else if (actualDatabaseName === "Azure Data Lake") {
    imageSrc = AzureDataLake;
  } else if (actualDatabaseName === "SalesForce") {
    imageSrc = SalesForce;
  } else if (actualDatabaseName === "SFTP") {
    imageSrc = SFTP;
  } else if (actualDatabaseName === "API") {
    imageSrc = API;
  }

  //below code for POST api handling
  //   handling the post API
  const [formData, setFormData] = useState({
    connectionName: "",
    product: {
      id: "",
    },
    hostname: "",
    port: "",
    service: "",
    database: "",
    schema:"",
    username: "",
    password: "",
    accountName: "",
    role: "",
    warehouse: "",
    accountKey: "",
    azureAccountName: "",
    StorageContainerName: "",
    sfdc_password: "",
    sfdc_security_token: "",
    sf_username: "",
    tenantId: "",
    client_id: "",
    client_secret: "",

    remote_host: "",
    remote_port: "",
    //remote_file_path: "",
    SSH_host_key_validation: "",
    authentication_type: "",

    personalAccessToken:"",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    // console.log("Input changed:", name, value);
    if (name === "API_addit_info") {
      if (value === "") {
        setOAuthSelect(false);
        setApiKeySelect(false);
        setBearerKeySelect(false);
        setBasicAuthSelect(false);
      } else if (value === "oauth_2p0") {
        setOAuthSelect(true);
        setApiKeySelect(false);
        setBearerKeySelect(false);
        setBasicAuthSelect(false);
      } else if (value === "api_key") {
        setApiKeySelect(true);
        setOAuthSelect(false);
        setBearerKeySelect(false);
        setBasicAuthSelect(false);
      } else if (value === "bearer_key") {
        setBearerKeySelect(true);
        setOAuthSelect(false);
        setApiKeySelect(false);
        setBasicAuthSelect(false);
      } else if (value === "basic_auth") {
        setBasicAuthSelect(true);
        setOAuthSelect(false);
        setApiKeySelect(false);
        setBearerKeySelect(false);
      }
    }
  };

  const handleServiceInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    console.log("Service changed:", name, value);
  };

  //below only for PORT input field we want user to only enter number and dot
  const handlePortInputChange = (event) => {
    const { name, value } = event.target;
    // Use a regular expression to allow numbers and dots
    const validValue = value.replace(/[^0-9.]/g, "");
    setFormData({ ...formData, [name]: validValue });
  };

  // Update the handleOptionChange function to set the selected product
  const handleOptionChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      product: {
        ...formData.product,
        id: value, // Update product.name
      },
    });
    setSelectedProduct(value);
    // console.log("Selected Product:", value);
  };

  const notify = (customText) => {
    toast.error(customText, {
      position: "top-center",
      autoClose: 1800,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  // Handle form submission and log the value
  const handleSubmit = (event, isTestConnection) => {
    event.preventDefault();
    if (formData.connectionName.length === 0) {
      notify("ConnectionName cannot be empty");
      setOpenModal(true);
      return;
    } else if (!formData.product.id) {
      notify("Please select product from the drop-down");
      setOpenModal(true);
      return;
    } else if (formData.tenantId.length === 0 && isAzureDataLake) {
      notify("Tenant Id Field cannot be empty");
      setOpenModal(true);
      return;
    } else if (formData.client_id.length === 0 && isAzureDataLake) {
      notify("Client_Id Field cannot be empty");
      setOpenModal(true);
      return;
    } else if (formData.client_secret.length === 0 && isAzureDataLake) {
      notify("Client Secret Field cannot be empty");
      setOpenModal(true);
      return;
    } else if (formData.accountName.length === 0 && isSnowflake) {
      notify("SnowFlake AccountName Field cannot be empty");
      setOpenModal(true);
      return;
    } else if (formData.role.length === 0 && isSnowflake) {
      notify("Role Field cannot be empty");
      setOpenModal(true);
      return;
    } else if (formData.warehouse.length === 0 && isSnowflake) {
      notify("Warehouse Field cannot be empty");
      setOpenModal(true);
      return;
    } else if (
      formData.hostname.length === 0 &&
      !isAzureBlobStorage &&
      !isSnowflake &&
      !isAzureDataLake &&
      !isSalesForce &&
      !isSFTP &&
      !isAPI
    ) {
      notify("Host Field cannot be empty");
      setOpenModal(true);
      return;
    } else if (
      formData.port.length === 0 &&
      !isAzureBlobStorage &&
      !isSnowflake &&
      !isAzureDataLake &&
      !isSalesForce &&
      !isSFTP &&
      !isAPI && 
      !isDremio
    ) {
      notify("Port Field cannot be empty");
      setOpenModal(true);
      return;
    } 
    // else if (
    //   formData.service.length === 0 &&
    //   !isAzureBlobStorage &&
    //   !isOracle &&
    //   !isSnowflake &&
    //   !isAzureDataLake &&
    //   !isSalesForce && !isOracle && !isDremio && !isPostgreSQL && !isMySQL && !isSFTP
    //   && !isMicrosoftSQL
    // ) { 
    //   notify("Service Field cannot be empty");
    //   setOpenModal(true);
    //   return;
   // } 
    else if (
      formData.database.length === 0 &&
      !isSnowflake &&
      !isAzureBlobStorage &&
      !isSalesForce &&
      !isAzureDataLake &&
      !isSFTP &&
      !isAPI && !isDremio
    ) {
      notify("Database Field cannot be empty");
      setOpenModal(true);
      return;
    } else if (
      formData.username.length === 0 &&
      !isSnowflake &&
      !isAzureBlobStorage &&
      !isSalesForce &&
      !isAzureDataLake &&
      !isAPI && !isDremio
    ) {
      notify("Username Field cannot be empty");
      setOpenModal(true);
      return;
    } else if (
      formData.password.length === 0 &&
      !isSnowflake &&
      !isAzureBlobStorage &&
      !isSalesForce &&
      !isAzureDataLake &&
      !isAPI && !isDremio
    ) {
      notify("Password Field cannot be empty");
      setOpenModal(true);
      return;
    } else if (
      formData.database.length === 0 &&
      !isAzureBlobStorage &&
      !isSalesForce &&
      !isOracle &&
      !isAzureDataLake &&
      isSnowflake
    ) {
      notify("Database Field cannot be empty");
      setOpenModal(true);
      return;
    } else if (
      formData.username.length === 0 &&
      !isAzureBlobStorage &&
      isSnowflake &&
      !isAzureDataLake &&
      !isSalesForce
    ) {
      notify("Username Field cannot be empty");
      setOpenModal(true);
      return;
    } else if (
      formData.password.length === 0 &&
      !isAzureBlobStorage &&
      !isAzureDataLake &&
      !isSalesForce &&
      isSnowflake
    ) {
      notify("Password Field cannot be empty");
      setOpenModal(true);
      return;
    } else if (formData.accountKey.length === 0 && isAzureBlobStorage) {
      notify("AccountKey Field cannot be empty");
      setOpenModal(true);
      return;
    } else if (formData.azureAccountName.length === 0 && isAzureBlobStorage) {
      notify("AzureAccountName Field cannot be empty");
      setOpenModal(true);
      return;
    } else if (
      formData.StorageContainerName.length === 0 &&
      isAzureBlobStorage
    ) {
      notify("StorageContainerName Field cannot be empty");
      setOpenModal(true);
      return;
    } else if (formData.sfdc_password.length === 0 && isSalesForce) {
      notify("SFDC_Password Field cannot be empty");
      setOpenModal(true);
      return;
    } else if (formData.sfdc_security_token.length === 0 && isSalesForce) {
      notify("SFDC_Security_Token Field cannot be empty");
      setOpenModal(true);
      return;
    } else if (formData.sf_username.length === 0 && isSalesForce) {
      notify("SF_username Field cannot be empty");
      setOpenModal(true);
      return;
    } else if (formData.personalAccessToken.length === 0 && isDremio) {
      notify("Personal Access Token Field cannot be empty");
      setOpenModal(true);
      return;
    }else {
      handlePostAPI(event, isTestConnection);
    }
    //after submittion making the form empty
    // setFormData({
    //   connectionName: "",
    //   product: {
    //     id: "",
    //   },
    //   hostname: "",
    //   port: "",
    //   service: "",
    //   database: "",
    //   username: "",
    //   password: "",
    //   accountName: "",
    //   role: "",
    //   warehouse: "",
    //   accountKey: "",
    //   azureAccountName: "",
    //   StorageContainerName: "",
    //   sfdc_password: "",
    //   sfdc_security_token: "",
    //   sf_username: "",
    //   tenantId: "",
    //   client_id: "",
    //   client_secret: "",

    //   remote_host: "",
    //   remote_port: "",
    //   // remote_file_path: "",
    //   SSH_host_key_validation: "",
    //   authentication_type: "",
    // });
  };

  //below we have created a function for handling custom payload
  const handlePostAPI = (event, isTestConnection) => {
    let customPayload = {
      connectionId: "",
      connectionFqn: "",
      connectionName: formData.connectionName,
      datasourceType: databaseName || actualDatabaseName,
      product: {
        id: formData.product.id,
      },
      connectionCreds: {},
      vaultKey: "",
    };
    // Add specific fields based on the databaseName
    console.log(actualDatabaseName);
    if (
      actualDatabaseName === "PostgreSQL" ||
      actualDatabaseName === "MySQL" ||
      actualDatabaseName === "MicrosoftSQL" ||
      actualDatabaseName === "Redshift" ||
      actualDatabaseName === "Clickhouse"
      // actualDatabaseName === "Dremio"
    ) {
      customPayload.connectionCreds = {
        hostname: formData.hostname,
        port: formData.port,
        database: formData.database,
        username: formData.username,
        password: formData.password,
      };
    }else if (databaseName === "Dremio" ) {
      customPayload.connectionCreds = {
        hostname: formData.hostname,
        personalAccessToken: formData.personalAccessToken,
      };
    } else if (databaseName === "Oracle" ) {
      customPayload.connectionCreds = {
        hostname: formData.hostname,
        port: formData.port,
        schema: formData.schema,
        database: formData.database,
        username: formData.username,
        password: formData.password,
      };
    } else if (databaseName === "AzureBlobStorage") {
      customPayload.connectionCreds = {
        accountKey: formData.accountKey,
        azureAccountName: formData.azureAccountName,
        StorageContainerName: formData.StorageContainerName,
      };
    } else if (databaseName === "Snowflake") {
      customPayload.connectionCreds = {
        database: formData.database,
        username: formData.username,
        password: formData.password,
        accountName: formData.accountName,
        role: formData.role,
        warehouse: formData.warehouse,
      };
    } else if (databaseName === "AzureDataLake") {
      customPayload.connectionCreds = {
        tenantId: formData.tenantId,
        accountName: formData.accountName,
        client_id: formData.client_id,
        client_secret: formData.client_secret,
      };
    } else if (databaseName === "SalesForce") {
      customPayload.connectionCreds = {
        sfdc_password: formData.sfdc_password,
        sfdc_security_token: formData.sfdc_security_token,
        sf_username: formData.sf_username,
      };
    } else if (databaseName === "SFTP") {
      customPayload.connectionCreds = {
        username: formData.username,
        password: formData.password,
        remote_host: formData.remote_host,
        remote_port: formData.remote_port,
        // remote_file_path: formData.remote_file_path,
        SSH_host_key_validation: formData.SSH_host_key_validation,
        authentication_type: formData.authentication_type,
      };
    } else if (databaseName === "API") {
      customPayload.connectionCreds = {
        API_endpoint: formData.API_endpoint,
        API_endpoint_value: formData.API_endpoint_value,
        API_addit_info: formData.API_addit_info,
        API_addit_oauth_token: formData.API_addit_oauth_token,
        API_addit_oauth_header_prefix: formData.API_addit_oauth_header_prefix,
        API_addit_oauth_auto_refresh_token:
          formData.API_addit_oauth_auto_refresh_token,
        API_addit_api_key: formData.API_addit_api_key,
        API_addit_api_value: formData.API_addit_api_value,
        API_addit_api_addto: formData.API_addit_api_addto,
        API_addit_bearer_token: formData.API_addit_bearer_token,
        API_addit_basic_uname: formData.API_addit_basic_uname,
        API_addit_basic_pword: formData.API_addit_basic_pword,
        headers_key: formData.headers_key,
        headers_value: formData.headers_value,
        body_format: formData.body_format,
        body_file_format: formData.body_file_format,
        body_content: formData.body_content,
        params_key: formData.params_key,
        params_value: formData.params_value,
        from_path_adls: formData.from_path_adls,
      };
    }
    customPayload.connectionCreds = JSON.stringify(
      customPayload.connectionCreds
    );
    console.log(customPayload, "customPayload outside");
    // Send the customPayload in the API request

    dispatch({ type: "START_BACKDROP_LOADING" });
    const ourRequest = axios.CancelToken.source();
    const sendJobData = async () => {
      try {
        console.log(customPayload, "<== inside call");
        let endpoint = isTestConnection
          ? "/metadatacrawl/test/"
          : "/datasources";
        let response = await userRequestMartbuilder.post(
          endpoint,
          customPayload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        //console.log(response.status, "RESPONSE");
        if (response.status === 200) {
          alert(`Data submitted successfully.`); 
        }else if(response.status === 201){
          alert(`Data submitted successfully.`); 
        } else {
          alert(
            `Success with status ${response.status}: ${response.data}`
          ); 
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          console.log("Request canceled", err.message);
        } else if (err.response) {
          alert(`Error ${err.response.status}: ${err.response.data}`); 
        } else if (err.request) {
          console.log(err.request);
          alert("The request was made but no response was received"); 
        } else {
          console.log("Error", err.message);
          alert("Error: " + err.message); 
        }
      } finally {
        dispatch({ type: "END_BACKDROP_LOADING" });
        if(!isTestConnection){
          navigate("/manageDatasources");
        } 
      }
    };

    sendJobData();
    if (databaseCardName && batchIngestion) { 
      resetRadioBtn();
    }
  };

  const showDataSourceCard = () => {
    hideInputField();
  };

  if (backdropLoading) {
    return <BackdropLoader message={"Please wait..."} />;
  }

  return (
    <div
      style={{
        height: "100%",
        // border: "1px solid red",
        paddingTop: "60px",
      }}
    >
      <div
        style={{
          background: "white",
          padding: "10px",
          marginLeft: "100px",
          width: "55%",
          borderRadius: "8px",
          border: "1px solid lightgray",
          boxShadow: "0 2px 4px 0 rgba(0,0,0,0.2)",
        }}
      >
        {shouldShowInputField && (
          <div style={{}}>
            {/* small image icon */}
            <div
              className="connectionInput"
              style={
                {
                  // border: "1px solid green"
                }
              }
            >
              {batchIngestion && (
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    color: "#247ba0",
                    borderColor: "#247ba0",
                    fontSize: "9.5px",
                  }}
                  onClick={showDataSourceCard}
                >
                  Back
                </Button>
              )}
              {imageSrc && (
                <div className="techDetails">
                  <img src={imageSrc} alt={databaseName} className="logo" />
                  <span className="">
                    {actualDatabaseName || datasourceType}{" "}
                  </span>
                </div>
              )}
            </div>
            <div className="connectionName">
              <div className="inputFieldWrapper connectionNameInputField">
                <label htmlFor="connectionName">Connection Name*</label> <br />
                <input
                  type="text"
                  id="connectionName"
                  name="connectionName"
                  value={formData.connectionName}
                  placeholder="Connection Name"
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <p>Products</p>
                <select
                  value={formData.product.id}
                  onChange={handleOptionChange}
                  name="product"
                >
                  <option value="">Select an option</option>
                  {productTableData?.map((option, index) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {isSFTP && (
              <>
                <div className="inputFieldWrapper">
                  <label htmlFor="remote_host">Remote Host</label> <br />
                  <input
                    type="text"
                    id="remote_host"
                    name="remote_host"
                    value={formData.remote_host}
                    placeholder="Remote Host"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="inputFieldWrapper">
                  <label htmlFor="remote_port">Remote Port</label> <br />
                  <input
                    type="text"
                    id="remote_port"
                    name="remote_port"
                    value={formData.remote_port}
                    placeholder="Remote Port"
                    onChange={handleInputChange}
                  />
                </div>
                {/* <div className="inputFieldWrapper">
                  <label htmlFor="remote_file_path">Remote File Path</label>{" "}
                  <br />
                  <input
                    type="text"
                    id="remote_file_path"
                    name="remote_file_path"
                    value={formData.remote_file_path}
                    onChange={handleInputChange}
                    placeholder="Remote File Path"
                  />
                </div> */}
                <div className="inputFieldWrapper">
                  <label htmlFor="SSH_host_key_validation">
                    SSH Host Key Validation
                  </label>{" "}
                  <br />
                  <select
                    id="SSH_host_key_validation"
                    value={formData.SSH_host_key_validation}
                    name="SSH_host_key_validation"
                    onChange={handleInputChange}
                  >
                    <option value="">Select </option>
                    <option value="enable">Enable</option>
                    <option value="disable">Disable</option>
                  </select>
                </div>
                <div className="inputFieldWrapper">
                  <label htmlFor="authentication_type">
                    Authentication Type
                  </label>{" "}
                  <br />
                  <select
                    id="authentication_type"
                    value={formData.authentication_type}
                    name="authentication_type"
                    onChange={handleInputChange}
                  >
                    <option value="">Select </option>
                    {/* Add options here for the second select */}
                    <option value="basic">Basic</option>
                  </select>
                </div>
              </>
            )}
            {isAPI && (
              <>
                <div className="inputFieldWrapper">
                  <label htmlFor="API_endpoint">API Endpoint</label> <br />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: "1rem",
                    }}
                  >
                    <select
                      id="API_endpoint"
                      value={formData.API_endpoint}
                      name="API_endpoint"
                      onChange={handleInputChange}
                      style={{ width: "7rem" }}
                    >
                      <option value="">Select </option>
                      <option value="get">GET</option>
                      <option value="post">POST</option>
                      <option value="put">PUT</option>
                      <option value="delete">DELETE</option>
                    </select>
                    <input
                      type="text"
                      id="API_endpoint_value"
                      name="API_endpoint_value"
                      value={formData.API_endpoint_value}
                      placeholder="API Endpoint Value"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="inputFieldWrapper">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: "1rem",
                    }}
                  >
                    <select
                      id="API_addit_info"
                      value={formData.API_addit_info}
                      name="API_addit_info"
                      onChange={handleInputChange}
                      style={{ width: "14rem" }}
                    >
                      <option value="">Select </option>
                      <option value="oauth_2p0">OAuth 2.0</option>
                      <option value="api_key">API Key</option>
                      <option value="bearer_key">Bearer Key</option>
                      <option value="basic_auth">Basic Auth</option>
                    </select>
                    {oauthSelect && (
                      <div
                        className="inputFieldWrapper"
                        style={{ flexGrow: "2" }}
                      >
                        <label htmlFor="API_addit_oauth_token">Token</label>{" "}
                        <br />
                        <input
                          style={{ margin: "0.4rem 0" }}
                          type="text"
                          id="API_addit_oauth_token"
                          name="API_addit_oauth_token"
                          value={formData.API_addit_oauth_token}
                          placeholder="Token"
                          onChange={handleInputChange}
                        />
                        <label htmlFor="API_addit_oauth_header_prefix">
                          Header Prefix
                        </label>{" "}
                        <br />
                        <input
                          style={{ margin: "0.4rem 0" }}
                          type="text"
                          id="API_addit_oauth_header_prefix"
                          name="API_addit_oauth_header_prefix"
                          value={formData.API_addit_oauth_header_prefix}
                          placeholder="Header Prefix"
                          onChange={handleInputChange}
                        />
                        <label htmlFor="API_addit_oauth_auto_refresh_token">
                          Auto Refresh Token
                        </label>{" "}
                        <br />
                        <select
                          id="API_addit_oauth_auto_refresh_token"
                          value={formData.API_addit_oauth_auto_refresh_token}
                          name="API_addit_oauth_auto_refresh_token"
                          onChange={handleInputChange}
                          // style={{ width: "14rem" }}
                        >
                          <option value="">Select</option>
                          <option value="true">True</option>
                          <option value="false">False</option>
                        </select>
                      </div>
                    )}
                    {apiKeySelect && (
                      <div
                        className="inputFieldWrapper"
                        style={{ flexGrow: "2" }}
                      >
                        <label htmlFor="API_addit_api_key">Key</label> <br />
                        <input
                          style={{ margin: "0.4rem 0" }}
                          type="text"
                          id="API_addit_api_key"
                          name="API_addit_api_key"
                          value={formData.API_addit_api_key}
                          placeholder="Key"
                          onChange={handleInputChange}
                        />
                        <label htmlFor="API_addit_api_value">Value</label>{" "}
                        <br />
                        <input
                          style={{ margin: "0.4rem 0" }}
                          type="text"
                          id="API_addit_api_value"
                          name="API_addit_api_value"
                          value={formData.API_addit_api_value}
                          placeholder="Value"
                          onChange={handleInputChange}
                        />
                        <label htmlFor="API_addit_api_addto">Add to</label>{" "}
                        <br />
                        <select
                          id="API_addit_api_addto"
                          value={formData.API_addit_api_addto}
                          name="API_addit_api_addto"
                          onChange={handleInputChange}
                          // style={{ width: "14rem" }}
                        >
                          <option value="">Select</option>
                          <option value="header">Header</option>
                          <option value="query_params">Query Params</option>
                        </select>
                      </div>
                    )}
                    {bearerKeySelect && (
                      <div
                        className="inputFieldWrapper"
                        style={{ flexGrow: "2" }}
                      >
                        <label htmlFor="API_addit_bearer_token">Token</label> <br />
                        <input
                          style={{ margin: "0.4rem 0 2rem" }}
                          type="text"
                          id="API_addit_bearer_token"
                          name="API_addit_bearer_token"
                          value={formData.API_addit_bearer_token}
                          placeholder="Token"
                          onChange={handleInputChange}
                        />
                      </div>
                    )}
                    {basicAuthSelect && (
                      <div
                        className="inputFieldWrapper"
                        style={{ flexGrow: "2" }}
                      >
                        <label htmlFor="API_addit_basic_uname">Username</label> <br />
                        <input
                          style={{ margin: "0.4rem 0" }}
                          type="text"
                          id="API_addit_basic_uname"
                          name="API_addit_basic_uname"
                          value={formData.API_addit_basic_uname}
                          placeholder="Username"
                          onChange={handleInputChange}
                        />
                        <label htmlFor="API_addit_basic_pword">Password</label> <br />
                        <div className="password-input-container">
                        <input
                          // style={{ margin: "0.4rem 0" }}
                          type={showPassword ? "text" : "password"}
                          id="API_addit_basic_pword"
                          name="API_addit_basic_pword"
                          value={formData.API_addit_basic_pword}
                          placeholder="Password"
                          onChange={handleInputChange}
                        />
                        <p
                          className="password-toggle-icon"
                          style={{border:"none"}}
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <IoMdEyeOff size={24} /> : <IoMdEye size={24} />}
                        </p>
                      </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="inputFieldWrapper">
                  <label htmlFor="headers">Headers</label> <br />
                  <div
                    id="headers"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: "1rem",
                    }}
                  >
                    <input
                      type="text"
                      id="headers_key"
                      name="headers_key"
                      value={formData.headers_key}
                      placeholder="Key"
                      onChange={handleInputChange}
                    />
                    <input
                      type="text"
                      id="headers_value"
                      name="headers_value"
                      value={formData.headers_value}
                      placeholder="Value"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="inputFieldWrapper">
                  <label htmlFor="body">Body</label> <br />
                  <div
                    id="body"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: "1rem",
                      marginBottom: "1rem",
                    }}
                  >
                    <select
                      id="body_format"
                      value={formData.body_format}
                      name="body_format"
                      onChange={handleInputChange}
                      style={{ width: "14rem" }}
                    >
                      <option value="">Select </option>
                      <option value="raw">raw</option>
                      <option value="preview">preview</option>
                      <option value="pretty">pretty</option>
                      <option value="visualize">visualize</option>
                    </select>
                    <select
                      id="body_file_format"
                      value={formData.body_file_format}
                      name="body_file_format"
                      onChange={handleInputChange}
                      style={{ width: "14rem" }}
                    >
                      <option value="">Select </option>
                      <option value="JSON">JSON</option>
                      <option value="JavaScript">JavaScript</option>
                      <option value="HTML">HTML</option>
                      <option value="XML">XML</option>
                    </select>
                  </div>
                  <input
                    type="text"
                    id="body_content"
                    name="body_content"
                    value={formData.body_content}
                    onChange={handleInputChange}
                    placeholder="Body Content"
                  />
                </div>
                <div className="inputFieldWrapper">
                  <label htmlFor="params">Params</label> <br />
                  <div
                    id="params"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: "1rem",
                    }}
                  >
                    <input
                      type="text"
                      id="params_key"
                      name="params_key"
                      value={formData.params_key}
                      placeholder="Key"
                      onChange={handleInputChange}
                    />
                    <input
                      type="text"
                      id="params_value"
                      name="params_value"
                      value={formData.params_value}
                      placeholder="Value"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="inputFieldWrapper">
                  <label htmlFor="from_path_adls">From Path (ADLS)</label>{" "}
                  <br />
                  <input
                    type="text"
                    id="from_path_adls"
                    name="from_path_adls"
                    value={formData.from_path_adls}
                    onChange={handleInputChange}
                    placeholder="From Path"
                  />
                </div>
              </>
            )}
            {shouldShowInputField && isSnowflake && (
              <>
                <div className="inputFieldWrapper">
                  <label htmlFor="accountName">Account Name</label> <br />
                  <input
                    type="text"
                    id="accountName"
                    name="accountName"
                    value={formData.accountName}
                    placeholder="Account Name"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="inputFieldWrapper">
                  <label htmlFor="role">Role</label> <br />
                  <input
                    type="text"
                    id="role"
                    name="role"
                    value={formData.role}
                    placeholder="Role"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="inputFieldWrapper">
                  <label htmlFor="warehouse">Warehouse</label> <br />
                  <input
                    type="text"
                    id="warehouse"
                    name="warehouse"
                    value={formData.warehouse}
                    placeholder="Warehouse"
                    onChange={handleInputChange}
                  />
                </div>
              </>
            )}
            {shouldShowInputField && isAzureDataLake && (
              <>
                <div className="inputFieldWrapper">
                  <label htmlFor="tenantId">Tenant Id</label> <br />
                  <input
                    type="text"
                    id="tenantId"
                    name="tenantId"
                    value={formData.tenantId}
                    placeholder="Tenant Id"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="inputFieldWrapper">
                  <label htmlFor="accountName">Account Name</label> <br />
                  <input
                    type="text"
                    id="accountName"
                    name="accountName"
                    value={formData.accountName}
                    placeholder="Account Name"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="inputFieldWrapper">
                  <label htmlFor="client_id">Client Id</label> <br />
                  <input
                    type="text"
                    id="client_id"
                    name="client_id"
                    value={formData.client_id}
                    placeholder="Client Id"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="inputFieldWrapper">
                  <label htmlFor="client_secret">Client Secret*</label> <br />
                  <input
                    type="text"
                    id="client_secret"
                    name="client_secret"
                    value={formData.client_secret}
                    placeholder="Client Secret"
                    onChange={handleInputChange}
                  />
                </div>
              </>
            )}
            {shouldShowInputField && !isAzureBlobStorage && !isSalesForce && (
              <>
                {!isSnowflake && !isAzureDataLake && !isSFTP && !isAPI && (
                  <div className="inputFieldWrapper">
                    <label htmlFor="hostname">Hostname*</label> <br />
                    <input
                      type="text"
                      id="hostname"
                      name="hostname"
                      value={formData.hostname}
                      placeholder="Hostname"
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                )}
                {!isSnowflake && !isAzureDataLake && !isSFTP && !isAPI && !isDremio && (
                  <div className="inputFieldWrapper">
                    <label htmlFor="port">Port*</label> <br />
                    <input
                      type="text"
                      id="port"
                      name="port"
                      value={formData.port}
                      placeholder="Port"
                      onChange={handlePortInputChange}
                      required
                    />
                  </div>
                )}
                {/* {!isSnowflake && !isAzureDataLake && ( */}
                {isSFTP && !isOracle && !isSFTP && !isAPI && (
                  <div className="inputFieldWrapper">
                    <label htmlFor="service">Service*</label> <br />
                    <input
                      type="text"
                      id="service"
                      name="service"
                      value={formData.service}
                      placeholder="Service"
                      onChange={handleServiceInputChange}
                    />
                  </div>
                )}
                {!isAzureDataLake && !isSFTP && !isAPI && !isDremio &&(
                  <div className="inputFieldWrapper">
                    <label htmlFor="database">Database*</label> <br />
                    <input
                      type="text"
                      id="database"
                      name="database"
                      value={formData.database}
                      placeholder="Database"
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                )}
                 {isDremio && (
                  <div className="inputFieldWrapper">
                    <label htmlFor="personalAccessToken">Personal Access Token*</label> <br />
                    <input
                      type="text"
                      id="personalAccessToken"
                      name="personalAccessToken"
                      value={formData.personalAccessToken}
                      placeholder="Personal Access Token"
                      onChange={handleInputChange}
                      required 
                    />
                  </div>
                )}
                {isOracle && (
                  <div className="inputFieldWrapper">
                    <label htmlFor="schema">Schema*</label> <br />
                    <input
                      type="text"
                      id="schema"
                      name="schema"
                      value={formData.schema}
                      placeholder="Schema"
                      onChange={handleInputChange}
                      required 
                    />
                  </div>
                )}
                {!isAzureDataLake && !isAPI && !isDremio && (
                  <div>
                    <div className="inputFieldWrapper">
                      <label htmlFor="username">Username*</label> <br />
                      <input
                        type="text"
                        id="username"
                        name="username"
                        value={formData.username}
                        placeholder="Username"
                        onChange={handleInputChange}
                        required
                        autoComplete="off"
                      />
                    </div>
                    <div className="inputFieldWrapper">
                      <label htmlFor="password">Password*</label> <br />
                      <div className="password-input-container">
                        <input
                          type={showPassword ? "text" : "password"}
                          id="password"
                          name="password"
                          value={formData.password}
                          placeholder="Password"
                          onChange={handleInputChange}
                          autoComplete="off"
                          required
                        />
                        <p
                          className="password-toggle-icon"
                          style={{border:"none"}}
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <IoMdEyeOff size={24} /> : <IoMdEye size={24}/>}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                <br />
              </>
            )}
          </div>
        )}
        {shouldShowInputField && isAzureBlobStorage && (
          <>
            <div className="inputFieldWrapper">
              <label htmlFor="accountKey">Account Key</label> <br />
              <input
                type="text"
                id="accountKey"
                name="accountKey"
                value={formData.accountKey}
                placeholder="Account Key"
                onChange={handleInputChange}
              />
            </div>
            <div className="inputFieldWrapper">
              <label htmlFor="azureAccountName">Account Name</label> <br />
              <input
                type="text"
                id="azureAccountName"
                name="azureAccountName"
                value={formData.azureAccountName}
                placeholder="Account Name"
                onChange={handleInputChange}
              />
            </div>
            <div className="inputFieldWrapper">
              <label htmlFor="StorageContainerName">
                Storage Container Name
              </label>{" "}
              <br />
              <input
                type="text"
                id="StorageContainerName"
                name="StorageContainerName"
                value={formData.StorageContainerName}
                onChange={handleInputChange}
                placeholder="Storage Container Name"
              />
            </div>
          </>
        )}
        {shouldShowInputField && isSalesForce && (
          <>
            <div className="inputFieldWrapper">
              <label htmlFor="sfdc_password">SFDC Password</label> <br />
              <div className="password-input-container">
                <input
                  type={showPassword ? "text" : "password"}
                  id="sfdc_password"
                  name="sfdc_password"
                  value={formData.sfdc_password}
                  placeholder="SFDC password"
                  onChange={handleInputChange}
                />
                <p
                  className="password-toggle-icon"
                  style={{border:"none"}}
                  onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
                >
                  {showPassword ? <IoMdEyeOff size={24} /> : <IoMdEye size={24} />}
                </p>
              </div>
            </div>
            <div className="inputFieldWrapper">
              <label htmlFor="sfdc_security_token">SFDC Security Token</label>{" "}
              <br />
              <input
                type="text"
                id="sfdc_security_token"
                name="sfdc_security_token"
                value={formData.sfdc_security_token}
                placeholder="SFDC Security Token"
                onChange={handleInputChange}
              />
            </div>
            <div className="inputFieldWrapper">
              <label htmlFor="sf_username">SF Username*</label> <br />
              <input
                type="text"
                id="sf_username"
                name="sf_username"
                value={formData.sf_username}
                placeholder="SF Username"
                onChange={handleInputChange}
              />
            </div>
          </>
        )}
        {openModal && <WarningPopUp />}
        <div
          className="button button-tab-jobview"
          style={{ justifyContent: "center" }}
        >
          <button
            className="rounded-md job-btn btn nar-wid"
            // variant="outlined"
            // size="small"
            // sx={{
            //   color: "#247ba0",
            //   borderColor: "#247ba0",
            //   fontSize: "9.5px",
            // }}
            onClick={(e) => handleSubmit(e, false)}
          >
            Submit
          </button>
          <button
            className="rounded-md job-btn btn nar-wid"
            // variant="outlined"
            // size="small"
            // sx={{
            //   color: "#247ba0",
            //   borderColor: "#247ba0",
            //   fontSize: "9.5px",
            //   marginLeft: "20px",
            // }}
            onClick={(e) => handleSubmit(e, true)}
          >
            Test Connection
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewConnection;
