import { useState, useContext, useEffect, useMemo } from "react";
// import Select from "react-select";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Checkbox,
  Chip,
  InputAdornment,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  Dialog,
  Grid,
  DialogTitle,
  TableContainer,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import axios from "axios";
import { userRequestMartbuilder } from "../../requestMethod";
import { AuthContext } from "../../AuthContext";
import "../../components/metadataCrawls/addNewData/AddNewMetaDataCrawl.css";

function AddNewPermission({ boolean, onOpen, tableType }) {
  // const [domainArray, setDomainArray] = useState([]);
  // const [domainValue, setDomainValue] = useState("");
  const [appID, setAppID] = useState();
  // const [appName, setAppName] = useState();
  const [aFlag, setAFlag] = useState();
  const [resourceID, setResourceID] = useState([]);
  const [resourceName, setResourceName] = useState([]);
  const [operaName, setOperaName] = useState([]);
  const [applicationData, setApplicationData] = useState();
  const [resourceData, setResourceData] = useState();

  const operationList = [
    { value: 1, label: "Create" },
    { value: 2, label: "Update" },
    { value: 3, label: "Delete" },
    { value: 4, label: "Read" },
  ];

  // const resourceList = [
  //   { value: 1, label: "Resource_1" },
  //   { value: 2, label: "Resource_2" },
  //   { value: 3, label: "Resource_3" },
  //   { value: 4, label: "Resource_4" },
  // ];

  // const applicationList = [
  //   { value: 1, label: "Application_1" },
  //   { value: 2, label: "Application_2" },
  //   { value: 3, label: "Application_3" },
  //   { value: 4, label: "Application_4" },
  // ];

  const accsessFlagList = [
    { value: 1, label: "Allow" },
    { value: 2, label: "Deny" },
  ];

  // Fetching values for application dropdown
  const fetchApplicationsData = async () => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(`/aatapplications`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });
      setApplicationData(response?.data);
      // console.log(response?.data);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  const fetchResourcesData = async () => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(
        `/aatapplications/resources`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      setResourceData(response?.data);
      // console.log(response?.data);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  const [values, setValues] = useState({
    name: "",
    // description: "",
    // domain: "",
    // accessFlag: "",
    // application: "",
    // resources: [],
    // resourceID: [],
    // operations: [],
  });

  // const handleInputChange = (e) => {
  //   setDomainValue(e.target.value);
  // };

  //array handling
  // const handleAddToDataArray = () => {
  //   if (domainValue.trim() !== "") {
  //     setDomainArray((prevDomainArray) => [...prevDomainArray, domainValue]);
  //     setDomainValue("");
  //   }
  // };

  //delete the item
  // const handleDelete = (index) => {
  //   const updateDomain = domainArray.filter((_, i) => i !== index);
  //   setDomainArray(updateDomain);
  // };

  //token for new axios
  const { getToken, token, error } = useContext(AuthContext);

  //api call for adding new data
  const AddNewPermissionData = async () => {
    const payload = {
      name: values.name,
      // description: values.description,
      // domain: domainArray,
      accessFlag: aFlag,
      applicationName: appID,
      resources: resourceID,
      operations: operaName,
    };
    console.log("Payload ", payload);
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.post(
        `/aatpermissions`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  // Search function for dropdown
  const [searchADText, setSearchADText] = useState();
  const [searchRDText, setSearchRDText] = useState();
  const containsText = (text, searchtext) => {
    return text?.toLowerCase().indexOf(searchtext?.toLowerCase()) > -1;
  };
  let displayedADOptions = useMemo(
    () =>
      applicationData?.filter((option) =>
        containsText(option.name, searchADText)
      ),
    [searchADText]
  );
  let displayedRDOptions = useMemo(
    () =>
      resourceData?.filter((option) => containsText(option.name, searchRDText)),
    [searchRDText]
  );

  const [showEmptyFieldMessage, setShowEmptyFieldMessage] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedValues = { ...values, [name]: value };
    setValues(updatedValues);
  };

  const handleAppChange = (event) => {
    setAppID(event.target.value);
    // setAppName(event.target.name);
  };

  const handleAFChange = (event) => {
    setAFlag(event.target.value);
  };

  const handleResChange = (event) => {
    // console.log(event)
    const {
      target: { value },
    } = event;
    setResourceName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    // setResourceID(event.target.value)
    event.target.value.map((name) => {
      resourceData?.map((resource) => {
        // console.log(resource.name, String(name));
        if (resource.name == name) {
          if (resource.id !== undefined) {
            // console.log(resource.id)
            setResourceID([...resourceID, resource.id]);
          }
        }
      });
      // console.log(resourceID);
    });
  };

  const handleOperaChange = (event) => {
    // console.log(event)
    const {
      target: { value },
    } = event;
    setOperaName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  // const handlePolicyChange = (event, target) => {
  //   // let data = {values};
  //   // data[target] = [];
  //   // event.map((e) => {
  //   //   data[target].push(e.label);
  //   // });
  //   // setValues(data);
  //   // console.log(values);

  //   if (target === "operations") {
  //     let data = { ...values };
  //     data[target] = [];
  //     event.map((e) => {
  //       data[target].push(e.label);
  //     });
  //     setValues(data);
  //     console.log(values);
  //   }

  //   if (target === "resources") {
  //     let data = { ...values };
  //     data[target] = [];
  //     data["resourceID"] = [];
  //     event.map((e) => {
  //       data[target].push(e.label);
  //       data["resourceID"].push(e.value);
  //     });
  //     setValues(data);
  //     console.log(values);
  //   }

  //   if (target === "application" || target === "accessFlag") {
  //     let data = { ...values };
  //     data[target] = "";
  //     data[target] = event.label;
  //     setValues(data);
  //     console.log(values);
  //   }
  // };

  // const handleTick = (event, target) => {
  //   let data = { values };
  //   data[target] = !data[target];
  //   setValues(data);
  // };

  const handleSubmit = async () => {
    if (!values.name.trim()) {
      setShowEmptyFieldMessage(true);
      setTimeout(() => {
        setShowEmptyFieldMessage(false);
      }, 2000);
      return;
    }
    await AddNewPermissionData();
    onOpen();
  };

  useEffect(() => {
    (async () => await getToken())();
    fetchApplicationsData();
    fetchResourcesData();
  }, [token]);

  return (
    <div style={{}}>
      <Dialog open={boolean} onClose={onOpen}>
        <DialogContent style={{ width: "600px", height: "50vh" }}>
          <Grid container mb={3}>
            <Grid item xs={11}>
              <Typography variant="h6" component="h2">
                Add New Permission
              </Typography>
            </Grid>
            <Grid color="text.secondary" item xs={1}>
              <CloseIcon
                onClick={onOpen}
                sx={{
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Name
            </Typography>
            <TextField
              margin="dense"
              label="Permission Name"
              name="name"
              value={values.name}
              onChange={handleChange}
              // fullWidth
              style={{ width: "71.5%" }}
              size="small"
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Application
            </Typography>
            {/* <div
              style={{ width: "71.5%", marginTop: "8px", marginBottom: "4px" }}
            >
              <Select
                id="application"
                placeholder={false}
                aria-label="application"
                options={applicationList}
                className="basic-multi-select"
                classNamePrefix="select"
                defaultValue={values.application}
                hideSelectedOptions={false}
                noOptionsMessage={() => "No options available"}
                onChange={(event) => handlePolicyChange(event, "application")}
              />
            </div> */}
            <Select
              style={{ width: "71.5%", margin: "4px 0", padding: "0" }}
              labelId="application"
              id="application"
              size="small"
              // multiple
              value={appID}
              onChange={handleAppChange}
              onOpen={() => setSearchADText("")}
              onClose={() => setSearchADText("")}
              MenuProps={{
                autoFocus: false,
              }}
              // input={<OutlinedInput id="select-multiple-chip" label="Application" />}
              // renderValue={(selected) => (
              //   <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              //     {selected.map((value) => (
              //       <Chip key={value} label={value} />
              //     ))}
              //   </Box>
              // )}
              // MenuProps={MenuProps}
            >
              <ListSubheader sx={{ border: "none" }}>
                <TextField
                  style={{ margin: "0 10px", width: "98%" }}
                  size="small"
                  // Autofocus on textfield
                  autoFocus
                  placeholder="Type to search..."
                  // fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setSearchADText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                      // Prevents autoselecting item while typing (default Select behaviour)
                      e.stopPropagation();
                    }
                  }}
                />
              </ListSubheader>
              {displayedADOptions === "" &&
                applicationData
                  ?.sort((a, b) =>
                    a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1
                  )
                  .map((appData) => (
                    <MenuItem
                      key={appData.id}
                      value={appData.name}
                      name={appData.name}
                      // style={getStyles(name, personName, theme)}
                    >
                      {appData.name}
                    </MenuItem>
                  ))}
              {displayedADOptions !== "" &&
                displayedADOptions
                  ?.sort((a, b) =>
                    a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1
                  )
                  .map((appData) => (
                    <MenuItem
                      key={appData.id}
                      value={appData.name}
                      name={appData.name}
                      // style={getStyles(name, personName, theme)}
                    >
                      {appData.name}
                    </MenuItem>
                  ))}
            </Select>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Access Flag
            </Typography>
            {/* <TextField
            margin="dense"
            label="Description"
            name="description"
            value={values.description}
            onChange={handleChange}
            // fullWidth
            style={{ width: "71.5%" }}
            // size="small"
            /> */}
            {/* <div
              style={{ width: "71.5%", marginTop: "8px", marginBottom: "4px" }}
            >
              <Select
                id="accessFlag"
                placeholder={false}
                aria-label="accessFlag"
                options={accsessFlagList}
                className="basic-multi-select"
                classNamePrefix="select"
                defaultValue={values.accessFlag}
                hideSelectedOptions={false}
                noOptionsMessage={() => "No options available"}
                onChange={(event) => handlePolicyChange(event, "accessFlag")}
              />
            </div> */}

            <Select
              style={{ width: "71.5%", margin: "4px 0", padding: "0" }}
              labelId="accessFlag"
              id="accessFlag"
              size="small"
              // multiple
              value={aFlag}
              onChange={handleAFChange}
              // input={<OutlinedInput id="select-multiple-chip" label="Application" />}
              // renderValue={(selected) => (
              //   <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              //     {selected.map((value) => (
              //       <Chip key={value} label={value} />
              //     ))}
              //   </Box>
              // )}
              // MenuProps={MenuProps}
            >
              {accsessFlagList?.map((af) => (
                <MenuItem
                  key={af.value}
                  value={af.label}
                  name={af.label}
                  // style={getStyles(name, personName, theme)}
                >
                  {af.label}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Resources
            </Typography>
            {/* <TextField
            margin="dense"
            label="Description"
            name="description"
            value={values.description}
            onChange={handleChange}
            // fullWidth
            style={{ width: "71.5%" }}
            // size="small"
            /> */}
            {/* <div
              style={{ width: "71.5%", marginTop: "8px", marginBottom: "4px" }}
            >
              <Select
                id="resources"
                placeholder={false}
                aria-label="resources"
                isMulti
                options={resourceList}
                className="basic-multi-select"
                classNamePrefix="select"
                defaultValue={values.resources}
                hideSelectedOptions={false}
                noOptionsMessage={() => "No options available"}
                onChange={(event) => handlePolicyChange(event, "resources")}
              />
            </div> */}
            <Select
              style={{ width: "71.5%", margin: "4px 0", padding: "0" }}
              labelId="resources"
              id="resources"
              size="small"
              multiple
              value={resourceName}
              onChange={handleResChange}
              onOpen={() => setSearchRDText("")}
              onClose={() => setSearchRDText("")}
              MenuProps={{
                autoFocus: false,
              }}
              // input={<OutlinedInput id="select-multiple-chip" label="Application" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              // MenuProps={MenuProps}
            >
              <ListSubheader sx={{ border: "none" }}>
                <TextField
                  style={{ margin: "0 10px", width: "98%" }}
                  size="small"
                  // Autofocus on textfield
                  autoFocus
                  placeholder="Type to search..."
                  // fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setSearchRDText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                      // Prevents autoselecting item while typing (default Select behaviour)
                      e.stopPropagation();
                    }
                  }}
                />
              </ListSubheader>
              {displayedRDOptions !== "" &&
                displayedRDOptions
                  ?.sort((a, b) =>
                    a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1
                  )
                  .map((resource) => (
                    <MenuItem
                      key={resource.id}
                      value={resource.name}
                      // name={resource.name}
                      // style={getStyles(name, personName, theme)}
                    >
                      {resource.name}
                    </MenuItem>
                  ))}
              {displayedRDOptions === "" &&
                resourceData
                  ?.sort((a, b) =>
                    a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1
                  )
                  .map((resource) => (
                    <MenuItem
                      key={resource.id}
                      value={resource.name}
                      // name={resource.name}
                      // style={getStyles(name, personName, theme)}
                    >
                      {resource.name}
                    </MenuItem>
                  ))}
            </Select>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Operations
            </Typography>
            {/* <TextField
            margin="dense"
            label="Description"
            name="description"
            value={values.description}
            onChange={handleChange}
            // fullWidth
            style={{ width: "71.5%" }}
            // size="small"
            /> */}
            {/* <div
              style={{ width: "71.5%", marginTop: "8px", marginBottom: "4px" }}
            >
              <Select
                id="operation"
                placeholder={false}
                aria-label="operation"
                isMulti
                options={operationList}
                className="basic-multi-select"
                classNamePrefix="select"
                defaultValue={values.operation}
                hideSelectedOptions={false}
                noOptionsMessage={() => "No options available"}
                onChange={(event) => handlePolicyChange(event, "operations")}
              />
            </div> */}
            <Select
              style={{ width: "71.5%", margin: "4px 0", padding: "0" }}
              labelId="operation"
              id="operation"
              size="small"
              multiple
              value={operaName}
              onChange={handleOperaChange}
              // input={<OutlinedInput id="select-multiple-chip" label="Application" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              // MenuProps={MenuProps}
            >
              {operationList?.map((opera) => (
                <MenuItem
                  key={opera.value}
                  value={opera.label}
                  // name={resource.name}
                  // style={getStyles(name, personName, theme)}
                >
                  {opera.label}
                </MenuItem>
              ))}
            </Select>
          </div>

          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Access Flag
            </Typography>
            <div style={{ width: "71.5%", margin: "0", padding: "0" }}>
            <Checkbox onChange={(event)=>{handleTick(event, "accessFlag")}} />
            </div>
          </div> */}

          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TextField
              sx={{ minWidth: "85%" }}
              size="small"
              margin="dense"
              // name="domainArray"
              placeholder="Domain"
              value={domainValue}
              onChange={handleInputChange}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleAddToDataArray}
            >
              Add
            </Button>
          </div> */}

          {/* {domainArray.length > 0 && (
            <div className="enteredText" style={{ margin: "10px 0 0 0" }}>
              <ul
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                {domainArray.map((data, index) => (
                  <li key={index}>
                    {data}
                    <span
                      className="cancel"
                      onClick={() => handleDelete(index)}
                    >
                      x
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          )} */}

          {showEmptyFieldMessage && (
            <Typography
              sx={{ fontSize: "14px", color: "red", margin: "5px 5px" }}
            >
              Please enter the name
            </Typography>
          )}
        </DialogContent>
        <Box sx={{ pb: 2, px: 2 }}>
          <DialogActions sx={{ justifyContent: "center" }}>
            <button
              // disabled={  values.email.trim() === ""}
              className="rounded-md job-btn btn width-72p"
              // fullWidth
              // variant="contained"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}

export default AddNewPermission;
