import React, { useContext, useEffect, useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { Delete, Info, RestartAlt } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { userRequestMartbuilder } from "../../requestMethod";
import RejectReasonModal from "../rejectReasonModal/RejectReasonModal";
import { AuthContext } from "../../AuthContext";
import { useValue } from "../../context/ContextProvider";

const RequestTable = ({ jobs }) => {
  const [tableData, setTableData] = useState(jobs);
  const [rowSelection, setRowSelection] = useState({});
  const [jobId, setJobId] = useState();
  const [rejectReason, setRejectReason] = useState();
  const [rowData, setRowData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const navigate = useNavigate();
  const { getToken, token, error } = useContext(AuthContext);

  useEffect(() => {
    setTableData(jobs);
  }, [jobs]);

  const handleModalToggle = () => {
    setOpenModal(!openModal);
  };

  const handleRejectReason = (e) => {
    setRejectReason(e.target.value);
  };

  const handleDeleteModalToggle = (row) => {
    console.log(row);
    setOpenDeleteModal(!openDeleteModal);
    setRowData(row);
  };

  const {
    state: { toggleEditButton,},
    dispatch,
  } = useValue();

  const handleInfoClick = (rowInfo) => {
    if (rowInfo) {
      dispatch({ type: "HIDE_EDIT_BUTTON" });
      dispatch({ type: "MART_BUILDER_EDIT_JOB_ID", payload: rowInfo?.original?.id});
      navigate(`/jobView/${rowInfo?.original?.name}`);
    } else {
      console.error("Invalid data or missing jobName property");
    }
  };
  

  const columns = useMemo(
    () => [
      {
        accessorKey: "product.domain.name",
        header: "Domain",
        size: 70,
        enableEditing: false,
      },
      {
        accessorKey: "name",
        header: "Job Name",
        enableColumnOrdering: false,
        enableEditing: false,
        size: 70,
      },
      {
        accessorKey: "description",
        header: "Description",
        size: 120,
      },
      {
        accessorKey: "jobCreationStatus.status",
        header: "Job Creation Status",
        size: 70,
      },
      {
        accessorKey: "targetLoadMethod",
        header: "Load Type",
        size: 70,
      },
      {
        accessorKey: "product.name",
        header: "Product",
        size: 70,
      },
      // {
      //   accessorKey: "dagName",
      //   header: "DAG",
      //   size: 70,
      // },
      {
        accessorKey: "product.business.name",
        header: "Business",
        size: 70,
      },
      // {
      //   accessorKey: "status",
      //   header: "Run Status",
      //   size: 70,
      // },
    ],
    []
  );

  useEffect(() => {
    //do something when the row selection changes...
    // console.info({ rowSelection });
    // (async () => await getToken())();
  }, [rowSelection]);

  useEffect(() => {
    if (Object.keys(rowSelection).length > 0) {
      const selectedJobs = Object.keys(rowSelection).map((selectedRow) => {
        const rowIndex = parseInt(selectedRow, 10);
        return tableData[rowIndex].id;
      });
      console.log("Selected Job Names:", selectedJobs);
      setJobId(selectedJobs);
    }
  }, [rowSelection, tableData]);

  const handleApprove = async () => {
    // console.log("handler");
    // return;
    const payload = {
      jobCreationStatus: "IN_PROGRESS",
      jobId: jobId,
      rejectReason: "",
    };
    console.log(payload);
    // return;
    try {
      const ourRequest = axios.CancelToken.source();
      let response = await userRequestMartbuilder.put(
        `/jobs/updateJobStatus`,
        payload,
        {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      // setEditData(response.data);
      // console.log(response.data)
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
  };

  const handleReject = async () => {
    // console.log("handler")
    // return;
    const payload = {
      jobCreationStatus: "REJECTED",
      jobId: jobId,
      rejectReason: rejectReason,
    };
    console.log(payload);
    // return;
    try {
      const ourRequest = axios.CancelToken.source();
      let response = await userRequestMartbuilder.put(
        `/jobs/updateJobStatus`,
        payload,
        {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      // setEditData(response.data);
      // console.log(response.data)
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    setOpenModal(false);
  };

  return (
    <>
      <MaterialReactTable
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              align: "center",
            },
            size: 120,
          },
        }}
        initialState={{
          density: "compact",
          pagination: { pageSize: 10, pageIndex: 0 },
        }}
        enableRowActions
        columns={columns}
        data={tableData}
        editingMode="modal" //default
        enableColumnOrdering
        positionActionsColumn="last"
        enableRowSelection
        state={{ rowSelection }}
        onRowSelectionChange={setRowSelection}
        renderRowActions={({ row, table }) => (
          // console.log(row.original.name)
          <Box
            sx={{
              display: "flex",
              gap: "3px",
              justifyContent: "center",
            }}
          >
            <Tooltip arrow placement="left" title="Info">
              <IconButton
                onClick={() => handleInfoClick(row)}
              >
                <Info />
              </IconButton>
            </Tooltip>
          </Box>
          //   <Tooltip arrow placement="left" title="Restart">
          //     <IconButton onClick={() => table.setEditingRow(row)}>
          //       <RestartAlt />
          //     </IconButton>
          //   </Tooltip>
          //   <Tooltip arrow placement="right" title="Delete">
          //     <IconButton
          //       color="error"
          //       onClick={() => handleDeleteModalToggle(row)}
          //     >
          //       <Delete />
          //     </IconButton>
          //   </Tooltip>
        )}
        renderTopToolbarCustomActions={() => (
          <div className="button-tab-jobview"> 
            <Link to={"/"}>
              {/* <Button
                color="success"
                variant="contained"
                disabled={Object.keys(rowSelection).length === 0}
                size="small"
                onClick={handleApprove}
              >
                Approve
              </Button> */}
              <button
                className="rounded-md job-btn btn nar-wid mar-bottom"
                style={{backgroundColor:"#2e7d32", border:"#2e7d32", color:"white"}}
                // variant="contained"
                disabled={Object.keys(rowSelection).length === 0}
                // size="small"
                onClick={handleApprove}
              >
                <span>Approve</span>
              </button>
            </Link>
            {/* <Button
              color="warning"
              variant="contained"
              disabled={Object.keys(rowSelection).length === 0}
              size="small"
              onClick={handleModalToggle}
              style={{ marginLeft: "10px" }}
            >
              Reject
            </Button> */}
            <button
              className="rounded-md job-btn btn nar-wid mar-bottom"
              // variant="contained"
              disabled={Object.keys(rowSelection).length === 0}
              // size="small"
              onClick={handleModalToggle}
              style={{ marginLeft: "10px", backgroundColor:"#d32f2f", border:"#d32f2f", color:"white" }}
            >
              <span>Reject</span>
            </button>
          </div>
        )}
      />
      {openModal && (
        <Box sx={{ position: "absolute", top: "100%", left: 0 }}>
          <RejectReasonModal
            onOpen={handleModalToggle}
            boolean={openModal}
            handleRejectReason={handleRejectReason}
            handleReject={handleReject}
            jobs={jobs}
          />
        </Box>
      )}
      {/* <CreateNewAccountModal
        columns={columns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
      /> */}
    </>
  );
};

//example of creating a mui dialog modal for creating new rows

export default RequestTable;
