import React, { createContext, useContext, useReducer } from "react";
import reducer from "./Reducer";

const initialState = {
  currentFilter: "Manage Jobs",
  circularLoading: false,
  backdropLoading: false,
  isEDAVisible: false,
  jobDetails: null,
  showIngestionJobForm: false,
  showCreateJob: "Create Job",
  dataSourceTable: "",
  datasetDetails: null,
  dataSources: null,
  batchFormLoading: false,
  showFolderTree: false,
  destinationConnectionBatch: "",
  sourceConnectionBatch: "",
  schedulerInfoBatch: "",
  dataBookToken: "",
  connectionId:"",
  availableJobId:"",
  editMetaDataCrawlId:"",
  editMetaDataCrawlFqn:"",
  maerskModalTableData:"",
  businessData:"",
  domainData:"",
  productData:[],
  martBuilderEditJobId:"",
  batchIngestionEditJobId:"",
  sourceConnectionName: null,
  sourceConnectionFqn: "",
  toggleEditButton:false,
  transformationCatalog: [],
  toggleNextButton:false,
};

const Context = createContext(initialState);

// Hook to access context value
export const useValue = () => {
  return useContext(Context);
};

const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};

export default ContextProvider;
