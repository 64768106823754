const streamingData = [
    {
        "topicName": "msk.capacity.keplacustomerdemandsimulationpreprod.topic.internal.any.v1",
        "consumerGroup": "msk.capacity.keplafreightlistpreprod.consumerGroup.v1",
        "empVersion": "V3",
        "vaultName": "emp-consumer",
        "empUserSecretKey": "kepla-retina-user",
        "empPwdSecretKey": "kepla-retina-pwd",
        "topicKeyType": "String",
        "topicValueType": "Avro",
        "adlsAccountName": "dlsazewddatalakeraw",
        "adlsPath": "comet-emp/retina-test/testdir/keplacustomer",
        "concurrency": 1,
        "additionalUsers": "yash.g@maersk.com,gaurab.dawn@maersk.com,vinayak.v@maersk.com,shivakumar.dorai@maersk.com,niketa.agarwal@maersk.com,sachin.shenoy@maersk.com,prabhat.lodhi@maersk.com,farooq.gandluri@maersk.com",
        "destinationFileFormat": "JSON",
        "status": 1,
        "version": 2,
        "consumerConfigs": "{\"enableAutoCommit\":false,\"autoCommitIntervalMS\":5000,\"autoOffsetReset\":\"earliest\",\"maxPollRecords\":500,\"heartbeatIntervalMS\":3000,\"sessionTimeoutMS\":45000,\"fetchMinBytes\":1,\"fetchMaxBytes\":1048476,\"fetchMaxWaitMS\":500,\"maxPartitionFetchBytes\":1048476}",
        "insertTs": "2023-12-18 10:47:30",
        "userEmail": "Gaurab.Dawn@maersk.com",
        "consumerConfig": {
            "enableAutoCommit": false,
            "autoCommitIntervalMS": 5000,
            "autoOffsetReset": "earliest",
            "maxPollRecords": 500,
            "heartbeatIntervalMS": 3000,
            "sessionTimeoutMS": 45000,
            "fetchMinBytes": 1,
            "fetchMaxBytes": 1048476,
            "fetchMaxWaitMS": 500,
            "maxPartitionFetchBytes": 1048476
        },
        "enableDLQ": true,
        "dlqName": "msk.capacity.keplacustomerdemandsimulationpreprod.topic.internal.any.v1.dlt",
        "enableDLQMessageProcessing": false,
        "enableIdempotentConsumer": false,
        "idempotentConsumerTopic": "",
        "idempotentConsumerGroupId": "",
        "adlsGen": 2,
        "empEnv": null,
        "domain": "null.dev.maersk-digital.net",
        "partitions": 0,
        "productName": null,
        "podName": null,
        "running": false
    },
    {
        "topicName": "msk.capacity.test.tradedemandsimulation.topic.internal.any.v1",
        "consumerGroup": "msk.capacity.gdafreightlistpp.consumergroup.v1",
        "empVersion": "V3",
        "vaultName": "emp-consumer",
        "empUserSecretKey": "retina-emp-user",
        "empPwdSecretKey": "retina-emp-pwd",
        "topicKeyType": "String",
        "topicValueType": "Avro",
        "adlsAccountName": "dlsazewddatalakeraw",
        "adlsPath": "comet-emp/retina-test/testdir",
        "concurrency": 1,
        "additionalUsers": "yash.g@maersk.com,gaurab.dawn@maersk.com,vinayak.v@maersk.com,shivakumar.dorai@maersk.com,niketa.agarwal@maersk.com,sachin.shenoy@maersk.com,prabhat.lodhi@maersk.com,farooq.gandluri@maersk.com",
        "destinationFileFormat": "JSON",
        "status": 1,
        "version": 1,
        "consumerConfigs": "{\"enableAutoCommit\":false,\"autoCommitIntervalMS\":5000,\"autoOffsetReset\":\"earliest\",\"maxPollRecords\":500,\"heartbeatIntervalMS\":3000,\"sessionTimeoutMS\":45000,\"fetchMinBytes\":1,\"fetchMaxBytes\":1048476,\"fetchMaxWaitMS\":500,\"maxPartitionFetchBytes\":1048476}",
        "insertTs": "2023-12-18 10:47:56",
        "userEmail": "Gaurab.Dawn@maersk.com",
        "consumerConfig": {
            "enableAutoCommit": false,
            "autoCommitIntervalMS": 5000,
            "autoOffsetReset": "earliest",
            "maxPollRecords": 500,
            "heartbeatIntervalMS": 3000,
            "sessionTimeoutMS": 45000,
            "fetchMinBytes": 1,
            "fetchMaxBytes": 1048476,
            "fetchMaxWaitMS": 500,
            "maxPartitionFetchBytes": 1048476
        },
        "enableDLQ": true,
        "dlqName": "msk.capacity.test.tradedemandsimulation.topic.internal.any.v1.DLT",
        "enableDLQMessageProcessing": false,
        "enableIdempotentConsumer": false,
        "idempotentConsumerTopic": "",
        "idempotentConsumerGroupId": "",
        "adlsGen": 2,
        "empEnv": null,
        "domain": "null.dev.maersk-digital.net",
        "partitions": 0,
        "productName": null,
        "podName": null,
        "running": false
    }
]

export default streamingData;