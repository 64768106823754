import React from "react";
import { useMemo, useState, useContext, useEffect } from "react";
import LinkIcon from "@mui/icons-material/Link";
import { Button, Box } from "@mui/material";
import MaerskModelsTable from "../../components/reactTable/MaerskModelsTable";
import axios from "axios";
import { userRequestMartbuilder } from "../../requestMethod";
import { AuthContext } from "../../AuthContext";
import { useValue } from "../../context/ContextProvider";
import DashBoardTabs from "../dashboard/DashBoardTabs";
import "../../components/reactTable/MaerskModalsTable.css";
import "./ManageAuditLog.css";
import AuditLogModal from "./auditLogModal/AuditLogModal";

const ManageAuditLog = () => {
  //check_one
  const [auditLogData, setAuditLogData] = useState();
  const [payloadData, setPayloadData] = useState();
  const [openModal, setOpenModal] = useState(false);
  
  //token for new axios;
  const { getToken, token, error } = useContext(AuthContext);
  const {state: { editMetaDataCrawlId }, dispatch,} = useValue();

  // Function to add zero for time(hr, min, sec) less than ten
  const checkTime=(i)=> {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  //axios fetching logic for all 3 table
  const fetchAuditLogData = async () => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(`/auditlogs`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });
      setAuditLogData(response?.data);
      // console.log("Datataaaaa", response?.data[0]["eventTimestamp"])
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  // Function to get payload data 
  const handlePayload = async (idValue) => {
    // console.log(idValue)
    dispatch({ type: "START_CIRCULAR_LOADING" });
    setOpenModal(!openModal);
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(
        `/auditlogs/payload/${idValue}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      setPayloadData(response?.data);
      //console.log("modal api", response?.data)
      dispatch({ type: "END_CIRCULAR_LOADING" });
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  const auditLogTableColumns = useMemo(() => [
    {
      accessorKey: "id",
      header: "ID",
      size: 70,
    },
    {
      accessorKey: "userId",
      header: "User ID",
      size: 140,
    },
    {
      accessorKey: "eventTimestamp",
      header: "Time",
      size: 140,
      Cell: ({ cell: { ...value } }) => {
        // console.log("Times value:", value.row.original.eventTimestamp);
        let timesObj = new Date(value.row.original.eventTimestamp);
        let displayDate =
          timesObj.getFullYear() +
          "/" +
          (timesObj.getMonth() + 1) +
          "/" +
          timesObj.getDate();
        let displayTime =
        checkTime(timesObj.getHours()) +
          ":" +
          checkTime(timesObj.getMinutes()) +
          ":" +
          checkTime(timesObj.getSeconds());
        return (
          <div>
            <p>{displayDate}</p>
            <p>{displayTime}</p>
          </div>
        );
      },
    },
    {
      accessorKey: "resourceAccessed",
      header: "Resource",
      size: 140,
    },
    {
      accessorKey: "requestPayload",
      header: "Payload",
      size: 140,
      Cell: ({ cell: { ...value } }) => {
        // console.log("resourceAccessed value:", value.row.original.resourceAccessed);
        // console.log("resourceAccessed value:", value.row.original.requestPayload);
        let idValue = value.row.original.id;
        return (
          <div
            style={{
              padding: "0 0.8rem",
            }}            
          >
            <LinkIcon sx={{cursor: "pointer", color:"blue"}}
            onClick={() => handlePayload(idValue)}/>
          </div>
        );
      },
    },
    {
      accessorKey: "action",
      header: "Action",
      size: 140,
    },
  ]);

  useEffect(() => {
    (async () => await getToken())();
    fetchAuditLogData();
  }, [openModal, token]);

  return (
    <div>
      {/* <DashBoardTabs /> */}
      <div className="wrapper">
        <div className="table_wrapper" style={{ 
          width: "1200px",
          // width: "100%",
          margin: "0px auto",
          paddingTop: "40px",
          // border: "1px solid red",
          }}>
          {/* first Table "users" */}
          <div
            style={{
              margin: "25px 0px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                // margin:"0 2.5rem",
                marginBottom: "20px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h2 id="manageUsersTable">Manage Audit Log</h2>
            </div>
            <div
              style={{
                //   border: "1px solid red",
                // padding: "40px 40px",
                // width: "1100px",
                width: "1200px",
                margin: "0px auto",
              }}
            >
              <MaerskModelsTable
                columns={auditLogTableColumns}
                data={auditLogData}
                tableType="auditLogData"
                fetchAuditLogData={fetchAuditLogData}
              />
            </div>
          </div>
        </div>
      </div>
      {openModal && (
        <Box sx={{ position: "absolute", top: "100%", left: 0 }}>
          <AuditLogModal
            data={payloadData}
            onOpen={() => setOpenModal(false)}
            boolean={openModal}
          />
        </Box>
      )}
    </div>
  );
};

export default ManageAuditLog;
