import React, { useEffect, useState } from "react";
import FolderTree from "../folderTree/FolderTree";
import { Box, Container, CssBaseline, Paper } from "@mui/material";

import { useValue } from "../../context/ContextProvider";
import IngestionStepperForm from "../ingestionStepperForm/IngestionStepperForm";

const BatchIngestion = () => {
  // const [dataSource, setDataSource] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  // const [dataBookToken, setDataBookToken] = useState(null);
  const {
    state: { showFolderTree, dataSources, dataBookToken },
    dispatch,
  } = useValue();

  //HIDING THE SIDE FOLDER TREE IN THE BEGINNING
  useEffect(() => dispatch({ type: "HIDE_FOLDERTREE" }), []);

  // Clearing any old dataset details in the context
  useEffect(() => dispatch({ type: "CLEAR_DATASET_DETAILS" }), []);

  return (
    <div
      // className="create-job-main"
      style={{
        paddingTop: "30px",
        paddingInline: "3.5%",
        // display: "grid",
        // transition: "all 0.5s",
        display: "flex",

        // gridTemplateColumns: `${showFolderTree ? "1.8fr 7.2fr" : "7fr 2fr"}`,
      }}
    >
      <div
        // className="foldertree-outer-contanier"
        style={{
          height: "80vh",
          overflow: "hidden",
          position: "fixed",
          left: `${showFolderTree ? "120px" : "-250px"}`,
          width: `${showFolderTree ? "16%" : "0"}`,
          transition: "all 0.5s",
          marginTop: "30px",
        }}
      >
        <div
          className="foldertree-inner-contanier"
          // style={{
          //   height: "78vh",
          //   marginTop: "5px",
          //   overflow: "hidden",
          //   backgroundColor: "#fff",
          //   borderRadius: "4px",
          //   border: "1.2px solid #eaeaea",
          // }}
        >
          <FolderTree data={dataSources} isLoading={isLoading} />
        </div>
      </div>

      <div
        style={{
          height: "80vh",
          width: "74%",
          position: "fixed",
          left: "15rem",
          marginTop: "30px",
          marginLeft: "30px",
          transform: `${
            showFolderTree ? "translate(120px, 0%)" : "translate(0px, 0%)"
          }`,
          transition: "transform 0.4s",
        }}
      >
        <CssBaseline />
        <Container component={Box} px={4} py={1}>
          <div>
            <Paper component={Box} p={2}>
              <IngestionStepperForm />
            </Paper>
            {/* {datasetDetails ? (
              <Paper component={Box} p={2}>
                <IngestionStepperForm />
              </Paper>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    background: "#fcd29f",
                    alignItems: "center",
                    padding: "4px",
                  }}
                >
                  <Info fontSize="small" />
                  <p
                    style={{
                      paddingLeft: "4px",
                    }}
                  >
                    Please select the Dataset first from the side panel.
                  </p>
                </div>
                <Paper component={Box} p={2} sx={{ opacity: "70%" }}>
                  <IngestionStepperForm />
                </Paper>
              </>
            )} */}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default BatchIngestion;
