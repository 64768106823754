import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { MARTBUILDER_API } from "../../requestMethod";

const manageDomainsApi = createApi({
  reducerPath: "manageDomains",
  baseQuery: fetchBaseQuery({
    baseUrl: MARTBUILDER_API,
  }),
  endpoints(builder) {
    return {
      fetchDomainsData: builder.query({
        providesTags: ["Domains"],
        query: () => {
          return {
            url: "/domains",
            method: "GET",
          };
        },
      }),
      AddDomainsData: builder.mutation({
        invalidatesTags: ["Domains"],
        query: (data) => {
          return {
            url: `/domains`, // Corrected the URL to match the 'todos' endpoint
            body: { ...data },
            method: "POST",
          };
        },
      }),
      RemoveDomainsData: builder.mutation({
        invalidatesTags: ["Domains"],
        query: (dataId) => {
          // Changed to use dynamic parameter 'dataId'
          return {
            url: `/domains/${dataId}`, // Corrected the URL to use dynamic parameter 'dataId'
            method: "DELETE",
          };
        },
      }),
      UpdateDomainsData: builder.mutation({
        invalidatesTags: ["Domains"],
        query: (updatedData) => {
          return {
            url: `/domains/${updatedData.id}`,
            body: { ...updatedData },
            method: "PUT",
          };
        },
      }),
    };
  },
});

export const {
  useFetchDomainsDataQuery,
  useAddDomainsDataMutation,
  useRemoveDomainsDataMutation,
  useUpdateDomainsDataMutation,
} = manageDomainsApi;

export { manageDomainsApi };
