import React, { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";

import axios from "axios";
import { userRequestMartbuilder } from "../../requestMethod";
import "./StatusModal.css";
import CircularLoader from "../Atoms/CircularLoader";
import TaskStatusLogs from "./TaskStatusLogs";
import { AuthContext } from "../../AuthContext";

const StatusModal = ({ boolean, onOpen, Item, jobType }) => {
  // console.log( "Item", Item)
  const [dagId, setDagId] = useState("");
  const [dagRunId, setDagRunId] = useState("");
  const [status, setStatus] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getToken, token, error } = useContext(AuthContext);

  //for the response dialog;
  // const [showResponse, setShowResponse] = useState(false)
  // const [responseData, setResponseData] = useState()

  useEffect(() => {
    if (Item) {
      setDagId(Item?.dag_id);
      setDagRunId(Item?.dag_run_id);
    }
  }, [Item]);

  //for the response dialog
  // const openResponseDialog = (data) => {
  //   setResponseData(data)
  //   setShowResponse(!showResponse)
  // }

  // fetching task status
  //with this api call we have to deal with "query" parameter
  useEffect(() => {
    const ourRequest = axios.CancelToken.source();
    setIsLoading(true);
    const fetchStatusData = async (dagId, dagRunId) => {
      try {
        if (dagId && dagRunId) {
          const response = await userRequestMartbuilder.get(
            `/jobs/dag/${dagId}/runs/${dagRunId}/taskinstances?jobType=${jobType}`,
            {
              headers: {
                // Define your custom headers here
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              CancelToken: ourRequest.token,
            }
          );
          setStatus(response?.data?.task_instances || []);
          setIsLoading(false);
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        } else {
          console.error(err);
        }
      }
    };
    fetchStatusData(dagId, dagRunId);
    return () => {
      ourRequest.cancel();
    };
  }, [dagId, dagRunId]);

  if (isLoading) {
    return (
      <Dialog open={boolean} onClose={onOpen} maxWidth={"md"} fullWidth>
        <DialogContent
          style={{
            height: "360px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularLoader />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <div>
      <Dialog
        open={boolean}
        onClose={onOpen}
        scroll="paper"
        fullWidth
        maxWidth={"md"}
      >
        <DialogTitle id="scroll-dialog-title">Run Status</DialogTitle>
        <DialogContent
          style={{
            width: "100%",
            paddingInline: "15px",
          }}
        >
          {status?.map((data, index) => (
            <TaskStatusLogs data={data} key={index} jobType={jobType} />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={onOpen}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default StatusModal;
