import React from "react";
import { useMemo, useState, useContext, useEffect } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { AddCircleOutline, BorderColor } from "@mui/icons-material";
import { Button, Box, Chip } from "@mui/material";
import MaerskModelsTable from "../../components/reactTable/MaerskModelsTable";
import axios from "axios";
import { userRequestMartbuilder } from "../../requestMethod";
import { AuthContext } from "../../AuthContext";
import DashBoardTabs from "../dashboard/DashBoardTabs";
import "../../components/reactTable/MaerskModalsTable.css";
import "./ManageAuthorization.css";
import AddNewUser from "../../components/manageAuthorizationModal/AddNewUser";
import AddNewRole from "../../components/manageAuthorizationModal/AddNewRole";
import AddNewPermission from "../../components/manageAuthorizationModal/AddNewPermission";
import { Link } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CloseIcon from "@mui/icons-material/Close";
import AuthorizationTabs from "./AuthorizationTabs";
import AddNewApplication from "../../components/manageAuthorizationModal/AddNewApplication";
import AddNewResource from "../../components/manageAuthorizationModal/AddNewResource";

const ManageAuthorization = () => {
  //check_one
  const [userData, setUserData] = useState();
  const [resourceData, setResourceData] = useState();
  const [applicationData, setApplicationData] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [activeTableType, setActiveTableType] = useState(null);
  //token for new axios;
  const { getToken, token, error } = useContext(AuthContext);

  // Random colour
  var singleColour;
  const randomColour = () => {
    var colors = [
      "#cb4154",
      "#008080",
      "#9caf88",
      "#bf5700",
      "#ff7f50",
      "#4169e1",
      "#7851a9",
    ];
    singleColour = colors[Math.floor(Math.random() * colors.length)];
    return singleColour;
  };

  //axios fetching logic for all 3 table
  // const fetchUserTableData = async () => {
  //   const ourRequest = axios.CancelToken.source();
  //   try {
  //     let response = await userRequestMartbuilder.get(`/aatusers`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //       },
  //       CancelToken: ourRequest.token,
  //     });
  //     setUserData(response?.data);
  //     // console.log(response?.data)
  //   } catch (err) {
  //     if (axios.isCancel(err)) {
  //       return;
  //     } else {
  //       console.error(err);
  //     }
  //   }
  //   return () => {
  //     ourRequest.cancel();
  //   };
  // };

  const fetchResourcesData = async () => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(
        `/aatapplications/resources`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      setResourceData(response?.data);
      // console.log(response?.data);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  const fetchApplicationsData = async () => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(`/aatapplications`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });
      setApplicationData(response?.data);
      // console.log(response?.data);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  const handleCreateNewRow = (tableType) => {
    // console.log(tableType)
    setActiveTableType(tableType);
    setOpenModal(!openModal);
  };

  const userTableColumns = useMemo(() => [
    // {
    //   accessorKey: "icon",
    //   header: "User Icon",
    //   size: 100,
    // Cell: ({ cell: { ...value } }) => {
    //   console.log("Roles value:", value.row.original.icon);
    //   let dps = value.row.original.icon;
    //   return (
    //     <div style={{display:"flex", alignItems:"center", gap:"4px"}}>
    //       {dps? <img src={dps} alt="DP" />
    //          : <AccountCircleIcon /> }
    //     </div>

    //   );
    // },
    // },
    {
      accessorKey: "name",
      header: "User Name",
      size: 140,
      Cell: ({ cell: { ...value } }) => {
        // console.log("Roles value:", value.row.original.name);
        let dps = value.row.original.name;
        return (
          <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <AccountCircleIcon />
            <span>{dps}</span>
          </div>
        );
      },
    },
    {
      accessorKey: "email_id",
      header: "User Email",
      size: 140,
    },
    {
      accessorKey: "domains",
      header: "Domains",
      size: 140,
    },
    {
      accessorKey: "roles",
      header: "Roles",
      size: 140,
      Cell: ({ cell: { ...value } }) => {
        // console.log("Roles value:", value.row.original.roles);
        let arrayRoles = value.row.original.roles;
        return arrayRoles?.map((role, index) => (
          <div style={{ marginBottom: "4px" }} key={role.id}>
            <Chip
              variant="outlined"
              // onDelete={() => console.log("You are trying to delete the Role")}
              // deleteIcon={
              //   <CloseIcon
              //     sx={{
              //       cursor: "pointer",
              //     }}
              //   />
              // }
              label={index + 1 + ". " + role.name}
            />
          </div>
        ));
      },
    },
  ]);

  const resourceTableColumns = useMemo(() => [
    {
      accessorKey: "id",
      header: "ID",
      size: 80,
    },
    {
      accessorKey: "application",
      header: "Application",
      size: 240,
      // Cell: ({ cell: { ...value } }) => {
      //   // console.log("Roles value:", value.row.original.name);
      //   let arrayApps = value.row.original.application;
      //   let name = value.row.original.name;
      //   if (arrayApps !== undefined) {
      //     return arrayApps?.map((apps, index) => (
      //       <div key={apps.id}>
      //         <strong>
      //           {apps.name}
      //         </strong>
      //       </div>
      //     ));
      //   // } else {
      //   //   return name?.map((index)=>
      //   //   <div key={index}>
      //   //     <strong>
      //   //       Application not available
      //   //     </strong>
      //   //   </div>
      //   //   )
      //   }
      // },
    },
    {
      accessorKey: "name",
      header: "Resource",
      size: 240,
    },
    {
      accessorKey: "description",
      header: "Description",
      size: 240,
    },
    // {
    //   accessorKey: "permissions",
    //   header: "Permissions",
    //   size: 140,
    //   Cell: ({ cell: { ...value } }) => {
    //     // console.log("Permissions value:", value.row.original.permissions);
    //     let arrayPermissions = value.row.original.permissions;
    //     return arrayPermissions?.map((permission, index) => (
    //       <div style={{ marginBottom: "4px" }} key={permission.id}>
    //         <Chip
    //           variant="outlined"
    //           style={{color:randomColour(), borderColor:singleColour,}}
    //           // onDelete={() => console.log("You are trying to delete the Role")}
    //           // deleteIcon={
    //           //   <CloseIcon
    //           //     sx={{
    //           //       cursor: "pointer",
    //           //     }}
    //           //   />
    //           // }
    //           label={index + 1 + ". " + permission.name}
    //         />
    //       </div>
    //     ));
    //   },
    // },
  ]);

  const applicationsTableColumns = useMemo(() => [
    {
      accessorKey: "id",
      header: "ID",
      size: 80,
    },
    {
      accessorKey: "name",
      header: "Name",
      size: 240,
    },
    {
      accessorKey: "description",
      header: "Description",
      size: 240,
    },
    // {
    //   accessorKey: "updatedBy",
    //   header: "Updated By",
    //   size: 280,
    // },
    // {
    //   accessorKey: "lastUpdatedAt ",
    //   header: "Last Updated Time",
    //   size: 280,
    // },
    // {
    //   accessorKey: "roles",
    //   header: "Roles",
    //   size: 140,
    //   Cell: ({ cell: { ...value } }) => {
    //     // console.log("Roles value:", value.row.original.roles);
    //     let arrayRoles = value.row.original.roles
    //     return arrayRoles?.map((role, index) => (
    //       <div key={role.id}>
    //         <strong> {index+1}. {role.name}</strong>
    //       </div>
    //     ));
    //   },
    // },
  ]);

  useEffect(() => {
    (async () => await getToken())();
    // fetchUserTableData();
    fetchResourcesData();
    fetchApplicationsData();
  }, [openModal, token]);

  return (
    <div>
      {/* <DashBoardTabs /> */}
      <AuthorizationTabs />
      <div className="wrapper">
        <div
          className="table_wrapper"
          style={{
            width: "1200px",
            // width: "100%",
            margin: "0px auto",
            paddingTop: "80px",
          }}
        >
          {/* first Table "applications" */}
          <div
            style={{
              margin: "25px 0px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                // margin:"0 2.5rem",
                marginBottom: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h2 id="managePermissionsTable">Applications Table</h2>
              {/* <AddCircleOutlineIcon
                style={{
                  fontSize: 32,
                  cursor: "pointer",
                }}
                // onClick={() => handleCreateNewRow("MaerskModels")}
              /> */}
              <button
                className="rounded-md job-btn btn nar-wid"
                // variant="outlined"
                // size="small"
                // sx={{
                //   color: "#247ba0",
                //   borderColor: "#247ba0",
                //   fontSize: "9.5px",
                // }}
                // startIcon={<AddCircleOutline />}
                onClick={() => handleCreateNewRow("Manage_Application")}
              >
                <AddCircleOutline fontSize="inherit" />
                <span>Add Application</span>
              </button>
              {/* <Button
                variant="outlined"
                size="small"
                sx={{
                  color: "#247ba0",
                  borderColor: "#247ba0",
                  fontSize: "9.5px",
                }}
                startIcon={<AddCircleOutline />}
                onClick={() => handleCreateNewRow("Manage_Permission")}
              >
                Add Permission
              </Button> */}
            </div>
            <div
              style={{
                // padding: "40px 40px",
                // width: "1100px",
                width: "1200px",
                margin: "0px auto",
              }}
            >
              <MaerskModelsTable
                columns={applicationsTableColumns}
                data={applicationData}
                tableType="ApplicationData"
                fetchApplicationsData={fetchApplicationsData}
              />
            </div>
          </div>

          {/* second Table "resources" */}
          <div
            style={{
              margin: "25px 0px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                // margin:"0 2.5rem",
                marginBottom: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h2 id="manageRolesTable">Resource Table</h2>
              {/* <AddCircleOutlineIcon
                style={{
                  fontSize: 32,
                  cursor: "pointer",
                }}
                // onClick={() => handleCreateNewRow("MaerskModels")}
              /> */}
              {/* <Link to="/manageAuthorization/addNewRole"> */}
              <button
                className="rounded-md job-btn btn nar-wid"
                // variant="outlined"
                // size="small"
                // sx={{
                //   color: "#247ba0",
                //   borderColor: "#247ba0",
                //   fontSize: "9.5px",
                // }}
                // startIcon={<AddCircleOutline />}
                onClick={() => handleCreateNewRow("Manage_Resource")}
              >
                <AddCircleOutline fontSize="inherit" />
                <span>Add Resource</span>
              </button>
              {/* </Link> */}
              {/* <Button
                variant="outlined"
                size="small"
                sx={{
                  color: "#247ba0",
                  borderColor: "#247ba0",
                  fontSize: "9.5px",
                }}
                startIcon={<AddCircleOutline />}
                onClick={() => handleCreateNewRow("Manage_Role")}
              >
                Add Role
              </Button> */}
            </div>
            <div
              style={{
                //   border: "1px solid red",
                // padding: "40px 40px",
                // width: "1100px",
                width: "1200px",
                margin: "0px auto",
              }}
            >
              <MaerskModelsTable
                columns={resourceTableColumns}
                data={resourceData}
                tableType="ResourceData"
                fetchResourcesData={fetchResourcesData}
              />
            </div>
          </div>

          {/* third Table "users" */}
          {/* <div
            style={{
              margin: "25px 0px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                // margin:"0 2.5rem",
                marginBottom: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h2 id="manageUsersTable">Manage Users Table</h2>
              <button
                className="rounded-md job-btn btn nar-wid"
                // variant="outlined"
                // size="small"
                // sx={{
                //   color: "#247ba0",
                //   borderColor: "#247ba0",
                //   fontSize: "9.5px",
                // }}
                // startIcon={<AddCircleOutline />}
                onClick={() => handleCreateNewRow("Manage_User")}
              >
                <AddCircleOutline fontSize="inherit" />
                <span>Add User</span>
              </button>
            </div>
            <div
              style={{
                //   border: "1px solid red",
                // padding: "40px 40px",
                // width: "1100px",
                width: "1200px",
                margin: "0px auto",
              }}
            >
              <MaerskModelsTable
                columns={userTableColumns}
                data={userData}
                tableType="UserData"
                fetchUserTableData={fetchUserTableData}
              />
            </div>
          </div> */}
        </div>
      </div>
      {openModal && (
        <Box sx={{ position: "absolute", top: "100%", left: 0 }}>
          {activeTableType === "Manage_Application" && (
            <AddNewApplication
              boolean={openModal}
              onOpen={() => setOpenModal(false)}
              tableType={"Manage_Application"}
            />
          )}
          {activeTableType === "Manage_Resource" && (
            <AddNewResource
              boolean={openModal}
              onOpen={() => setOpenModal(false)}
              tableType={"Manage_Resource"}
            />
          )}
          {/* {activeTableType === "Manage_User" && (
            <AddNewUser
              boolean={openModal}
              onOpen={() => setOpenModal(false)}
              tableType={"Manage_User"}
            />
          )} */}
          {/* {activeTableType === "Manage_Role" && (
          <AddNewRole
            boolean={openModal}
            onOpen={() => setOpenModal(false)}
            tableType={"Manage_Role"}
          />
          )} */}
          {activeTableType === "Manage_Permission" && (
            <AddNewPermission
              boolean={openModal}
              onOpen={() => setOpenModal(false)}
              tableType={"Manage_Permission"}
            />
          )}
        </Box>
      )}
    </div>
  );
};

export default ManageAuthorization;
