import React, { useContext, useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { userRequestMartbuilder } from "../../requestMethod";
import axios from "axios";
import { AuthContext } from "../../AuthContext";

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];



const Example = ({metricsData, fetchGraphData,jobNameDetailValue, setDays}) => {  

// console.log(metricsData, "metricsData")
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (!metricsData) return;
  
    // Transform the API response into the data format expected by Recharts
    const transformedData = [];
    const metricNames = metricsData.metrics.map(metric => metric.metricName);
    const allDates = new Set(metricsData.metrics.flatMap(metric => 
      Object.keys(metric.metricValues)
    ));
  
    allDates.forEach(dateStr => {
      const date = new Date(dateStr);
      const formattedDate = date.toISOString().split('T')[0]; // Formats the date as "YYYY-MM-DD"
  
      const dataPoint = { date: formattedDate };
      metricsData.metrics.forEach(metric => {
   
        dataPoint[metric.metricName] = metric.metricValues[dateStr] || 0;
      });
      transformedData.push(dataPoint);
    });
  
    setChartData(transformedData);
    // console.log(transformedData, "<++transformedData"); 
  }, [metricsData]);
  
 

  

  

  return (
 <div style={{ height:"600px", paddingTop:"5%", paddingLeft:"8%"}} >
       <div style={{paddingLeft:"100px"}}>
      </div>
      <div style={{border:"1px solid grey", borderRadius:"10px", padding:"5px", textAlign:"center", width:"max-content", marginLeft:"70px", margin:"20px", backgroundColor:"#e6fff2"}} > Resource Name : {metricsData.resourceName} </div>
     <ResponsiveContainer width="90%" height="90%"  >
      <LineChart
        width={500}
        height={500} 
        data={chartData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 50,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" /> 
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
          type="monotone" 
          dataKey="recordsRead"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        /> 
        <Line type="monotone" dataKey="recordsWritten" stroke="#82ca9d" />
        <Line type="monotone" dataKey="bytesRead" stroke="#85ca5d" />
      </LineChart>
    </ResponsiveContainer>
 </div>
  );
};
export default Example;
