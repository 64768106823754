import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

const Name = styled.p`
  width: 135px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.8px;
  text-align: right;
`;

const Breadcrumbs = () => {
  const location = useLocation();
  const paths = location.pathname.split("/").filter(Boolean);
  // console.log(paths)
  // console.log(paths[1])

  function camelCaseToSpacedTextWithCapitalizedFirst(inputString) {
    const spacedText = inputString.replace(/([a-z])([A-Z])/g, "$1 $2");
    return spacedText.charAt(0).toUpperCase() + spacedText.slice(1);
  }

  if (
    paths[0] === "manageProjects" ||
    paths[0] === "jobRequest" ||
    paths[0] === "manageMetadatacrawls" ||
    (paths[0] === "manageDatasources" && paths[1] === undefined) ||
    (paths[0] === "manageAuthorization" && paths[1] === undefined) ||
    paths[0] === "manageAuditLog" ||
    paths[0] === "landingPage" ||
    paths[0] === undefined
  ) {
    return;
  }
  return (
    <nav
      style={{
        // backgroundColor: "#fff",
        marginLeft: "0.5%",
        // paddingLeft:"100px",
        backgroundColor: "#f8f9fa",
        // backdropFilter: "blur(10px)",
        // marginLeft: "7%",
        marginTop: "2px",
        marginBottom: "2px",
        fontWeight: "500",
        fontSize: "16px",
        paddingTop: "10px",
        paddingBottom: "10px",
        position: "fixed",
        left: "120px",
        // width: "max-content",
        width: "100%",
        zIndex: "99",
        // textAlign: "center",
      }}
    >
      {paths[0] ? (
        <span>
          <Link to={"/"}>Home</Link> /
        </span>
      ) : null}

      {paths.map((path, index) => (
        <span key={path}>
          {index > 0 && " / "}
          {index === 1 && path === "edit" ? (
            <span style={{ fontWeight: "500" }}>
              {camelCaseToSpacedTextWithCapitalizedFirst(path)}
            </span>
          ) : (
            <>
              {index === paths.length - 1 ? (
                <span style={{ fontWeight: "500" }}>
                  {camelCaseToSpacedTextWithCapitalizedFirst(path)}
                </span>
              ) : (
                <Link to={`/${paths.slice(0, index + 1).join("/")}`}>
                  {camelCaseToSpacedTextWithCapitalizedFirst(path)}
                </Link>
              )}
            </>
          )}
        </span>
      ))}
    </nav>
  );
};

export default Breadcrumbs;
