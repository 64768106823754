import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  ListSubheader,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { userRequestMartbuilder } from "../../../requestMethod";
import axios from "axios";
import "./AddNewMetaDataCrawl.css";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../AuthContext";
import AzureDataLakeForm from "../azureDataLakeForm/AzureDataLakeForm";
import Autocomplete from "@mui/material/Autocomplete";
import { useValue } from "../../../context/ContextProvider";
import BackdropLoader from "../../Atoms/BackdropLoader";
import useWindowDimensions from "../../Atoms/useWindowDimensions";
import CircularLoader from "../../Atoms/CircularLoader";

const AddNewMetaDataCrawlData = () => {
  const [dataSource, setDataSource] = useState([]);
  const [dataSourceName, setDataSourceName] = useState("");
  const [isSchemaSelected, setIsSchemaSelected] = useState(false);
  const [isTableFilterSelected, setIsTableFilterSelected] = useState(false);
  const [isAutoCrawlSelected, setIsAutoCrawlSelected] = useState(false);
  const [isDelimiterSelected, setIsDelimiterSelected] = useState(false);

  const { getToken, token, error } = useContext(AuthContext);

  // screen size
  const { height, width } = useWindowDimensions();

  // schema
  const [inputValue, setInputValue] = useState("");
  const [inputVealue, setInputVealue] = useState("");
  const [schemaInclusivePattern, setSchemaInclusivePattern] = useState([]);
  const [schemaExclusivePattern, setSchemaExclusivePattern] = useState([]);
  //tableSchema
  const [inputTableInclusiveValue, setInputTableInclusiveValue] = useState("");
  const [inputTableExclusiveValue, setInputTableExclusiveValue] = useState("");
  const [tableInclusivePatterns, setTableInclusivePatterns] = useState([]);
  const [tableExclusivePattern, setTableExclusivePattern] = useState([]);
  //for handling case if the user select AzureDataLake
  const [AzureDataLake, setAzureDataLake] = useState(true);

  //for handling case if the user select SFTP
  const [SFTP, setSFTP] = useState(true);

  //for payload handling
  const [customPayloadAzure, setCustomPayloadAzure] = useState(false);
  const [customPayloadSFTP, setCustomPayloadSFTP] = useState(false);

  //for product drop down
  const [selectedProduct, setSelectedProduct] = useState("");
  const [productTableData, setProductTableData] = useState();
  const [loadingProducts, setLoadingProducts] = useState(false);

  // Search function for dropdown
  const [searchText, setSearchText] = useState();
  const containsText = (text, searchtext) => {
    return text?.toLowerCase().indexOf(searchtext?.toLowerCase()) > -1;
  };

  const {
    dispatch,
    state: { backdropLoading },
  } = useValue();

  const dataSourceOptions = useMemo(
    () =>
      dataSource?.map((ds) => ({
        label: ds.connectionFqn,
        value: ds.connectionFqn,
      })) || [],
    [dataSource]
  );

  const productOptions = productTableData
    ?.filter((item) => item.name && item.id)
    .map((item) => ({
      label: item.name,
      value: item.id,
    }));

  //radio button
  const handleDataSource = (event, newValue) => {
    let selectedDataSource;
    if (newValue) {
      const selectedId = newValue.label;
      setDataSourceName(selectedId);

      selectedDataSource = dataSource.find(
        (item) => item.connectionFqn === selectedId
      );
    }

    if (selectedDataSource?.datasourceType === "AzureDataLake") {
      setAzureDataLake(false);
      setCustomPayloadAzure(true);
      setCustomPayloadSFTP(false);
    } else {
      setAzureDataLake(true);
    }

    if (selectedDataSource?.datasourceType === "SFTP") {
      setSFTP(false);
      setCustomPayloadSFTP(true);
      setCustomPayloadAzure(false);
    } else {
      setSFTP(true);
    }
  };

  const handleSchemaRadioChange = () => {
    setIsSchemaSelected(!isSchemaSelected);
  };

  const handleTableFilterRadioChange = () => {
    setIsTableFilterSelected(!isTableFilterSelected);
  };

  const handleAutoCrawlRadioChange = () => {
    setIsAutoCrawlSelected(!isAutoCrawlSelected);
  };

  const handleDelimiterRadioChange = () => {
    setIsDelimiterSelected(!isDelimiterSelected);
  };

  //handling the input fields
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSchemaExclusiveChange = (e) => {
    setInputVealue(e.target.value);
  };

  const handleTableInclusiveChange = (e) => {
    setInputTableInclusiveValue(e.target.value);
  };

  const handleTableExclusiveChange = (e) => {
    setInputTableExclusiveValue(e.target.value);
  };

  //array handling
  const handleAddToDataArray = () => {
    if (inputValue.trim() !== "") {
      setSchemaInclusivePattern([...schemaInclusivePattern, inputValue]);
      setInputValue("");
    }
  };

  const schemaExclusive = () => {
    if (inputVealue.trim() !== "") {
      setSchemaExclusivePattern([...schemaExclusivePattern, inputVealue]);
      setInputVealue("");
    }
  };

  const tableInclusive = () => {
    if (inputTableInclusiveValue.trim() !== "") {
      setTableInclusivePatterns([
        ...tableInclusivePatterns,
        inputTableInclusiveValue,
      ]);
      setInputTableInclusiveValue("");
    }
  };

  const tableExclusive = () => {
    if (inputTableExclusiveValue.trim() !== "") {
      setTableExclusivePattern([
        ...tableExclusivePattern,
        inputTableExclusiveValue,
      ]);
      setInputTableExclusiveValue("");
    }
  };

  const navigate = useNavigate();
  const handleSubmit = () => {
    if (!dataSourceName) {
      alert("Please select a Datasource Connection");
      return;
    }
    console.log("Data Array:", schemaInclusivePattern);
    metaDataCrawlPostReq();
    setSchemaInclusivePattern([]);
    setSchemaExclusivePattern([]);
    setTableInclusivePatterns([]);
    setTableExclusivePattern([]);
  };

  // Define, state for the payload
  const [crawlPayload, setCrawlPayload] = useState({
    cronSchedule: "",
    datasourceConnectionFqn: "",
    name: "",
    product: {
      id: "",
    },
    id: "",
    isAutoCrawl: false,
    schemaExclusiveFilterPatterns: "",
    schemaInclusiveFilterPatterns: "",
    tableExclusiveFilterPatterns: "",
    tableInclusiveFilterPatterns: "",
  });

  const [azureData, setAzureData] = useState({
    adlsContainerName: "",
    adlsDatasetPath: "",
    adlsDatasetSchemaName: "",
    adlsDoesPathContainTables: true,
    adlsFileFormat: "",
    adlsStorageAccountName: "",
  });

  const [SFTPData, setSFTPData] = useState({
    sftpFileDirectory: "",
    csvDelimiter: "",
    isCSVDelimiter: false,
  });

  const azurePayload = {
    ...azureData,
    name: crawlPayload.name,
    product: {
      id: selectedProduct,
    },
    datasourceConnectionFqn: dataSourceName,
    id: crawlPayload.id,
    isAutoCrawl: isAutoCrawlSelected,
    cronSchedule: crawlPayload.cronSchedule,
  };

  const sftpPayload = {
    ...SFTPData,
    name: crawlPayload.name,
    product: {
      id: selectedProduct,
    },
    datasourceConnectionFqn: dataSourceName,
    id: crawlPayload.id,
    isAutoCrawl: isAutoCrawlSelected,
    isCSVDelimiter: isDelimiterSelected,
    cronSchedule: crawlPayload.cronSchedule,
  };

  const regularPayload = {
    cronSchedule: crawlPayload.cronSchedule,
    datasourceConnectionFqn: dataSourceName,
    name: crawlPayload.name,
    product: {
      id: selectedProduct,
    },
    id: crawlPayload.id,
    isAutoCrawl: isAutoCrawlSelected,
    schemaInclusiveFilterPatterns: schemaInclusivePattern,
    schemaExclusiveFilterPatterns: schemaExclusivePattern,
    tableInclusiveFilterPatterns: tableInclusivePatterns,
    tableExclusiveFilterPatterns: tableExclusivePattern,
  };

  const metaDataCrawlPostReq = async () => {
    dispatch({ type: "START_BACKDROP_LOADING" });
    const ourRequest = axios.CancelToken.source();

    let payload;
    if (customPayloadAzure) {
      payload = azurePayload;
    } else if (customPayloadSFTP) {
      payload = sftpPayload;
    } else {
      payload = regularPayload;
    }
    let response;
    try {
      response = await userRequestMartbuilder.post(`/metadatacrawl/`, payload, {
        headers: {
          // Define your custom headers here
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
        if (err.response.status === 200) {
          alert(err.message);
        } else if (err.response.status === 500) {
          alert(err.message);
        } else if (err.response.status === 404) {
          alert(err.message);
        }
      }
    } finally {
      dispatch({ type: "END_BACKDROP_LOADING" });
      navigate("/manageMetadatacrawls");
    }
  };

  const fetchDataSources = async () => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(`/datasources/`, {
        headers: {
          // Define your custom headers here
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });
      setDataSource(response?.data || []);
      // console.log(response?.data);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
  };

  //for product fetching
  const fetchProductTableData = async () => {
    setLoadingProducts(true);
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(`/product`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });
      setProductTableData(response?.data);
      // console.log(response.data, "<==product");
      // dispatch({ type: "PRODUCT_DATA", payload: response.data });
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    } finally {
      setLoadingProducts(false);
    }
    return () => {
      ourRequest.cancel();
    };
  };

  useEffect(() => {
    (async () => await getToken())();
    fetchProductTableData();
    fetchDataSources();
  }, [getToken, token]);

  //delete the item
  const handleDeleteFromSchemaInclusive = (index) => {
    const updatedPatterns = schemaInclusivePattern.filter(
      (_, i) => i !== index
    );
    setSchemaInclusivePattern(updatedPatterns);
  };

  const handleDeleteFromSchemaExclusive = (index) => {
    const updatedPatterns = schemaExclusivePattern.filter(
      (_, i) => i !== index
    );
    setSchemaExclusivePattern(updatedPatterns);
  };

  const handleDeleteFromTableInclusive = (index) => {
    const updatedPatterns = tableInclusivePatterns.filter(
      (_, i) => i !== index
    );
    setTableInclusivePatterns(updatedPatterns);
  };

  const handleDeleteFromTableExclusive = (index) => {
    const updatedPatterns = tableExclusivePattern.filter((_, i) => i !== index);
    setTableExclusivePattern(updatedPatterns);
  };

  if (backdropLoading) {
    return <BackdropLoader message={"Please wait..."} />;
  }

  // Update the handleOptionChange function to set the selected product

  const handleOptionChange = (event, newValue) => {
    setSelectedProduct(newValue ? newValue.value : "");
  };

  return (
    <>
      <div
        style={{
          paddingTop: "80px",
          // textAlign: "center",
          // width: "1200px",
          width: "86%",
          // marginLeft: "200px",
          marginLeft: "6.25rem",
        }}
      >
        <h2 style={{ marginLeft: "20px" }}>Add New Metadata-Crawl</h2>
        <div
          style={{
            margin: "1rem",
            padding: "1.8rem",
            background: "#ffffff",
            boxShadow: "0 2px 4px 0 rgba(0,0,0,0.2)",
          }}
        >
          <div
            style={
              width < 1440
                ? {
                    display: "flex",
                    // border:"1px solid red",
                    flexWrap: "wrap",
                    width: "max-content",
                    flexDirection: "column",
                  }
                : {
                    display: "flex",
                    // border:"1px solid red",
                    flexWrap: "wrap",
                    width: "max-content",
                  }
            }
          >
            <div
              className="arrayItem"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                // variant="subtitle1"
                style={{
                  color: "black !important",
                  fontWeight: 550,
                  marginRight: "108px",
                  minWidth: "200px",
                  textAlign: "left",
                }}
              >
                Crawl Name
              </Typography>
              <TextField
                sx={{ minWidth: "450px" }}
                size="small"
                margin="dense"
                name="name"
                placeholder="Crawl Name"
                value={crawlPayload.name}
                onChange={(e) =>
                  setCrawlPayload({
                    ...crawlPayload,
                    name: e.target.value,
                  })
                }
              />
            </div>
            <div
              style={width < 1440 ?{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                textAlign: "center",
                width: "350px",
                marginLeft: "0",
              }:{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                textAlign: "center",
                width: "350px",
                marginLeft: "20px",
              }}
            >
              <Typography
                style={
                  width < 1440
                    ? {
                        color: "black !important",
                        fontWeight: "550",
                        marginRight: "108px",
                        minWidth: "200px",
                        textAlign: "left",
                      }
                    : {
                        color: "black !important",
                        fontWeight: 550,
                        marginLeft: "20px",
                        marginRight: "28px",
                      }
                }
              >
                Product
              </Typography>
              <FormControl>
                {loadingProducts ? (
                  <CircularLoader />
                ) : (
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={productOptions}
                    sx={{ width: 220 }}
                    onChange={handleOptionChange}
                    isOptionEqualToValue={(option, value) =>
                      option.label === value.label
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Product"
                        InputProps={{
                          ...params.InputProps,
                          style: { padding: 3 },
                        }}
                      />
                    )}
                  />
                )}
              </FormControl>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              textAlign: "center",
              width: "900px",
              marginTop: "15px",
            }}
          >
            <Typography
              style={{
                color: "black !important",
                fontWeight: 550,
                marginRight: "90px",
                padding: "0px",
              }}
            >
              Choose Datasource Connection
            </Typography>
            <FormControl sx={{ minWidth: 500, padding: 0 }}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                style={{ padding: "0px" }}
                options={dataSourceOptions}
                sx={{ width: 450, padding: "0px" }}
                onChange={handleDataSource}
                isOptionEqualToValue={(option, value) =>
                  option.label === value.label
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Datasource"
                    InputProps={{ ...params.InputProps, style: { padding: 3 } }}
                  />
                )}
              />
            </FormControl>
          </div>

          {SFTP && (
            <>
              {AzureDataLake && (
                <div style={{}}>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        textAlign: "center",
                        width: "900px",
                        margin: "20px 0px",
                      }}
                    >
                      <Typography
                        style={{
                          color: "black !important",
                          fontWeight: 550,
                          marginRight: "33px",
                        }}
                      >
                        Schema Filter Pattern
                      </Typography>
                      <input
                        style={{ width: "260px" }}
                        type="checkbox"
                        id="schema"
                        name="radioGroup"
                        onChange={handleSchemaRadioChange}
                      />
                    </div>

                    {isSchemaSelected && (
                      <div>
                        <div
                          className="arrayItem"
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            // variant="subtitle1"
                            style={{
                              color: "black !important",
                              fontWeight: 550,
                              marginRight: "108px",
                              minWidth: "200px",
                              textAlign: "left",
                            }}
                          >
                            Schema Inclusive Pattern
                          </Typography>
                          <TextField
                            sx={{ minWidth: "220px" }}
                            size="small"
                            margin="dense"
                            name="schemaInclusivePattern"
                            // placeholder="Comma-Separated"
                            value={inputValue}
                            onChange={handleInputChange}
                          />

                          <button
                            className="rounded-md job-btn btn nar-wid"
                            onClick={handleAddToDataArray}
                            style={{
                              margin: "0 10px",
                            }}
                          >
                            Add
                          </button>
                          {schemaInclusivePattern.length > 0 && (
                            <div className="enteredText">
                              <ul style={{ paddingLeft: "0" }}>
                                {schemaInclusivePattern.map((data, index) => (
                                  <li key={index}>
                                    {data}
                                    <span
                                      className="cancel"
                                      onClick={() =>
                                        handleDeleteFromSchemaInclusive(index)
                                      }
                                    >
                                      x
                                    </span>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                          className="arrayItem"
                        >
                          <Typography
                            // variant="subtitle1"
                            style={{
                              color: "black !important",
                              fontWeight: 550,
                              marginRight: "97px",
                              minWidth: "210px",
                              textAlign: "left",
                            }}
                          >
                            Schema Exclusive Pattern
                          </Typography>
                          <TextField
                            sx={{ minWidth: "220px" }}
                            size="small"
                            margin="dense"
                            name="schemaExclusivePattern"
                            // placeholder="Comma-Separated"
                            value={inputVealue}
                            onChange={handleSchemaExclusiveChange}
                          />
                          <button
                            className="rounded-md job-btn btn nar-wid"
                            onClick={schemaExclusive}
                            style={{
                              margin: "0 10px",
                            }}
                          >
                            Add
                          </button>
                          {schemaExclusivePattern.length > 0 && (
                            <div className="enteredText">
                              <ul style={{ paddingLeft: "0" }}>
                                {schemaExclusivePattern.map((data, index) => (
                                  <li key={index}>
                                    {data}{" "}
                                    <span
                                      className="cancel"
                                      onClick={() =>
                                        handleDeleteFromSchemaExclusive(index)
                                      }
                                    >
                                      x
                                    </span>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        textAlign: "center",
                        width: "900px",
                        margin: "20px 0px",
                      }}
                    >
                      <Typography
                        style={{
                          color: "black !important",
                          fontWeight: 550,
                          marginRight: "52px",
                        }}
                      >
                        Table Filter Pattern
                      </Typography>
                      <input
                        style={{ width: "260px" }}
                        type="checkbox"
                        id="tableFilter"
                        name="radioGroup"
                        onChange={handleTableFilterRadioChange}
                      />
                    </div>

                    {isTableFilterSelected && (
                      <div>
                        <div
                          className="arrayItem"
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            // variant="subtitle1"
                            // style={{ fontWeight: 550, marginRight: "180px", minWidth:"200px" }}
                            style={{
                              color: "black !important",
                              fontWeight: 550,
                              marginRight: "148px",
                              minWidth: "max-content",
                              textAlign: "left",
                            }}
                          >
                            Table Inclusive Pattern
                          </Typography>
                          <TextField
                            sx={{ minWidth: "220px" }}
                            size="small"
                            margin="dense"
                            name="tableInclusivePattern"
                            //placeholder="Comma-Separated"
                            value={inputTableInclusiveValue}
                            onChange={handleTableInclusiveChange}
                          />
                          <button
                            className="rounded-md job-btn btn nar-wid"
                            onClick={tableInclusive}
                            style={{
                              margin: "0 10px",
                            }}
                          >
                            Add
                          </button>
                          {tableInclusivePatterns.length > 0 && (
                            <div className="enteredText">
                              <ul style={{ paddingLeft: "0" }}>
                                {tableInclusivePatterns.map((data, index) => (
                                  <li key={index}>
                                    {data}{" "}
                                    <span
                                      className="cancel"
                                      onClick={() =>
                                        handleDeleteFromTableInclusive(index)
                                      }
                                    >
                                      x
                                    </span>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                        <div
                          className="arrayItem"
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            // variant="subtitle1"
                            // style={{ fontWeight: 550, marginRight: "180px", minWidth:"200px" }}
                            style={{
                              color: "black !important",
                              fontWeight: 550,
                              marginRight: "145px",
                              minWidth: "max-content",
                            }}
                          >
                            Table Exclusive Pattern
                          </Typography>
                          <TextField
                            sx={{ minWidth: "220px" }}
                            size="small"
                            margin="dense"
                            name="tableExclusivePattern"
                            // placeholder="Comma-Separated"
                            value={inputTableExclusiveValue}
                            onChange={handleTableExclusiveChange}
                          />
                          <button
                            className="rounded-md job-btn btn nar-wid"
                            onClick={tableExclusive}
                            style={{
                              margin: "0 10px",
                            }}
                          >
                            Add
                          </button>
                          {tableExclusivePattern.length > 0 && (
                            <div className="enteredText">
                              <div>
                                <ul style={{ paddingLeft: "0" }}>
                                  {tableExclusivePattern.map((data, index) => (
                                    <li key={index}>
                                      {data}{" "}
                                      <span
                                        className="cancel"
                                        onClick={() =>
                                          handleDeleteFromTableExclusive(index)
                                        }
                                      >
                                        x
                                      </span>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}

          {!SFTP && (
            <div
              className="arrayItem"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                // variant="subtitle1"
                style={{
                  color: "black !important",
                  fontWeight: 550,
                  marginRight: "108px",
                  minWidth: "200px",
                  textAlign: "left",
                }}
              >
                SFTP File Directory
              </Typography>
              <TextField
                sx={{ width: "220px" }}
                size="small"
                margin="dense"
                name="sftpFileDirectory"
                placeholder="SFTP File Directory"
                value={sftpPayload.sftpFileDirectory}
                onChange={(e) =>
                  setSFTPData({
                    ...SFTPData,
                    sftpFileDirectory: e.target.value,
                  })
                }
              />
            </div>
          )}

          {!SFTP && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                textAlign: "center",
                width: "900px",
                marginTop: "20px",
              }}
            >
              <Typography
                style={{
                  color: "black !important",
                  fontWeight: 550,
                  marginRight: "80px",
                }}
              >
                Custom Delimiter
              </Typography>
              <input
                style={{ width: "232px" }}
                type="checkbox"
                id="autoCrawl"
                name="radioGroup"
                onChange={handleDelimiterRadioChange}
              />
            </div>
          )}

          {isDelimiterSelected && (
            <div>
              <div
                className="arrayItem"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <Typography
                  // variant="subtitle1"
                  style={{
                    fontWeight: 550,
                    marginRight: "98px",
                    minWidth: "200px",
                  }}
                >
                  Delimiter Character
                </Typography>
                <TextField
                  sx={{ width: "220px" }}
                  size="small"
                  margin="dense"
                  name="cronScheduleField"
                  placeholder=""
                  value={sftpPayload.csvDelimiter}
                  onChange={(e) =>
                    setSFTPData({
                      ...SFTPData,
                      csvDelimiter: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          )}

          {!AzureDataLake && (
            <AzureDataLakeForm
              azureData={azureData}
              setAzureData={setAzureData}
            />
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              textAlign: "center",
              width: "900px",
              marginTop: "20px",
            }}
          >
            <Typography
              style={{
                color: "black !important",
                fontWeight: 550,
                marginRight: "110px",
              }}
            >
              Is Auto Crawl
            </Typography>
            <input
              style={{ width: "232px" }}
              type="checkbox"
              id="autoCrawl"
              name="radioGroup"
              onChange={handleAutoCrawlRadioChange}
            />
          </div>

          {isAutoCrawlSelected && (
            <div>
              <div
                className="arrayItem"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <Typography
                  // variant="subtitle1"
                  style={{
                    fontWeight: 550,
                    marginRight: "108px",
                    minWidth: "200px",
                  }}
                >
                  {/* <Typography style={{ fontWeight: 550, marginRight: "168px" }}> */}
                  Cron Schedule Field
                </Typography>
                <TextField
                  sx={{ width: "220px" }}
                  size="small"
                  margin="dense"
                  name="cronScheduleField"
                  placeholder=""
                  value={crawlPayload.cronSchedule}
                  onChange={(e) =>
                    setCrawlPayload({
                      ...crawlPayload,
                      cronSchedule: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          )}

          {/* {AzureDataLake ? ( */}
          <div
            className="button-tab-jobview"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "30px",
            }}
          >
            {/* <button
                className="rounded-md job-btn btn"
                style={{ fontSize: "12px" }}
                // variant="outlined"
                // size="small"
                // sx={{
                //   color: "#247ba0",
                //   borderColor: "#247ba0",
                //   fontSize: "10px",
                //   marginRight: "30px",
                // }}
                onClick={handleSubmit}
              >
                Schedule Crawl
              </button> */}
            <button
              className="rounded-md job-btn btn"
              style={{ fontSize: "12px" }}
              // variant="outlined"
              // size="small"
              // sx={{
              //   color: "#247ba0",
              //   borderColor: "#247ba0",
              //   fontSize: "10px",
              // }}
              onClick={handleSubmit}
            >
              Submit Crawl
            </button>
          </div>
          {/* ) : (
            <div
              className="button-tab-jobview"
              style={{
                textAlign: "left",
                marginTop: "30px",
                paddingLeft: "180px",
              }}
            >
              <button
                className="rounded-md job-btn btn"
                style={{ fontSize: "12px" }}
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          )} */}
        </div>
      </div>
    </>
  );
};

export default AddNewMetaDataCrawlData;
