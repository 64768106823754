import React, { useMemo } from "react";
import { useState, useContext } from "react";
import {
  Grid,
  DialogActions,
  Typography,
  Autocomplete,
  ListSubheader,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Select from "react-select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import WarningPopUp from "../../Atoms/WarningPopUp";
import axios from "axios";
import { userRequestMartbuilder } from "../../../requestMethod";
import { AuthContext } from "../../../AuthContext";
import { useValue } from "../../../context/ContextProvider";

const AddProduct = () => {
  const { getToken, token, error } = useContext(AuthContext);

  const {
    state: {
      maerskModalTableData = [],
      businessData = [],
      domainData = [],
      productData = [],
    },
    dispatch,
  } = useValue();

  const [openModal, setOpenModal] = useState(false);
  const [maerskModel, setMaerskModel] = useState([]);
  const [business, setBusiness] = useState([]);
  const [platform, setPlatform] = useState([]);

  const [values, setValues] = useState({
    name: "",
    orgModel: [],
    business: [],
    platform: [],
  });

  const AddProductTableData = async (values) => { 
    console.log(values, "<==payload"); 
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.post(`/product`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });
      if (response.status === 200 || response.status === 201) {
        alert("Sucess - Status Code 200/201");
        navigate("/manageProjects");
      }

    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedValues = { ...values, [name]: value };
    setValues(updatedValues);
  };

  const handleModalToggle = () => {
    setOpenModal(!openModal);
  };

  const handleSubmit = () => {
    handleModalToggle();
    if (!values.name.trim()) {
      toast.error("Name cannot be empty");
      return;
    }
    if (maerskModel.length === 0) {
      toast.error("Please select at least one Maersk Model");
      return;
    }
    if (business.length === 0) {
      toast.error("Please select at least one Business");
      return;
    }
    if (platform.length === 0) {
      toast.error("Please select at least one Platform");
      return;
    }

    AddProductTableData(values);
  };

  const navigate = useNavigate();

  const maerskModelOptions = Array.isArray(maerskModalTableData)
    ? maerskModalTableData.map((model) => ({
        value: model.id,
        label: model.name,
      }))
    : []; 

  const businessOptions = Array.isArray(businessData)
    ? businessData.map((business) => ({
        value: business.id,
        label: business.name,
      }))
    : [];

  const domainOptions = Array.isArray(domainData)
    ? domainData.map((domain) => ({
        value: domain.id,
        label: domain.name,
      }))
    : [];

  const handleMaerskModelChange = (selectedOptions) => {
    setMaerskModel(selectedOptions);
    setValues((prevValues) => ({
      ...prevValues,
      orgModel: selectedOptions.map((option) => ({ id: option.value })),
    }));
  };

  const handleBusinessChange = (selectedOptions) => {
    setBusiness(selectedOptions);
    setValues((prevValues) => ({
      ...prevValues,
      business: selectedOptions.map((option) => ({ id: option.value })),
    }));
  };

  const handlePlatformChange = (selectedOptions) => {
    setPlatform(selectedOptions);
    setValues((prevValues) => ({
      ...prevValues,
      platform: selectedOptions.map((option) => ({ id: option.value })),
    }));
  };

  return (
    <div style={{ height: "100%", paddingTop: "60px" }}>
      <div
        style={{
          padding: "100px 20px",
          maxWidth: "740px",
          margin: "0px auto",
          border: "1px solid lightgray",
          borderRadius: "4px",
          backgroundColor: "#ffffff",
          boxShadow: "0 2px 4px 0 rgba(0,0,0,0.2)",
        }}
      >
        <div>
          <Grid container mb={3}>
            <Grid item xs={11}>
              <Typography variant="h4" component="h4">
                Product Details 
              </Typography>
            </Grid>

            <Grid color="text.secondary" item xs={1}>
              <CloseIcon
                onClick={() => navigate("/manageProjects")}
                sx={{
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography style={{ flex: 1, fontWeight: 600 }}>Name</Typography>
          <TextField
            margin="dense"
            label="Enter name"
            name="name"
            value={values.name}
            onChange={handleChange}
            style={{ width: "41.5%" }}
            inputProps={{ style: { height: "20px", padding: "10px" } }}
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            margin: "20px 0px",
          }}
        >
          <Typography
            style={{
              flex: 1,
              color: "black !important",
              fontWeight: 600,
            }}
          >
            By Maersk Model
          </Typography>
          <Select
            id="maersk-model"
            placeholder="Select models..."
            aria-label="maersk-model"
            options={maerskModelOptions}
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
            value={maerskModel}
            onChange={handleMaerskModelChange}
            styles={{
              container: (provided) => ({ ...provided, width: "41.5%" }),
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            margin: "20px 0px",
          }}
        >
          <Typography
            style={{
              flex: 1,
              color: "black !important",
              fontWeight: 600,
            }}
          >
            Business
          </Typography>
          <Select
            id="business"
            placeholder="Select business..."
            aria-label="business"
            options={businessOptions}
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
            value={business}
            onChange={handleBusinessChange}
            styles={{
              container: (provided) => ({ ...provided, width: "41.5%" }),
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            margin: "20px 0px",
          }}
        >
          <Typography
            style={{
              flex: 1,
              color: "black !important",
              fontWeight: 600,
            }}
          >
            Platform
          </Typography>
          <Select
            id="platform"
            placeholder="Select platform..."
            aria-label="platform"
            options={domainOptions}
            isMulti
            className="basic-multi-select"
            classNamePrefix="select"
            value={platform}
            onChange={handlePlatformChange}
            styles={{
              container: (provided) => ({ ...provided, width: "41.5%" }),
            }}
          />
        </div>

        <Box sx={{ pb: 2, px: 2, m: 8 }}>
          <DialogActions sx={{ justifyContent: "center" }}>
            <button
              className="rounded-md job-btn btn width-72p"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </DialogActions>
        </Box>
        {openModal && <WarningPopUp />}
      </div>
    </div>
  );
};

export default AddProduct;
