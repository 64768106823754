import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import RequestTable from "../../components/reactTable/RequestTable";
import { userRequestMartbuilder } from "../../requestMethod";
import DashBoardTabs from "../dashboard/DashBoardTabs";
import { AuthContext } from "../../AuthContext";

const JobRequest = () => {
  const [approvalJobs, setApprovalJobs] = useState({});
  const { getToken, token, error } = useContext(AuthContext);

  useEffect(() => {
    (async () => await getToken())();
    const ourRequest = axios.CancelToken.source();
    const fetchPendingApprovalJobs = async () => {
      try {
        let response = await userRequestMartbuilder.get(
          `/jobs/status/PENDING_APPROVAL`,
          {
            headers: {
              // Define your custom headers here
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            CancelToken: ourRequest.token,
          }
        );
        setApprovalJobs(response.data);
        // console.log(response.data)
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        } else {
          console.error(err);
        }
      }
    };
    fetchPendingApprovalJobs();
    return () => {
      ourRequest.cancel();
    };
  }, [token]);

  return (
    <>
      {/* <DashBoardTabs /> */}
      <Box
        sx={{
          width: "90%",
          marginInline: "auto",
          paddingBottom: "20px",
          marginTop: "30px",
          paddingTop:"50px",
        }}
      >
        <h2 style={{margin:"25px 0px"}} > Job Requests </h2>
        <RequestTable jobs={approvalJobs} />
      </Box>
    </>
  );
};

export default JobRequest;
