import React from "react"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material"

const CustomConfirmModal = ({ open, onClose, tableType, textToDisplay,handleRunStatusApiCall  }) => {
    
  const runStatus = async () => {
     
    
    // Close the modal after deletion
    handleRunStatusApiCall()
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Request Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {textToDisplay}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={runStatus} color="secondary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CustomConfirmModal
