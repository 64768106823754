import React from "react"
import { Link } from "react-router-dom"
const NotFound = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "55vh",
        fontSize: "18px",
        color: "#62aed1",
      }}
    >
      <h3
        style={{
          fontWeight: "300",
          color: "#6f6f6f",
          fontSize: "28px",
          marginBottom: "14px",
        }}
      >
        The page you are looking for is not here!{" "}
      </h3>
      <div>
        <h4>
          {" "}
          Click{" "}
          <Link replace to="/">
            here
          </Link>{" "}
          to go back to home page.
        </h4>
      </div>
    </div>
  )
}
export default NotFound
