import React from "react"
import ManageDataSourceTable from "../../components/reactTable/ManageDataSourceTable"
import DashBoardTabs from "../dashboard/DashBoardTabs"

const ManageDataSourceTablePage = () => {
  return (
    <div style={{paddingTop:"35px"}} >
      {/* <DashBoardTabs/> */}
     
      <ManageDataSourceTable />
    </div>
  )
}

export default ManageDataSourceTablePage
