import React, { useCallback, useMemo, useEffect, useState,useContext } from "react";
import { MaterialReactTable } from "material-react-table";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import { Delete, Edit , Info} from "@mui/icons-material";
import { data } from "./makeData";
import { userRequestMartbuilder } from "../../requestMethod";
import { useValue } from "../../context/ContextProvider";
import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { useValue } from "../../context/ContextProvider";
// import { userRequestMartbuilder } from "../../requestMethod";
import { AuthContext } from "../../AuthContext";

const BatchIngestionTable = () => {
  const [jobs, setJobs] = useState([]);
  const [tableData, setTableData] = useState(() => jobs);
  const { getToken, token, error } = useContext(AuthContext);
  const [Dag, setDag] = useState();
  const navigate = useNavigate();

  const {
    state: { jobTypeModal, jobDetails },
  } = useValue();

  const dispatch = useDispatch();

  // Fetching Jobs
  const fetchJobs = async () => {
    dispatch({ type: "START_CIRCULAR_LOADING" });
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(`/ingestion/batch`, {
        headers: {
          // Define your custom headers here
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });
      setJobs(response.data);
      // console.log(response.data);
      dispatch({ type: "END_CIRCULAR_LOADING" });
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    // fetchJobs();
    return () => {
      ourRequest.cancel();
    };
  };

  useEffect(() => {
    let timeoutId;
    const fetchDataWithDelay = () => {
      timeoutId = setTimeout(() => {
        fetchJobs();
      }, 1100);
    };
    fetchDataWithDelay();
    return () => {
      clearTimeout(timeoutId);
    };
  }, [navigate, tableData]);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     fetchJobs();
  //   }, 1100);
  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, []);

  const handleDeleteRow = useCallback(
    (row) => {
      if (
        alert(`Are you sure you want to delete ${row.getValue("firstName")}`)
      ) {
        return;
      }
      //send api delete request here, then refetch or update local table data for re-render
      tableData.splice(row.index, 1);
      setTableData([...tableData]);
    },
    [tableData]
  );

  const columns = useMemo(() => [
    {
      accessorKey: "sourceConnection.connectionName",
      header: "Connection Name",
      enableColumnOrdering: false,
      enableEditing: false, //disable editing on this column
      enableSorting: false,
      size: 80,
    },
    {
      accessorKey: "scheduleInfo.profileName",
      header: "Profile Name",
      size: 140,
    },
    {
      accessorKey: "sourceConnection.product.name",
      header: "Product Name",
      size: 140,
    },
    {
      accessorKey: "scheduleInfo.startDate",
      header: "Start Date",
    },
    {
      accessorKey: "scheduleInfo.endDate",
      header: "End Date",
      size: 80,
    },
  ]);

  return (
    <>
      <div
        style={{
          width: "90%",
          margin: "0px auto",
        }}
      >
        <MaterialReactTable
          displayColumnDefOptions={{
            "mrt-row-actions": {
              muiTableHeadCellProps: {
                align: "center",
              },
            },
          }}
          initialState={{
            density: "compact",
            pagination: { pageSize: 10, pageIndex: 0 },
          }}
          columns={columns}
          data={jobs}
          editingMode="modal" //default
          enableColumnOrdering
          enableEditing
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Tooltip arrow placement="left" title="Edit">
              <IconButton onClick={() => navigate(`/Batchview/${Dag}`,{state:row.original.id})}> 
               <Info/>
               </IconButton>
                <IconButton onClick={() => table.setEditingRow(row)}>
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="right" title="Delete">
                <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                  <Delete />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        />
      </div>
    </>
  );
};

export default BatchIngestionTable;
