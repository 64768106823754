import { useState, useContext, useEffect, useMemo } from "react";
// import Select from "react-select";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Chip,
  InputAdornment,
  InputLabel,
  ListSubheader,
  OutlinedInput,
  TextField,
} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Dialog,
  Grid,
  DialogTitle,
  TableContainer,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import axios from "axios";
import { userRequestMartbuilder } from "../../requestMethod";
import { AuthContext } from "../../AuthContext";
import "../../components/metadataCrawls/addNewData/AddNewMetaDataCrawl.css";
import { useNavigate } from "react-router";
import { Input } from "@mui/base";

function AddNewRoleModal({ boolean, onOpen, tableType }) {
  // function AddNewRole({ boolean, onOpen, tableType }) {
  // const [permissionsArray, setPermissionsArray] = useState([]);
  // const [appsArray, setAppsArray] = useState([]);
  // const [resourcesArray, setResourcesArray] = useState([]);
  // const [operasArray, setOperasArray] = useState([]);
  // const [domainsArray, setDomainsArray] = useState([]);
  const [permissionData, setPermissionData] = useState();
  const [permName, setPermName] = useState([]);
  const [permID, setPermID] = useState([]);
  // const [permissionsValue, setPermissionsValue] = useState("");

  const [values, setValues] = useState({
    name: "",
    description: "",
    // permissions:[],
    // permissionsID:[],
  });

  // Search function for dropdown
  const [searchText, setSearchText] = useState();
  const containsText = (text, searchtext) => {
    return text?.toLowerCase().indexOf(searchtext?.toLowerCase()) > -1;
  };
  let displayedOptions = useMemo(
    () =>
      permissionData?.filter((option) => containsText(option.name, searchText)),
    [searchText]
  );

  // const [policyFields, setPolicyFields] = useState([
  //   {
  //     applications: "",
  //     resource: [],
  //     permissions: "",
  //     operations: [],
  //     domains: [],
  //   },
  // ]);

  // const permissionsList = [
  //   { value: 1, label: "Read_Write_Jobs" },
  //   { value: 2, label: "Read_Only_jobs" },
  //   { value: 3, label: "Can_Approve_Jobs" },
  // ];

  // const handlePolicyChange = (event, target) => {
  // let data = [...policyFields];
  // data[index][event.target.name] = event.target.value;
  // setPolicyFields(data);
  // console.log(policyFields);

  // if (
  // target === "domains" ||
  // target === "operations" ||
  // target === "resource"||
  //   target === "permissions"
  // ) {
  //   let data = {...values};
  //   data[target] = [];
  //   data["permissionsID"] = [];
  //   event.map((e) => {
  //     data[target].push(e.label);
  //     data["permissionsID"].push(e.value);
  //   });
  //   setValues(data);
  //   console.log(values);
  // }

  // if (target === "applications") {
  //   let data = {...values};
  //   data[target] = "";
  //   data[target] = event.label;
  //   setValues(data);
  //   console.log(values);
  // }
  // };

  // const addPolicy = () => {
  //   let object = {
  //     applications: "",
  //     resource: [],
  //     permissions: "",
  //     operations: [],
  //     domains: [],
  //   };

  //   setPolicyFields([...policyFields, object]);
  // };

  // const removePolicy = (index) => {
  //   let data = [...policyFields];
  //   data.splice(index, 1);
  //   setPolicyFields(data);
  // };

  //array handling
  // const handleAddToDataArray = () => {
  //   if (permissionsValue.trim() !== "") {
  //     setPermissionsArray((prevPermissionsArray) => [
  //       ...prevPermissionsArray,
  //       permissionsValue,
  //     ]);
  //     setPermissionsValue("");
  //   }
  // };

  //delete the item
  // const handleDelete = (index) => {
  //   const updatePermissions = permissionsArray.filter((_, i) => i !== index);
  //   setPermissionsArray(updatePermissions);
  // };

  // Fetching data for permissions dropdown
  const fetchPermissionsData = async () => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(`/aatpermissions`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });
      setPermissionData(response?.data);
      console.log(response?.data);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  //token for new axios
  const { getToken, token, error } = useContext(AuthContext);

  //api call for adding new data
  const AddNewRoleData = async () => {
    const payload = {
      name: values.name,
      description: values.description,
      permissionIds: permID,
      // applications: policyFields.applications,
      // resource: policyFields.resource,
      // operations: policyFields.operations,
      // domains: policyFields.domains,
    };
    console.log("Payload", payload);
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.post(`/aatroles`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  const [showEmptyFieldMessage, setShowEmptyFieldMessage] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedValues = { ...values, [name]: value };
    setValues(updatedValues);
  };

  const handleSubmit = async () => {
    if (!values.name.trim()) {
      setShowEmptyFieldMessage(true);
      setTimeout(() => {
        setShowEmptyFieldMessage(false);
      }, 2000);
      return;
    }
    await AddNewRoleData();
    onOpen();
    // navigate("/manageAuthorization")
  };

  const handlePermChange = (event) => {
    const {
      target: { value },
    } = event;
    setPermName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    // setResourceID(event.target.value)
    event.target.value.map((name) => {
      permissionData?.map((perm) => {
        // console.log(resource.name, String(name));
        if (perm.name == name) {
          if (perm.id !== undefined) {
            // console.log(resource.id)
            setPermID([...permID, perm.id]);
          }
        }
      });
      // console.log(resourceID);
    });
  };

  const navigate = useNavigate();

  useEffect(() => {
    (async () => await getToken())();
    fetchPermissionsData();
  }, [token]);

  return (
    // <div style={{ height: "100%", paddingTop: "60px" }}>
    //   <div
    //     style={{
    //       padding: "80px 20px 20px",
    //       // maxWidth: "700px",
    //       maxWidth: "94%",
    //       margin: "0px auto",
    //       border: "1px solid lightgray",
    //       borderRadius: "4px",
    //       backgroundColor: "#ffffff",
    //       boxShadow: "0 2px 4px 0 rgba(0,0,0,0.2)",
    //       // transition: "0.3s",
    //     }}
    //   >
    //     <div style={{}}>
    //       <Grid container mb={3}>
    //         <Grid item xs={11}>
    //           <Typography variant="h4" component="h4">
    //             Add Role Details
    //           </Typography>
    //         </Grid>

    //         <Grid color="text.secondary" item xs={1}>
    //           <CloseIcon
    //             onClick={() => navigate("/manageAuthorization")}
    //             sx={{
    //               cursor: "pointer",
    //             }}
    //           />
    //         </Grid>
    //       </Grid>
    //     </div>
    //     <div
    //       style={{
    //         display: "flex",
    //         alignItems: "center",
    //       }}
    //     >
    //       <Typography
    //         style={{
    //           flex: 1,
    //           color: "black !important",
    //           fontWeight: 600,
    //         }}
    //       >
    //         Role Name
    //       </Typography>
    //       <TextField
    //         margin="dense"
    //         label="Enter name"
    //         name="name"
    //         value={values.name}
    //         onChange={handleChange}
    //         style={{ width: "71.5%" }}
    //       />
    //     </div>

    //     <div
    //       style={{
    //         display: "flex",
    //         alignItems: "center",
    //         margin: "20px 0px",
    //       }}
    //     >
    //       <Typography
    //         style={{
    //           flex: 1,
    //           color: "black !important",
    //           fontWeight: 600,
    //         }}
    //       >
    //         Role Description
    //       </Typography>
    //       <TextField
    //         margin="dense"
    //         label="Enter Description"
    //         name="name"
    //         value={values.name}
    //         onChange={handleChange}
    //         style={{ width: "71.5%" }}
    //       />
    //     </div>
    //     {policyFields.map((policy, index) => {
    //       return (
    //         <div
    //           key={index}
    //           style={{
    //             // maxWidth:"90%",
    //             margin: "20px 0px",
    //             padding: "28px 16px",
    //             border: "2px solid lightgray",
    //             borderRadius: "4px",
    //             backgroundColor: "#ffffff",
    //             // boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    //             // transition: "0.3s",
    //           }}
    //         >
    //           <div
    //             style={{
    //               display: "flex",
    //               alignItems: "center",
    //               margin: "20px 0px",
    //             }}
    //           >
    //             <Typography
    //               style={{
    //                 flex: 1,
    //                 color: "black !important",
    //                 fontWeight: 600,
    //               }}
    //             >
    //               Policy {index + 1}
    //             </Typography>
    //             <div
    //               style={{
    //                 display: "flex",
    //                 alignItems: "center",
    //                 justifyContent: "center",
    //                 // maxWidth: "335px",
    //                 // maxHeight: "80px",
    //               }}
    //             >
    //               <CancelIcon onClick={() => removePolicy(index)} />
    //             </div>
    //           </div>
    //           <div
    //             style={{
    //               display: "grid",
    //               gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    //               gap: "1rem",
    //             }}
    //           >
    //             <div
    //               style={{
    //                 maxWidth: "285px",
    //                 maxHeight: "80px",
    //                 marginBottom: "1rem",
    //               }}
    //             >
    //               <label htmlFor="application">Application</label>
    //               <Select
    //                 id="application"
    //                 placeholder={false}
    //                 aria-label="application"
    //                 options={permissionsList}
    //                 className="basic-multi-select"
    //                 classNamePrefix="select"
    //                 defaultValue={policyFields[index].applications}
    //                 hideSelectedOptions={false}
    //                 noOptionsMessage={() => "No options available"}
    //                 onChange={(event) =>
    //                   handlePolicyChange(event, index, "applications")
    //                 }
    //               />
    //             </div>

    //             <div
    //               style={{
    //                 maxWidth: "285px",
    //                 maxHeight: "80px",
    //                 marginBottom: "1rem",
    //               }}
    //             >
    //               <label htmlFor="resource">Resource</label>
    //               <Select
    //                 id="resource"
    //                 placeholder={false}
    //                 aria-label="resource"
    //                 isMulti
    //                 options={permissionsList}
    //                 className="basic-multi-select"
    //                 classNamePrefix="select"
    //                 defaultValue={policyFields[index].resource}
    //                 hideSelectedOptions={false}
    //                 noOptionsMessage={() => "No options available"}
    //                 onChange={(event) =>
    //                   handlePolicyChange(event, index, "resource")
    //                 }
    //               />
    //             </div>

    //             <div
    //               style={{
    //                 maxWidth: "285px",
    //                 maxHeight: "80px",
    //                 marginBottom: "1rem",
    //               }}
    //             >
    //               <label htmlFor="permission">Permission</label>
    //               <Select
    //                 id="permission"
    //                 placeholder={false}
    //                 aria-label="permission"
    //                 options={permissionsList}
    //                 className="basic-multi-select"
    //                 classNamePrefix="select"
    //                 defaultValue={policyFields[index].permissions}
    //                 // isClearable={true}
    //                 hideSelectedOptions={false}
    //                 noOptionsMessage={() => "No options available"}
    //                 onChange={(event) =>
    //                   handlePolicyChange(event, index, "permissions")
    //                 }
    //               />
    //             </div>

    //             <div
    //               style={{
    //                 maxWidth: "285px",
    //                 maxHeight: "80px",
    //                 marginBottom: "1rem",
    //               }}
    //             >
    //               <InputLabel htmlFor="operation">Operation</InputLabel>
    //               <Select
    //                 id="operation"
    //                 placeholder={false}
    //                 aria-label="operation"
    //                 options={permissionsList}
    //                 isMulti
    //                 className="basic-multi-select"
    //                 classNamePrefix="select"
    //                 defaultValue={policyFields[index].operations}
    //                 hideSelectedOptions={false}
    //                 noOptionsMessage={() => "No options available"}
    //                 onChange={(event) =>
    //                   handlePolicyChange(event, index, "operations")
    //                 }
    //               />
    //             </div>

    //             <div
    //               style={{
    //                 maxWidth: "285px",
    //                 maxHeight: "80px",
    //                 marginBottom: "1rem",
    //               }}
    //             >
    //               <label htmlFor="domains">Domain</label>
    //               <Select
    //                 id="domains"
    //                 placeholder={false}
    //                 aria-label="domains"
    //                 options={permissionsList}
    //                 isMulti
    //                 className="basic-multi-select"
    //                 classNamePrefix="select"
    //                 defaultValue={policyFields[index].domains}
    //                 hideSelectedOptions={false}
    //                 noOptionsMessage={() => "No options available"}
    //                 onChange={(event) =>
    //                   handlePolicyChange(event, index, "domains")
    //                 }
    //               />
    //             </div>
    //           </div>
    //         </div>
    //       );
    //     })}

    //     <Box>
    //       <DialogActions sx={{ justifyContent: "flex-end" }}>
    //         <button
    //           className="rounded-md job-btn-plain btn"
    //           style={{
    //             display: "flex",
    //             alignItems: "center",
    //             justifyContent: "center",
    //             gap: "0.8rem",
    //             color: "#ffffff",
    //             backgroundColor: "#2e7d32",
    //           }}
    //           onClick={addPolicy}
    //         >
    //           <AddCircleOutlineIcon /> <span>Add Policy</span>
    //         </button>
    //       </DialogActions>
    //     </Box>

    //     <Box sx={{ pb: 2, px: 2, m: 8 }}>
    //       <DialogActions sx={{ justifyContent: "center" }}>
    //         <button
    //           className="rounded-md job-btn btn"
    //           style={{ width: "28%", height: "2.8rem", fontSize: "1.2rem" }}
    //           // fullWidth variant="contained"
    //           onClick={handleSubmit}
    //         >
    //           Submit
    //         </button>
    //       </DialogActions>
    //     </Box>
    //     {openModal && <WarningPopUp />}
    //   </div>
    // </div>

    <div style={{}}>
      <Dialog open={boolean} onClose={onOpen}>
        <DialogContent style={{ width: "600px", height: "40vh" }}>
          <Grid container mb={3}>
            <Grid item xs={11}>
              <Typography variant="h6" component="h2">
                Add New Role
              </Typography>
            </Grid>
            <Grid color="text.secondary" item xs={1}>
              <CloseIcon
                onClick={onOpen}
                sx={{
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Name
            </Typography>
            <TextField
              margin="dense"
              label="Role Name"
              name="name"
              value={values.name}
              onChange={handleChange}
              // fullWidth
              style={{ width: "71.5%" }}
              size="small"
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Description
            </Typography>
            <TextField
              margin="dense"
              label="Role Description"
              name="description"
              value={values.description}
              onChange={handleChange}
              // fullWidth
              style={{ width: "71.5%" }}
              size="small"
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Permissions
            </Typography>
            {/* <div style={{ width: "71.5%", marginTop: "8px", marginBottom: "4px" }}>
              <Select
                id="permissions"
                placeholder={false}
                aria-label="permissions"
                options={permissionsList}
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                defaultValue={values.permissions}
                hideSelectedOptions={false}
                noOptionsMessage={() => "No options available"}
                onChange={(event) => handlePolicyChange(event, "permissions")}
              />
            </div> */}
            <Select
              style={{ width: "71.5%", margin: "4px 0", padding: "0" }}
              labelId="permissions"
              id="permissions"
              size="small"
              multiple
              value={permName}
              onChange={handlePermChange}
              onOpen={() => setSearchText("")}
              onClose={() => setSearchText("")}
              MenuProps={{
                autoFocus: false,
              }}
              // input={<OutlinedInput id="select-multiple-chip" label="Application" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              // MenuProps={MenuProps}
            >
              <ListSubheader sx={{ border: "none" }}>
                <TextField
                  style={{ margin: "0 10px", width: "98%" }}
                  size="small"
                  // Autofocus on textfield
                  autoFocus
                  placeholder="Type to search..."
                  // fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                      // Prevents autoselecting item while typing (default Select behaviour)
                      e.stopPropagation();
                    }
                  }}
                />
              </ListSubheader>
              {displayedOptions !== "" &&
                displayedOptions
                  ?.sort((a, b) =>
                    a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1
                  )
                  .map((permData) => (
                    <MenuItem
                      key={permData.id}
                      value={permData.name}
                      // name={permData.name}
                      // style={getStyles(name, personName, theme)}
                    >
                      {permData.name}
                    </MenuItem>
                  ))}
              {displayedOptions === "" &&
                permissionData
                  ?.sort((a, b) =>
                    a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1
                  )
                  .map((permData) => (
                    <MenuItem
                      key={permData.id}
                      value={permData.name}
                      // name={permData.name}
                      // style={getStyles(name, personName, theme)}
                    >
                      {permData.name}
                    </MenuItem>
                  ))}
            </Select>
          </div>

          {/* {permissionsArray.length > 0 && (
            <div className="enteredText" style={{ margin: "10px 0 0 0" }}>
              <ul
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                {permissionsArray.map((data, index) => (
                  <li key={index}>
                    {data}
                    <span
                      className="cancel"
                      onClick={() => handleDelete(index)}
                    >
                      x
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          )} */}

          {showEmptyFieldMessage && (
            <Typography
              sx={{ fontSize: "14px", color: "red", margin: "5px 5px" }}
            >
              Please enter the name
            </Typography>
          )}
        </DialogContent>
        <Box sx={{ pb: 2, px: 2 }}>
          <DialogActions sx={{ justifyContent: "center" }}>
            <button
              // disabled={  values.email.trim() === ""}
              className="rounded-md job-btn btn width-72p"
              // fullWidth
              // variant="contained"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}

export default AddNewRoleModal;
