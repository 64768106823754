import styled from "styled-components";
 
export const Name = styled.p`
  width: 25%;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.8px;
  text-align: right;
`;
 
 
export const Aligner = styled.div`
  display: flex;
  justify-content: start;
  align-items: flex-start;
  gap: 8px;
  padding-top: 8px;
`;
 
export const Value = styled.div`
  border: 0.4px solid gray;
  padding: 5px;
  border-radius: 4px;
  width: 75%;
  min-height: 25px;
  overflow: auto;
  font-size: 13.5px;
`;
 
export const Container = styled.div`
  padding: 15px;
  padding-inline: 4%;
  display: flex;
  flex-direction: column;
  height: 85vh;
 
`;
 
export const Title = styled.h3`
  font-size: 20px;
  color: #d7d7d7;
  letter-spacing: 0.5px;
  font-weight: 700;
  margin-left: 11px;
`;
 
export const ChartsDiv = styled.div`
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0px;
  margin-bottom: 100px;
`;
 
export const MetricsDiv = styled.div`
  height: 85vh;
  /* margin-left: 10px; */
  padding-left: 45px;
  width: 645px;
  margin: 10px;
  display: flex;
  /* margin-top: 120px; */
  margin-bottom: 100px;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
`;
 
export const Divider = styled.div`
  height: 160px;
  width: 1px;
  border: none;
  background: linear-gradient(
    180deg,
    rgba(217, 25, 32, 1) 0%,
    rgba(217, 25, 32, 0.8786108193277311) 48%,
    rgba(217, 25, 66, 0.19793855042016806) 100%
  );
`;
 
export const Heading = styled.p`
  font-size: 14px;
  letter-spacing: 1px;
  padding: 5px;
  color: #cfcfcf;
  margin-bottom: 3px;
`;
 
export const Chips = styled.li`
  width: fit-content;
  background: #e8e8e8;
  font-weight: 300;
  font-size: 12px;
  color: #6b6b6b;
  padding: 1.5px 11px;
  border: 0.4px solid #6b6b6b;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 5px;
`;
 
export const TaskDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
 
export const TaskDetails = styled.div`
  width: 50%; /* Adjust the width as needed */
`;
 
export const BatchsourceTargetContainer = styled.div`
  width: 50%; /* Adjust the width as needed */
  margin-left: 3rem;
`;