import styled from "styled-components"

export const Query = styled.div`
  border: 0.4px solid gray;
  /* padding: 8px; */
  /* background: #000; */
  color: white;
  border-radius: 4px;
  max-height: 115px;
  min-width: 450px;
  overflow: scroll;
  font-size: 13.5px;
`

export const Pair = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-block: 4px;
  gap: 4px;
`

export const Key = styled.div`
  /* width: 90px; */
  font-size: 10.5px;
  font-weight: 600;
  color: gray;
`

export const TaskTitle = styled.p`
  font-size: 11.5px;
  margin-bottom: 6px;
  letter-spacing: 0.3px;
  font-weight: 600;
`
