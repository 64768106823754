import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/scss/style.scss";
import App from "./App";
import ContextProvider from "./context/ContextProvider";
// import { ThemeProvider } from "styled-components";
import theme from "./theme";

import {
  PublicClientApplication,
  EventType,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { msalConfig } from "./msalConfig";

const msalInstance = new PublicClientApplication(msalConfig);
// const accounts = msalInstance.getAllAccounts();

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {
  if (
    event.eventType === EventType.LOGIN_SUCCESS ||
    event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
    event.eventType === EventType.SSO_SILENT_SUCCESS
  ) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

// MSAL  Using an account object
// // SSO login
const request = {
  scopes: msalConfig.loginRequest,
  account: msalInstance.getActiveAccount(),
};
const ssoLogin = async () => {
  try {
    await msalInstance.ssoSilent(request);
  } catch (err) {
    if (err instanceof InteractionRequiredAuthError) {
      await msalInstance.loginRedirect(request).catch((error) => {
        console.log(err);
      });
    } else {
      console.log(err);
    }
  }
};
//
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
    // console.log("ACQUIRE_TOKEN_SUCCESS");
  } else if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
    if (
      event.error.name === "InteractionRequiredAuthError" &&
      window.location.pathname.startsWith("/")
    ) {
      ssoLogin();
    } else {
      console.log("ACQUIRE_TOKEN_FAILURE");
    }
  } else if (event.eventType === EventType.LOGIN_FAILURE) {
    if (
      event.error.name === "BrowserAuthError" &&
      window.location.pathname.startsWith("/")
    ) {
      ssoLogin();
    } else {
      console.log("LOGIN FAILURE");
    }
  } else {
    console.log("Callback finished");
  }
});
// // login
msalInstance
  .handleRedirectPromise()
  .then(() => {
    if (window.location.pathname.startsWith("/")) {
      const account = msalInstance.getActiveAccount();
      if (!account) {
        ssoLogin();
      }
    }
  })
  .catch((err) => {
    console.log(err);
  });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ContextProvider>
      {/* <ThemeProvider theme={theme}> */}
      <App instance={msalInstance} />
      {/* </ThemeProvider> */}
    </ContextProvider>
  </React.StrictMode>
);
