import { useState,useEffect,useContext } from "react";
import Typography from "@mui/material/Typography";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  Grid,
  DialogTitle,
  TableContainer,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useValue } from "../../context/ContextProvider";
import { AuthContext } from "../../AuthContext";

function JobTypeModal({ boolean, onOpen, hideStreamingSync }) {
  const { getToken, token, error, getDatabookToken, DCtoken } = useContext(AuthContext);
  const [selectedOption, setSelectedOption] = useState(""); // to keep track of the selected option

  const {
    dispatch,
    state: { showCreateJob, jobDetails, sourceConnectionName},
  } = useValue();
  const navigate = useNavigate();

  // console.log(selectedOption);
  const handleChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);
    console.log(selectedValue);
    if (selectedValue === "Mart Builder") {
      // console.log("jobDetails jobDetails", jobDetails)
      dispatch({ type: "HIDE_INGESTION_JOB_FORM" });
      dispatch({ type: "CLEAR_JOB_DETAILS" });
      dispatch({ type: "CLEAR_SOURCE_CONNECTION_NAME"});
      // console.log("sourceConnectionName", sourceConnectionName)
      navigate("/createMartBuilderJob");
    } else if (selectedValue === "Batch Ingestion") {
      navigate("/createBatchIngestionJob");
    }else if (selectedValue === "Streaming Sync") {
      navigate("/createStreamingSyncJob");
    }
  };

  //   const handleSubmit = () => {
  //     if (!selectedOption) {
  //       setShowEmptyFieldMessage(true);
  //       setTimeout(() => {
  //         setShowEmptyFieldMessage(false);
  //       }, 2000); // Show the message for 2 seconds
  //       return;
  //     }
  //     onOpen();
  //     // AddBusinessData(values);
  //   };
  
  useEffect(() => { 
    if (DCtoken === undefined || DCtoken === null) {
      getDatabookToken();
    }
  }, [DCtoken, getDatabookToken]);  

  return (
    <div>
      <Dialog open={boolean} onClose={onOpen}>
        <DialogContent style={{ width: "600px" }}>
          <Grid container mb={3}>
            <Grid item xs={11}>
              <Typography variant="h6" component="h2">
                Choose Job Type
              </Typography>
            </Grid>
            <Grid color="text.secondary" item xs={1}>
              <CloseIcon
                onClick={onOpen}
                sx={{
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>

          <RadioGroup
            name="jobType"
            value={selectedOption}
            onChange={handleChange}
          >
            <FormControlLabel
              value="Batch Ingestion"
              control={<Radio />}
              label="Batch Ingestion/ CDC"
            />
             {!hideStreamingSync && (
            <>
            <FormControlLabel
              value="Mart Builder"
              control={<Radio />}
              label="Mart Builder"
            />
              </>
           )}
           {!hideStreamingSync && (
            <>
             <FormControlLabel
               value="Streaming Sync"
               control={<Radio />}
               label="Streaming Sync"
             />
              </>
           )}
             <FormControlLabel
               value="Streaming Transformation"
               control={<Radio />}
               label="Streaming Transformation"
             />

          </RadioGroup>
        </DialogContent>
        {/* <Box sx={{ pb: 2, px: 2 }}>
          <DialogActions>
            <Button
              fullWidth
              variant="contained"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </DialogActions>
        </Box> */}
      </Dialog>
    </div>
  );
}

export default JobTypeModal;
