import React from "react"
import { Box, CircularProgress } from "@mui/material"

const CircularLoader = ({ color, thickness, size }) => {
  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <CircularProgress
        color={color || "info"}
        thickness={thickness || 4}
        size={size || 40}
      />
    </Box>
  )
}

export default CircularLoader
