import React, { useContext, useEffect, useMemo, useState } from "react";
import ManageDatasources from "../../pages/manageDatasources/ManageDatasources";
import { Controller, useFormContext } from "react-hook-form";
import {
  Box,
  FormControl,
  ListSubheader,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
  TextField,
  Autocomplete,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { userRequestMartbuilder } from "../../requestMethod";
import styled from "styled-components";
import { useValue } from "../../context/ContextProvider";
import { AuthContext } from "../../AuthContext";

export const Name = styled.p`
  width: 135px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.8px;
  text-align: right;
`;

export const Aligner = styled.div`
  display: flex;
  justify-content: start;
  align-items: flex-start;
  gap: 8px;
  padding-top: 8px;
`;

export const Value = styled.div`
  border: 0.4px solid gray;
  padding: 5px;
  border-radius: 4px;
  width: 350px;
  min-height: 25px;
  overflow: scroll;
  font-size: 13.5px;
`;

const SourceConnection = () => {
  const [selectedOption, setSelectedOption] = useState("existingDataSource");
  const [dataSource, setDataSource] = useState([]);
  const [dataSourceView, setDataSourceView] = useState("");

  // Search function for dropdown
  const [searchText, setSearchText] = useState();
  const containsText = (text, searchtext) => {
    return text?.toLowerCase().indexOf(searchtext?.toLowerCase()) > -1;
  };
  let displayedOptions = useMemo(
    () =>
      dataSource?.filter((option) =>
        containsText(option.connectionName, searchText)
      ),
    [searchText]
  );

  const {
    state: { sourceConnectionBatch },
    dispatch,
  } = useValue();
  const { getToken, token, error } = useContext(AuthContext);

  // modal states and functions
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // const { control } = useFormContext();

  // radio button
  const handleOptionChange = (event) => {
    const newValue = event.target.value;
    setSelectedOption(newValue);
    //console.log("Selected Option:", newValue);
  };

  const resetRadioBtn = () => {
    setSelectedOption("existingDataSource");
  };

  // select
  const handleChange = (e) => {
    // setSelectedItem(e.target.value);
    dispatch({
      type: "SET_SOURCE_SELECT_BATCH_I",
      payload: e.target.value,
    });
  };

  // FETCHING CONNECTION DATASOURCE BY ID

  const fetchDataSourceById = async (dataS) => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(`/datasources/${dataS}`, {
        headers: {
          // Define your custom headers here
          Authorization: `Bearer ${token}`, 
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });
      // console.log(response.data);
      setDataSourceView(response.data);
      // dispatch({ type: "SET_DATASOURCES", payload: response.data })
      // setIsLoading(false)
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
  };

  // FETCHING CONNECTION DATASOURCE
  const fetchDataSource = async () => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(`/datasources/`, {
        headers: {
          // Define your custom headers here
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });
      //console.log(response.data);
      setDataSource(response.data);
      displayedOptions = response.data
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    // fetchDataSource();
    return () => {
      ourRequest.cancel();
    };
  };

  useEffect(() => {
    if(!token){
      getToken();
    } 
      fetchDataSource(); 
  }, [selectedOption, token]);

  return (
    <>
      {dataSourceView && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 500,
              bgcolor: "background.paper",
              // border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              color="#62aed1"
            >
              {dataSourceView.connectionName}
            </Typography>
            <div id="modal-modal-description" style={{ fontSize: "16px"}}>
              <Aligner>
                <Name>Connection Fqn :</Name>
                <Value>{dataSourceView?.connectionFqn}</Value>
              </Aligner>
              <Aligner>
                <Name>Datasource Type :</Name>
                <Value>{dataSourceView?.datasourceType}</Value>
              </Aligner>
              <Aligner>
                <Name>Product :</Name>
                <Value>{dataSourceView?.product?.name}</Value>
              </Aligner>
              <Aligner>
                <Name>vaultKey :</Name>
                <Value>{dataSourceView?.vaultKey}</Value>
              </Aligner>
            </div>
          </Box>
        </Modal>
      )}
      <div style={{ marginTop: "50px", padding: "10px 45px", paddingBottom:"120px" }}>
        <h3 style={{ marginBottom: "20px" }}>Define Source</h3>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "100px",
          }}
        >
          <div style={{ display: "flex", textAlign: "center" }}>
            <div
              style={{
                border: "1px solid pink",
                borderRadius: "6px",
                padding: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <input
                type="radio"
                name="sourceOption"
                value="existingDataSource"
                checked={selectedOption === "existingDataSource"}
                onChange={handleOptionChange}
              />
              <p>Select an existing Data Source</p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              // width: "285px",
              textAlign: "center",
              // marginLeft: "80px",
            }}
          >
            <div
              style={{
                border: "1px solid pink",
                borderRadius: "6px",
                padding: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <input
                type="radio"
                name="sourceOption"
                value="newSource"
                checked={selectedOption === "newSource"}
                onChange={handleOptionChange}
              />
              <p>Set Up a new Source</p>
            </div>
          </div>
        </div>
        {selectedOption !== "newSource" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "250px",
              marginTop: "25px",
            }}
          >
            <FormControl
              size="small"
              variant="outlined"
              fullWidth
              sx={{ mb: 0, mt: 2, minWidth: 120 }}
            >
              <InputLabel id="Source-connection">Source Connection</InputLabel>
              <Select
                label="Source Connection"
                name="sourceConnection"
                value={sourceConnectionBatch}
                onChange={handleChange}
                onOpen={()=> setSearchText("")}
                onClose={() => setSearchText("")}
                MenuProps={{
                  autoFocus: false,
                  PaperProps: {
                    style: {
                      maxHeight: "28rem", // Adjust the max height as needed
                    },
                  },
                }}
                // renderValue={() => sourceConnectionBatch}
              >
                <ListSubheader sx={{ border: "none" }}>
                  <TextField
                    style={{ margin: "0 10px", width: "98%" }}
                    size="small"
                    // Autofocus on textfield
                    autoFocus
                    placeholder="Type to search..."
                    // fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key !== "Escape") {
                        // Prevents autoselecting item while typing (default Select behaviour)
                        e.stopPropagation();
                      }
                    }}
                  />
                </ListSubheader>
                {displayedOptions !== "" && displayedOptions?.sort((a, b) =>
                    a.connectionName?.toLowerCase() >
                    b.connectionName?.toLowerCase()
                      ? 1
                      : -1
                  )
                  .map((item) => (
                    <MenuItem key={item.connectionId} value={item.connectionId}>
                      {item.connectionName}
                    </MenuItem>
                  ))}
                  {displayedOptions === "" && dataSource?.sort((a, b) =>
                    a.connectionName?.toLowerCase() >
                    b.connectionName?.toLowerCase()
                      ? 1
                      : -1
                  )
                  .map((item) => (
                    <MenuItem key={item.connectionId} value={item.connectionId}>
                      {item.connectionName}
                    </MenuItem>
                  ))}
              </Select>

              {/* <Autocomplete
                disablePortal
                id="Source-connection"
                options={dataSource ?.sort((a, b) =>
                  a.connectionName?.toLowerCase() >
                  b.connectionName?.toLowerCase()
                    ? 1
                    : -1
                )}
                getOptionLabel={(option) => option.connectionName}
                renderOption={(props, option) => (
                  <li {...props} key={option.connectionId} style={{border:"none"}}>
                    {option.connectionName}
                  </li>
                )}
                // sx={{ width: 300 }}
                onChange={(event, value) => {
                  dispatch({
                    type: "SET_SOURCE_SELECT_BATCH_I",
                    payload: value ? value.connectionId : ""
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Source Connection" />
                )}
              /> */}

              {dataSourceView && (
                <span
                  style={{ color: "#0093bb", cursor: "pointer" }}
                  onClick={handleOpen}
                >
                  view connection
                </span>
              )}
            </FormControl>
          </div>
        )}
        {selectedOption === "newSource" && (
          <div
            style={{
              border: "1px solid grey",
              marginTop: "90px",
              borderRadius: "5px",
            }}
          >
            <ManageDatasources
              batchIngestion={true}
              resetRadioBtn={resetRadioBtn}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default SourceConnection;
