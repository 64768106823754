import { useState, useContext, useEffect, useMemo } from "react";
// import Select from "react-select";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Checkbox,
  Chip,
  InputAdornment,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  Grid,
  DialogTitle,
  TableContainer,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import axios from "axios";
import { userRequestMartbuilder } from "../../requestMethod";
import { AuthContext } from "../../AuthContext";
import "../../components/metadataCrawls/addNewData/AddNewMetaDataCrawl.css";

function AddNewResource({ boolean, onOpen, tableType }) {
  // const [domainArray, setDomainArray] = useState([]);
  // const [domainValue, setDomainValue] = useState("");
  const [appID, setAppID] = useState();
  const [appName, setAppName] = useState();
  const [applicationData, setApplicationData] = useState();

  // const applicationList = [
  //   { value: 1, label: "Application_1" },
  //   { value: 2, label: "Application_2" },
  //   { value: 3, label: "Application_3" },
  //   { value: 4, label: "Application_4" },
  // ];

  // const applicationList =[
  //   {value:"", label:""},
  // ]

  const [values, setValues] = useState({
    name: "",
    description: "",
    // applications: "",
    // applicationsID: "",
    // domain: "",
    // accessFlag: false,
    // application: "",
    // operation: [],
  });

  // const handleInputChange = (e) => {
  //   setDomainValue(e.target.value);
  // };

  //array handling
  // const handleAddToDataArray = () => {
  //   if (domainValue.trim() !== "") {
  //     setDomainArray((prevDomainArray) => [...prevDomainArray, domainValue]);
  //     setDomainValue("");
  //   }
  // };

  //delete the item
  // const handleDelete = (index) => {
  //   const updateDomain = domainArray.filter((_, i) => i !== index);
  //   setDomainArray(updateDomain);
  // };

  //token for new axios
  const { getToken, token, error } = useContext(AuthContext);

  // Fetching values for application dropdown
  const fetchApplicationsData = async () => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(`/aatapplications`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });
      setApplicationData(response?.data);
      // console.log(response?.data);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  //api call for adding new data
  const AddNewResourceData = async () => {
    const payload = {
      name: values.name,
      description: values.description,
      // applications: values.applications,
      // domain: domainArray,
    };
    console.log("Payload ", payload);
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.put(
        `/aatapplications/addResource/${appID}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  // Search function for dropdown
  const [searchText, setSearchText] = useState();
  const containsText = (text, searchtext) => {
    return text?.toLowerCase().indexOf(searchtext?.toLowerCase()) > -1;
  };
  let displayedOptions = useMemo(
    () =>
      applicationData?.filter((option) =>
        containsText(option.name, searchText)
      ),
    [searchText]
  );

  const [showEmptyFieldMessage, setShowEmptyFieldMessage] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedValues = { ...values, [name]: value };
    setValues(updatedValues);
  };

  const handleAppChange = (event) => {
    setAppID(event.target.value);
    setAppName(event.target.name);
  };

  // const handlePolicyChange = (event, target) => {
  //   let data = { values };
  //   data[target] = "";
  //   data["applicationsID"] = "";
  //   // event.map((e) => {
  //   data[target] = event.label;
  //   data["applicationsID"] = event.value;
  //   // });
  //   setValues(data);
  //   console.log(values);
  // };

  // const handleTick = (event, target) => {
  //   let data = {values};
  //   data[target] = !data[target];
  //   setValues(data);
  // };

  const handleSubmit = async () => {
    if (!values.name.trim()) {
      setShowEmptyFieldMessage(true);
      setTimeout(() => {
        setShowEmptyFieldMessage(false);
      }, 2000);
      return;
    }
    await AddNewResourceData();
    onOpen();
  };

  useEffect(() => {
    (async () => await getToken())();
    fetchApplicationsData();
  }, [token]);

  return (
    <div style={{}}>
      <Dialog open={boolean} onClose={onOpen}>
        <DialogContent style={{ width: "600px", height: "40vh" }}>
          <Grid container mb={3}>
            <Grid item xs={11}>
              <Typography variant="h6" component="h2">
                Add New Resource
              </Typography>
            </Grid>
            <Grid color="text.secondary" item xs={1}>
              <CloseIcon
                onClick={onOpen}
                sx={{
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Application
            </Typography>
            {/* <div style={{ width: "71.5%", margin: "0", padding: "0" }}>
              <Select
                id="application"
                placeholder={false}
                aria-label="application"
                options={applicationList}
                className="basic-multi-select"
                classNamePrefix="select"
                defaultValue={values.applications}
                hideSelectedOptions={false}
                noOptionsMessage={() => "No options available"}
                onChange={(event) => handlePolicyChange(event, "applications")}
              />
            </div> */}
            <Select
              style={{ width: "71.5%", margin: "0", padding: "0" }}
              labelId="application"
              id="application"
              size="small"
              // multiple
              value={appName}
              onChange={handleAppChange}
              onOpen={() => setSearchText("")}
              onClose={() => setSearchText("")}
              MenuProps={{
                autoFocus: false,
              }}
              // input={<OutlinedInput id="select-multiple-chip" label="Application" />}
              // renderValue={(selected) => (
              //   <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              //     {selected.map((value) => (
              //       <Chip key={value} label={value} />
              //     ))}
              //   </Box>
              // )}
              // MenuProps={MenuProps}
            >
              <ListSubheader sx={{ border: "none" }}>
                <TextField
                  style={{ margin: "0 10px", width: "98%" }}
                  size="small"
                  // Autofocus on textfield
                  autoFocus
                  placeholder="Type to search..."
                  // fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                      // Prevents autoselecting item while typing (default Select behaviour)
                      e.stopPropagation();
                    }
                  }}
                />
              </ListSubheader>
              {displayedOptions !== "" &&
                displayedOptions
                  ?.sort((a, b) =>
                    a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1
                  )
                  .map((appData) => (
                    <MenuItem
                      key={appData.id}
                      value={appData.id}
                      name={appData.name}
                      // style={getStyles(name, personName, theme)}
                    >
                      {appData.name}
                    </MenuItem>
                  ))}
              {displayedOptions === "" &&
                applicationData
                  ?.sort((a, b) =>
                    a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1
                  )
                  .map((appData) => (
                    <MenuItem
                      key={appData.id}
                      value={appData.id}
                      name={appData.name}
                      // style={getStyles(name, personName, theme)}
                    >
                      {appData.name}
                    </MenuItem>
                  ))}
            </Select>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Name
            </Typography>
            <TextField
              margin="dense"
              // label="Name"
              name="name"
              value={values.name}
              onChange={handleChange}
              // fullWidth
              style={{ width: "71.5%" }}
              size="small"
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Description
            </Typography>
            {/* <TextField
            margin="dense"
            label="Description"
            name="description"
            value={values.description}
            onChange={handleChange}
            // fullWidth
            style={{ width: "71.5%" }}
            // size="small"
            /> */}
            {/* <div style={{ width: "71.5%", margin: "0", padding: "0" }}> */}
            <TextField
              margin="dense"
              // label="Description"
              name="description"
              value={values.description}
              onChange={handleChange}
              // fullWidth
              style={{ width: "71.5%" }}
              size="small"
            />
            {/* </div> */}
          </div>

          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Access Flag
            </Typography>
            <div style={{ width: "71.5%", margin: "0", padding: "0" }}>
            <Checkbox onChange={(event)=>{handleTick(event, "accessFlag")}} />
            </div>
          </div> */}

          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TextField
              sx={{ minWidth: "85%" }}
              size="small"
              margin="dense"
              // name="domainArray"
              placeholder="Domain"
              value={domainValue}
              onChange={handleInputChange}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleAddToDataArray}
            >
              Add
            </Button>
          </div> */}

          {/* {domainArray.length > 0 && (
            <div className="enteredText" style={{ margin: "10px 0 0 0" }}>
              <ul
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                {domainArray.map((data, index) => (
                  <li key={index}>
                    {data}
                    <span
                      className="cancel"
                      onClick={() => handleDelete(index)}
                    >
                      x
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          )} */}

          {showEmptyFieldMessage && (
            <Typography
              sx={{ fontSize: "14px", color: "red", margin: "5px 5px" }}
            >
              Please enter the name
            </Typography>
          )}
        </DialogContent>
        <Box sx={{ pb: 2, px: 2 }}>
          <DialogActions sx={{ justifyContent: "center" }}>
            <button
              // disabled={  values.email.trim() === ""}
              className="rounded-md job-btn btn width-72p"
              // fullWidth
              // variant="contained"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}

export default AddNewResource;
