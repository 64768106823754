import React from "react"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material"

const DeleteModal = ({ open, onClose, handleDeleteRow,tableType, row }) => {
  // console.log(row)
  const handleConfirmDelete = async () => {
    // Call the handleDeleteRow function with the row prop
    await handleDeleteRow(row,tableType) // Make sure it's an asynchronous call if needed
    // Close the modal after deletion
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this item?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirmDelete} color="secondary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteModal
