import React from "react"
import { useNavigate } from "react-router-dom"
import { useMsal, useIsAuthenticated } from "@azure/msal-react"
import { ListItemIcon, Menu, MenuItem } from "@mui/material"
import { Settings } from "@mui/icons-material"

const UserMenu = ({ anchorUserMenu, setAnchorUserMenu }) => {
  const nevigate = useNavigate()
  const { instance } = useMsal()
  const handleCloseUserMenu = () => {
    setAnchorUserMenu(null)
  }

  const handleLogout = () => {
    instance.logoutRedirect()
  }
  return (
    <Menu
      anchorEl={anchorUserMenu}
      open={Boolean(anchorUserMenu)}
      onClose={handleCloseUserMenu}
      onClick={handleCloseUserMenu}
    >
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <Settings fontSize="small" />
        </ListItemIcon>
        logout
      </MenuItem>
    </Menu>
  )
}

export default UserMenu
