import React, { useState } from "react";

import { useFormContext, Controller, useFieldArray } from "react-hook-form";

import {
  Button,
  IconButton,
  TextField,
  Tooltip,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Input,
} from "@mui/material";
import { Close, ExpandMore } from "@mui/icons-material";
import { useValue } from "../../context/ContextProvider";

const TaskForm = ({ watch }) => {
  const { control } = useFormContext();
  const { dispatch } = useValue();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "tasks",
  });

  const [isExpanded, setIsExpanded] = useState(false);
  const handleEdaClick = () => {
    dispatch({ type: "SHOW_EDA" });
  };

  return (
    <>
      <div
        style={{
          margin: "35px",
          padding: "10px",
          borderRadius: "5px",
          border: "0.5px solid #aaaaaa",
        }}
      >
        {fields.map((task, index) => {
          const values = watch();
          // console.log(values.tasks[index]);
          const selectedLoadMethod = values.tasks[index].targetLoadMethod;
          const selectedIncrementalMode = values.tasks[index].incrementalMode;

          return (
            <div
              key={task.id}
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                gap: "6px",
                marginTop: "10px",
              }}
            >
              <h4 style={{ paddingBlock: "4px" }}>Task {index + 1}</h4>
              <div style={{ display: "flex", gap: "10px" }}>
                <Controller
                  name={`tasks[${index}].name`}
                  control={control}
                  defaultValue={task.text}
                  render={({ field }) => (
                    <>
                      <TextField
                        id="Task Name"
                        label="Task Name"
                        fullWidth
                        size="small"
                        // placeholder={`tasks[${index}].name`}
                        {...field}
                      />
                    </>
                  )}
                />
                <Controller
                  name={`tasks[${index}].targetName`}
                  control={control}
                  defaultValue={task.text}
                  render={({ field }) => (
                    <>
                      <TextField
                        id="Target Table"
                        label="Target Table"
                        fullWidth
                        size="small"
                        // placeholder={`tasks[${index}].name`}
                        {...field}
                      />
                    </>
                  )}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  gap: "8px",
                }}
              >
                <Controller
                  control={control}
                  name={`tasks[${index}].code`}
                  render={({ field }) => (
                    <TextField
                      id="query"
                      label="Query"
                      multiline
                      rows={4}
                      fullWidth
                      // placeholder="query1"
                      {...field}
                    />
                  )}
                />

                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  sx={{ fontSize: "11px" }}
                  onClick={handleEdaClick}
                >
                  TEST
                </Button>
              </div>
              <div className={`expandable-div `}>
                <div className={`content ${isExpanded ? "expanded" : ""}`}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <p style={{ fontSize: "14px", fontWeight: 400 }}>
                      Advance Options
                    </p>
                    <ExpandMore
                      onClick={() => setIsExpanded(!isExpanded)}
                      sx={{ cursor: "pointer" }}
                    />
                  </div>
                  <div
                    style={{
                      padding: "8px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Controller
                        control={control}
                        name={`tasks[${index}].targetLoadMethod`}
                        render={({ field }) => {
                          // console.log(field);
                          return (
                            <FormControl>
                              <InputLabel id="select-label">
                                Target Load Method
                              </InputLabel>
                              <Select
                                sx={{ width: "250px" }}
                                size="small"
                                labelId="select-label"
                                label="Target Load Method"
                                {...field}
                              >
                                <MenuItem value="">None</MenuItem>
                                <MenuItem value="View">View</MenuItem>
                                <MenuItem value="Table">Table</MenuItem>
                                <MenuItem value="Incremental">
                                  Incremental
                                </MenuItem>
                              </Select>
                            </FormControl>
                          );
                        }}
                      />

                      {selectedLoadMethod === "Incremental" && (
                        <Controller
                          control={control}
                          name={`tasks[${index}].incrementalMode`}
                          render={({ field }) => (
                            <FormControl sx={{ width: "250px" }}>
                              <InputLabel id="sub-select-label">
                                Incremental Mode
                              </InputLabel>
                              <Select
                                size="small"
                                labelId="sub-select-label"
                                label="Incremental Mode"
                                {...field}
                                // value={subSelectedValue}
                                // onChange={handleSubChange}
                              >
                                <MenuItem value="Append">Append</MenuItem>
                                <MenuItem value="Merge">Merge</MenuItem>
                                <MenuItem value="Partition_by">
                                  Partition By
                                </MenuItem>
                              </Select>
                            </FormControl>
                          )}
                        />
                      )}
                    </div>

                    {(selectedIncrementalMode === "Append" ||
                      selectedIncrementalMode === "Merge") &&
                      selectedLoadMethod === "Incremental" && (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "10px",
                          }}
                        >
                          <Controller
                            control={control}
                            name={`tasks[${index}].uniqueKeys`}
                            render={({ field }) => {
                              // const handleChange = (e) => {};
                              return (
                                <TextField
                                  size="small"
                                  id="outlined-basic"
                                  label="unique_keys"
                                  sx={{ width: "250px" }}
                                  variant="outlined"
                                  {...field}
                                  // onChange={handleChange}
                                />
                              );
                            }}
                          />
                          <Controller
                            control={control}
                            name={`tasks[${index}].schemaChange`}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                id="outlined-basic"
                                label="on_schema_change"
                                variant="outlined"
                                sx={{ width: "250px" }}
                                {...field}
                              />
                            )}
                          />
                        </div>
                        <div>
                          <Controller
                            control={control}
                            name={`tasks[${index}].watermarkCols`}
                            render={({ field }) => (
                              <TextField
                                size="small"
                                id="outlined-basic"
                                label="Watermark Columns"
                                variant="outlined"
                                sx={{ width: "250px" }}
                                {...field}
                              />
                            )}
                          />
                        </div>
                      </div>
                    )}

                    {selectedIncrementalMode === "Partition_by" &&
                      selectedLoadMethod === "Incremental" && (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "10px",
                          }}
                        >
                          <Controller
                            control={control}
                            name={`tasks[${index}].partitionByCols`}
                            render={({ field }) => (
                              <TextField
                                id="outlined-basic"
                                label="Partition by cols"
                                variant="outlined"
                                size="small"
                                sx={{ width: "250px" }}
                                {...field}
                              />
                            )}
                          />
                          <Controller
                            control={control}
                            name={`tasks[${index}].fileFormat`}
                            render={({ field }) => (
                              <TextField
                                id="outlined-basic"
                                label="file format"
                                variant="outlined"
                                size="small"
                                sx={{ width: "250px" }}
                                {...field}
                              />
                            )}
                          />
                        </div>
                      </div>
                    )}

                    <p style={{ fontSize: "14px", fontWeight: 600 }}>
                      Export Settings
                    </p>
                    <p style={{ fontSize: "14px", fontWeight: 400 }}>
                      Signed URL :{" "}
                      <a style={{ color: "rgb(112, 112, 112)" }} href="/">
                        https://path/to/data/product/api/endpoint
                      </a>
                    </p>
                    <div
                      style={{
                        paddingTop: "15px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        // width: "100%",
                      }}
                    >
                      <Controller
                        control={control}
                        name={`tasks[${index}].exportToKafka`}
                        render={({ field }) => (
                          <div style={{ display: "flex", gap: "6px" }}>
                            <TextField
                              id="export"
                              // label="Target"
                              variant="standard"
                              type="checkbox"
                              {...field}
                            />
                            <p>Export</p>
                          </div>
                        )}
                      />
                      <p style={{ fontSize: "15.5px", fontWeight: 500 }}>
                        Kafka Topic:{" "}
                        <span style={{ fontWeight: 400 }}>
                          EMPTopicToWhichDataProductCDCEventsAreWritten
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <Tooltip title="Remove Task">
                <IconButton
                  aria-label="delete"
                  onClick={() => remove(index)}
                  sx={{
                    position: "absolute",
                    top: -5,
                    right: 0,
                  }}
                  size="small"
                >
                  <Close fontSize="20px" />
                </IconButton>
              </Tooltip>
            </div>
          );
        })}
        <Button
          variant="contained"
          color="success"
          size="small"
          sx={{ mt: 2, fontSize: "11px" }}
          onClick={() => {
            append({
              code: "",
              description: "",
              targetName: "",
              codeUrl: "",
              dependsOnTask: "",
              enableQc: "",
              exportToKafka: "",
              fileFormat: "",
              incrementalMode: "",
              partitionByCols: "",
              name: "",
              kafkaTopic: "",
              signedUrl: "",
              targetLoadMethod: "",
              uniqueKeys: "",
              watermarkCols: "",
              schemaChange: "",
              type: "",
              //runmode:""
            });
          }}
        >
          Add Tasks
        </Button>
      </div>
    </>
  );
};

/* <Controller
              control={control}
              name="method"
              render={({ field }) => (
                <FormControl
                  size="small"
                  variant="outlined"
                  sx={{ mb: 2, mt: 2, minWidth: 120 }}
                >
                  <InputLabel id="select-method">Method</InputLabel>
                  <Select
                    labelId="select-method"
                    id="method"
                    label="Method"
                    margin="none"
                    fullWidth
                    {...field}
                  >
                    <MenuItem value="Overwrite">Overwrite</MenuItem>
                    <MenuItem value="Append">Append</MenuItem>
                  </Select>
                </FormControl>
              )}
            /> */

export default TaskForm;
