import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const WarningPopUp = () => {
  return (
    <div>
      {/* <button onClick={notify}>Notify!</button> */}
      <ToastContainer
        position="top-right" // Position the ToastContainer at the top-center of the screen
        autoClose={1300} // Automatically close the toast after 5 seconds
        //hideProgressBar  Hide the progress bar
        newestOnTop // Display new toasts on top
        closeOnClick // Close the toast when clicked
        rtl={false} // Left-to-right layout
        pauseOnFocusLoss // Pause toasts on focus loss
        draggable // Allow dragging the toast
        pauseOnHover // Pause toasts on hover
        style={{ width: "450px", height: "250px" }} // Set the width of the ToastContainer
      />
    </div>
  );
};

export default WarningPopUp;
