import { OpenInNew } from "@mui/icons-material"
import { Chip, Paper, Tooltip } from "@mui/material"
import { Light as SyntaxHighlighter } from "react-syntax-highlighter"
import { a11yLight } from "react-syntax-highlighter/dist/esm/styles/hljs"
import { sql } from "react-syntax-highlighter/dist/esm/languages/hljs"

import React from "react"

import { Key, Pair, Query, TaskTitle } from "./JobViewTask.styled"
import { format } from "sql-formatter"

SyntaxHighlighter.registerLanguage("sql", sql)

const JobViewTasks = ({ details }) => {
  // STRING MODIFIER
  const shortenString = (longString) => {
    if (longString.length <= 30) {
      return longString
    }

    const firstPart = longString.slice(0, 11)
    const lastPart = longString.slice(-18)

    return `${firstPart}...${lastPart}`
  }

  return (
    <div
      style={{
        background: "#fafafa",
        // border: "0.3px solid gray",
        borderRadius: "4px",
        margin: "2px",
        width: "45%",
        height: "100%",
        // overflow: "scroll",
        paddingBottom: "10px",
      }}
    >
      <p
        style={{
          fontSize: "15px",
          fontWeight: "600",
          letterSpacing: "0.4px",
          padding: "6px",
          color: "#247ba0",
        }}
      >
        Tasks:
      </p>
      <div className="task-details"
        // style={{
        //   marginInline: "12px",
        //   display: "flex",
        //   flexDirection: "column",
        //   gap: "10px",
        //   height: "95%",
        //   overflow: "scroll",
        //   paddingBottom: "4px",
        // }}
      >
        {details?.tasks &&
          details?.tasks.map((task) => (
            <Paper
            className="jobview-paper"
              // sx={{ p: 1.5, overflowX: "auto",}}
              elevation={1}
              key={task.fqn}
            >
              <TaskTitle>{task.name}</TaskTitle>
              {task.description && (
                <Pair style={{ alignItems: "flex-start" }}>
                  <Key>Description :</Key>

                  <div style={{ fontSize: "11px", letterSpacing: "0.4px" }}>
                    {task.description}
                  </div>
                </Pair>
              )}

              <div className="query-box">
                <SyntaxHighlighter
                  language="sql"
                  style={a11yLight}
                  // customStyle={{ ...styles }}
                  wrapLongLines={true}
                  showLineNumbers={true}
                >
                  {/* {task.code || "// Query"} */}
                  {format(task.code, { language: "mysql" })}
                </SyntaxHighlighter>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Pair style={{ width: "100%", alignItems: "flex-start" }}>
                  <Key style={{ paddingTop: "3px" }}>dependsOnTask :</Key>
                  <Tooltip
                    placement="left-start"
                    title={task?.dependsOnTask}
                    arrow
                  >
                    <Chip
                      label={shortenString(task?.dependsOnTask)}
                      size="small"
                      sx={{ p: 0.5, mb: 0.6, mr: 0.6, fontSize: 11 }}
                    />
                  </Tooltip>
                  {/* {task.dependsOnTask.length &&
                    task.dependsOnTask.map((chip) => (
                      <div key={chip}>
                        <Tooltip placement="left-start" title={chip} arrow>
                          <Chip
                            label={shortenString(chip)}
                            size="small"
                            sx={{ p: 0.5, mb: 0.6, mr: 0.6, fontSize: 11 }}
                          />
                        </Tooltip>
                      </div>
                    ))} */}
                </Pair>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Pair style={{ padding: 0 }}>
                  <Key>codeUrl :</Key>
                  <a
                    style={{
                      color: "black",
                      fontStyle: "oblique",
                      cursor: "pointer",
                    }}
                    href={task.codeUrl}
                  >
                    <OpenInNew fontSize="12px" />
                  </a>
                </Pair>
              </div>
            </Paper>
          ))}
      </div>
    </div>
  )
}

export default JobViewTasks
