import React from "react";
import "./LandingPage.css";
import rightArrow from "./right-arrow.png";
import {  Tooltip} from "@mui/material";
import { Link } from "react-router-dom";
import { useState } from "react";
import JobTypeModal from "../../components/selectJobtypeModal/JobTypeModal";
import metaData from "../../../src/assets/sideNavBarLogo/assets/Metadata-Crawl.png";


const LandingPage = () => {

// showhide
const [openModal, setOpenModal] = useState(false);

const handleCreateJobClick = () => {
    // console.log("handleCreateJobClick")
    setOpenModal(!openModal);
};

  return (
    <div className="mainWrapper">
      <div className="versionDetails arrow-box">
        <h3>Check out the Release Notes for the new and improved Datacraft 1.0!</h3>
      </div>
      <div className="datacraftInfo" >
        <p><b>Datacraft</b> is a data integration platform that enables businesses to replicate and sync data from various sources to their data warehouses or lakes. With connectors for a wide array of data sources and a user-friendly interface, Datacraft simplifies the process of managing data pipelines, build data-marts and insights, allowing organizations to make better-informed decisions based on comprehensive data insights.</p>
      </div>
      <div className="shortcuts">

       <Link to="/manageProjects">
       <div className="infoCard">
        {/* <Tooltip arrow placement="top" title="Product Registration">Product Registration</Tooltip> */}
        <span >Product Registration</span>
          <p>Tool for onboarding domain products to the data platform</p>
        </div>
        </Link>

        {/* <img src={rightArrow} alt="rightArrow" /> */}
        <Link to="/manageDatasources">
        <div className="infoCard">
        <span style={{ fontWeight: "400" }}>Datasource Registration</span>
          <p>Data source registration securely stores the relevant credentials in the vault.</p>
        </div>
        </Link>

        <Link to="/manageMetadatacrawls">
        <div className="infoCard"> 
          <span style={{ fontWeight: "400" }}>Metadata Crawler</span>
          <p>Tool that automatically scans and collects metadata from various data sources within an organization's infrastructure.</p>
        </div>
        </Link>

        <Link to="">
        <div onClick={handleCreateJobClick} className="infoCard">
          <span style={{ fontWeight: "400" }}>Data Ingestion</span>
          <p>Data ingestion self-serve enables users to autonomously ingest data from various sources into their analytics environment without relying on IT support.</p>
          {/* <div className="imageWrapperf" >
          <img src={metaData} alt="metaData" className="landingPageLogo"/>
          </div> */}
        </div>
        </Link>
         
        <Link to="/createMartBuilderJob">
        <div className="infoCard"> 
          <span style={{ fontWeight: "400" }}>Mart Builder</span>
          <p>A data mart builder self-serve system empowers users to independently create data marts tailored to their specific analytical needs. </p>
        </div>
        </Link>

        <Link to="">
        <div className="infoCard"> 
          <span style={{ fontWeight: "400" }}>Semantic Builder</span>
          <p>The Semantic Layer is a virtual abstract layer that sits between the raw data sources and the end-user reporting tools.</p>
        </div>
        </Link>

        <Link to="https://databook-dev.maestro.maersk.com/databook/datasets">
        <div className="infoCard"> 
          <span style={{ fontWeight: "400" }}>Data Catalog</span>
          <p>Databook is a centralized inventory that organizes and indexes metadata about an organization's data assets. It helps users discover, understand, and access data, facilitating better data governance, collaboration, and decision-making.</p>
        </div>
        </Link>

            {/* <div class="singleCardWrapper">
                <div class="imageWrapper">
                    <img className="image" src={metaData} alt=""/>
                    <h3>Semantic</h3>
                </div>
                <div class="infoContent">
                <span style={{ fontWeight: "400" }}>Semantic Builder</span>
                    <p>Coming Soon... MORE TEXT DETAILS HERE...</p>
                </div>
            </div> */}

      </div>
      <hr style={{margin:"70px 30px"}} />

      <div style={{border:"1px solid grey",  margin:"0px 40px", borderRadius:"10px"}} >
          <div style={{height:"max-content",padding:"20px 60px"}} className="videoBlock" >
            <h3>Getting started with datacraft</h3>
            <iframe width="600" height="415"  
            style={{borderRadius: "10px", marginTop:"20px"}}

            src="https://www.youtube.com/embed/-1qmLR8u-EU?si=WEkmCB-L7h4Yvc44" title="YouTube video player" frameborder="10" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <span className="userGuide" >User Guide</span>
          </div>
      </div>
      {openModal && (
        <JobTypeModal onOpen={handleCreateJobClick} boolean={openModal} hideStreamingSync={true} />
      )}
    </div>
  );
};

export default LandingPage;
