import { useState, useEffect, useContext } from "react";
import { Button, Modal, TextField, Typography } from "@mui/material";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  Grid,
  DialogTitle,
  TableContainer,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import axios from "axios";
import { userRequestMartbuilder } from "../../requestMethod";
import { AuthContext } from "../../AuthContext";

const MaerskModalTableEdit = ({
  boolean,
  onToggle,
  row,
  tableType,
  fetchMaerskModalData,
  fetchBusinessTableData,
  fetchDomainTableData,
}) => {
  const [tableValue, setTableValue] = useState(tableType);
  // console.log(tableValue)
  const { getToken, token, error } = useContext(AuthContext);

  //for submitting the edit response
  const UpdateMaerskModalData = async (updatedData) => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.put(
        `/orgmodel/${updatedData.id}`,
        values,
        {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      // console.log(response.data);
      fetchMaerskModalData();
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    // UpdateMaerskModalData();
  };

  const UpdateBusinessData = async (updatedData) => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.put(
        `/business/${updatedData.id}`,
        values,
        {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      // console.log(response.data);
      fetchBusinessTableData();
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    // UpdateMaerskModalData();
  };

  const UpdateDomainsData = async (updatedData) => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.put(
        `/platform/updatePlatform`,
        updatedData,
        {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      // console.log(response.data);
      fetchDomainTableData();
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    // UpdateMaerskModalData();
  };

  const [values, setValues] = useState({
    name: row?.original?.name || "", // Set default value to an empty string
    description: row?.original?.description || "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedValues = { ...values, [name]: value };
    setValues(updatedValues);
  };

  const handleSubmit = async () => {
    if (values.name.trim()) {
      const updatedData = {
        id: row.original.id,
        name: values.name.trim(),
        description: values.description.trim(),
      };

      try {
        switch (tableValue) {
          case "MaerskModels":
            await UpdateMaerskModalData(updatedData);
            console.log(tableType);
            break;
          case "Business":
            await UpdateBusinessData(updatedData);
            break;
          case "Domain":
            await UpdateDomainsData(updatedData);
            break;
          default:
            break;
        }
        onToggle();
      } catch (error) {
        // Handle any errors here
        console.error("Error updating data:", error);
      }
    }
  };

  return (
    <div>
      <Dialog open={boolean} onClose={() => onToggle(row, tableType)}>
        <DialogContent style={{ width: "600px" }}>
          <Grid container mb={3}>
            <Grid item xs={11}>
              {(() => {
                switch (tableType) {
                  case "MaerskModels":
                    return (
                      <Typography variant="h6" component="h2">
                        Edit Maersk Model Data
                      </Typography>
                    );
                  case "Business":
                    return (
                      <Typography variant="h6" component="h2">
                        Edit Business Data
                      </Typography>
                    );
                  case "Domain":
                    return (
                      <Typography variant="h6" component="h2">
                        Edit Domain Data
                      </Typography>
                    );
                  default:
                    return (
                      <Typography variant="h6" component="h2">
                        Edit Data
                      </Typography>
                    );
                }
              })()}
            </Grid>
            <Grid color="text.secondary" item xs={1}>
              <CloseIcon
                onClick={() => onToggle(row, tableType)}
                sx={{
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>
          <TextField
            value={values.name}
            onChange={handleChange}
            name="name"
            label="Name"
            margin="dense"
            fullWidth
            disabled
          />
          <TextField
            value={values.description}
            onChange={handleChange}
            name="description"
            label="Description"
            margin="dense"
            fullWidth
          />
        </DialogContent>
        <Box sx={{ pb: 2, px: 2 }}>
          <DialogActions>
            <Button variant="contained" onClick={handleSubmit}>
              Update
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
};

export default MaerskModalTableEdit;
