import React from "react";
import "./DataCards.css";

const DataCards = ({ title, value }) => {
  return (
    <div
      className="data-card"
      // style={{ border: "1px solid grey",padding:"8px", borderRadius:"5px",marginBottom:"30px" }}
    >
      <div className="data-card-content">
        <h3>{value}</h3>
        <p>{title}</p>
      </div>
    </div>
  );
};

export default DataCards;
