import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  Grid,
  DialogTitle,
  TableContainer,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import "../IngestionForm/connectionDetailsModal/ConnectionDetailsModal.css";

function JobSummaryDetailsModal({ data, boolean, onOpen }) {
  //console.log(data);
  return (
    <div style={{paddingBottom:"120px"}}>
      <Dialog open={boolean} onClose={onOpen}>
        <DialogContent style={{ width: "600px" }}>
          <Grid container mb={3}>
            <Grid item xs={11}>
              <Typography variant="h6" component="h2">
                Job Details
              </Typography>
              <div style={{ margin: "35px" }}>
                {data && (
                  <div style={{ marginTop: "30px" }}>
                    <div>
                      <label htmlFor="">JobType </label>
                      <br />
                      <input
                        className="inputField"
                        type="text"
                        placeholder={data.jobType}
                        disabled
                      />
                    </div>
                    <div>
                      <label htmlFor="">Job Name </label>
                      <br />
                      <input
                        className="inputField"
                        type="text"
                        placeholder={data?.jobName}
                        disabled
                      />
                    </div>
                    <div>
                      <label htmlFor="">Job Frequency Type</label>
                      <br />
                      <input
                        className="inputField"
                        type="text"
                        placeholder={data.jobFrequencyType}
                        disabled
                      />
                    </div>
                    
                    <div>
                      <label htmlFor="">Product Name</label>
                      <br />
                      <input
                        className="inputField"
                        type="text"
                        placeholder={data?.product}
                        disabled
                      />
                    </div>
                    <div>
                      <label htmlFor="">Job Creation Status </label>
                      <br />
                      <input
                        className="inputField"
                        type="text"
                        placeholder={data?.jobCreationStatus}
                        disabled
                      />
                    </div>
                    <div>
                      <label htmlFor="">CreatedAt </label>
                      <br />
                      <input
                        className="inputField"
                        type="text"
                        placeholder={data.createdAt}
                        disabled
                      />
                    </div>
                    <div>
                      <label htmlFor="">Last-Modified At </label>
                      <br />
                      <input
                        className="inputField"
                        type="text"
                        placeholder={data?.lastModifiedAt}
                        disabled
                      />
                    </div>

                    <div>
                      <label htmlFor="">Job Frequency Type </label>
                      <br />
                      <input
                        className="inputField"
                        type="text"
                        placeholder={data.jobFrequencyType}
                        disabled
                      />
                    </div>
                  </div>
                )}
              </div>
            </Grid>
            <Grid color="text.secondary" item xs={1}>
              <CloseIcon
                onClick={onOpen}
                sx={{
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default JobSummaryDetailsModal;
