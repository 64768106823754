import { useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { TextField } from "@mui/material";
import { Link } from "react-router-dom";
// import { useAddBusinessDataMutation } from "../../../store";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  Grid,
  DialogTitle,
  TableContainer,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

function RejectReasonModal({
  boolean,
  onOpen,
  handleRejectReason,
  handleReject,
}) {
  const [showEmptyFieldMessage, setShowEmptyFieldMessage] = useState(false);
  const [values, setValues] = useState({
    name: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedValues = { ...values, [name]: value };
    setValues(updatedValues);
  };

  const handleSubmit = () => {
    if (!values.name.trim()) {
      setShowEmptyFieldMessage(true);
      setTimeout(() => {
        setShowEmptyFieldMessage(false);
      }, 2000); // Show the message for 2 seconds
      return;
    }

    onOpen();
    //     AddBusinessData(values);
  };

  return (
    <div style={{ border: "1px solid red" }}>
      <Dialog open={boolean} onClose={onOpen} sx={{ border: "1px solid red" }}>
        <DialogContent style={{ width: "600px" }}>
          <Grid container mb={3}>
            <Grid item xs={11}>
              <Typography variant="h6" component="h2">
                Reject Reason
              </Typography>
            </Grid>
            <Grid color="text.secondary" item xs={1}>
              <CloseIcon
                onClick={onOpen}
                sx={{
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>

          <TextField
            margin="dense"
            label="Reject Reason"
            name="name"
            placeholder="Please mention the reject reason"
            onChange={handleRejectReason}
            fullWidth
          />
          {showEmptyFieldMessage && (
            <Typography sx={{ fontSize: "14px", color: "red", margin: "5px" }}>
              Input field cannot be empty
            </Typography>
          )}
        </DialogContent>
        <Box sx={{ pb: 2, px: 2 }}>
          <DialogActions>
            <Link to={"/"} >
              <Button
                // disabled={  values.email.trim() === ""}
                fullWidth
                variant="contained"
                onClick={handleReject}
              >
                Submit
              </Button>
            </Link>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}

export default RejectReasonModal;
