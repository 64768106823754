import { useEffect, useState } from "react";
import DashBoardTabs from "./DashBoardTabs";
import { Box } from "@mui/material";
import { Route, Routes, useNavigate } from "react-router-dom";
import ManageJobs from "../manageJobs/ManageJobs";
import CreateJob from "../../components/createJob/CreateJob";
import JobRequest from "../jobRequest/JobRequest";
import ManageProjects from "../manageProjects/ManageProjects";
import AddProduct from "../../components/allManageProjectsModal/ProductDetailsModal/AddProduct";
import JobView from "../jobView/JobView";
// import ProductTableEdit from "../../components/ManageProjectEditModal/ProductTableEdit";
// import EditJobView from "../editJobView/EditJobView";
import BatchIngestion from "../../components/batchIngestion/BatchIngestion";
import NotFound from "../../components/Atoms/NotFound";
import StreamingSync from "../../components/streamingSync/StreamingSync";
import ManageDatasources from "../manageDatasources/ManageDatasources";
import NewConnection from "../newConnection/NewConnection";
import ManageDataSourceTablePage from "../manageDatasources/ManageDataSourceTablePage";
import EditDatasourcePage from "../editDatasource/EditDatasourcePage";
import ManageMetadataCrawls from "../manageMetadataCrawls/ManageMetadataCrawls";
import AddNewMetaDataCrawl from "../../components/metadataCrawls/addNewData/AddNewMetaDataCrawl";
import EditMetaDataCrawl from "../../components/metadataCrawls/EditData/EditMetaDataCrawl";
import ManageAuthorization from "../manageAuthorization/ManageAuthorization";
import ManageAuditLog from "../manageAuditLog/ManageAuditLog";

import BatchJobsView from "../Batchview/BatchJobsView";
import StreamingSyncForm from "../streamingSync/StreamingSyncForm";
import AddNewRole from "../../components/manageAuthorizationModal/AddNewRole";
import RoleTableEdit from "../../components/ManageProjectEditModal/RoleTableEdit";
import ManageAuthorizationRole from "../manageAuthorization/ManageAuthorizationRole";
import ManageAuthorizationUser from "../manageAuthorization/ManageAuthorizationUser";

import LandingPage from "../landingPage/LandingPage";
import CronTest from "../manageAuditLog/CronTest";
// Styles/Components

function HomeNavigator() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/");
  }, []);
  return <></>;
}

function ManageDatasourcesNavigator() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/manageDatasources/newConnection");
  }, []);
  return <></>;
}

function EditDatasourceTypeNavigator() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/manageDatasources/");
  }, []);
  return <></>;
}

function AddNewMetaDataCrawlTypeNavigator() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/manageMetadatacrawls/");
  }, []);
  return <></>;
}

function JobViewNavigator() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/");
  }, []);
  return <></>;
}

function DashBoard() {
  return (
    <Box
      sx={{
        marginInline: "auto",
        width: "100%",
        // paddingInline: "5%",
        height: "100%",
        backgroundColor: "#f8f9fa",
      }}
    >
      <Routes>
        <Route path="/">
          <Route index element={<LandingPage />} />
          <Route
            path="jobRequest"
            element={
              <>
                <JobRequest />
              </>
            }
          />

          <Route path="/manageJobs" element={<ManageJobs/>} />
          <Route path="/Batchview" element={<HomeNavigator />} />
          <Route
            path="/Batchview/:jobId"
            element={
              <>
                <BatchJobsView />
              </>
            }
          />
           
          <Route path="/jobView" element={<JobViewNavigator />} />
          <Route
            path="/jobView/:jobName"
            element={
              <>
                <JobView />
              </>
            }
          />
          <Route
            path="/jobView/:jobId/edit"
            element={
              <>
                <CreateJob />
              </>
            }
          />
          <Route
            path="createBatchIngestionJob"
            element={
              <>
                <BatchIngestion />
              </>
            }
          />
          <Route
            path="createStreamingSyncJob"
            element={
              <>
                <StreamingSyncForm />
              </>
            }
          />
          <Route
            path="manageProjects"
            element={
              <>
                <ManageProjects />
              </>
            }
          />
          <Route
            path="manageDatasources"
            element={
              <>
                <ManageDataSourceTablePage />
              </>
            }
          />
          <Route
            path="/manageDatasources/ChooseDatasourceType"
            element={
              <>
                <ManageDatasources />
              </>
            }
          />
          <Route
            path="/ChooseDatasourceType"
            element={<ManageDatasourcesNavigator />}
          />
          <Route
            path="/manageDatasources/ChooseDatasourceType/:databaseName"
            element={<NewConnection />}
          />
          <Route path="/edit" element={<EditDatasourceTypeNavigator />} />
          <Route
            path="/manageDatasources/Edit/:datasourceType/:connectionFqn/:connectionId"
            element={<EditDatasourcePage />}
          />
          {/* <Route path="/:datasourceType" element={<EditDatasourceTypeNavigator />} /> */}

          <Route path="addProduct" element={<AddProduct />} />
          <Route path="createMartBuilderJob" element={<CreateJob />} />
          <Route path="*" element={<NotFound />} />
          <Route
            path="/manageMetadatacrawls"
            element={<ManageMetadataCrawls />}
          />
          <Route path="/metadataCrawls/new" element={<AddNewMetaDataCrawl />} />
          <Route
            path="/metadataCrawls"
            element={<AddNewMetaDataCrawlTypeNavigator />}
          />
          <Route path="/metadataCrawls/edit" element={<EditMetaDataCrawl />} />
          <Route
            path="/manageAuthorization"
            element={<ManageAuthorization />}
          />
          <Route
            path="/manageAuthorization/applications"
            element={<ManageAuthorization />}
          />
          <Route
            path="/manageAuthorization/roles"
            element={<ManageAuthorizationRole />}
          />
          <Route
            path="/manageAuthorization/users"
            element={<ManageAuthorizationUser />}
          />
          <Route path="/manageAuthorization/addNewRole" element={<AddNewRole />} />
          <Route path="/manageAuthorization/editRole" element={<RoleTableEdit />} />
          <Route path="/manageAuditLog" element={<ManageAuditLog />} />
          <Route path="/cronTest" element={<CronTest />} />
        </Route>
      </Routes>
    </Box>
  );
}

export default DashBoard;
