import React, { useEffect, useState } from "react";
import DashBoardTabs from "../dashboard/DashBoardTabs";
import { CardData } from "./dummyData";
import "./ManageDatasources.css";
import { Link } from "react-router-dom";
import PostgreSQL from "../manageDatasources/assets/postgre.svg";
import { Button } from "@mui/material";
import { AddCircleOutline, Refresh } from "@mui/icons-material"; 
import NewConnection from "../newConnection/NewConnection";

const ManageDatasources = ({ batchIngestion, resetRadioBtn }) => {
  const [searchText, setSearchText] = useState("");
  const [allCards, setAllCards] = useState();
  const [filteredCards, setFilteredCards] = useState();
  const [showInputField, setShowInputField] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState();

  useEffect(() => {
    setAllCards(CardData);
    setFilteredCards(CardData);
  }, []);

  const filterData = (searchText, allCards) => {
    const filteredData = allCards.filter((card) =>
      card.name.toLowerCase()?.includes(searchText?.toLowerCase())
    );
    return filteredData;
  };

  const handleChange = (e) => {
    const searchTextValue = e.target.value;
    setSearchText(searchTextValue);
    // console.log(searchTextValue)
    const filteredData = filterData(searchTextValue, allCards);
    setFilteredCards(filteredData);
  };

  const handleCardClick = (cardName) => {
    console.log("Clicked Card Name:", cardName);
    setSelectedCard(cardName); // Store the selected card name
    setShowInputField(true);
  };

  const hideInputField = ()=>{
    setShowInputField(false)
  }

  return (
    <div>
      {/* <DashBoardTabs /> */}
      <div className="mainWrapper">
        {!batchIngestion ? (
          <>
            <div className="inputBox">
              {/* Search :{" "} */}
              <input
                type="text"
                className="inputField"
                value={searchText}
                onChange={handleChange}
                placeholder="Search..."
              />
            </div>
            <div className="cardWrapper">
              {filteredCards?.map((card) => (
                <Link
                  to={
                    "/manageDatasources/ChooseDatasourceType/" +
                    card.name.replace(/\s+/g, "")
                  }
                  key={card.name}
                  // style={{ border: "1px solid red" }}
                  className="singleCard"
                >
                  {/* <div style={{ border: "1px solid blue" }} > */}
                  <div className="imageText">
                    <img
                      src={card.image}
                      alt={card.name}
                      className="techLogo"
                    />
                    <span className="techName">{card.name} </span>
                  </div>
                  <p className="otherInfo">{card.otherInfo} </p>
                  {/* </div> */}
                </Link>
              ))}
            </div>
          </>
        ) : (
          !showInputField && (
            <>
              <div className="inputBox">
                {/* Search :{" "} */}
                <input
                  type="text"
                  className="inputField"
                  value={searchText}
                  onChange={handleChange}
                  placeholder="Search..."
                />
              </div>
              <div className="cardWrapper">
                {filteredCards?.map((card) => (
                  <div
                    key={card.name}
                    className="singleCard"
                    onClick={() => handleCardClick(card.name)}
                  >
                    {/* <div style={{ border: "1px solid blue" }} > */}
                    <div className="imageText">
                      <img
                        src={card.image}
                        alt={card.name}
                        className="techLogo"
                      />
                      <span className="techName">{card.name} </span>
                    </div>
                    <p className="otherInfo">{card.otherInfo} </p>
                    {/* </div> */}
                  </div>
                ))}
              </div>
            </>
          )
        )}
      </div>
      {showInputField && (
        <>
          <NewConnection
            databaseCardName={selectedCard}
            batchIngestion={batchIngestion}
            resetRadioBtn={resetRadioBtn}
            hideInputField={hideInputField}
          />
        </>
      )}
    </div>
  );
};

export default ManageDatasources;
