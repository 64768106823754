import React from "react"
import { Backdrop, CircularProgress,Typography } from "@mui/material"

const BackdropLoader = ({ condition,message }) => {
  return (
    <Backdrop open={true} sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}>
      {message && (
        <Typography sx={{ mt: 2, mr:4,color: 'white',fontSize:"30px" }}>
          {message}
        </Typography>
      )}
      <CircularProgress sx={{ color: "white" }} />
    </Backdrop>
  )
}

export default BackdropLoader
