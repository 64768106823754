import React, { useState, useEffect } from "react";
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Button,
  TextField,
  Box,
} from "@mui/material";
import Select from "react-select";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { useValue } from "../../context/ContextProvider";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TransformationModal from "../IngestionForm/transformationModal/TransformationModal";
import CancelIcon from "@mui/icons-material/Cancel";

const BatchsourceTarget = ({ data, onSavePayload }) => {
  const [openTranformationModal, setOpenTranformationModal] = useState(false);

  const {
    state: { toggleEditButton, transformationCatalog },
    dispatch,
  } = useValue();

  const [transf, setTransf] = useState(data);

  // useEffect(() => { 
  //   transf?.forEach((item) => {
  //     if (Array.isArray(item?.transformations)) {
  //       item.transformations.forEach((transformation) => {
  //         //console.log(transformation, "log")
  //       });
  //     }
  //   });
  // }, [transf]);

  useEffect(() => { 
    generatePayload()
  }, []);

  const columns = [
    {
      Header: "Source Column Name",
      accessor: "sourceColumnName",
    },
    {
      Header: "Source Type",
      accessor: "sourceType",
    },
    {
      Header: "Target Column Name",
      accessor: "targetColumnName",
    },
    {
      Header: "Destination Type",
      accessor: "destinationType",
    },
    {
      Header: "Transformation Expression",
      accessor: "transformationExpression",
    },
  ];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const generateUniqueId = () => `${new Date().getTime()}-${Math.random()}`;

  const [editedData, setEditedData] = useState(
    data.map((item) => ({
      ...item,
      transformationExpressions:
        item.transformations?.map((trans) => ({
          ...trans, // Spread the existing properties
          id: generateUniqueId(), // Assign a unique ID
        })) || [],
    }))
  );

  const [TablePayload, setTablePayload] = useState();

  const [activeRowIndex, setActiveRowIndex] = useState(null);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const displayedData = editedData.slice(startIndex, endIndex);

  const handleEditTransformation = (index, newValue) => {
    const updatedData = [...editedData];
    updatedData[index].transformationExpression = newValue;
    setEditedData(updatedData);
  };

  // Function to generate the payload JSON
  const generatePayload = () => {
    const payloadData = editedData.map((row) => ({
      sourceColumnName: row.sourceColumnName,
      sourceType: row.sourceType,
      targetColumnName: row.targetColumnName,
      destinationType: row.destinationType,
      transformations: row.transformationExpressions
        .filter((transExpr) => !!transExpr)
        .map(({ id, ...rest }) => rest),
    }));

    //console.log("Final payloadData:", payloadData);
    setTablePayload({ sourceTargetColumnMapping: payloadData });
    onSavePayload({ sourceTargetColumnMapping: payloadData });
  };

  const handleTransformation = (index) => {
    setActiveRowIndex(index);
    setOpenTranformationModal(!openTranformationModal);
  };

  // working fine
  const handleSelectChange = (selectedOptions, index) => {
    console.log("Selected options: ", selectedOptions);

    const updatedData = [...editedData];
    let transformations = selectedOptions.map((option) => {
      let parts = option.value.split(" ");
      let transformationType = parts[0];
      let args = parts.slice(1);

      let argObject = args.reduce((acc, arg) => {
        let [key, value] = arg.split(":");
        acc[key] = value;
        return acc;
      }, {});

      return { [transformationType]: argObject };
    });

    updatedData[index].transformationExpression = transformations;
    setEditedData(updatedData);
    console.log("Transformations after selection: ", transformations);
  };

  const transformedCatalogOptions = transformationCatalog?.map((item) => ({
    value: item,
    label: item,
  }));

  const handleTransformationSubmit = (transformationExpression) => {
    if (activeRowIndex !== null) {
      const newData = [...editedData];
      const newTransformation = {
        ...transformationExpression,
        id: generateUniqueId(), // Generate a "unique" ID for the new transformation
      };
      newData[activeRowIndex].transformationExpressions.push(newTransformation);
      setEditedData(newData);
    }
  };

  const handleRemoveTransformation = (rowIndex, transformationId) => {
    const newData = [...editedData];
    newData[rowIndex].transformationExpressions = newData[
      rowIndex
    ].transformationExpressions.filter(
      (trans) => trans.id !== transformationId
    );
    generatePayload();
    setEditedData(newData);
  };

  return (
    <div style={{ position: "relative" }}>
      {isFullScreen ? (
        <div
          style={{
            position: "fixed",
            top: "64px",
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            zIndex: 999,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TableContainer
            component={Paper}
            style={{ width: "100vw", height: "100vh" }}
          >
            {/*   <Button style={{ marginTop: "5px" }} variant="contained" onClick={toggleFullScreen}>
              Exit Full Screen
            </Button> */}
            <FullscreenExitIcon
              style={{
                margin: "14px",
                cursor: "pointer",
              }}
              onClick={toggleFullScreen}
            />
            <Table size="small" style={{ marginBottom: "7.4rem" }}>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.accessor}>
                      <span style={{ fontWeight: "bold" }}>
                        {column.Header}
                      </span>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {displayedData.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {columns.map((column) => (
                      <TableCell key={column.accessor}>
                        {column.accessor === "transformationExpression" ? (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div>
                              <>
                                {row.transformationExpressions.map(
                                  (transformation) => (
                                    <div key={transformation.id}>
                                      {Object.entries(transformation).map(
                                        ([key, value]) => {
                                          if (key !== "id") {
                                            return (
                                              <p
                                                key={key}
                                                style={{
                                                  margin: 0,
                                                  display: "flex",
                                                  alignItems: "center",
                                                  border: "1px solid grey",
                                                  borderRadius: "5px",
                                                  padding: "3px",
                                                }}
                                              >
                                                {`${key}: ${JSON.stringify(
                                                  value
                                                )}`}
                                                <span
                                                  style={{
                                                    cursor: "pointer",
                                                    marginLeft: "5px",
                                                    color: "red",
                                                  }}
                                                  onClick={() =>
                                                    handleRemoveTransformation(
                                                      rowIndex,
                                                      transformation.id
                                                    )
                                                  }
                                                >
                                                  X
                                                </span>
                                              </p>
                                            );
                                          }
                                          return null;
                                        }
                                      )}
                                    </div>
                                  )
                                )}
                              </>
                              <Button
                                onClick={() => handleTransformation(rowIndex)}
                              >
                                <AddCircleOutlineIcon />
                              </Button>
                            </div>
                          </div>
                        ) : (
                          row[column.accessor]
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              style={{
                zIndex: 9999,
                backgroundColor: "white",
                borderTop: "2px solid lightgray",
                position: "fixed",
                left: "0px",
                bottom: "0px",
                width: "100%",
              }}
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={editedData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </div>
      ) : (
        <div>
          <TableContainer
            component={Paper}
            // style={{ padding: "20px", height: "400px", width: "32rem" }}
            style={{ padding: "20px", height: "auto", width: "100%" }}
          >
            {/*   <Button variant="contained" onClick={toggleFullScreen}>
              Full Screen
            </Button> */}
            <FullscreenIcon
              style={{ marginLeft: "10px", cursor: "pointer" }}
              onClick={toggleFullScreen}
            />
            <Table size="small">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.accessor}>
                      <span style={{ fontWeight: "bold" }}>
                        {column.Header}
                      </span>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {displayedData.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {columns.map((column) => (
                      <TableCell key={column.accessor}>
                        {column.accessor === "transformationExpression" ? (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div style={{display:"flex"}} >
                              <div style={{border:"1px solid grey", width:"300px", minHeight:"40px" , borderRadius:"3px"}}>
                                {row.transformationExpressions.map(
                                  (transformation) => (
                                    <div key={transformation.id}  >
                                      {Object.entries(transformation).map(
                                        ([key, value]) => {
                                          if (key !== "id") {
                                            return (
                                              <p
                                                key={key}
                                                style={{
                                                  margin: 0,
                                                  display: "flex",
                                                  alignItems: "center",
                                                  border: "1px solid grey",
                                                  borderRadius: "3px",
                                                  padding: "3px",
                                                }}
                                              >
                                                {`${key}: ${JSON.stringify(
                                                  value
                                                )}`}
                                                <span
                                                  style={{
                                                    cursor: "pointer",
                                                    marginLeft: "5px",
                                                    color: "red",
                                                  }}
                                                  onClick={() =>
                                                    handleRemoveTransformation(
                                                      rowIndex,
                                                      transformation.id
                                                    )
                                                  }
                                                >
                                                  X
                                                </span>
                                              </p>
                                            );
                                          }
                                          return null;
                                        }
                                      )}
                                    </div>
                                  )
                                )}
                              </div>
                              <Button
                                onClick={() => handleTransformation(rowIndex)}
                              >
                                <AddCircleOutlineIcon />
                              </Button>
                            </div>
                          </div>
                        ) : (
                          row[column.accessor]
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={editedData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </div>
      )}
      {openTranformationModal && (
        <Box sx={{ position: "absolute", top: "100%", left: 0 }}>
          <TransformationModal
            boolean={openTranformationModal}
            onOpen={handleTransformation}
            onSubmit={handleTransformationSubmit}
            generatePayload={generatePayload}
            usePayload={true}
          />
        </Box>
      )}
    </div>
  );
};

export default BatchsourceTarget;
