import { Button, Step, StepLabel, Stepper } from "@mui/material";
import React, { useContext, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import "../stepperForm/Expand.css";
import axios from "axios";
import { userRequestMartbuilder } from "../../requestMethod";
import { useNavigate } from "react-router-dom";
import { useValue } from "../../context/ContextProvider";
import StreamJobConfig from "../streamingSyncFormElement/StreamJobConfig";
import BasicDetailsStreamingSync from "../streamingSyncFormElement/BasicDetailsStreamingSync";
import { AuthContext } from "../../AuthContext";

function getSteps() {
  return ["Basic", "Stream Job Config"];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return <BasicDetailsStreamingSync />;
    case 1:
      return <StreamJobConfig />;
    default:
      return "unknown step";
  }
}

const blankForm = {
  // name: "",
  // product: {
  //   id: "",
  // },
  streamingIngestionDetail: {
    adlsAccountName: "",
    adlsGen: "",
    autoCommitIntervalMs: 0,
    autoOffsetReset: "",
    concurrencyValue: 0,
    consumerGroup: "",
    containerPath: "",
    dlqTopic: "",
    empVersion: "",
    enableAtLeastOnceDelivery: true,
    enableAutoCommit: true,
    enableDLQ: true,
    exactlyOnceKeyTopic: "",
    fetchMaxBytes: 0,
    fetchMaxWaitMs: 0,
    fetchMinBytes: 0,
    heartbeatIntervalMs: 0,
    id: "",
    keyType: "",
    maxPartitionFetchBytes: 0,
    maxPollRecords: 0,
    passwordVaultSecretKey: "",
    productName: "",
    sessionTimeoutMs: 0,
    topicName: "",
    userVaultSecretKey: "",
    valueType: "",
    vaultName: "",
  },
  business: "",
  dagName: "",
  dependsOnJobs: [],
  dependsOnPackages: "", // not on the ui
  description: "", // not on the ui
  domain: "", // not on the ui
  endDate: "",
  environment: "development",
  executionEngine: "", // not on the ui
  fqn: "", // not on the ui
  id: "", // not on the ui
  // --------------------------
  jobCreationStatus: "FAILED", // not on the ui
  jobType: "",
  name: "", // reuse the dataset name
  notificationFailureEmail: "", //on UI DM-tab
  notificationMissedSLAEmail: "", //on UI
  notificationSuccessEmail: "", //on UI
  orgModel: "", //not on UI
  product: {
    id: "",
  }, // product on the UI 1step
  project: "",
  publishMessage: "", //not needed on the UI
  rejectReason: "", //not needed on the UI
  retryCount: 0,
  retryInterval: 0,
  runAsAccount: "",
  schedule: "",
  sla: 0,
  startDate: "",
  targetLoadMethod: "",
  timezone: "UTC",
  updateVersion: true,
  version: 0,
};

const StreamingSyncForm = () => {
  // const {
  //   state: { jobDetails },
  // } = useValue();
  // console.log(jobDetails);

  const [collectedData, setCollectedData] = useState({});
  const { getToken, token, error } = useContext(AuthContext);
  // console.log(jobDetails);
  // const dataPresent = !!jobDetails;

  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const steps = getSteps();
  const methods = useForm({
    defaultValues: blankForm,
  });

  // const handleBack = () => {
  //   setActiveStep(activeStep - 1);
  // };

  const handelNext = (data) => {
    console.log("hn");
    console.log(data);

    // Submission on final step
    setCollectedData({ ...collectedData, ...data });
    console.log("step2");
    if (activeStep === 1) {
      const ourRequest = axios.CancelToken.source();
      const sendJobData = async () => {
        try {
          let response = await userRequestMartbuilder.post(
            "/jobs/ingestion/streaming/jobs",
            data,
            {
              headers: {
                // Define your custom headers here
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              CancelToken: ourRequest.token,
            }
          );
          console.log(response.data);
        } catch (err) {
          if (axios.isCancel(err)) {
            return;
          } else {
            console.error(err);
          }
        }
      };
      sendJobData();
      console.log("done with API");
      navigate("/");
    }
    setActiveStep(activeStep + 1);
  };
  const handelBack = () => {
    setActiveStep(activeStep - 1);
  };

  // const submitEdit = () => {
  //   const ourRequest = axios.CancelToken.source();
  //   const updatedData = async () => {
  //     try {
  //       let response = await userRequestMartbuilder.put(
  //         `/jobs/${jobDetails.id}`,
  //         collectedData,
  //         {
  //           cancelToken: ourRequest.token,
  //         }
  //       );
  //       // console.log(response.data);
  //     } catch (err) {
  //       if (axios.isCancel(err)) {
  //         return;
  //       } else {
  //         console.error(err);
  //       }
  //     }
  //   };
  //   updatedData();
  // };

  // TODO
  // can divide the functionality b/w next step and on final submit
  const onSubmit = (data) => {
    console.log("ko");
    console.log(data);

    const ourRequest = axios.CancelToken.source();
    const sendJobData = async () => {
      try {
        let response = await userRequestMartbuilder.post("/jobs", data, {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        });
        console.log(response.data);
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        } else {
          console.error(err);
        }
      }
    };
    sendJobData();
  };

  return (
    <div>
      <Stepper activeStep={activeStep}>
        <Step>
          <StepLabel>Basic</StepLabel>
        </Step>
        <Step>
          <StepLabel>Stream Job Config</StepLabel>
        </Step>
      </Stepper>

      {activeStep === steps.length ? (
        <div
          style={{
            height: "250px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h4>Thank You</h4>
        </div>
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handelNext)}>
            <div
              style={{
                maxHeight: "600px",
                // overflowX: "scroll",
                overflow: "auto",
                paddingRight: "15px",
                // border: "1px solid green",
                marginTop: "10px",
              }}
            >
              {getStepContent(activeStep)}
            </div>
            <div className="button-tab-jobview">
              <button
                className="rounded-md job-btn-plain btn"
                variant="text"
                size="small"
                disabled={activeStep === 0}
                onClick={handelBack}
              >
                Back
              </button>
              {activeStep === 1 ? (
                <button
                  className="rounded-md job-btn btn"
                  variant="contained"
                  size="small"
                  type="submit"
                  // onClick={handelNext}
                  // onClick={onSubmit}
                >
                  Finish
                </button>
              ) : (
                <button
                  className="rounded-md job-btn btn"
                  variant="contained"
                  size="small"
                  // onClick={handelNext}
                  type="submit"
                >
                  Next
                </button>
              )}
            </div>
          </form>
        </FormProvider>
      )}
    </div>
  );
};

export default StreamingSyncForm;
