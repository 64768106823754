import { useState, useContext } from "react";
import Select from "react-select";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Chip, OutlinedInput, TextField } from "@mui/material";
import { Select as MUISelect } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  Grid,
  DialogTitle,
  TableContainer,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import axios from "axios";
import { userRequestMartbuilder } from "../../requestMethod";
import { AuthContext } from "../../AuthContext";
import "../../components/metadataCrawls/addNewData/AddNewMetaDataCrawl.css";

function AddNewUser({ boolean, onOpen, tableType }) {
  // const [domainValue, setDomainValue] = useState("");
  // const [domainArray, setDomainArray] = useState([]);
  // const [rolesArray, setRolesArray] = useState([]);

  const [values, setValues] = useState({
    name: "",
    email_id: "",
    // domains: [],
    // roles: [],
  });

  // const domainslist = [{ value: 0, label: "Sales" }];

  // const roleslist = [
  //   { value: 0, label: "domainadmin" },
  //   { value: 1, label: "domainlead" },
  //   { value: 2, label: "domaindeveloper" },
  //   { value: 3, label: "user" },
  // ];

  // const handleInputChange = (e) => {
  //   setDomainValue(e.target.value);
  // };

  // Dropdown change function
  // const handleDropdownChange = (event, type) => {
  //   if (type === "domain") {
  //     let domData = [];
  //     event.map((e) => {
  //       domData.push(e.label);
  //       console.log(domData);
  //     });
  //     setDomainArray(domData);
  //     console.log("ddd", domainArray);
  //   }
  // 
  //   if (type === "roles") {
  //     let rolData = [];
  //     event.map((e) => {
  //       rolData.push(e.label);
  //       console.log(rolData);
  //     });
  //     setRolesArray(rolData);
  //     console.log("rrr", rolesArray);
  //   }
  // };

  // const handleDomainDelete = (e) => {
  //   console.log(e.target.value);
  //   // var array = [...domainsArray]; // make a separate copy of the array
  //   // var index = array.indexOf(e.target.value);
  //   // if (index !== -1) {
  //   //   array.splice(index, 1);
  //   //   setDoamainsArray(array);
  //   // }
  //   setDomainArray((currentSelectedItems) =>
  //     currentSelectedItems.filter((item) => item !== e.target.value)
  //   );
  // };

  // const handleDomainInputChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setDomainArray(
  //     // On autofill we get a stringified value.
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // };

  // const handleRolesInputChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setRolesArray(
  //     // On autofill we get a stringified value.
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // };

  //array handling
  // const handleAddToDataArray = () => {
  //   if (domainValue.trim() !== "") {
  //     setDomainArray((prevDomainArray) => [...prevDomainArray, domainValue]);
  //     setDomainValue("");
  //   }
  // };

  //delete the item
  // const handleDelete = (index) => {
  //   const updateDomain = domainArray.filter((_, i) => i !== index);
  //   setDomainArray(updateDomain);
  // };

  //token for new axios
  const { getToken, token, error } = useContext(AuthContext);

  //api call for adding new data
  const AddNewUserData = async () => {
    const payload = {
      name: values.name,
      email_id: values.email_id,
      // domains: domainArray,
      // roles: rolesArray,
    };
    console.log("Payload", payload);
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.post(`/aatusers`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  const [showEmptyFieldMessage, setShowEmptyFieldMessage] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedValues = { ...values, [name]: value };
    setValues(updatedValues);
  };

  const handleSubmit = async () => {
    if (!values.name.trim()) {
      setShowEmptyFieldMessage(true);
      setTimeout(() => {
        setShowEmptyFieldMessage(false);
      }, 2000);
      return;
    }
    await AddNewUserData();
    onOpen();
  };

  return (
    <div style={{}}>
      <Dialog open={boolean} onClose={onOpen}>
        <DialogContent style={{ width: "600px", height:"auto" }}>
          <Grid container mb={3}>
            <Grid item xs={11}>
              <Typography variant="h6" component="h2">
                Add New User
              </Typography>
            </Grid>
            <Grid color="text.secondary" item xs={1}>
              <CloseIcon
                onClick={onOpen}
                sx={{
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Name
            </Typography>
            <TextField
              margin="dense"
              label="Name"
              name="name"
              value={values.name}
              onChange={handleChange}
              // fullWidth
              style={{ width: "71.5%" }}
              // size="small"
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              E-mail
            </Typography>
            <TextField
              margin="dense"
              label="E-mail"
              name="email_id"
              value={values.email_id}
              onChange={handleChange}
              // fullWidth
              style={{ width: "71.5%" }}
              // size="small"
            />
          </div>

          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // gap:"7rem",
              margin: "10px 0",
            }}
          > */}
            {/* <TextField
              sx={{ minWidth: "85%" }}
              size="small"
              margin="dense"
              // name="domainArray"
              placeholder="Domain"
              value={domainValue}
              onChange={handleInputChange}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleAddToDataArray}
            >
              Add
            </Button> */}
            {/* <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Domain
            </Typography> */}
            {/* <Select
              multiple
              labelId="domain"
              id="domain"
              value={domainArray}
              onChange={handleDomainInputChange}
              style={{ width: "71.5%" }}
              // input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={value}
                      onDelete={handleDomainDelete}
                      deleteIcon={<CloseIcon />}
                    />
                  ))}
                </Box>
              )}
            >
              {domainslist?.map((item) => (
                <MenuItem
                  key={item}
                  value={item}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    // You can add more styles here if needed
                  }}
                >
                  {item}
                </MenuItem>
              ))}
            </Select> */}
            {/* <div style={{ width: "71.5%", margin: "0", padding: "0" }}>
              <Select
                isMulti
                id="domains"
                placeholder={false}
                aria-label="domains"
                options={domainslist}
                className="basic-multi-select"
                classNamePrefix="select"
                defaultValue={domainArray}
                hideSelectedOptions={false}
                noOptionsMessage={() => "No options available"}
                onChange={(event) => handleDropdownChange(event, "domain")}
              />
            </div> */}
          {/* </div> */}

          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "10px 0",
            }}
          > */}
            {/* <TextField
              sx={{ minWidth: "85%" }}
              size="small"
              margin="dense"
              // name="domainArray"
              placeholder="Domain"
              value={domainValue}
              onChange={handleInputChange}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleAddToDataArray}
            >
              Add
            </Button> */}
            {/* <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              Roles
            </Typography> */}
            {/* <Select
              multiple
              labelId="roles"
              id="roles"
              value={rolesArray}
              onChange={handleRolesInputChange}
              style={{ width: "71.5%" }}
              // input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={value}
                      onDelete={handleDomainDelete}
                      deleteIcon={<CloseIcon />}
                    />
                  ))}
                </Box>
              )}
            >
              {roleslist?.map((item) => (
                <MenuItem
                  key={item}
                  value={item}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    // You can add more styles here if needed
                  }}
                >
                  {item}
                </MenuItem>
              ))}
            </Select> */}

            {/* <div style={{ width: "71.5%", margin: "0", padding: "0" }}>
              <Select
                isMulti
                id="roles"
                placeholder={false}
                aria-label="roles"
                options={roleslist}
                className="basic-multi-select"
                classNamePrefix="select"
                defaultValue={rolesArray}
                hideSelectedOptions={false}
                noOptionsMessage={() => "No options available"}
                onChange={(event) => handleDropdownChange(event, "roles")}
              />
            </div> */}
          {/* </div> */}

          {/* {domainArray.length > 0 && (
            <div className="enteredText" style={{ margin: "10px 0 0 0" }}>
              <ul
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                {domainArray.map((data, index) => (
                  <li key={index}>
                    {data}
                    <span
                      className="cancel"
                      onClick={() => handleDelete(index)}
                    >
                      x
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          )} */}

          {showEmptyFieldMessage && (
            <Typography
              sx={{ fontSize: "14px", color: "red", margin: "5px 5px" }}
            >
              Please enter the name
            </Typography>
          )}
        </DialogContent>
        <Box sx={{ pb: 2, px: 2 }}>
          <DialogActions sx={{ justifyContent: "center" }}>
            <button
              // disabled={  values.email.trim() === ""}
              className="rounded-md job-btn btn width-72p"
              // fullWidth
              // variant="contained"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}

export default AddNewUser;
