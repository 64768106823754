import { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Box,
  Chip,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  Grid,
  DialogTitle,
  // TableContainer,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useValue } from "../../context/ContextProvider";
import axios from "axios";
import { userRequestMartbuilder } from "../../requestMethod";
import { AuthContext } from "../../AuthContext";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

function UserDetailsModal({ boolean, onOpen, email }) {
  const [selectedOption, setSelectedOption] = useState(""); // to keep track of the selected option

  const [emailID, setEmailID] = useState();
  const [emailName, setEmailName] = useState();

  const [userData, setUserData] = useState({}); //to store the userdata from fetch user data
  const [userMail, setUserMail] = useState();
  const [roleData, setRoleData] = useState();
  const [orgModelData, setOrgModelData] = useState();
  const [domainData, setDomainData] = useState();

  const [showTable, setShowTable] = useState(0);

  const { getToken, token, error } = useContext(AuthContext);

  const {
    dispatch,
    state: { showCreateJob, jobDetails, sourceConnectionName },
  } = useValue();
  const navigate = useNavigate();

  // console.log(selectedOption);
  // const handleChange = (e) => {
  //   const selectedValue = e.target.value;
  //   setSelectedOption(selectedValue);
  //   console.log(selectedValue);
  //   if (selectedValue === "Mart Builder") {
  //     // console.log("jobDetails jobDetails", jobDetails)
  //     dispatch({ type: "HIDE_INGESTION_JOB_FORM" });
  //     dispatch({ type: "CLEAR_JOB_DETAILS" });
  //     dispatch({ type: "CLEAR_SOURCE_CONNECTION_NAME" });
  //     // console.log("sourceConnectionName", sourceConnectionName)
  //     navigate("/createMartBuilderJob");
  //   } else if (selectedValue === "Batch Ingestion") {
  //     navigate("/createBatchIngestionJob");
  //   } else if (selectedValue === "Streaming Sync") {
  //     navigate("/createStreamingSyncJob");
  //   }
  // };

  //   const handleSubmit = () => {
  //     if (!selectedOption) {
  //       setShowEmptyFieldMessage(true);
  //       setTimeout(() => {
  //         setShowEmptyFieldMessage(false);
  //       }, 2000); // Show the message for 2 seconds
  //       return;
  //     }
  //     onOpen();
  //     // AddBusinessData(values);
  //   };

  // Fetching values for users dropdown
  const fetchUsersData = async () => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(`/aatusers/${email}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });
      setUserData(response?.data);
      // console.log(response?.data);
      setUserMail(response?.data.emailId)
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  const fetchRolesData = async (email) => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(
        `/user_role/user_permission/user/${email}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      );
      setRoleData(response?.data);
      // console.log("role data", response?.data);
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  const handleEmailChange = (event) => {
    setEmailID(event.target.value);
    setEmailName(event.target.name);
  };

  useEffect(() => {
    (async () => await getToken())();
    fetchUsersData();
  }, [token]);

  useEffect(()=>{
    fetchRolesData(userMail);
  },[userMail])

  return (
    <div>
      <Dialog open={boolean} onClose={onOpen} fullWidth maxWidth="md">
        <DialogContent style={{ width: "900px" }}>
          <Grid container mb={3}>
            <Grid item xs={11}>
              <Typography variant="h6" component="h2">
                User Permission Details
              </Typography>
            </Grid>
            <Grid color="text.secondary" item xs={1}>
              <CloseIcon
                onClick={onOpen}
                sx={{
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 600,
              }}
            >
              E-mail
            </Typography>
            {/* <Select
              style={{ width: "71.5%", margin: "0", padding: "0" }}
              labelId="email"
              id="email"
              size="small"
              // multiple
              value={emailName}
              onChange={handleEmailChange}
              // input={<OutlinedInput id="select-multiple-chip" label="Application" />}
              // renderValue={(selected) => (
              //   <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              //     {selected.map((value) => (
              //       <Chip key={value} label={value} />
              //     ))}
              //   </Box>
              // )}
              // MenuProps={MenuProps}
            >
              {userData?.map((useData) => (
                <MenuItem
                  key={useData.id}
                  value={useData.id}
                  name={useData.emailId}
                  // style={getStyles(name, personName, theme)}
                >
                  {useData.emailId}
                </MenuItem>
              ))}
            </Select> */}
            <TextField
              margin="dense"
              // label="E-mail"
              name="email_id"
              value={userData?.emailId || ""}
              disabled={true}
              // onChange={handleChange}
              // fullWidth
              style={{ width: "71.5%" }}
              // size="small"
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "1rem 0.2rem",
              borderBottom: "1px solid black",
            }}
          >
            <Typography
              style={{
                flex: 1,
                color: "black !important",
                fontWeight: 800,
              }}
            >
              Roles
            </Typography>
          </div>

          {roleData?.roles?.map((policy, index) => {
            return (
              <div
                key={index}
                style={{
                  width: "100%",
                  height: "auto",
                  margin: "20px 0px",
                  padding: "28px 16px",
                  border: "2px solid lightgray",
                  borderRadius: "4px",
                  backgroundColor: "#ffffff",
                  // boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                  // transition: "0.3s",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "20px 0px",
                  }}
                >
                  <Typography
                    style={{
                      flex: 1,
                      color: "black !important",
                      fontWeight: 600,
                    }}
                  >
                    Mapping {index + 1}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    gap: "1rem",
                  }}
                >
                  <div
                    style={{
                      maxWidth: "285px",
                      maxHeight: "80px",
                      marginBottom: "1rem",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {/* <label htmlFor="role">Role</label> */}
                    <Typography
                      style={{
                        flex: 1,
                        color: "black !important",
                        fontWeight: 400,
                      }}
                    >
                      Role
                    </Typography>
                    {/* <Select
                      // style={{ width: "71.5%", margin: "0", padding: "0" }}
                      labelId="role"
                      id="role"
                      size="small"
                      // multiple
                      // value={roleName}
                      value={policyFields[index].role}
                      // onChange={handleRoleChange}
                      // onChange={(event) =>
                      //   handlePolicyChange(event, index, "role")
                      // }
                      // input={<OutlinedInput id="select-multiple-chip" label="Application" />}
                      // renderValue={(selected) => (
                      //   <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      //     {selected.map((value) => (
                      //       <Chip key={value} label={value} />
                      //     ))}
                      //   </Box>
                      // )}
                      // MenuProps={MenuProps}
                    >
                      {roleData?.map((rolData) => (
                        <MenuItem
                          key={rolData.id}
                          value={rolData.id}
                          name={rolData.name}
                          // style={getStyles(name, personName, theme)}
                        >
                          {rolData.name}
                        </MenuItem>
                      ))}
                    </Select> */}
                    <TextField
                      margin="dense"
                      // label="Role"
                      labelId="role"
                      id="role"
                      disabled={true}
                      value={policy.name}
                      // onChange={handleRoleChange}
                      // fullWidth
                      // style={{ width: "71.5%" }}
                      size="small"
                    />
                  </div>

                  <div
                    style={{
                      maxWidth: "285px",
                      maxHeight: "80px",
                      marginBottom: "1rem",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      style={{
                        flex: 1,
                        color: "black !important",
                        fontWeight: 400,
                      }}
                    >
                      By Maersk Model
                    </Typography>
                    {/* <label htmlFor="byMaerskModel">By Maersk Model</label> */}
                    {/* <Select
                      // style={{ width: "71.5%", margin: "0", padding: "0" }}
                      labelId="byMaerskModel"
                      id="byMaerskModel"
                      size="small"
                      multiple
                      value={policyFields[index].byMaerskModel}
                      // onChange={(event) =>
                      //   handlePolicyChange(event, index, "byMaerskModel")
                      // }
                      // input={<OutlinedInput id="select-multiple-chip" label="Application" />}
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                      // MenuProps={MenuProps}
                    >
                      {orgModelData?.map((org) => (
                        <MenuItem
                          key={org.fqn}
                          value={org.fqn}
                          name={org.name}
                          // style={getStyles(name, personName, theme)}
                        >
                          {org.name}
                        </MenuItem>
                      ))}
                    </Select> */}
                    <TextField
                      margin="dense"
                      // label="By Maersk Model"
                      labelId="byMaerskModel"
                      id="byMaerskModel"
                      disabled={true}
                      // value={policyFields[index].byMaerskModel}
                      value={policy.byOrgModels}
                      // onChange={handleRoleChange}
                      // fullWidth
                      // style={{ width: "71.5%" }}
                      size="small"
                    />
                  </div>

                  <div
                    style={{
                      maxWidth: "285px",
                      maxHeight: "80px",
                      marginBottom: "1rem",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      style={{
                        flex: 1,
                        color: "black !important",
                        fontWeight: 400,
                      }}
                    >
                      Domain
                    </Typography>
                    {/* <label htmlFor="domains">Domain</label> */}
                    {/* <Select
                      // style={{ width: "71.5%", margin: "0", padding: "0" }}
                      labelId="domains"
                      id="domains"
                      size="small"
                      multiple
                      value={policyFields[index].domains}
                      // onChange={(event) =>
                      //   handlePolicyChange(event, index, "domains")
                      // }
                      // input={<OutlinedInput id="select-multiple-chip" label="Application" />}
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                      // MenuProps={MenuProps}
                    >
                      {domainData?.map((dom) => (
                        <MenuItem
                          key={dom.fqn}
                          value={dom.fqn}
                          name={dom.name}
                          // style={getStyles(name, personName, theme)}
                        >
                          {dom.name}
                        </MenuItem>
                      ))}
                    </Select> */}
                    <TextField
                      margin="dense"
                      // label="Domain"
                      labelId="domains"
                      id="domains"
                      disabled={true}
                      // value={policyFields[index].domains}
                      value={policy.domains.toString()}
                      // onChange={handleRoleChange}
                      // fullWidth
                      // style={{ width: "71.5%" }}
                      size="small"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "1rem 0",
                  }}
                >
                  <Typography
                    style={{
                      flex: 1,
                      color: "black !important",
                      fontWeight: 600,
                    }}
                  >
                    Description
                  </Typography>
                  {/* <Select
                    style={{ width: "71.5%", margin: "0", padding: "0" }}
                    labelId="description"
                    id="description"
                    size="small"
                    // multiple
                    value={policyFields[index].description}
                    onChange={handleEmailChange}
                    // input={<OutlinedInput id="select-multiple-chip" label="Application" />}
                    // renderValue={(selected) => (
                    //   <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    //     {selected.map((value) => (
                    //       <Chip key={value} label={value} />
                    //     ))}
                    //   </Box>
                    // )}
                    // MenuProps={MenuProps}
                  >
                    {userData?.map((useData) => (
                      <MenuItem
                        key={useData.id}
                        value={useData.id}
                        name={useData.emailId}
                        // style={getStyles(name, personName, theme)}
                      >
                        {useData.emailId}
                      </MenuItem>
                    ))}
                  </Select> */}
                  <TextField
                    margin="dense"
                    // label="Description"
                    labelId="description"
                    id="description"
                    disabled={true}
                    value={policy.description}
                    // onChange={handleRoleChange}
                    // fullWidth
                    style={{ width: "71.5%", margin: "0", padding: "0" }}
                    size="small"
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "2rem 0.2rem 0",
                    borderBottom: "1px solid lightgray",
                  }}
                >
                  <Typography
                    style={{
                      flex: 1,
                      color: "black !important",
                      fontWeight: 800,
                    }}
                  >
                    Permissions
                  </Typography>
                  <KeyboardArrowDownIcon
                    style={
                      showTable === index + 1
                        ? { cursor: "pointer", transform: "rotate(180deg)" }
                        : { cursor: "pointer" }
                    }
                    onClick={() =>
                      showTable === 0 || showTable !== index + 1
                        ? setShowTable(index + 1)
                        : setShowTable(0)
                    }
                  />
                </div>

                {showTable === index + 1 && (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          {/* <TableCell align="right">Applications</TableCell> */}
                          <TableCell>Applications</TableCell>
                          <TableCell>Access Flag</TableCell>
                          <TableCell>Resource</TableCell>
                          <TableCell>Operation</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* {rows?.map((row) => ( */}
                        {policy.permissions?.map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell>{row.applicationName}</TableCell>
                            <TableCell>{row.accessFlag}</TableCell>
                            <TableCell>
                              {row.resources?.map((reso) => (
                                <pre>{reso.name}</pre>
                              ))}
                            </TableCell>
                            <TableCell>
                              {row.operations?.map((opera) => (
                                <pre>{opera}</pre>
                              ))}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </div>
            );
          })}

          {/* <RadioGroup
            name="jobType"
            value={selectedOption}
            onChange={handleChange}
          >
            <FormControlLabel
              value="Batch Ingestion"
              control={<Radio />}
              label="Batch Ingestion"
            />
            <FormControlLabel
              value="Mart Builder"
              control={<Radio />}
              label="Mart Builder"
            />
            <FormControlLabel
              value="Streaming Sync"
              control={<Radio />}
              label="Streaming Sync"
            />
            <FormControlLabel
              value="Streaming Transformation"
              control={<Radio />}
              label="Streaming Transformation"
            />
          </RadioGroup> */}
        </DialogContent>
        {/* <Box sx={{ pb: 2, px: 2 }}>
          <DialogActions>
            <Button
              fullWidth
              variant="contained"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </DialogActions>
        </Box> */}
      </Dialog>
    </div>
  );
}

export default UserDetailsModal;
