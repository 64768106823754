import React, {
  useCallback,
  useMemo,
  useEffect,
  useState,
  useContext,
} from "react"
import { MaterialReactTable } from "material-react-table"
import { useNavigate } from "react-router-dom"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material"
import axios from "axios"
import { Delete, Edit, Info } from "@mui/icons-material"
import { data } from "./makeData"
import { useValue } from "../../context/ContextProvider"
import { useDispatch } from "react-redux"
// import { useNavigate } from "react-router-dom";
// import { useValue } from "../../context/ContextProvider";
import {
  userRequestEmpConsumerAPI,
  userRequestMartbuilder,
} from "../../requestMethod"
import { AuthContext } from "../../AuthContext"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline"
import streamingData from "./streamingData"

const StreamingSyncTable = () => {
  const [jobs, setJobs] = useState([])
  const [tableData, setTableData] = useState(() => jobs)
  const { getToken, token, error } = useContext(AuthContext)
  const [Dag, setDag] = useState()
  const navigate = useNavigate()

  const {
    state: { jobTypeModal, jobDetails },
  } = useValue()

  // useEffect(()=>{
  //   setJobs(streamingData)
  // },[streamingData])

  const dispatch = useDispatch()

  const tokenw = localStorage.getItem("userToken")

  try {
    // console.log(tokenw)
  } catch (e) {
    console.error("Error parsing JSON from local storage:", e)
  }

  const payload = { userEmail: "prabhat.lodhi@maersk.com" }


  // Fetching StreamingJob
  const fetchStreamingJobs = async () => {
    dispatch({ type: "START_CIRCULAR_LOADING" })
    const ourRequest = axios.CancelToken.source()
    console.log(payload, "emp-api-payload")
    try {
      let response = await userRequestEmpConsumerAPI.post(
        `/e2e/retrieveTenantDetailsByUser`,
        payload,
        {
          headers: {
            // Define your custom headers here
            "Access-Control-Allow-Origin": "http://localhost:3000",
            Authorization: `Bearer ${tokenw}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        }
      )

      setJobs(response.data)
      console.log(response.data, "<=== emp response")
      dispatch({ type: "END_CIRCULAR_LOADING" })
    } catch (err) {
      if (axios.isCancel(err)) {
        return
      } else {
        console.error(err)
      }
    }
    // fetchJobs();
    return () => {
      ourRequest.cancel()
    }
  }

  useEffect(() => {
    let timeoutId
    const fetchDataWithDelay = () => {
      timeoutId = setTimeout(() => {
        fetchStreamingJobs()
      }, 800)
    }
    fetchDataWithDelay()
    return () => {
      clearTimeout(timeoutId)
    }
  }, [navigate, tableData])


  const handleDeleteRow = useCallback(
    (row) => {
      if (
        alert(`Are you sure you want to delete ${row.getValue("firstName")}`)
      ) {
        return
      }
      //send api delete request here, then refetch or update local table data for re-render
      tableData.splice(row.index, 1)
      setTableData([...tableData])
    },
    [tableData]
  )

  const columns = useMemo(() => [
    {
      accessorKey: "topicName",
      header: "Topic Name",
      enableColumnOrdering: false,
      enableEditing: false, //disable editing on this column
      enableSorting: false,
      size: 80,
    },
    {
      accessorKey: "consumerGroup",
      header: "Consumer Group",
      size: 140,
    },
    {
      accessorKey: "status",
      header: "Status",
      Cell: ({ cell }) => {
        return cell.getValue() === 1 ? (
          <Tooltip arrow placement="right" title="Play">
            <PlayArrowIcon />
          </Tooltip>
        ) : (
          <Tooltip arrow placement="right" title="Stop">
            <PauseCircleOutlineIcon />
          </Tooltip>
        )
      },
    },
    {
      accessorKey: "insertTs",
      header: "Created",
      size: 80,
    },
  ])

  return (
    <>
      <div
        style={{
          width: "90%",
          margin: "0px auto",
        }}
      >
        <MaterialReactTable
          displayColumnDefOptions={{
            "mrt-row-actions": {
              muiTableHeadCellProps: {
                align: "center",
              },
            },
          }}
          initialState={{
            density: "compact",
            pagination: { pageSize: 10, pageIndex: 0 },
          }}
          columns={columns}
          data={jobs}
          editingMode="modal" //default
          enableColumnOrdering
          enableEditing
          positionActionsColumn="last"
          renderRowActions={({ row, table }) => (
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Tooltip arrow placement="left" title="Edit">
                {/* <IconButton
                  onClick={() =>
                    navigate(`/Batchview/${Dag}`, { state: row.original.id })
                  }
                >
                  <Info />
                </IconButton> */}
                <IconButton onClick={() => table.setEditingRow(row)}>
                  <Edit />
                </IconButton>
              </Tooltip>
              {/* <Tooltip arrow placement="right" title="Delete">
                <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                  <Delete />
                </IconButton>
              </Tooltip> */}
            </Box>
          )}
        />
      </div>
    </>
  )
}

export default StreamingSyncTable
