import React from "react";
import { useState, useEffect, useContext } from "react";
import { useFormContext, Controller } from "react-hook-form";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import { userRequestMartbuilder } from "../../requestMethod";
import { AuthContext } from "../../AuthContext";

const BasicInfoForm = () => {
  const [productTableData, setProductTableData] = useState();
  const { getToken, token, error } = useContext(AuthContext);
  //for product
  const fetchProductTableData = async () => {
    const ourRequest = axios.CancelToken.source();
    try {
      let response = await userRequestMartbuilder.get(`/product`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        CancelToken: ourRequest.token,
      });
      setProductTableData(response?.data);
      console.log(response.data);
      // dispatch({ type: "PRODUCT_DATA", payload: response.data });
    } catch (err) {
      if (axios.isCancel(err)) {
        return;
      } else {
        console.error(err);
      }
    }
    return () => {
      ourRequest.cancel();
    };
  };

  useEffect(() => {
    (async () => await getToken())();
    fetchProductTableData();
  }, [token]);

  const { control } = useFormContext();
  return (
    <div
      style={{
        marginTop: "40px",
        // display: "grid",
        // gridTemplateColumns: "1fr 1fr",
        // columnGap: "60px",
        marginBottom: "20px",
        padding: "5px 45px",
        paddingBottom:"180px"
      }}
    >
      <Controller
        control={control}
        name="name"
        render={({ field }) => (
          <TextField
            id="job-name"
            label="Job Name"
            variant="outlined"
            placeholder="Enter Your Job Name"
            fullWidth
            size="small"
            margin="normal"
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="description"
        render={({ field }) => (
          <TextField
            id="job-description"
            label="Job Description"
            variant="outlined"
            placeholder="Job Description"
            fullWidth
            margin="normal"
            size="small"
            {...field}
          />
        )}
      />

      <div
        style={{
          marginTop: "15px",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          columnGap: "150px",
        }}
      >
        <Controller
          control={control}
          name="product.id"
          render={({ field }) => (
            <FormControl
              size="small"
              variant="outlined"
              sx={{ mb: 2, mt: 2, minWidth: 120 }}
            >
              <InputLabel id="select-product">Product</InputLabel>
              <Select
                labelId="select-product"
                id="product"
                label="product"
                {...field}
              >
                {productTableData
                  ?.sort((a, b) =>
                    a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1
                  )
                  .map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="executionEngine"
          render={({ field }) => (
            <FormControl
              size="small"
              variant="outlined"
              sx={{ mb: 2, mt: 2, minWidth: 120 }}
            >
              <InputLabel id="select-executionEngine">
                Execution Engine
              </InputLabel>
              <Select
                labelId="select-executionEngine"
                id="executionEngine"
                label="Execution Engine"
                {...field}
              >
                <MenuItem value="databricks">databricks</MenuItem>
                <MenuItem value="dremio">dremio</MenuItem>
                <MenuItem value="sparkOnK8s">sparkOnK8s</MenuItem>
              </Select>
            </FormControl>
          )}
        />
      </div>
    </div>
  );
};

export default BasicInfoForm;
