import React, { useMemo } from "react"; 
import MainUITable from "./MainUITable";
import { 
  Select,
  MenuItem, 
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton, 
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useState, useEffect, useContext } from "react"; 
import { Link } from "react-router-dom";
// import "./ManageProjects.css"; 
import { AuthContext } from "../../../AuthContext";
import { userRequestMartbuilder } from "../../../requestMethod";
import { useValue } from "../../../context/ContextProvider"; 
 
import axios from "axios";
import { Delete, Edit, CheckCircle, Cancel } from "@mui/icons-material";
import StatusModal from "../../statusModal/StatusModal";
import CircularLoader from "../../Atoms/CircularLoader";

const RunStatusCell = ({ cell }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [runs, setRuns] = useState([]);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [status, setStatus] = useState({});
  const { token } = useContext(AuthContext);

  const dagName = cell.row.original.dagName;

  useEffect(() => {
    const fetchRuns = async () => {
      if (!dagName) return;
      setIsLoading(true);
      try {
        const payload = {
          "METADATA_CRAWLER": [dagName]
        };
        const response = await userRequestMartbuilder.post(
          `/jobs/dag/runs`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        // console.log(response.data, "<===runs")
        setRuns(response.data[dagName]?.dagRuns?.dag_runs || []);
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch runs", error);
        setIsLoading(false);
      }
    };
    
    fetchRuns();
  }, [dagName, token]);

  const handleOpenStatusModal = (item) => {
    console.log(item, "++item")
    setStatus(item);
    setOpenStatusModal(!openStatusModal);
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  
  return (
    <>
      {runs.map((item, index) => (
        <Tooltip key={index} title={item.execution_date}>
          {item.state === "success" ? (
            <CheckCircle color="success" onClick={() => handleOpenStatusModal(item)} />
          ) : (
            <Cancel color="error" onClick={() => handleOpenStatusModal(item)} />
          )}
        </Tooltip>
      ))} 
      {openStatusModal && (
                <StatusModal
                  onOpen={handleOpenStatusModal}
                  boolean={openStatusModal}
                  Item={status}
                  jobType={"METADATA_CRAWLER"}
                />
              )}
    </>
  );
};


const TableDataFetching = ({ jobFqn,singleRowFetchData,isAutoCrawlSelected,storedToken }) => {  

  //token for new axios 
  const { getToken, token, error } = useContext(AuthContext);

  //showhide run status modal
  const [showRunStatus, setShowRunStatus] = useState(false);
  //for sending as a prop single row data
  const [singleRowData, setSingleRowData] = useState();

  //state for table
  const [crawlMetrics, setCrawlMetrics] = useState(); 
  const [ tableRow, setTableRow] = useState()
  //for handling the select dropdown
  const [dropdownValue, setDropdownValue] = useState("status");

  useEffect(() => {
    // Only set the tableRow state if singleRowFetchData is not null or undefined
    if(singleRowFetchData) {
      setTableRow(Array.isArray(singleRowFetchData) ? singleRowFetchData : [singleRowFetchData]);
    }
  }, [singleRowFetchData]);
  

  useEffect(() => { 
    if (!jobFqn) {
      console.log("Skipping API call because jobFqn is undefined");
      return;
    }
    const fetchMetaDataMetrics = async () => {
      const ourRequest = axios.CancelToken.source();
      try { 
        let response = await userRequestMartbuilder.get(`/metrics/metrics/metadata_crawl/${jobFqn}/${dropdownValue}`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
            "Content-Type": "application/json",
          },
          CancelToken: ourRequest.token,
        });
        // console.log(response?.data, "<===table fetch"); 
        setCrawlMetrics(Array.isArray(response.data) ? response.data : [response.data]); 
      } catch (err) {
        if (axios.isCancel(err)) {
          console.log("API call to fetch meta data metrics was cancelled");
        } else {
          console.error("Error fetching meta data metrics:", err);
        }
      }
      return () => {
        ourRequest.cancel("Cancelling in cleanup");
      };
    };
    fetchMetaDataMetrics();
  }, [dropdownValue, jobFqn]);

  // useEffect(()=>{ console.log("dropdown")  },[dropdownValue])

  const columns = useMemo(() => [
    {
      accessorKey: "jobId",
      header: "Job Name",
      size: 140,
    },
    {
      accessorKey: "objectId",
      header: "Object Id",
      size: 140,
    },
    {
      accessorKey: "batchDate",
      header: "Batch Date",
      size: 140,
    },
    {
      accessorKey: "metricKey",
      header: "Key",
      size: 140,
    },
    {
      accessorKey: "metricValue",
      header: "Value",
      size: 140,
    },
    {
      accessorKey: "comments",
      header: "Comment",
      size: 140,
    },
  ]);

  const columnsForStatus = useMemo(() => [
    {
      accessorKey: "dagName",
      header: "Dag Name",
      size: 140,
    },
    {
      accessorKey: "cronSchedule",
      header: "Schedule",
      size: 140,
    },
    {
      accessorKey: "lastnRuns",
      header: "Run Status",
      size: 70,
      Cell: ({ cell }) => <RunStatusCell cell={cell} />,
    }, 
  ]);
 
 

  // Handle dropdown change
  const handleDropdownChange = (e) => {
    setDropdownValue(e.target.value);
  };

  const runStatusCheck = (runStatus) => {
    setSingleRowData(runStatus);
    setShowRunStatus(!showRunStatus);
  };

  return (
    <div > 
      <div className="wrapper" style={{paddingTop:"50px"}} >
        <div className="table_wrapper" style={{ width: "100%",  }}>

            { isAutoCrawlSelected &&
              (<div style={{ 
                // position:"relative",
                // right:"30px",
                width: "100%",
                }}>
                <h3 id="maerskModels" style={{marginBottom:"20px"}} >Metadata-Crawl Status</h3> 
                 
                   {!tableRow ? (
                  <CircularLoader />
                ) : (
                  <MainUITable
                    columns={columnsForStatus}
                    data={tableRow}
                  />
                )}
              </div>)
            }


          <div
            style={{
              margin: "25px 0px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          > 
            <div style={{  
              width: "100%",
                // border:"1px solid green"
              }}>
                <div style={{display:"flex", justifyContent:"space-between", marginBottom:"20px"}} >
                <h3 id="maerskModels">Metadata-Crawl Metrics</h3> 

                <Select
                    sx={{ width: "220px",marginRight:"30px"}}
                    size="small"
                    // name="adlsFileFormat"  
                    value={dropdownValue}
                    onChange={handleDropdownChange}
                    // id="file-format-select"
                    // labelId="file-format-label"  
                    // disabled={disableFieldProp}
                  >
                    <MenuItem value="status">Status</MenuItem>
                    <MenuItem value="timeTaken">Time Taken</MenuItem>
                    <MenuItem value="datasetsInferred">Datasets Inferred</MenuItem>
                    <MenuItem value="colsInferred">Column Inferred</MenuItem> 
                </Select>

                </div> 
                {!crawlMetrics ? (
                <CircularLoader />
              ) : (
                <MainUITable 
                dropdownValue={dropdownValue} 
                  columns={columns}
                  data={crawlMetrics}
                />
              )}
            </div>
           
          </div>
        </div>
      </div> 
    </div>
  );
};

export default TableDataFetching;
