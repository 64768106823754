import { Info } from "@mui/icons-material";
import "./IngestionForm.css";
import React, { useEffect } from "react";
import { useValue } from "../../context/ContextProvider";

const Banner = () => {
  const { dispatch } = useValue();
  useEffect(() => {
    dispatch({ type: "SHOW_FOLDERTREE" });
  }, []);
  return (
    <div className="banner-main"
      // style={{
      //   display: "flex",
      //   background: "#fcd29f",
      //   alignItems: "center",
      //   height: "200px",
      //   padding: "10px 45px",
      //   margin: "14px",
      // }}
    >
      <Info fontSize="small" />
      <p
        style={{
          paddingLeft: "4px",
        }}
      >
        Please select the Dataset first from the side panel, to proceed further.
      </p>
    </div>
  );
};

export default Banner;
