export const data = [
  {
    jobName: "Job 1",
    description: "Job 1 description",
    type: "Recur",
    Project: "Project 1",
    dag: "DAG_ID",
    runStatus: [1, 1, 1, 0, 0],
    updatedBy: "sam@makes.com",
    updatedAt: "2023-03-30",
    creationStatus: "2023-03-30",
  },
  {
    jobName: "Job 2",
    description: "Job 2 description",
    type: "Recur",
    Project: "Project 2",
    dag: "DAG_ID",
    runStatus: [1, 1, 1, 0, 0],
    updatedBy: "sam@makes.com",
    updatedAt: "2023-03-30",
    creationStatus: "2023-03-30",
  },
  {
    jobName: "Job 3",
    description: "Job 3 description",
    type: "Recur",
    Project: "Project 3",
    dag: "DAG_ID",
    runStatus: [1, 1, 1, 0, 0],
    updatedBy: "sam@makes.com",
    updatedAt: "2023-03-30",
    creationStatus: "2023-03-30",
  },
  {
    jobName: "Job 4",
    description: "Job 4 description",
    type: "Recur",
    Project: "Project 4",
    dag: "DAG_ID",
    runStatus: [1, 1, 1, 0, 0],
    updatedBy: "sam@makes.com",
    updatedAt: "2023-03-30",
    creationStatus: "2023-03-30",
  },
  {
    jobName: "Job 5",
    description: "Job 5 description",
    type: "Recur",
    Project: "Project 5",
    dag: "DAG_ID",
    runStatus: [1, 1, 1, 0, 0],
    updatedBy: "sam@makes.com",
    updatedAt: "2023-03-30",
    creationStatus: "2023-03-30",
  },
  {
    jobName: "Job 6",
    description: "Job 6 description",
    type: "Recur",
    Project: "Project 6",
    dag: "DAG_ID",
    runStatus: [1, 1, 1, 0, 0],
    updatedBy: "sam@makes.com",
    updatedAt: "2023-03-30",
    creationStatus: "2023-03-30",
  },
  {
    jobName: "Job 7",
    description: "Job 7 description",
    type: "Recur",
    Project: "Project 7",
    dag: "DAG_ID",
    runStatus: [1, 1, 1, 0, 0],
    updatedBy: "sam@makes.com",
    updatedAt: "2023-03-30",
    creationStatus: "2023-03-30",
  },
  {
    jobName: "Job 8",
    description: "Job 8 description",
    type: "Recur",
    Project: "Project 8",
    dag: "DAG_ID",
    runStatus: [1, 1, 1, 0, 0],
    updatedBy: "sam@makes.com",
    updatedAt: "2023-03-30",
    creationStatus: "2023-03-30",
  },
  {
    jobName: "Job 9",
    description: "Job 9 description",
    type: "Recur",
    Project: "Project 9",
    dag: "DAG_ID",
    runStatus: [1, 1, 1, 0, 0],
    updatedBy: "sam@makes.com",
    updatedAt: "2023-03-30",
    creationStatus: "2023-03-30",
  },
  {
    jobName: "Job 10",
    description: "Job 10 description",
    type: "Recur",
    Project: "Project 10",
    dag: "DAG_ID",
    runStatus: [1, 1, 1, 0, 0],
    updatedBy: "sam@makes.com",
    updatedAt: "2023-03-30",
    creationStatus: "2023-03-30",
  },
  {
    jobName: "Job 11",
    description: "Job 11 description",
    type: "Recur",
    Project: "Project 11",
    dag: "DAG_ID",
    runStatus: [1, 1, 1, 0, 0],
    updatedBy: "sam@makes.com",
    updatedAt: "2023-03-30",
    creationStatus: "2023-03-30",
  },
  {
    jobName: "Job 12",
    description: "Job 12 description",
    type: "Recur",
    Project: "Project 12",
    dag: "DAG_ID",
    runStatus: [1, 1, 1, 0, 0],
    updatedBy: "sam@makes.com",
    updatedAt: "2023-03-30",
    creationStatus: "2023-03-30",
  },
  {
    jobName: "Job 13",
    description: "Job 13 description",
    type: "Recur",
    Project: "Project 13",
    dag: "DAG_ID",
    runStatus: [1, 1, 1, 0, 0],
    updatedBy: "sam@makes.com",
    updatedAt: "2023-03-30",
    creationStatus: "2023-03-30",
  },
  {
    jobName: "Job 14",
    description: "Job 14 description",
    type: "Recur",
    Project: "Project 14",
    dag: "DAG_ID",
    runStatus: [1, 1, 1, 0, 0],
    updatedBy: "sam@makes.com",
    updatedAt: "2023-03-30",
    creationStatus: "2023-03-30",
  },
]

export const edaData = [
  {
    "col 1": "",
    "col 2": "",
    "col 3": "",
    "col 4": "",
  },
  {
    "col 1": "",
    "col 2": "",
    "col 3": "",
    "col 4": "",
  },
  {
    "col 1": "",
    "col 2": "",
    "col 3": "",
    "col 4": "",
  },
  {
    "col 1": "",
    "col 2": "",
    "col 3": "",
    "col 4": "",
  },
  {
    "col 1": "",
    "col 2": "",
    "col 3": "",
    "col 4": "",
  },
  {
    "col 1": "",
    "col 2": "",
    "col 3": "",
    "col 4": "",
  },
  {
    "col 1": "",
    "col 2": "",
    "col 3": "",
    "col 4": "",
  },
  {
    "col 1": "",
    "col 2": "",
    "col 3": "",
    "col 4": "",
  },
  {
    "col 1": "",
    "col 2": "",
    "col 3": "",
    "col 4": "",
  },
  {
    "col 1": "",
    "col 2": "",
    "col 3": "",
    "col 4": "",
  },
  {
    "col 1": "",
    "col 2": "",
    "col 3": "",
    "col 4": "",
  },
]
