import { Button, Step, StepLabel, Stepper } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import "../stepperForm/Expand.css";
import BasicDetails from "../IngestionForm/BasicDetails";
import ColumnsTransformation from "../IngestionForm/ColumnsTranformation";
import ScheduleDetails from "../IngestionForm/ScheduleDetails";
import SourceConnection from "../IngestionForm/SourceConnection";
import DestinationConnection from "../IngestionForm/DestinationConnection";
import ReviewDetails from "../IngestionForm/ReviewDetails";
import TableData from "../IngestionForm/TableData";
import AdvanceInfoForm from "../advanceInfoForm/AdvanceInfoForm";
import TaskForm from "../taskForm/TaskForm";
import BackdropLoader from "../Atoms/BackdropLoader";
import axios from "axios";
import {
  userRequestDataBook,
  userRequestMartbuilder,
} from "../../requestMethod";
import { useNavigate } from "react-router-dom";
import { useValue } from "../../context/ContextProvider";
import FormTaskSection from "../IngestionForm/FormTaskSection";
import { Info } from "@mui/icons-material";
import Banner from "../IngestionForm/Banner";
import { AuthContext } from "../../AuthContext";

function getSteps() {
  return [
    "Source Connection",
    "Destination Connection",
    "Job Details",
    // "Basic",
    "Columns Transformation",
    // "Review Details",
  ];
}

function getStepContent(step, activeStep, handelBack) {
  switch (step) {
    case 0:
      return <SourceConnection />;
    case 1:
      return <DestinationConnection />;
    // case 2:
    //   return <ScheduleDetails />;
    // case 3:
    //   return <BasicDetails />;
    // case 4:
    //   return <ColumnsTransformation />;
    default:
      return "unknown step";
  }
}

const IngestionStepperForm = () => {
  const {
    dispatch,
    state: { datasetDetails, dataBookToken },
  } = useValue();
  const { getToken, token, error,getDatabookToken, DCtoken  } = useContext(AuthContext);

  // console.log(datasetDetails)
  // const [collectedData, setCollectedData] = useState({});
  // console.log(jobDetails);
  // const dataPresent = !!jobDetails;

  // FETCHING DATASOURCE
  useEffect(() => {
    const ourRequest = axios.CancelToken.source();

    const fetchDataSource = async () => {
      try {
        let response = await userRequestDataBook.get(
          `/databook/datasets/sources`,
          {
            headers: {
              // Define your custom headers here
              Authorization: `Bearer ${DCtoken}`,
              "Content-Type": "application/json",
            },
            CancelToken: ourRequest.token,
          }
        );
        // console.log(response.data)
        // setDataSource(response.data)
        dispatch({
          type: "SET_DATASOURCES",
          payload: response.data,
        });
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        } else {
          console.error(err);
        }
      }
    };
    fetchDataSource();
    return () => {
      ourRequest.cancel();
    };
  }, []);

  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const steps = getSteps();

  const handelNext = () => {
    setActiveStep(activeStep + 1);
  };
  const handelBack = () => {
    setActiveStep(activeStep - 1); 
    dispatch({ type: "HIDE_BATCH_NEXT_BUTTON" });
  };

  // const submitEdit = () => {
  //   const ourRequest = axios.CancelToken.source();
  //   const updatedData = async () => {
  //     try {
  //       let response = await userRequestMartbuilder.put(
  //         `/jobs/${jobDetails.id}`,
  //         collectedData,
  //         {
  //           cancelToken: ourRequest.token,
  //         }
  //       );
  //       // console.log(response.data);
  //     } catch (err) {
  //       if (axios.isCancel(err)) {
  //         return;
  //       } else {
  //         console.error(err);
  //       }
  //     }
  //   };
  //   updatedData();
  // };

  // TODO
  // can divide the functionality b/w next step and on final submit
  const onSubmit = (data) => {
    console.log("ko");
    // return
    console.log(data);

    const ourRequest = axios.CancelToken.source();
    const sendJobData = async () => {
      try {
        let response = await userRequestMartbuilder.post("/jobs", data, {
          headers: {
            // Define your custom headers here
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        console.log(response.data);
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        } else {
          console.error(err);
        }
      }
    };
    sendJobData();
  };

  if (token === undefined) {
    return <BackdropLoader />;
  }
  return (
    <div>
      <Stepper activeStep={activeStep}>
        <Step>
          <StepLabel>Source Connnection</StepLabel>
        </Step>
        <Step>
          <StepLabel>Destination Connnection</StepLabel>
        </Step>
        <Step>
          <StepLabel>Job Details</StepLabel>
        </Step>
        {/* <Step>
          <StepLabel>Basic</StepLabel>
        </Step> */}
        <Step>
          <StepLabel>Columns Transformation</StepLabel>
        </Step>
        {/* <Step>
          <StepLabel>Review Details</StepLabel>
        </Step> */}
      </Stepper>
      {/* {dataPresent ? <button>hihi</button> : "" } */}
      {activeStep < 2 ? (
        <div
          style={{
            maxHeight: "75vh",
            // overflowX: "scroll",
            overflow: "auto",
            // border: "1px solid green",
          }}
        >
          {activeStep === 0 && getStepContent(0)}
          {activeStep === 1 && getStepContent(1)}
          {/* {activeStep === 2 && getStepContent(2)} */}
          <div className="button-tab-jobview">
            <button
              className="rounded-md job-btn-plain btn"
              // variant="text"
              // size="small"
              disabled={activeStep === 0}
              onClick={handelBack}
            >
              Back
            </button>
            {activeStep === 3 ? (
              <button
                className="rounded-md job-btn btn"
                // variant="contained"
                // size="small"
                type="submit"
                // onClick={handelNext}
                // onClick={onSubmit}
              >
                Finish
              </button>
            ) : (
              <button
                className="rounded-md job-btn btn"
                // variant="contained"
                // size="small"
                // disabled={disableNext}
                onClick={handelNext}
                type="submit"
              >
                Next
              </button>
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            maxHeight: "75vh",
            // overflowX: "scroll",
            overflow: "auto",
            // border: "1px solid green",
          }}
        >
          {datasetDetails ? (
            <FormTaskSection
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              handelBack={handelBack}
              globalNext={handelNext}
            />
          ) : (
            <Banner />
          )}
        </div>
      )}
    </div>
  );
};

export default IngestionStepperForm;
