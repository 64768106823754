import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { userRequestMartbuilder } from "../../requestMethod";
import { useValue } from "../../context/ContextProvider";
import BackdropLoader from "../../components/Atoms/BackdropLoader";

import styled from "styled-components";
import { Box, Chip } from "@mui/material";
import JobViewTasks from "../../components/jobViewTask/JobViewTask";
import "./JobView.css";
import { Link } from "react-router-dom";
import { AuthContext } from "../../AuthContext";

export const Name = styled.p`
  width: 135px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.8px;
  text-align: right;
`;

export const Aligner = styled.div`
  display: flex;
  justify-content: start;
  align-items: flex-start;
  gap: 8px;
  padding-top: 8px;
`;

export const Value = styled.div`
  border: 0.4px solid gray;
  padding: 5px;
  border-radius: 4px;
  width: 350px;
  min-height: 25px;
  overflow: scroll;
  font-size: 13.5px;
`;

export const Container = styled.div`
  padding: 15px;
  padding-inline: 6%;
  display: flex;
  flex-direction: column;
  height: 85vh;
  overflow-y: scroll;
`;

export const Title = styled.h3`
  font-size: 20px;
  color: #d7d7d7;
  letter-spacing: 0.5px;
  font-weight: 700;
  margin-left: 11px;
`;

export const ChartsDiv = styled.div`
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0px;
  margin-bottom: 100px;
`;

export const MetricsDiv = styled.div`
  height: 85vh;
  /* margin-left: 10px; */
  padding-left: 45px;
  width: 645px;
  margin: 10px;
  display: flex;
  /* margin-top: 120px; */
  margin-bottom: 100px;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: center; */
`;

export const Divider = styled.div`
  height: 160px;
  width: 1px;
  border: none;
  background: linear-gradient(
    180deg,
    rgba(217, 25, 32, 1) 0%,
    rgba(217, 25, 32, 0.8786108193277311) 48%,
    rgba(217, 25, 66, 0.19793855042016806) 100%
  );
`;

export const Heading = styled.p`
  font-size: 14px;
  letter-spacing: 1px;
  padding: 5px;
  color: #cfcfcf;
  margin-bottom: 3px;
`;

export const Chips = styled.li`
  width: fit-content;
  background: #e8e8e8;
  font-weight: 300;
  font-size: 12px;
  color: #6b6b6b;
  padding: 1.5px 11px;
  border: 0.4px solid #6b6b6b;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 5px;
`;

const JobView = () => {
  const params = useParams();
  const id = params.jobId;
  const {
    dispatch,
    state: {
      backdropLoading,
      jobDetails,
      martBuilderEditJobId,
      toggleEditButton,
    },
  } = useValue();
  const { getToken, token, error } = useContext(AuthContext);
  // console.log(token, "hello");
const [jobName, setJobName] = useState()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const ourRequest = axios.CancelToken.source();
    dispatch({ type: "START_BACKDROP_LOADING" });
    const fetchPendingApprovalJobs = async () => {
      //console.log(martBuilderEditJobId, "martBuilderEditJobId");
      try {
        let response = await userRequestMartbuilder.get(
          `/jobs/${martBuilderEditJobId}`,
          {
            headers: {
              // Define your custom headers here
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            CancelToken: ourRequest.token,
          }
        );
        // setJobDetails(response.data);
        dispatch({ type: "SET_JOB_DETAILS", payload: response.data });
        dispatch({ type: "END_BACKDROP_LOADING" });
        setJobName(response?.data?.name)
        console.log(response.data);
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        } else {
          console.error(err);
        }
      }
    };
    fetchPendingApprovalJobs();
    return () => {
      ourRequest.cancel();
    };
  }, []);
  // console.log(jobDetails);

  if (backdropLoading) {
    return <BackdropLoader />;
  }
  return (
    <Container>
      <div>
        {/* <JobViewDetails fqn={fqn} /> */}
        <div
          style={{
            width: "80vw",
            margin: "10px",
            padding: "10px",
            border: "1.5px solid #eaeaea",
            borderRadius: "4px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Title id='details'>Details</Title>
            <Box className='button-box'>
              {toggleEditButton && (
                <Link to={`/jobView/${jobName}/edit`}>
                  <button className='edit-button'>Edit</button>
                </Link>
              )}
            </Box>
          </div>
          <div
            style={{
              // paddingInline: "15px",
              // paddingTop: "15px",

              display: "flex",
              gap: "25px",
              // gridTemplateColumns: "1fr 1fr",
              marginBottom: "45px",
              // height: "90vh",
              marginTop: "30px",
              marginLeft: "20px",
              scrollbarWidth: "thin",
            }}
          >
            <Box style={{ width: "44%" }}>
              <div
                style={{
                  margin: "5px",
                  color: "#247ba0",
                  // paddingBottom: "5px",
                  fontWeight: "600",
                }}
              >
                <span>Basic</span>
                {/* <hr style={{ width: "550px", marginBottom: "10px" }} /> */}
              </div>
              <Aligner>
                <Name>Job Name : </Name>

                {/* <input
                  type="text"
                  value={editName}
                  onChange={handleNameChange}
                  disabled
                /> */}
                <div className="value-JobView">
                  <span>{jobDetails?.name}</span>
                </div>
              </Aligner>
              <Aligner>
                <Name>Job Description : </Name>

                {/* <input
                  type="text"
                  value={description}
                  onChange={editDescription}
                  disabled={!toggleEditBtn}
                /> */}
                <div className="value-JobView">
                  <span>{jobDetails?.description}</span>
                </div>
              </Aligner>
              <Aligner>
                <Name>Product : </Name>

                {/* <input type="text" value={product} disabled /> */}
                <div className="value-JobView">
                  <span>{jobDetails?.product.name}</span>
                </div>
              </Aligner>

              <Aligner>
                <Name>Execution Engine : </Name>

                {/* <input
                  type="text"
                  value={ExeEngine}
                  onChange={editEngine}
                  disabled={!toggleEditBtn}
                /> */}

                <div className="value-JobView">
                  <span>{jobDetails?.executionEngine}</span>
                </div>
              </Aligner>
              <div
                style={{
                  margin: "5px",
                  color: "#247ba0",
                  // padding: "10px 0px",
                  marginTop: "35px",
                  fontWeight: "600",
                }}
              >
                <span>Advance: </span>
                {/* <hr
                style={{
                  width: "550px",
                  marginBottom: "10px",
                }}
              /> */}
              </div>

              <Aligner>
                <Name>Business : </Name>

                {/* <input
                  type="text"
                  value={editBusinessName}
                  onChange={editBusiness}
                  disabled
                /> */}

                <div className="value-JobView">
                  <span>{jobDetails?.product?.business?.name}</span>
                </div>
              </Aligner>
              <Aligner>
                <Name>Domain : </Name>

                {/* <input
                  type="text"
                  value={editDomainName}
                  onChange={editDomain}
                  disabled
                /> */}
                <div className="value-JobView">
                  <span>{jobDetails?.product.domain?.name}</span>
                </div>
              </Aligner>
              <Aligner>
                <Name>Environment : </Name>

                {/* <input
                  type="text"
                  value={environment}
                  onChange={editEnvironment}
                  disabled={!toggleEditBtn}
                /> */}

                <div className="value-JobView">
                  <span>{jobDetails?.environment}</span>
                </div>
              </Aligner>

              <Aligner>
                <Name>Run As Account : </Name>

                {/* <input
                  type="text"
                  value={runAccount}
                  onChange={editRunAccount}
                  disabled={!toggleEditBtn}
                /> */}

                <div className="value-JobView">
                  <span>{jobDetails?.runAsAccount}</span>
                </div>
              </Aligner>
              <Aligner>
                <Name>Start Date : </Name>

                {/* <input
                  type="text"
                  value={startDate}
                  onChange={editStartDate}
                  disabled={!toggleEditBtn}
                /> */}

                <div className="value-JobView">
                  <span>{jobDetails?.startDate}</span>
                </div>
              </Aligner>
              <Aligner>
                <Name>End Date : </Name>

                {/* <input
                  type="text"
                  value={endDate}
                  onChange={editEndDate}
                  disabled={!toggleEditBtn}
                /> */}

                <div className="value-JobView">
                  <span>{jobDetails?.endDate}</span>
                </div>
              </Aligner>
              <Aligner>
                <Name>Schedule : </Name>

                {/* <input
                  type="text"
                  value={schedule}
                  onChange={editSchedule}
                  disabled={!toggleEditBtn}
                /> */}

                <div className="value-JobView">
                  <span>{jobDetails?.schedule}</span>
                </div>
              </Aligner>
              <Aligner>
                <Name>SLA : </Name>

                {/* <input
                  type="text"
                  value={slaValue}
                  onChange={editSLA}
                  disabled={!toggleEditBtn}
                /> */}

                <div className="value-JobView">
                  <span>{jobDetails?.sla} Minutes</span>
                </div>
              </Aligner>
              <Aligner>
                <Name>Retry Count : </Name>

                {/* <input
                  type="text"
                  value={retryCount}
                  onChange={editRetryCount}
                  disabled={!toggleEditBtn}
                /> */}

                <div className="value-JobView">
                  <span>{jobDetails?.retryCount}</span>
                </div>
              </Aligner>
              <Aligner>
                <Name>Publish Message: </Name>

                {/* <input
                  type="text"
                  value={publishMessage}
                  onChange={editPublishMessage}
                  disabled={!toggleEditBtn}
                /> */}

                <div className="value-JobView">
                  <span>{jobDetails?.publishMessage}</span>
                </div>
              </Aligner>
              <Aligner>
                <Name>Job Owner : </Name>

                {/* <input
                  type="text"
                  value={jobOwner}
                  onChange={editJobOwner}
                  disabled={!toggleEditBtn}
                /> */}

                <div className="value-JobView">
                  <span>{jobDetails?.publishMessage}</span>
                </div>
              </Aligner>
              <Aligner>
                <Name>Time Zone : </Name>

                {/* <input
                  type="text"
                  value={timeZone}
                  onChange={editTimeZone}
                  disabled={!toggleEditBtn}
                /> */}

                <div className="value-JobView">
                  <span>{jobDetails?.timezone}</span>
                </div>
              </Aligner>
              <Aligner>
                <Name>Success Email: </Name>

                {/* <input
                  type="text"
                  value={successEmail}
                  onChange={editPublishMessage}
                  disabled={!toggleEditBtn}
                /> */}

                <div className="value-JobView">
                  <span>{jobDetails?.notificationSuccessEmail}</span>
                </div>
              </Aligner>
              <Aligner>
                <Name>Failed Email : </Name>

                {/* <input
                  type="text"
                  value={failureEmail}
                  onChange={editPublishMessage}
                  disabled={!toggleEditBtn}
                /> */}
                <div className="value-JobView">
                  <span>{jobDetails?.notificationFailureEmail}</span>
                </div>
              </Aligner>
              <Aligner>
                <Name>Missed SLA Email : </Name>

                {/* <input
                  type="text"
                  value={missedSLAEmail}
                  onChange={editPublishMessage}
                  disabled={!toggleEditBtn}
                /> */}
                <div className="value-JobView">
                  <span>{jobDetails?.notificationMissedSLAEmail}</span>
                </div>
              </Aligner>
              <Aligner>
                <Name>Target Load Method:</Name>

                {/* <input
                  type="text"
                  value={targetLoadMethod}
                  onChange={editTargetLoadMethod}
                  disabled
                /> */}

                <div className="value-JobView">
                  <span>{jobDetails?.targetLoadMethod}</span>
                </div>
              </Aligner>

              {/* <Box> */}
              {/* {toggleEditBtn ? (
                <Box className="button-box">
                  <button className="edit-button" onClick={updateData}>
                    Save
                  </button>
                  <button className="edit-button" onClick={toggleEdit}>
                    Cancel
                  </button>
                </Box>
              ) : ( */}

              {/* )} */}
              {/* </Box> */}
            </Box>
            {jobDetails && <JobViewTasks details={jobDetails} />}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default JobView;
